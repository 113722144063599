import React, {useState} from 'react'
import {motion, AnimatePresence} from 'framer-motion'
import classNames from 'classnames'
import {ArrowSmUpIcon} from '@heroicons/react/outline'

export const SpoilerText = ({maxChars = 150, text = '', children}) => {
    const [expanded, setExpanded] = useState(false)
    const toggleExpanded = () => setExpanded(!expanded)

    const summary = text.substring(0, maxChars).trim() + '...'

    const variants = {
        open: {height: 'auto'},
        closed: {height: 'auto'},
    }

    return (
        <div className='text-gray-600 dark:text-gray-400 transform transition-all'>
            <AnimatePresence>
                <motion.div
                    animate={expanded ? 'open' : 'closed'}
                    transition={{duration: 0.6}}
                    variants={variants}
                    className='overflow-hidden'
                >
                    {children(expanded ? text : summary)}
                </motion.div>
            </AnimatePresence>
            {text.length > maxChars ? (
                <ArrowSmUpIcon
                    onClick={toggleExpanded}
                    className={classNames(
                        'inline-flex flex-shrink-0 cursor-pointer w-5 h-5 text-blue-600 hover:underline focus:outline-none transform transition-all',
                        {
                            'rotate-180': !expanded,
                        }
                    )}
                />
            ) : null}
        </div>
    )
}
