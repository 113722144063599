import React, {useState, useMemo} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {SelectorIcon} from '@heroicons/react/solid'
import classNames from 'classnames'
import {usePopper} from 'react-popper'
import cn from 'classnames'
import {useOnClickOutside} from '@hooks'
import {CheckBox} from '@components/forms'
import GoogleTagManager from '@utils/googleUtils/googleTagManager'
import {SelectPortal} from '@components/forms/Selects/Select/SelectPortal'
import SelectBody from '@components/forms/Selects/Select/SelectBody'
import {MultiSelectBody} from '@components/forms/Selects/MultiSelect/MultiSelectBody'
import {MultiSelectPortal} from '@components/forms/Selects/MultiSelect/MultiSelectPortal'

const MultiSelect = ({
    label,
    className,
    options,
    onChange,
    children,
    disabled,
    placeholder,
    portal,
    helperText,
    error,
    selected = [],
    renderOptionsName,
    selectedInHelper = true,
    size = 'base',
}) => {
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        placement: 'bottom',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 0],
                },
            },
        ],
    })

    const [isOpen, setIsOpen] = useState(false)

    useOnClickOutside({current: referenceElement}, () => {
        setIsOpen(false)
    })

    useMemo(() => {
        if (error && helperText) {
            GoogleTagManager.dataLayer({
                event: 'mainEvent',
                eventCategory: 'Message - Front',
                eventAction: 'message',
                eventLabel: helperText,
                // email: userData.email,
                // user_id: userData.user_id,
            })
        }
    }, [error, helperText])

    const isSelected = (value) => {
        let fn = (el) => el === value
        if (value.key?.toString()) {
            fn = (el) => el.key === value.key
        }
        return !!selected.find(fn)
    }

    const handleSelect = (value) => {
        if (!isSelected(value)) {
            const selectedOptionsUpdated = [
                ...selected,
                options.find((el) =>
                    value.key?.toString() ? el.key === value.key : el === value
                ),
            ]
            onChange(selectedOptionsUpdated)
        } else {
            handleDeselect(value)
        }
        setIsOpen(true)
    }

    const handleDeselect = (value) => {
        const selectedOptionsUpdated = selected.filter((el) =>
            value.key?.toString() ? el.key !== value.key : el !== value
        )
        onChange(selectedOptionsUpdated)
        return setIsOpen(true)
    }

    const selectedOptionsName = useMemo(() => {
        return renderOptionsName
            ? renderOptionsName(selected)
            : selected
                  .map((option) => {
                      if (typeof option === 'object') return option.name
                      return option
                  })
                  .join(', ') || ''
    }, [selected, renderOptionsName])

    const handleClick = () => {
        setIsOpen(!isOpen)
    }

    const multiSelectBody = {
        setPopperElement,
        referenceElement,
        styles,
        attributes,
        options,
        size,
        portal,
        childElement: children,
        isSelected,
        handleSelect,
    }
    return (
        <Listbox
            value={selected}
            onChange={(value) => handleSelect(value)}
            open={isOpen}
            disabled={disabled}
        >
            {() => (
                <div className={cn(className, 'w-full')}>
                    {label?.length && (
                        <Listbox.Label
                            className={classNames(
                                'block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1',
                                {
                                    'opacity-50': disabled,
                                }
                            )}
                        >
                            {label}
                        </Listbox.Label>
                    )}
                    <div className='relative w-full'>
                        <Listbox.Button
                            as={'div'}
                            ref={setReferenceElement}
                            onClick={handleClick}
                            open={isOpen}
                            disabled={disabled}
                            className={classNames(
                                'flex justify-between bg-white relative min-h-[38px] w-full border border-gray-300 rounded-md shadow-sm text-left cursor-pointer focus:outline-none transition-all',
                                'dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600',
                                size === 'sm' && 'pl-3 pr-2.5 py-1.5 text-xs',
                                size === 'md' &&
                                    'pl-4 pr-3 py-2 text-sm font-medium leading-4',
                                size === 'base' &&
                                    'pl-5 pr-4 py-3 text-sm font-medium',
                                size === 'lg' &&
                                    'pl-5 pr-4 py-3 text-base font-medium',
                                size === 'xl' &&
                                    'pl-7 pr-6 py-3 text-base font-medium',
                                {
                                    'opacity-50': disabled,
                                    'dark:hover:bg-gray-600': !disabled,
                                }
                            )}
                        >
                            <span className='block truncate'>
                                {selectedOptionsName || (
                                    <span className={'text-gray-300'}>
                                        {placeholder}
                                    </span>
                                )}
                            </span>
                            <span className='flex items-center pointer-events-none'>
                                <SelectorIcon
                                    className='h-5 w-5 text-gray-400 dark:text-gray-200'
                                    aria-hidden='true'
                                />
                            </span>
                        </Listbox.Button>
                        {!!helperText && (
                            <p
                                className={cn(
                                    'mt-2 text-sm text-gray-500 dark:text-gray-400',
                                    {
                                        'text-red-500 dark:text-red-300': error,
                                    }
                                )}
                            >
                                {helperText}
                            </p>
                        )}
                        {portal ? (
                            <MultiSelectPortal {...multiSelectBody} />
                        ) : (
                            <MultiSelectBody {...multiSelectBody} />
                        )}
                        {selectedOptionsName && selectedInHelper && (
                            <div className='pt-1 text-sm text-gray-900 dark:text-gray-300'>
                                <span>{`Выбрано: ${selectedOptionsName}`}</span>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Listbox>
    )
}

export {MultiSelect}
