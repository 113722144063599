import React, {forwardRef, useMemo, useRef} from 'react'
import cn from 'classnames'
import {nanoid} from 'nanoid'
import GoogleTagManager from '@utils/googleUtils/googleTagManager'
import classNames from 'classnames'
export const CheckBox = forwardRef(
    (
        {
            className,
            disabled,
            name = 'comments',
            label = '',
            description,
            checked,
            onChange,
            right,
            helperText,
            error,
            helperClassName,
            containerClassName,
            ...rest
        },
        ref
    ) => {
        const id = useRef(nanoid(5))

        useMemo(() => {
            if (error && helperText) {
                GoogleTagManager.dataLayer({
                    event: 'mainEvent',
                    eventCategory: 'Message - Front',
                    eventAction: 'message',
                    eventLabel: helperText,
                    // email: userData.email,
                    // user_id: userData.user_id,
                })
            }
        }, [error, helperText])

        const checkBox = (
            <div className='flex items-center h-5'>
                <input
                    ref={ref}
                    id={id.current}
                    aria-describedby='comments-description'
                    name={name}
                    type='checkbox'
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                    className={cn(
                        className,
                        'disabled:opacity-50 h-4 w-4 text-blue-500 dark:text-blue-400 border-gray-300 dark:border-gray-600 rounded  cursor-pointer focus:ring-0 focus:outline-none focus:ring-offset-0 transition-colors ease-in-out duration-200',
                        {
                            'border-red-500 dark:border-red-300': error,
                        }
                    )}
                    {...rest}
                />
            </div>
        )

        return (
            <div
                className={classNames(
                    containerClassName,
                    'relative flex flex-col items-start '
                )}
            >
                <div className={'flex items-start '}>
                    {!right && checkBox}
                    {(label || description) && (
                        <div
                            className={cn('text-sm', {
                                'ml-3': !right,
                                'mr-3': right,
                                'opacity-50': disabled,
                            })}
                        >
                            {label && (
                                <label
                                    htmlFor={id.current}
                                    className='font-medium cursor-pointer text-gray-700 dark:text-gray-300'
                                >
                                    {label}
                                </label>
                            )}
                            {description && (
                                <p
                                    id={`${id.current}-description`}
                                    className='text-gray-500 dark:text-gray-500'
                                >
                                    {description}
                                </p>
                            )}
                        </div>
                    )}
                    {right && checkBox}
                </div>
                {!!helperText && (
                    <p
                        className={cn(
                            'mt-2 text-sm text-gray-500 dark:text-gray-400',
                            {
                                'text-red-500 dark:text-red-300': error,
                            },
                            helperClassName
                        )}
                    >
                        {helperText}
                    </p>
                )}
            </div>
        )
    }
)
