export class PollingV2 {
    name
    delay
    pollingFunction
    setProcessing
    restartProcess
    intervals = new Map()
    timeouts
    onCheckResult
    isControlPolling = false
    isPooling = false

    constructor({
        pollingFunction,
        delay = 300,
        setProcessing = () => {},
        name = 'Polling',
        restartProcess = true,
        isControlPolling = false,
        onCheckResult = () => {},
    }) {
        this.pollingFunction = pollingFunction
        this.delay = delay
        this.setProcessing = setProcessing
        this.name = name
        this.restartProcess = restartProcess
        this.onCheckResult = onCheckResult
        this.isControlPolling = isControlPolling
    }

    startPolling = async (...params) => {
        if (this.isPooling) {
            this.stopPolling(true)
        }
        if (this.isControlPolling) {
            if (!this.timeouts) {
                this.setProcessing(true)
                this.isPooling = true
            }
            this._controlStart(...params)
        } else {
            if (!this.intervals.size) {
                this.setProcessing(true)
                this.isPooling = true
            }
            this._noControlStart(...params)
        }
    }

    _controlStart = async (...params) => {
        this.timeouts = setTimeout(
            async () => {
                this.setProcessing(true)
                this.isPooling = true
                try {
                    const data = await this.pollingFunction(...params)
                    if (this.onCheckResult(data)) {
                        this._controlStart(...params)
                    } else {
                        this.stopPolling()
                    }
                } catch (error) {
                    console.error(
                        this.name,
                        {pollingFunction: this.pollingFunction},
                        error
                    )
                    this.stopPolling()
                }
            },
            !this.timeouts ? 0 : this.delay
        )
    }

    _noControlStart = async (...params) => {
        const intervalId = setInterval(async () => {
            this.setProcessing(true)
            this.isPooling = true
            try {
                await this.pollingFunction(...params)
            } catch (error) {
                console.error(
                    this.name,
                    {pollingFunction: this.pollingFunction},
                    error
                )
            }
        }, this.delay)
        this.intervals.set(intervalId, null)
    }

    stopPolling = (skipStopProcess) => {
        if (this.isControlPolling) {
            clearTimeout(this.timeouts)
            this.timeouts = undefined
        } else {
            for (const intervalId of this.intervals.keys()) {
                clearInterval(intervalId)
                this.intervals.delete(intervalId)
            }
        }
        this.isPooling = false
        if (!skipStopProcess) {
            this.setProcessing(false)
        }
    }
}
