import {FilterIcon} from '@heroicons/react/outline'
import React from 'react'

export const ResetFilter = () => {
    return (
        <div className={'flex items-center space-x-2'}>
            <span>Отключить фильтр</span>
            <FilterIcon className={'w-4 h-4 text-blue-500 mr-2'} />
        </div>
    )
}
