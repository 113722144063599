import {AUTH_CLIENT_ID, AUTH_CLIENT_SECRET} from '@utils/constants/envConstants'

export const COMMON_PROPS = {
    client_id: process.env[AUTH_CLIENT_ID],
    client_secret: process.env[AUTH_CLIENT_SECRET],
}

export const marketplaceName = {
    Ozon: 'Ozon',
    Wildberries: 'Wildberries',
    Aliexpress: 'Aliexpress',
    Yandex: 'Yandex',
    Demo: 'Demo',
    Ethalon: 'Ethalon',
}
export const marketplaceId = {
    Ozon: 1,
    Wildberries: 2,
    Aliexpress: 4,
    Yandex: 10,
    Demo: 20,
    Ethalon: 21,
}
export const marketplaceNameById = {
    1: 'Ozon',
    2: 'Wildberries',
    4: 'Aliexpress',
    10: 'Yandex',
    20: 'Demo',
    21: 'Ethalon',
}

export const errors = {
    cancelToken: 'Cancellation',
}

export const IMPORT_STATUS_KEY = {
    ready: 'ready',
    imported: 'imported',
    error: 'error',
}

export const USER_ROLE = {
    admin: 'admin',
    manager: 'manager',
    user: 'user',
}

export const errorOldAcc = 'Для обеспечения высокого уровня безопасности'

export const adminFilterStatus = {
    main: 'main',
    mp: 'mp',
    tariff: 'tariff',
}
export const xlsStatus = {
    failed: 'failed', //ошибка при генерации файла,
    completed: 'completed', //успешная генерация файла, возможно скачивание
    pending: 'pending', //задачу только поставили в очередь
    inProcess: 'in_process', //в процессе генерации
    uploading: 'uploading', //процесс загрузки файла в S3
}
export const reportsStatus = {
    preparation: 0, //подготовка
    sentToKafka: 1, //отправлено в кафку
    executedOnTheHandler: 2, //исполняется на обработчике
    failure: 9, //неудача
    warning: 8, //неудача
    success: 10, //успех
}

export const transStatuses = {
    sale: 'sale',
    cancel: 'cancel',
    return: 'return',
    promo: 'promo',
    refund: 'refund',
    advert: 'advert',
    penalty: 'penalty',
}
export const transStatusesRu = {
    sale: 'продажа',
    cancel: 'отмена',
    return: 'возврат',
    promo: 'продвижение',
    refund: 'невыкуп',
    advert: 'реклама',
    penalty: 'штраф',
}

export const QUERY_TYPE_PARAMS = {
    date: 'date',
    array: 'array',
    string: 'array',
    boolean: 'boolean',
}
export const STATUSES_FILE_MAP = {
    fileDrop: 'fileDrop',
    fileAdded: 'fileAdded',
    process: 'process',
    checkStatus: 'checkStatus',
}
export const blockedTariff = {
    blocked: true,
    internal: true,
}

export const COST_AVAILABILITY = {
    noCost: 'no_cost',
    partCost: 'part_cost',
    fullCost: 'full_cost',
}

export const ONBOARD_STEP = {
    mpAdded: 'mp_added',
    mpImportComplete: 'mp_import_complete',
    costsAdded: 'costs_added',
    unitChecked: 'unit_checked',
    profitChecked: 'profit_checked',
    abcChecked: 'abc_checked',
    forecastChecked: 'forecast_checked',
    regionalChecked: 'regional_checked',
    finalClosed: 'final_closed',
    completed: 'completed',
}

export const STATUSES_VARIANT = {
    success: 10,
    failed: 9,
}

export const MIN_IMPORT_STATUSES = 4

export const YANDEX_DELIVERY_TYPE = {
    FBS: true,
    DBS: true,
    FBY: true,
}

export const ONBOARDING_KEY = {
    margin: 'M',
    unit: 'U',
    market: 'Market',
}

export const CONNECTED_MP_ACTIONS = {
    open: 'Open',
    fill: 'Fill',
    start: 'Start',
    complete: 'Complete',
    fail: 'Fail',
}

export const SUCCESS_SUPPORT_MESSAGE_SEND =
    'Мы получили вашу заявку и скоро с вами свяжемся. Работаем с понедельника по пятницу, с 10:00 до 19:00 по Москве.'

export const BLOCKED_NAV_ITEM_TOOLTIP =
    'Для начала работы с отчетами подключите хотя бы один кабинет маркетплейса.'

export const BLOCKED_NAV_ITEM = {
    dashboard: true,
    margin: true,
    finance: true,
    productAnalytics: true,
    deliveriesRegions: true,
    notificationTelegram: true,
    accountTariff: true,
    helpList: true,
    promotion: true,
}

export const BLOCKED_PROFILE_ITEM = {
    tariff: true,
    settings: true,
}

export const abcOptions = [
    {
        key: 'a',
        name: 'A',
    },
    {
        key: 'b',
        name: 'B',
    },
    {
        key: 'c',
        name: 'C',
    },
]
