import React from 'react'
import classNames from 'classnames'

export const ConfirmBannerWrapper = ({children, red}) => {
    return (
        <div
            className={classNames(
                'relative isolate flex items-center justify-center gap-x-6 overflow-hidden px-6 py-2.5 sm:px-3.5',
                {
                    'bg-[#dbe4eb] dark:bg-[#dbe4eb]': !red,
                    'bg-red-300 dark:bg-red-500': red,
                }
            )}
        >
            {children}
        </div>
    )
}
