import React from 'react'
import {ChevronDownIcon, ChevronUpIcon} from '@heroicons/react/outline'
import cn from 'classnames'

const ChevTrigger = ({
    className,
    classNameValue,
    classNameChev,
    opened,
    value,
}) => {
    return (
        <span className={cn(className, 'flex items-center cursor-pointer')}>
            <span className={cn(classNameValue, `text-left truncate`)}>
                {value}
            </span>
            {opened ? (
                <ChevronUpIcon
                    className={cn(
                        classNameChev,
                        'flex-shrink-0 w-4 h-4 text-gray-500 ml-1'
                    )}
                />
            ) : (
                <ChevronDownIcon
                    className={cn(
                        classNameChev,
                        'flex-shrink-0 w-4 h-4 text-blue-500 ml-1'
                    )}
                />
            )}
        </span>
    )
}

export {ChevTrigger}
