import {makeAutoObservable, toJS} from 'mobx'
import {format} from 'date-fns'
import {ru} from 'date-fns/locale'
import {TokenService} from '@services/TokenService'

const MAX_LENGTH_MESSAGE = 3800

class ErrorStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        this.userStore = rootStore.userStore
        makeAutoObservable(this)

        // Создаем новый экземпляр WorkerExecutor с кодом функции для worker
        this.errorWorker = new Worker(
            new URL('../utils/worker/errorWorker.js', import.meta.url)
        )
    }

    hasError = false
    error = null
    errorInfo = null

    setHasError = (value = false) => {
        this.hasError = value
    }
    setError = (value = null) => {
        this.error = value
    }
    setErrorInfo = (value = null) => {
        this.errorInfo = value
    }

    resetError = () => {
        this.setHasError()
        this.setError()
        this.setErrorInfo()
    }

    get userData() {
        return {
            email: toJS(this.userStore.userData.email),
            phone_number: toJS(this.userStore.userData.phone_number),
            date: format(new Date(), "yyyy-MM-dd kk':'mm':'ss", {locale: ru}),
            pathname: window.location.pathname,
        }
    }

    sendError = () => {
        if (TokenService.isEmulationExists()) return

        const errorData = {
            message: this.error.message,
            stack: this.error.stack,
            token: TokenService.getToken(), // Убедитесь, что передаете токен, если нужно
        }

        // Передаем только нужные данные
        const userData = this.userData

        const taskData = {
            errorData,
            userData,
            processEnv: process.env,
            MAX_LENGTH_MESSAGE: MAX_LENGTH_MESSAGE,
        }

        // Отправляем задачу на выполнение в worker
        this.errorWorker.postMessage({method: 'sendError', data: taskData})
    }

    sendErrorGTM = (errorText, response) => {
        if (TokenService.isEmulationExists()) return

        const errorData = {
            errorText,
            config: response
                ? {
                      data: response.config.data,
                      headers: response.config.headers,
                      statusText: response.statusText,
                  }
                : null,
            token: TokenService.getToken(), // Токен, если нужно
        }

        const userData = this.userData
        const taskData = {
            errorData,
            userData,
            processEnv: process.env,
            MAX_LENGTH_MESSAGE: MAX_LENGTH_MESSAGE,
        }
        // Отправляем задачу на выполнение в worker
        this.errorWorker.postMessage({method: 'sendErrorGTM', data: taskData})
    }
}

export default ErrorStore
