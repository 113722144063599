import {action, makeObservable, observable} from 'mobx'

import CatsMatchingService from '@services/CatsMatchingService'
import {PaginationStore} from '../../../../../store/PaginationStore'

//constants
const initialCat = {
    id: 0,
    name: 'Выберите категорию',
}

class MasterCatsStore {
    constructor(rootStore, parentStore) {
        this.rootStore = rootStore
        this.parentStore = parentStore

        makeObservable(this, {
            blockedProducts: observable,
            partLinkedMasters: observable,
            unlinkedMasters: observable,
            masterCats: observable,
            activeCatIndex: observable,
            activeCatsTree: observable,
            activeCatId: observable,
            unlinkedProducts: observable,
            setBlockedProducts: action,
            setPartLinkedMasters: action,
            setUnlinkedMasters: action,
            setMasterCats: action,
            setActiveCatIndex: action,
            setActiveCatsTree: action,
            spliceActiveCatsTree: action,
            pushActiveCatsTree: action,
            setActiveCatId: action,
            setUnlinkedProducts: action,
            onMasterCatChange: action,
            setActiveCatsLoading: action,
            setActiveCatsPagination: action,
            setActiveCatsOptions: action,
        })
    }

    blockedProducts = 0
    partLinkedMasters = 0
    unlinkedMasters = 0
    masterCats = []
    activeCatIndex = 0
    activeCatsTree = []
    unlinkedProducts = 0
    activeCatId = 0

    setBlockedProducts = (number = 0) => {
        this.blockedProducts = number
    }

    setPartLinkedMasters = (number = 0) => {
        this.partLinkedMasters = number
    }

    setUnlinkedMasters = (number = 0) => {
        this.unlinkedMasters = number
    }

    setMasterCats = (array = []) => {
        this.masterCats = array
    }

    setActiveCatIndex = (index = 0) => {
        this.activeCatIndex = index
    }

    setActiveCatId = (index = 0) => {
        this.activeCatId = index
    }

    onNextCatClick = () => {
        if (this.masterCats.length > this.activeCatIndex + 1) {
            this.setActiveCatIndex(this.activeCatIndex + 1)
        } else {
            this.setActiveCatIndex(0)
        }

        this.mergeActiveCatsTreeData(this.masterCats[this.activeCatIndex])
        this.setUnlinkedProducts(
            this.masterCats[this.activeCatIndex].unlinkedProducts
        )
    }

    setActiveCatsTree = (array = []) => {
        this.activeCatsTree = array
    }

    setActiveCatsLoading = (index, loading) => {
        this.activeCatsTree[index].loading = loading
    }

    setActiveCatsPagination = (index, pagination) => {
        this.activeCatsTree[index].paginationStore.setPagination(pagination)
    }

    setActiveCatsOptions = (index, options) => {
        this.activeCatsTree[index].options = options
    }

    spliceActiveCatsTree = (index) => {
        this.activeCatsTree.splice(index, this.activeCatsTree.length - index)
    }

    pushActiveCatsTree = (object) => {
        this.activeCatsTree.push(object)
    }

    setUnlinkedProducts = (number = 0) => {
        this.unlinkedProducts = number
    }

    onMasterCatChange = async (index, option) => {
        this.spliceActiveCatsTree(index + 1)
        this.activeCatsTree[index].selected = option
        const payload = {
            limit: 10,
            page: 1,
            value: '',
        }
        const data = await this.getMasterCatId(payload, option?.id)
        this.pushActiveCatsTree({
            paginationStore: new PaginationStore(),
            loading: false,
            options: data?.childs,
            selected: initialCat,
            parentId: option?.id,
        })
        this.setActiveCatId(data.id)
        this.setUnlinkedProducts(data?.unlinkedProducts)
    }

    onMasterCatSearch = async (index, option, value, page) => {
        const payload = {
            limit: page ? page * 10 : 10,
            page: 1,
            value: value,
        }
        this.setActiveCatsLoading(index, true)
        let data = {}
        try {
            if (option.parentId) {
                data = await this.getMasterCatId(payload, option.parentId)
            } else {
                data = await this.getMasterTopCats(payload)
            }
            const {limit, page, total, totalPages} = data
            this.setActiveCatsPagination(index, {
                limit,
                page,
                total,
                totalPages,
            })
            this.setActiveCatsOptions(index, data?.childs)
        } catch (e) {
            console.log(e)
        } finally {
            this.setActiveCatsLoading(index, false)
        }
    }
    collectCats = (cat, data) => {
        const {limit, page, total, totalPages} = data
        const cats = {
            paginationStore: new PaginationStore(),
            loading: false,
            options: data?.childs || [],
            selected: {id: cat.id, name: cat.name},
            parentId: cat.parentId,
        }
        cats.options = data?.childs
        cats.selected = {id: cat.id, name: cat.name}
        cats.paginationStore.setPagination({
            limit,
            page,
            total,
            totalPages,
        })
        return cats
    }

    mergeActiveCatsTreeData = async (source) => {
        this.setActiveCatId(source.id)
        const catsTree = await Promise.all(
            source.mastercategoryTree.map(async (cat) => {
                const payload = {
                    limit: 10,
                    page: 1,
                    value: '',
                }
                if (cat?.parentId) {
                    const {data} = await CatsMatchingService.getMasterCatId(
                        payload,
                        cat.parentId
                    )
                    return this.collectCats(cat, data)
                } else {
                    const {data} = await CatsMatchingService.getMasterTopCats(
                        payload
                    )
                    return this.collectCats(cat, data)
                }
            })
        )
        this.setActiveCatsTree(catsTree)
    }

    getMasterUnlinkedCats = async () => {
        try {
            const {data} = await CatsMatchingService.getMasterUnlinkedCats()
            this.setMasterCats(data?.masterCategories || [])
            this.setActiveCatIndex(0)
            if (!!data?.masterCategories?.length) {
                await this.postMasterUnlinkedCats(
                    data?.masterCategories[this.activeCatIndex].id
                )
            } else {
                this.setActiveCatsTree([])
                const {dataTopCats} = await this.getMasterTopCats()
                this.pushActiveCatsTree({
                    options: dataTopCats?.childs,
                    selected: initialCat,
                    paginationStore: new PaginationStore(),
                    loading: false,
                    parentId: null,
                })
            }

            this.setUnlinkedProducts(
                this.masterCats[this.activeCatIndex]?.unlinkedProducts
            )
        } catch (error) {
            console.log(error)
        }
    }

    postMasterUnlinkedCats = async (id) => {
        try {
            const {data} = await CatsMatchingService.postMasterUnlinkedCats({
                id,
            })
            await this.mergeActiveCatsTreeData(data)
            // this.setMasterCats(data?.masterCategories || [])
            // this.setActiveCatIndex(0)
            // if (!!data?.masterCategories?.length) {
            //     await this.mergeActiveCatsTreeData(
            //         this.masterCats[this.activeCatIndex]
            //     )
            // } else {
            //     await this.getMasterTopCats()
            // }

            this.setUnlinkedProducts(
                this.masterCats[this.activeCatIndex]?.unlinkedProducts
            )
        } catch (error) {
            console.log(error)
        }
    }

    getMasterUnlinkedHeader = async () => {
        try {
            const {data} = await CatsMatchingService.getMasterUnlinkedHeader()
            this.setBlockedProducts(data?.blockedProducts || 0)
            this.setPartLinkedMasters(data?.partLinkedMasters || 0)
            this.setUnlinkedMasters(data?.unlinkedMasters || 0)
        } catch (error) {
            console.log(error)
        }
    }

    getMasterTopCats = async (payload) => {
        if (!payload) {
            payload = {
                limit: 10,
                page: 1,
                value: '',
            }
        }
        try {
            const {data} = await CatsMatchingService.getMasterTopCats(payload)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    getMasterCatId = async (payload, catId) => {
        try {
            const {data} = await CatsMatchingService.getMasterCatId(
                payload,
                catId
            )
            return Promise.resolve(data)
        } catch (error) {
            console.log(error)
            return Promise.reject(error)
        }
    }
}

export default MasterCatsStore
