import React from 'react'

const ErrorBlock = ({error}) => {
    return (
        <div className={'text-sm text-red-500 dark:text-red-300 text-center'}>
            {error}
        </div>
    )
}

export default ErrorBlock
