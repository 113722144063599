//libs
import {makeObservable, observable, action, computed} from 'mobx'

//services
import AttrsMatchingService from '@services/AttrsMatchingService/AttrsMatchingService'

//constants
const initialFeed = {
    id: 0,
    name: 'Выберите фид',
}

const initialCat = {
    id: 0,
    name: 'Выберите категорию',
}

const initialAttr = {
    id: 0,
    name: 'Выберите свойство',
}

const initialPagination = {
    total: 0,
    page: 1,
}

class FeedAttrsStore {
    constructor(rootStore, parentStore) {
        this.rootStore = rootStore
        this.parentStore = parentStore

        makeObservable(this, {
            edit: observable,
            links: observable,
            linksToDelete: observable,
            feed: observable,
            feedCats: observable,
            feedAttr: observable,
            feedAttrDict: observable,
            checkedFeedItems: observable,
            setEdit: action,
            setLinks: action,
            setFeedOptions: action,
            changeFeed: action,
            setFeedCats: action,
            pushFeedCats: action,
            spliceFeedCats: action,
            onFeedCatChange: action,
            setFeedAttrOptions: action,
            handleCheckFeedDictItem: action,
            clearCheckedFeedItems: action,
            changeFeedAttr: action,
            setFeedAttrDictOptions: action,
            handleFeedDictSearch: action,
            linkedItemsValue: computed,
        })
    }

    edit = false

    links = []

    linksToDelete = []

    feed = {
        options: [],
        selected: initialFeed,
    }

    feedCats = []

    feedAttr = {
        options: [],
        selected: initialAttr,
    }

    feedAttrDict = {
        options: [],
        searchString: '',
        pagination: initialPagination,
    }

    checkedFeedItems = []

    setEdit = (value = true) => {
        this.edit = value
    }

    setLinks = (array = []) => {
        this.links = array
    }

    onFeedEdit = (item) => {
        this.parentStore.stores.masterAttrsStore.onMasterEdit(
            item?.masterItemId,
            'feed'
        )
    }

    setFeedOptions = (array = []) => {
        this.feed.options = array
    }

    mergeFeedsData = (data) => {
        return data.feeds.map((feed) => {
            return {id: feed.feedUuid, name: feed.name}
        })
    }

    changeFeed = (value) => {
        this.feed.selected = value
    }

    handleChangeFeed = (value) => {
        this.changeFeed(value)
        this.getFeedTopCats()
    }

    setFeedCats = (array = []) => {
        this.feedCats = array
    }

    pushFeedCats = (obj) => {
        this.feedCats.push(obj)
    }

    spliceFeedCats = (index) => {
        this.feedCats.splice(index, this.feedCats.length - index)
    }

    onFeedCatChange = (index, child) => {
        this.spliceFeedCats(index + 1)
        this.feedCats[index].selected = child
        this.getFeedCats(child.id)
    }

    setFeedAttrOptions = (array = []) => {
        this.feedAttr.options = array
    }

    changeFeedAttr = (value) => {
        this.feedAttr.selected = value
    }

    handleChangeFeedAttr = (value) => {
        this.changeFeedAttr(value)
        if (value.id !== 0) {
            this.getFeedAttrDict()
        }
        this.clearCheckedFeedItems()
    }

    handleFeedDictSearch = (e) => {
        this.feedAttrDict.searchString = e.target.value
        this.getFeedAttrDict(e.target.value)
    }

    setFeedAttrDictOptions = (array = []) => {
        this.feedAttrDict.options = array
    }

    handleCheckFeedDictItem = (item, checked) => {
        if (this.edit) {
            const feedIndex = this.links.findIndex(
                (feed) => String(feed.feedId) === String(this.feed.selected.id)
            )
            const linkIndex = this.links[feedIndex]?.linked?.findIndex(
                (linkedItem) => String(linkedItem.value) === String(item.value)
            )

            if (linkIndex >= 0) {
                const isDelete = Boolean(
                    this.links[feedIndex].linked[linkIndex]?.delete
                )
                this.links[feedIndex].linked[linkIndex].delete = !isDelete
                return
            }
        }

        if (!this.checkedFeedItems.includes(item)) {
            if (checked) {
                this.checkedFeedItems.push(item)
            }
        } else {
            if (!checked) {
                const index = this.checkedFeedItems.findIndex(
                    (arrItem) => arrItem === item
                )
                this.checkedFeedItems.splice(index, 1)
            }
        }
    }

    isFeedDictItemChecked = (item) => {
        if (this.edit) {
            const feedLinks = this.links.find(
                (feed) => String(feed.feedId) === String(this.feed.selected.id)
            )?.linked
            const link = feedLinks?.find(
                (linkedItem) => String(linkedItem.value) === String(item.value)
            )

            return (
                this.checkedFeedItems.includes(item) || (link && !link?.delete)
            )
        }

        return this.checkedFeedItems.includes(item) || item?.isLinked
    }

    isFeedDictItemDisabled = (item) => {
        if (this.edit) {
            const feedLinks = this.links.find(
                (feed) => String(feed.feedId) === String(this.feed.selected.id)
            )?.linked
            const link = feedLinks?.find(
                (linkedItem) => String(linkedItem.value) === String(item.value)
            )

            return item?.isLinked && !link
        }

        return item?.isLinked
    }

    clearCheckedFeedItems = () => {
        this.checkedFeedItems = []
    }

    getFeedSources = async () => {
        try {
            const {data} = await AttrsMatchingService.getFeedSources()
            this.setFeedOptions(this.mergeFeedsData(data))
        } catch (error) {
            console.log(error)
        }
    }

    getFeedTopCats = async () => {
        this.clearCheckedFeedItems()

        try {
            const {
                data: {childs},
            } = await AttrsMatchingService.getFeedTopCats(this.feed.selected.id)
            this.setFeedCats([])
            this.setFeedAttrOptions([])
            this.pushFeedCats({
                options: childs,
                selected: initialCat,
            })
        } catch (error) {
            console.log(error)
        }
    }

    getFeedCats = async (catId) => {
        this.clearCheckedFeedItems()

        try {
            const {
                data: {id, parentId, childs, attrs},
            } = await AttrsMatchingService.getFeedCats(
                this.feed.selected.id,
                catId
            )
            this.pushFeedCats({
                options: childs,
                selected: initialCat,
                id,
                parentId,
            })
            this.setFeedAttrDictOptions([])
            if (attrs?.length) {
                this.setFeedAttrOptions(attrs)
            } else {
                this.setFeedAttrOptions([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    getFeedAttrDict = async (search = '') => {
        this.clearCheckedFeedItems()

        const query = search
            ? {
                  filterByName: search,
              }
            : undefined

        try {
            const {
                data: {items},
            } = await AttrsMatchingService.getFeedAttrDict(
                this.feed.selected.id,
                this.feedAttr.selected.id,
                query
            )
            this.setFeedAttrDictOptions(items)
        } catch (error) {
            console.log(error)
        }
    }

    get linkedItemsValue() {
        return this.checkedFeedItems.map((item) => item.value)
    }

    postFeedItemsMatching = async () => {
        if (this.edit) {
            const itemsId = []

            this.links.forEach((feed) => {
                feed.linked.forEach((item) => {
                    itemsId.push(item.id)
                })
            })

            const deleteQuery = {
                master_item_id:
                    this.parentStore.stores.masterAttrsStore.editableItemId,
                dictionary_items_id: itemsId,
                feed_attr_id: this.feedAttr.selected.id,
            }
            try {
                await AttrsMatchingService.putModifyLinks(deleteQuery)
            } catch (error) {
                return Promise.reject(error)
            }
        }

        const body = {}
        if (this.feed.selected?.id)
            Object.assign(body, {feed_id: this.feed.selected.id})
        if (this.linkedItemsValue.length)
            Object.assign(body, {linked_items_value: this.linkedItemsValue})
        if (this.feedAttr.selected?.id)
            Object.assign(body, {linker_attr_id: this.feedAttr.selected.id})
        if (this.parentStore.stores.masterAttrsStore.masterAttr.selected?.id)
            Object.assign(body, {
                master_attr:
                    this.parentStore.stores.masterAttrsStore.masterAttr.selected
                        .id,
            })
        if (
            this.parentStore.stores.masterAttrsStore.masterAttrDict.selected?.id
        )
            Object.assign(body, {
                master_item:
                    this.parentStore.stores.masterAttrsStore.masterAttrDict
                        .selected.id,
            })

        try {
            const {data} = await AttrsMatchingService.postAttrsMatching(body)
            await this.getFeedAttrDict()
            await this.parentStore.stores.masterAttrsStore.getMasterAttrDict()
            this.clearCheckedFeedItems()
        } catch (error) {
            console.log(error)
        }

        this.setEdit(false)
        this.setLinks([])
        this.parentStore.stores.masterAttrsStore.setEdit(false)
    }
}

export default FeedAttrsStore
