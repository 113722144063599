import {ApiFactory} from '@services/ApiFactory'
import {baseConfig} from '@services/apiConfigs'
import {YmlParserUrl} from '../../routes-enums'

const ymlParserInstance = ApiFactory.createApi(baseConfig)

class YmlParserService {
    static getYmlLink = async (id) => {
        return await ymlParserInstance.get(YmlParserUrl.ymlLinkId(id))
    }

    static getAllYmlLinks = async () => {
        return await ymlParserInstance.get(YmlParserUrl.ymlLink)
    }

    static postYmlLink = async (payload) => {
        return await ymlParserInstance.post(YmlParserUrl.ymlLink, payload)
    }

    static editYmlLink = async (id, payload) => {
        return await ymlParserInstance.put(YmlParserUrl.ymlLinkId(id), payload)
    }

    static deleteYmlLink = async (id) => {
        return await ymlParserInstance.delete(YmlParserUrl.ymlLinkId(id))
    }

    static deleteAllYmlLinks = async () => {
        return await ymlParserInstance.delete(YmlParserUrl.ymlLink)
    }

    static getLinkStatus = async (id) => {
        return await ymlParserInstance.get(YmlParserUrl.ymlLinkStatusId(id))
    }
}

export default YmlParserService
