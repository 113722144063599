import {observer} from 'mobx-react'
import Screen1 from '../../images/withoutLegal_1.png'
import Screen2 from '../../images/withoutLegal_2.png'
import {ImportWbFileUpload} from '../ImportWbFileUpload'
import React from 'react'

export const ImportWbWithoutLegalFiles = observer(({xlsUploadFileStore}) => {
    return (
        <ul
            className={
                'text-gray-700 dark:text-gray-300 list-decimal ml-4 mt-2 space-y-2'
            }
            role='list'
        >
            <li>
                Выберите кабинет и укажите юр. лицо, которое будет привязано к
                этому кабинету. Перейдите в раздел "Финансовые отчёты" Кабинета
                Продавца Wildberries и скопируйте название в столбце Юридическое
                лицо
            </li>
            <div className={'flex items-center'}>
                <img className={'max-w-4xl'} src={Screen1} alt={''} />
            </div>
            <li>
                Выгрузите в Excel список финансовых отчетов в разделе
                "Финансовые отчёты" Кабинет Продавца Wildberries (кнопка Скачать
                Excel)
            </li>
            <div className={'flex items-center'}>
                <img className={'max-w-4xl'} src={Screen2} alt={''} />
            </div>
            <li>Загрузите полученный файл в формате Excel БЕЗ ИЗМЕНЕНИЙ.</li>
            <ImportWbFileUpload
                uploadFileStore={xlsUploadFileStore}
                extension={'xlsx'}
                accept={[
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                ]}
            />

            <li>
                Дождитесь обработки файла, не переходите на другую страницу. В
                всплывающем окне подтвердите привязку юр.лица к кабинету, если
                данные корректны или отредактируйте название юр.лица, если
                данные не совпали
                <br />
                <br />
                После подтверждения юр.лица вам будет доступна загрузка фин.
                отчетов
            </li>
        </ul>
    )
})
