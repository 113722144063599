import React, {Fragment, useCallback} from 'react'
import {Transition} from '@headlessui/react'
import {XIcon} from '@heroicons/react/outline'
import cn from 'classnames'

const OverlayBody = ({
    open,
    setOpen,
    onClose,
    children,
    title,
    size = 'md',
}) => {
    const handleClose = useCallback(() => {
        if (typeof onClose === 'function') onClose()
        else setOpen(false)
    }, [])

    return (
        <Transition.Root show={open} as={Fragment}>
            <div className='fixed inset-0 overflow-hidden z-50'>
                <div className='absolute inset-0 overflow-hidden'>
                    <Transition.Child
                        as={Fragment}
                        enter='ease-in-out duration-500'
                        enterFrom='opacity-0'
                        enterTo='opacity-100'
                        leave='ease-in-out duration-500'
                        leaveFrom='opacity-100'
                        leaveTo='opacity-0'
                    >
                        <div
                            onClick={handleClose}
                            className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'
                        />
                    </Transition.Child>

                    <div className='fixed inset-y-0 right-0 sm:pl-16 max-w-full z-[150] flex'>
                        <Transition.Child
                            as={Fragment}
                            enter='transform transition ease-in-out duration-500 sm:duration-700'
                            enterFrom='translate-x-full'
                            enterTo='translate-x-0'
                            leave='transform transition ease-in-out duration-500 sm:duration-700'
                            leaveFrom='translate-x-0'
                            leaveTo='translate-x-full'
                        >
                            <div
                                className={cn('w-screen', {
                                    'w-screen sm:max-w-2xl': size === 'lg',
                                    'w-screen sm:max-w-5xl': size === 'xl',
                                    'w-screen sm:max-w-md': size === 'md',
                                    'w-screen': size === 'full',
                                })}
                            >
                                <div className='h-full flex flex-col py-6 bg-white dark:bg-gray-700 shadow-xl overflow-y-scroll'>
                                    <div className='px-4 sm:px-6'>
                                        <div className='flex items-start justify-between'>
                                            <div className='text-xl font-medium text-gray-900 dark:text-gray-100'>
                                                {title}
                                            </div>
                                            <div className='ml-3 h-7 flex items-center'>
                                                <button
                                                    type='button'
                                                    className='bg-white dark:bg-gray-700 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none'
                                                    onClick={handleClose}
                                                >
                                                    <span className='sr-only'>
                                                        Close panel
                                                    </span>
                                                    <XIcon
                                                        className='h-6 w-6'
                                                        aria-hidden='true'
                                                    />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-6 relative flex-1 px-4 sm:px-6'>
                                        {/* Replace with your content */}
                                        {/*<div className='absolute inset-0 px-4 sm:px-6'>*/}
                                        {/*    <div*/}
                                        {/*        className='h-full border-2 border-dashed border-gray-200'*/}
                                        {/*        aria-hidden='true'*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        {/* /End replace */}
                                        {children}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </div>
        </Transition.Root>
    )
}

export {OverlayBody}
