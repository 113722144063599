import React from 'react'
import {observer} from 'mobx-react'
import {useSelector} from '@hooks'
import {Header} from '@components/navigation'
import {ContainerFullOnMobile} from '@components/layout'
import IntegrationsContent from '@views/Integrations/Integrations/IntegrationsContent'

const Integrations = observer(({setSidebarOpen}) => {
    const {myProfileOptions} = useSelector((store) => store.layoutSidebarStore)

    return (
        <>
            <Header
                setSidebarOpen={setSidebarOpen}
                menuOptions={myProfileOptions}
                title={'Интеграции'}
            />
            <ContainerFullOnMobile className='py-6 flex-grow'>
                <IntegrationsContent />
            </ContainerFullOnMobile>
        </>
    )
})
export default Integrations
