import React from 'react'
import {format, isValid} from 'date-fns'
import {ru} from 'date-fns/locale'
import cn from 'classnames'

const ExpirationDate = ({
    date,
    label = 'До:',
    className,
    colorClassName = 'text-red-500 dark:text-red-300',
}) => {
    if (!isValid(date)) return ''
    return (
        <span className={cn(className, colorClassName)}>
            {format(date, `'${label} ' d MMMM yyyy 'г.'`, {
                locale: ru,
            })}
        </span>
    )
}

export {ExpirationDate}
