import React from 'react'
import classNames from 'classnames'

export const LinkUnderline = ({
    className,
    onClick,
    label,
    isBlocked,
    color = 'gray',
}) => {
    return (
        <div
            className={classNames(
                className,
                'text-sm underline  hover:opacity-75 cursor-pointer',
                {
                    'pointer-events-none opacity-50': isBlocked,
                    'text-gray-700 dark:text-gray-100': color === 'gray',
                    'text-blue-500 dark:text-blue-300': color === 'blue',
                }
            )}
            onClick={onClick}
        >
            <span>{label}</span>
        </div>
    )
}
