import React from 'react'
import {Link} from 'react-router-dom'
import {AppPath} from '@routesEnums'

export const ErrorZipContent = ({documentList}) => {
    return (
        <div className={'flex flex-col space-y-4'}>
            <p>
                {`Загружаемый фин.отчет ${documentList
                    .map((item) => `{{${item}}}`)
                    .join(', ')} не найден в системе.`}
            </p>
            <a
                href={
                    'https://seller.wildberries.ru/suppliers-mutual-settlements/reports-implementations/reports-weekly-new'
                }
                target={'_blank'}
                className={
                    'cursor-pointer text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-200'
                }
            >
                Скачайте с Кабинета Продавца Wildberries и загрузите заново
                список финансовых отчетов в разделе "Финансовые отчёты" (кнопка
                скачать Excel).
            </a>
            <Link
                to={AppPath.marketplacesImportWb}
                className={
                    'cursor-pointer text-blue-500 hover:text-blue-700 dark:text-blue-400 dark:hover:text-blue-200'
                }
            >
                Перейдите по ссылке и повторите загрузку фин. отчетов.
            </Link>
            <p>
                Убедитесь, что загружаемые фин.отчеты принадлежат выбранному
                кабинету.
            </p>
        </div>
    )
}
