import {action, makeObservable, observable} from 'mobx'
import InformerService from '@services/InformerService/InformerService'
import {keysToSnake} from '@utils/helpers'

export class InformerStore {
    constructor() {
        makeObservable(this, {
            informer: observable,
            setInformer: action,
        })
    }

    informer = {}

    setInformer = (value = {}) => {
        this.informer = value
    }

    getInformerData = async () => {
        try {
            const {data} = await InformerService.getInformerData()
            this.setInformer(keysToSnake(data))
        } catch (e) {
            console.log(e)
        }
    }
}
