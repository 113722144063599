import {createContext, useContext} from 'react'
const DropdownContext = createContext({})

const useDropdown = () => {
    const {triggerClick, opened, setOpened} = useContext(DropdownContext)

    return {
        triggerClick,
        opened,
        setOpened,
    }
}

const DropdownProvider = DropdownContext.Provider

export {useDropdown, DropdownProvider}
