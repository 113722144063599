import {ModalFooter} from '@components/overlays/modals/ModalFooter'
import {Button} from '@components/elements'
import {observer} from 'mobx-react'
import {useAuditContext} from '@views/marketplaces/pages/Marketplaces/components/Audit/context/AuditContext'
import {ACTION_MAP} from '@views/marketplaces/pages/Marketplaces/components/Audit/constants'

export const StartPage = observer(() => {
    const {auditStore} = useAuditContext()

    const handleClickEnroll = () => {
        auditStore.setDontSkip(true)
        auditStore.setCurrentAction(ACTION_MAP.enroll)
    }
    const handleClickNextTime = () => {
        auditStore.setDontSkip(true)
        auditStore.setCurrentAction(ACTION_MAP.nextTime)
        auditStore.setAuditType('next_time')
    }
    const handleClickDontAudit = () => {
        auditStore.setDontSkip(true)
        auditStore.setCurrentAction(ACTION_MAP.dontAudit)
        auditStore.setAuditType('not_interested')
    }
    return (
        <>
            <div
                className={
                    'flex flex-col gap-y-4 text-gray-600 dark:text-gray-400'
                }
            >
                <p>Рады приветствовать вас на платформе Seller24 🥳</p>
                <p>
                    Среди наших услуг есть аудит ваших кабинетов на
                    маркетплейсах. На нем наши аналитики с собственным опытом
                    торговли на маркетплейсах покажут вам, на каких товарах вы
                    теряете деньги и за счет каких простых действий можно
                    увеличить ваши продажи до 300%.
                </p>
                <p>
                    Стоимость аудита - 7990 руб., но для новых пользователей мы
                    проводим его первый раз{' '}
                    <strong className={'underline'}>бесплатно</strong>.
                </p>
            </div>
            <ModalFooter>
                <div className={'flex w-full space-x-2'}>
                    <Button className={'w-2/3'} onClick={handleClickEnroll}>
                        Записаться на аудит
                    </Button>
                    <Button
                        className={'w-1/3'}
                        color={'secondary'}
                        onClick={handleClickNextTime}
                    >
                        Не нужно
                    </Button>
                    {/*<Button*/}
                    {/*    className={'w-full'}*/}
                    {/*    color={'white'}*/}
                    {/*    onClick={handleClickDontAudit}*/}
                    {/*>*/}
                    {/*    Не хочу аудит*/}
                    {/*</Button>*/}
                </div>
            </ModalFooter>
        </>
    )
})

StartPage.displayName = 'StartPage'
