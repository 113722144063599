import React from 'react'
import cn from 'classnames'

import {ReactComponent as MenuIconSvg} from '../../../assets/icons/menuIcons/menu.svg'
import {ReactComponent as MenuAdsIconSvg} from '../../../assets/icons/menuIcons/menu_ads.svg'
import {ReactComponent as MenuDashboardIconSvg} from '../../../assets/icons/menuIcons/menu_dashboard.svg'
import {ReactComponent as MenuEducationIconSvg} from '../../../assets/icons/menuIcons/menu_education.svg'
import {ReactComponent as MenuEventsIconSvg} from '../../../assets/icons/menuIcons/menu_events.svg'
import {ReactComponent as MenuImagesIconSvg} from '../../../assets/icons/menuIcons/menu_images.svg'
import {ReactComponent as MenuIntegrationIconSvg} from '../../../assets/icons/menuIcons/menu_integration.svg'
import {ReactComponent as MenuMarketAnalyticsIconSvg} from '../../../assets/icons/menuIcons/menu_marketAnalytics.svg'
import {ReactComponent as MenuMainIconSvg} from '../../../assets/icons/menuIcons/menu_main.svg'
import {ReactComponent as MenuMarketplacesIconSvg} from '../../../assets/icons/menuIcons/menu_marketplaces.svg'
import {ReactComponent as MenuMonitoringIconSvg} from '../../../assets/icons/menuIcons/menu_monitoring.svg'
import {ReactComponent as MenuOrdersIconSvg} from '../../../assets/icons/menuIcons/menu_orders.svg'
import {ReactComponent as MenuProductsIconSvg} from '../../../assets/icons/menuIcons/menu_products.svg'
import {ReactComponent as MenuReportsIconSvg} from '../../../assets/icons/menuIcons/menu_reports.svg'
import {ReactComponent as MenuReviewsIconSvg} from '../../../assets/icons/menuIcons/menu_reviews.svg'
import {ReactComponent as MenuRichcontentIconSvg} from '../../../assets/icons/menuIcons/menu_richcontent.svg'
import {ReactComponent as MenuRichcontent2IconSvg} from '../../../assets/icons/menuIcons/menu_richcontent2.svg'
import {ReactComponent as MenuSearchIconSvg} from '../../../assets/icons/menuIcons/menu_search.svg'
import {ReactComponent as MenuSettingsIconSvg} from '../../../assets/icons/menuIcons/menu_settings.svg'
import {ReactComponent as MenuSupportIconSvg} from '../../../assets/icons/menuIcons/menu_support.svg'
import {ReactComponent as MenuTasksIconSvg} from '../../../assets/icons/menuIcons/menu_tasks.svg'
import {ReactComponent as MenuTelegramIconSvg} from '../../../assets/icons/menuIcons/menu_telegram.svg'
import {ReactComponent as MenuYoutubeIconSvg} from '../../../assets/icons/menuIcons/menu_youtube.svg'
import {ReactComponent as Seller24Svg} from '../../../assets/icons/logo/Seller24.svg'
import {ReactComponent as Seller24BlackSvg} from '../../../assets/icons/logo/Seller24Black.svg'
import {ReactComponent as LogoIconSvg} from '../../../assets/icons/logo/logo.svg'
import {ReactComponent as LogoMiniIconSvg} from '../../../assets/icons/logo/logo-mini.svg'
import {ReactComponent as ThinRubIconSvg} from '../../../assets/icons/thin/rub.svg'
import {ReactComponent as ThinRubRoundIconSvg} from '../../../assets/icons/thin/rubRound.svg'
import {ReactComponent as TimeLoaderIconSvg} from '../../../assets/icons/outline/time-loader.svg'
import {ReactComponent as AttentionIconSvg} from '../../../assets/icons/outline/attention.svg'
import {ReactComponent as CopyOrderSvg} from '../../../assets/icons/seller24Svg/copy-order.svg'

import {ReactComponent as ProductIconSvg} from '../../../assets/icons/seller24Svg/product-icon.svg'
import {ReactComponent as EmptyOrdersSvg} from '../../../assets/icons/seller24Svg/empty-orders.svg'
import {ReactComponent as DashboardStubSvg} from '../../../assets/icons/seller24Svg/dashboard-stub.svg'
import {ReactComponent as LineSvg} from '../../../assets/icons/seller24Svg/line.svg'
import {ReactComponent as BarcodeSvg} from '../../../assets/icons/seller24Svg/barcode.svg'
import {ReactComponent as NoAvatarSvg} from '../../../assets/icons/seller24Svg/no-avatar.svg'
import {ReactComponent as PlaySvg} from '../../../assets/icons/seller24Svg/play.svg'
import {ReactComponent as WhatsappSvg} from '../../../assets/icons/outline/whatsapp.svg'

function makeIconComponent(
    IconComponent,
    displayName = '',
    extraClassName = ''
) {
    const Component = ({className = ''}) => (
        <IconComponent className={cn('svg-icon', className, extraClassName)} />
    )
    Component.displayName = displayName
    return Component
}

export const MenuIcon = makeIconComponent(MenuIconSvg, 'MenuIcon')
export const MenuAdsIcon = makeIconComponent(MenuAdsIconSvg, 'MenuAdsIconSvg')
export const MenuDashboardIcon = makeIconComponent(
    MenuDashboardIconSvg,
    'MenuDashboardIconSvg'
)
export const MenuEducationIcon = makeIconComponent(
    MenuEducationIconSvg,
    'MenuEducationIconSvg'
)
export const MenuEventsIcon = makeIconComponent(
    MenuEventsIconSvg,
    'MenuEventsIconSvg'
)
export const MenuImagesIcon = makeIconComponent(
    MenuImagesIconSvg,
    'MenuImagesIconSvg'
)
export const MenuIntegrationIcon = makeIconComponent(
    MenuIntegrationIconSvg,
    'MenuIntegrationIconSvg'
)
export const MenuMarketplacesIcon = makeIconComponent(
    MenuMarketplacesIconSvg,
    'MenuMarketplacesIconSvg'
)
export const MenuMarketAnalyticsIcon = makeIconComponent(
    MenuMarketAnalyticsIconSvg,
    'MenuMarketAnalyticsIconSvg'
)
export const MenuMainIcon = makeIconComponent(
    MenuMainIconSvg,
    'MenuMainIconSvg'
)
export const MenuMonitoringIcon = makeIconComponent(
    MenuMonitoringIconSvg,
    'MenuMonitoringIconSvg'
)
export const MenuOrdersIcon = makeIconComponent(
    MenuOrdersIconSvg,
    'MenuOrdersIconSvg'
)
export const MenuProductsIcon = makeIconComponent(
    MenuProductsIconSvg,
    'MenuProductsIconSvg'
)
export const MenuReportsIcon = makeIconComponent(
    MenuReportsIconSvg,
    'MenuReportsIconSvg'
)
export const MenuReviewsIcon = makeIconComponent(
    MenuReviewsIconSvg,
    'MenuReviewsIconSvg'
)
export const MenuRichcontentIcon = makeIconComponent(
    MenuRichcontentIconSvg,
    'MenuRichcontentIconSvg'
)
export const MenuRichcontent2Icon = makeIconComponent(
    MenuRichcontent2IconSvg,
    'MenuRichcontent2IconSvg'
)
export const MenuSearchIcon = makeIconComponent(
    MenuSearchIconSvg,
    'MenuSearchIconSvg'
)
export const MenuSettingsIcon = makeIconComponent(
    MenuSettingsIconSvg,
    'MenuSettingsIconSvg'
)
export const MenuSupportIcon = makeIconComponent(
    MenuSupportIconSvg,
    'MenuSupportIconSvg'
)
export const MenuTasksIcon = makeIconComponent(
    MenuTasksIconSvg,
    'MenuTasksIconSvg'
)
export const MenuTelegramIcon = makeIconComponent(
    MenuTelegramIconSvg,
    'MenuTelegramIconSvg'
)
export const MenuYoutubeIcon = makeIconComponent(
    MenuYoutubeIconSvg,
    'MenuYoutubeIconSvg'
)

export const LogoIcon = makeIconComponent(LogoIconSvg, 'LogoIconSvg')
export const LogoMiniIcon = makeIconComponent(
    LogoMiniIconSvg,
    'LogoIconMiniSvg'
)

export const Seller24Icon = makeIconComponent(Seller24Svg, 'Seller24Svg')
export const Seller24BlackIcon = makeIconComponent(
    Seller24BlackSvg,
    'Seller24BlackSvg'
)

export const ThinRubIcon = makeIconComponent(ThinRubIconSvg, 'ThinRubIconSvg')

export const ThinRubRoundIcon = makeIconComponent(
    ThinRubRoundIconSvg,
    'ThinRubRoundIconSvg'
)

export const TimeLoaderIcon = makeIconComponent(
    TimeLoaderIconSvg,
    'TimeLoaderIconSvg'
)

export const ProductIcon = makeIconComponent(ProductIconSvg, 'ProductIcon')
export const CopyOrderIcon = makeIconComponent(CopyOrderSvg, 'CopyOrderIcon')
export const EmptyOrdersIcon = makeIconComponent(
    EmptyOrdersSvg,
    'EmptyOrdersIcon'
)
export const LineIcon = makeIconComponent(LineSvg, 'LineIcon')
export const BarcodeIcon = makeIconComponent(BarcodeSvg, 'BarcodeIcon')
export const DashboardStubIcon = makeIconComponent(
    DashboardStubSvg,
    'dashboardStub'
)
export const NoAvatarIcon = makeIconComponent(NoAvatarSvg, 'NoAvatarIcon')
export const AttentionIcon = makeIconComponent(
    AttentionIconSvg,
    'AttentionIcon'
)

export const WhatsappIcon = makeIconComponent(WhatsappSvg, 'WhatsappIcon')
export const PlayIcon = makeIconComponent(PlaySvg, 'PlayIcon')
