import {createContext, useContext, useMemo} from 'react'

const LimitBlockedContext = createContext()

const useLimitContext = (withOutEmulation) => {
    const userStore = useContext(LimitBlockedContext)

    const exceedLimitCabinets = useMemo(() => {
        if (userStore.isEmulation && !withOutEmulation) return false
        const totalCabinets = userStore.pim?.apikey_qty || 0
        const currentLimit = userStore.limitCabinets
        return totalCabinets > currentLimit
    }, [userStore.pim, userStore.limitCabinets, userStore.isEmulation])

    const exceedLimitProducts = useMemo(() => {
        if (userStore.isEmulation && !withOutEmulation) return false
        const totalProducts = userStore.pim.gmv_skus || 0
        const currentLimitProducts = userStore.restrictions?.max_products || 0
        return totalProducts > currentLimitProducts
    }, [
        userStore.restrictions,
        userStore.pim,
        userStore.limitCabinets,
        userStore.isEmulation,
    ])

    const productsRatio = useMemo(() => {
        if (userStore.isEmulation && !withOutEmulation) return 0
        const totalProducts = userStore.pim.gmv_skus || 0
        const currentLimitProducts = userStore.restrictions?.max_products || 0
        if (totalProducts <= currentLimitProducts) return 0
        return Number((currentLimitProducts / totalProducts).toFixed(2))
    }, [userStore.pim, userStore.restrictions])

    return {
        exceedLimitCabinets,
        exceedLimitProducts,
        productsRatio,
    }
}

export {LimitBlockedContext, useLimitContext}
