import React from 'react'
import {Tab as HeadlessUiTab} from '@headlessui/react'
import cn from 'classnames'
import {TooltipLite} from '@components/elements'

const Tab = ({children, className, tooltip, ...props}) => {
    const overrideClassName = (data) => {
        if (typeof className === 'function') return className(data)

        return cn(
            className,
            'group outline-none inline-flex flex-shrink-0 items-center py-4 -mx-1 px-1 border-b-2 font-medium text-base',
            {
                'border-gray-800 text-gray-800 dark:border-gray-200 dark:text-gray-200':
                    data.selected,
                'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300':
                    !data.selected,
            }
        )
    }
    if (tooltip) {
        return (
            <TooltipLite
                classNameBody={'max-w-[250px]'}
                offset={[0, 10]}
                content={tooltip}
            >
                {(ref) => {
                    return (
                        <HeadlessUiTab
                            ref={ref}
                            className={overrideClassName}
                            {...props}
                        >
                            {({selected}) => children(selected)}
                        </HeadlessUiTab>
                    )
                }}
            </TooltipLite>
        )
    }

    return (
        <HeadlessUiTab className={overrideClassName} {...props}>
            {({selected}) => children(selected)}
        </HeadlessUiTab>
    )
}

export {Tab}
