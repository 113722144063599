import React from 'react'
import cn from 'classnames'

const TooltipBody = ({
    setPopperElement,
    classNameBody,
    styles,
    defaultStyles,
    transitionStyles,
    state,
    zIndex,
    attributes,
    content,
    isArrow,
    setArrowElement,
    side,
}) => {
    return (
        <div
            ref={setPopperElement}
            className={cn(
                'min-w-[170px] p-2 md:p-4 bg-white rounded-md shadow-lg text-sm text-gray-500 border border-transparent',
                'dark:bg-gray-700 dark:border-gray-600 dark:text-gray-300 dark:border-gray-600',
                classNameBody
            )}
            style={{
                ...styles.popper,
                ...defaultStyles,
                ...transitionStyles[state],
                zIndex,
            }}
            {...attributes.popper}
        >
            {content}
            {isArrow && (
                <div
                    ref={setArrowElement}
                    className={cn(
                        `tooltip-lite__arrow-container`,
                        `tooltip-lite__arrow-container--${side}`
                    )}
                    style={{...styles.arrow}}
                >
                    <div
                        className={cn(
                            `w-4 h-4 bg-white border-b border-l rotate-45 border-transparent`,
                            'dark:bg-gray-700 dark:border-gray-600'
                        )}
                    />
                </div>
            )}
        </div>
    )
}

export {TooltipBody}
