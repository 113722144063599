import {updateCancelToken} from '@services/apiService'
import {RepostSettingsUrl} from '../../routes-enums'
import {baseConfig} from '@services/apiConfigs'
import {ApiFactory} from '@services/ApiFactory'

const reportsSettingsInstance = ApiFactory.createApi(baseConfig)

class ReportsSettingsService {
    static getSettingsTypeReports = async (query) => {
        return await reportsSettingsInstance.get(
            RepostSettingsUrl.settingsTypeReports,
            query
        )
    }

    static getSettingsProfitability = async (query, source) => {
        const config = {
            cancelToken: updateCancelToken(source),
        }
        return await reportsSettingsInstance.get(
            RepostSettingsUrl.settingsProfitability,
            query,
            config
        )
    }
    static postSettingsProfitability = async (payload) => {
        return await reportsSettingsInstance.post(
            RepostSettingsUrl.settingsProfitability,
            payload
        )
    }

    static getSettingsTax = async (query, source) => {
        const config = {
            cancelToken: updateCancelToken(source),
        }
        return await reportsSettingsInstance.get(
            RepostSettingsUrl.settingsTax,
            query,
            config
        )
    }
    static postSettingsTax = async (payload) => {
        return await reportsSettingsInstance.post(
            RepostSettingsUrl.settingsTax,
            payload
        )
    }

    static deleteSettingsTax = async (query) => {
        const config = {
            params: query,
        }
        return await reportsSettingsInstance.delete(
            RepostSettingsUrl.settingsTax,
            undefined,
            config
        )
    }

    static getSettingsActiveProduct = async (query, source) => {
        const config = {
            cancelToken: updateCancelToken(source),
        }
        return await reportsSettingsInstance.get(
            RepostSettingsUrl.settingsActiveProduct,
            query,
            config
        )
    }
    static postSettingsPActiveProduct = async (payload) => {
        return await reportsSettingsInstance.post(
            RepostSettingsUrl.settingsActiveProduct,
            payload
        )
    }
}

export default ReportsSettingsService
