import {makeObservable, observable, action} from 'mobx'
import CatalogService from '@services/CatalogService/CatalogService'

//stores
import CardTabsStatsStore from '../CardTabs/CardTabsStats/store/CardTabsStatsStore'
import CardTabsReviewsStore from '../CardTabs/CardTabsReviews/store/CardTabsReviewsStore'

class CatalogCardStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        this.stores = {
            cardTabsStatsStore: new CardTabsStatsStore(rootStore),
            cardTabsReviewsStore: new CardTabsReviewsStore(rootStore),
        }
        makeObservable(this, {
            card: observable,
            pending: observable,
            clearCard: action,
            setCard: action,
            setPending: action,
        })
    }

    tabs = [
        // {
        //     id: 'stats',
        //     name: 'Статистика',
        //     disabled: false,
        //     visible: true,
        // },
        {
            id: 'info',
            name: 'Информация',
            disabled: false,
            visible: true,
        },
        {
            id: 'reviews',
            name: 'Отзывы',
            disabled: true,
            count: 'Обновляем, скоро будет',
            visible: true,
        },
    ]

    card = {}

    pending = false

    clearCard = () => {
        this.card = {}
    }

    setCard = (obj) => {
        this.card = Object.assign({}, this.card, obj)
    }

    setPending = (value = true) => {
        this.pending = value
    }

    getCard = async (id) => {
        this.setPending(true)
        try {
            const {data} = await CatalogService.getCard(id)

            this.clearCard()
            this.setCard(data)
            this.setPending(false)
        } catch (error) {
            console.log(error)
            this.setPending(false)
        }
    }
}

export default CatalogCardStore
