import {lightenColor} from '@utils/helpers'
import Gradient from '@utils/gradient'
import {useCallback} from 'react'

export const useColorGradient = () => {
    const getGradient = useCallback((color, count, percent = 10) => {
        const color50 = lightenColor(color, percent)
        const colorGradient = new Gradient()
        colorGradient.setGradient(color, color50)

        colorGradient.setMidpoint(count)
        return colorGradient.getArray()
    }, [])

    return {getGradient}
}
