//libs
import React from 'react'

//components
import {Button} from '@components/elements'
import {Link} from 'react-router-dom'

const EmptyList = ({className, icon, title, summary, cta, to}) => {
    return (
        <>
            <div
                className={`relative h-full w-full \
                flex flex-col items-center justify-center text-center \
                ${className}`}
            >
                {icon && icon}
                {!!title?.length && (
                    <p
                        className={
                            'text-2xl dark:text-gray-100 font-semibold whitespace-pre-line'
                        }
                    >
                        {title}
                    </p>
                )}
                {!!summary?.length && (
                    <p
                        className={
                            'text-lg text-gray-600 dark:text-gray-400 whitespace-pre-line mt-4'
                        }
                    >
                        {summary}
                    </p>
                )}
                {!!to && !!cta && (
                    <Link to={to} className={'mt-6'}>
                        <Button>{cta}</Button>
                    </Link>
                )}
            </div>
        </>
    )
}

export {EmptyList}
