import React from 'react'
import {ReactComponent as LoadIcon} from './loaderIcon/loader.svg'
import {ReactComponent as LoadSmallIcon} from './loaderIcon/loader_sm.svg'
import LoadXSIcon from './loaderIcon/loader_xs.png'
import classNames from 'classnames'

const Loader = ({sm, xs, overlayed = true, className}) => {
    return (
        <div
            className={classNames(className, {
                'absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center':
                    overlayed,
            })}
        >
            {overlayed ? (
                <div
                    className={
                        'absolute top-0 left-0 right-0 bottom-0 opacity-10 bg-ui-white z-40'
                    }
                />
            ) : (
                ''
            )}
            <div className={'animate-spin'}>
                {!(sm || xs) && <LoadIcon />}
                {sm && <LoadSmallIcon />}
                {xs && <img width={8} height={8} src={LoadXSIcon} alt={''} />}
            </div>
        </div>
    )
}

const LoaderFull = ({
    label = 'Загрузка данных. Пожалуйста, подождите...',
    flex = false,
    absolute = false,
    transparent = false,
    className = 'mb-10',
    ...props
}) => {
    return (
        <div
            className={classNames(
                'z-[40] flex flex-col items-center justify-center dark:text-gray-200',
                {
                    'absolute w-full bg-gray-50/80 dark:bg-gray-800/80':
                        absolute,
                },
                flex ? 'flex-grow' : 'h-full'
            )}
        >
            <Loader {...props} overlayed={false} className={className} />
            <span className={'text-center'}>{label}</span>
        </div>
    )
}

export {Loader, LoaderFull}
