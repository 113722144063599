import React from 'react'
import classNames from 'classnames'
import {Amount} from '@components/elements'

export const StandardField = ({
    value,
    className,
    currency,
    showCurrency = true,
    decimalLength,
}) => {
    const number = Number(value)
    return (
        <div className={classNames(className, 'flex justify-end')}>
            <Amount
                value={number}
                currency={currency}
                decimalLength={decimalLength}
                showCurrency={showCurrency}
            />
        </div>
    )
}
