import React from 'react'
import {DownloadIcon} from '@heroicons/react/outline'
import {useDropzone} from 'react-dropzone'
import cn from 'classnames'

const FileDropper = ({
    onChange = () => {},
    onBlur = () => {},
    acceptedFiles = ['image/*', 'application/*'],
    dropzoneText = '',
    Icon = (
        <DownloadIcon
            className={'w-14 h-14 text-gray-400 dark:text-gray-400'}
        />
    ),
    name = '',
    className = '',
    maxFiles = 10,
}) => {
    const {getRootProps, getInputProps} = useDropzone({
        accept: acceptedFiles,
        onDrop: onChange,
        maxFiles,
    })

    return (
        <div
            {...getRootProps()}
            className={cn(
                className,
                'flex flex-col p-10 items-center justify-center relative w-full rounded-lg border-2 border-dashed  border-gray-300 dark:border-gray-600 overflow-hidden cursor-pointer shadow hover:shadow-lg'
            )}
        >
            {Icon}
            <input {...getInputProps()} name={name} onBlur={onBlur} />
            <span className={'text-gray-600 dark:text-gray-400 my-6'}>
                {dropzoneText}
            </span>
        </div>
    )
}

export {FileDropper}
