import {Alert} from '@components/feedback'
import {TextField} from '@components/forms/TextFields'
import {Select} from '@components/forms/Selects'
import HelperLabel from '../../../../components/HelperLabel'
import FooterButtons from '../../../../components/FooterButtons'
import {Overlay} from '@components/overlays'
import YandexHelp from '../../YandexHelp'
import React, {useMemo, useState} from 'react'
import {observer} from 'mobx-react'
import * as yup from 'yup'
import {useOnboardingContext} from '@components/navigation/header/Onboarding/context/OnboardingContext'
import {Controller, useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import MarketService from '@services/MarketService/MarketService'
import {geErrorMessage} from '@utils/errors/marketpalcesError'
import {Loader} from '@components/elements/Loader'
import {Badge, Button} from '@components/elements'
import {CONNECTED_MP_ACTIONS, YANDEX_DELIVERY_TYPE} from '@utils/constants'
import {useAnalyticEventsHook} from '@hooks'
import {useStartField} from '@hooks/customHooks/useStartField'

const yandexSchema = yup.object().shape({
    name: yup
        .string()
        .max(50, 'Имя личного кабинета не должно превышать 50 символов')
        .required('Имя личного кабинета не должно быть пустым'),
    campaign: yup.object({
        id: yup.string().required('Кампания не должна быть пустой'),
    }),
})

export const YandexConnectorsForm = observer(
    ({yandexAuthStore, onCancel, onSuccess, onError, connectorData}) => {
        const {connectedMpEvent} = useAnalyticEventsHook()
        const {onboardingStepStore} = useOnboardingContext()
        const shopUrl = connectorData?.credentials.marketUrl || ''
        const token =
            connectorData?.credentials.token || yandexAuthStore.authToken
        const campaign = useMemo(() => {
            let campaign
            if (connectorData?.credentials.campaignId) {
                campaign = yandexAuthStore.getCampaignsOptions(
                    Number(connectorData?.credentials.campaignId)
                )
            }
            return campaign
        }, [connectorData?.credentials.campaignId])
        const defaultValues = {
            name: connectorData ? connectorData.name : '',
            marketUrl: shopUrl ? shopUrl : '',
            campaign: campaign,
        }

        const {
            control,
            register,
            handleSubmit,
            setValue,
            formState: {errors},
        } = useForm({
            defaultValues,
            mode: 'all',
            resolver: yupResolver(yandexSchema),
        })

        const [responseError, setResponseError] = useState()
        const [helpOpen, setHelpOpen] = useState(false)
        const [connectedLoader, setConnectedLoader] = useState(false)

        const handleLoginYandex = async ({name, campaign, marketUrl}) => {
            const id = connectorData?.id
            const payload = {
                token: token,
                mp_account: {
                    name,
                    market_url: marketUrl,
                    campaign_id: campaign.id,
                    delivery_type: campaign.deliveryType,
                    verify: JSON.parse(process.env.REACT_APP_YANDEX_VERYFY),
                },
            }

            setConnectedLoader(true)
            try {
                const {data} = await MarketService.postLoginYandex(payload, id)
                if (onboardingStepStore.isOnboarding) {
                    onboardingStepStore.setIsConnectedCabinet(true)
                }
                if (onSuccess) onSuccess(id, data)
            } catch (e) {
                if (!e?.response.data?.result && e?.response.data?.message) {
                    setResponseError(geErrorMessage(e?.response.data))
                } else {
                    setResponseError(geErrorMessage({code: -1}))
                }
                if (onError) onError()
            } finally {
                setConnectedLoader(false)
            }
        }

        const handleClickHelp = (open) => {
            connectedMpEvent(CONNECTED_MP_ACTIONS.open, 'Instruction')
            setHelpOpen(open)
        }

        const handleClickField = () => {
            connectedMpEvent(CONNECTED_MP_ACTIONS.fill, 'MP_formYandex')
        }

        const onClickField = useStartField(handleClickField)

        const renderButtonLabel = () => {
            if (connectedLoader) {
                return (
                    <>
                        <Loader sm overlayed={false} className={'mr-2'} />
                    </>
                )
            }
            if (connectorData) return 'Сохранить'

            return 'Подключить'
        }

        const renderButton = (style) => {
            return (
                <Button
                    className={style}
                    disabled={connectedLoader}
                    onClick={handleSubmit(handleLoginYandex)}
                >
                    {renderButtonLabel()}
                </Button>
            )
        }

        return (
            <>
                <form className={'w-full'}>
                    {responseError && (
                        <Alert error title={responseError} className={'mb-6'} />
                    )}
                    <Controller
                        control={control}
                        name={'campaign'}
                        render={({field: {onChange, value}}) => (
                            <Select
                                label={'Магазин/модель продажи'}
                                className={'mb-4'}
                                disabled={connectorData}
                                options={
                                    connectorData
                                        ? yandexAuthStore.campaigns.options
                                        : yandexAuthStore.campaigns.options.filter(
                                              (campaign) =>
                                                  YANDEX_DELIVERY_TYPE[
                                                      campaign.deliveryType
                                                  ]
                                          )
                                }
                                selected={value}
                                renderName={(selected) =>
                                    selected
                                        ? `${selected.name} [${selected.deliveryType}]`
                                        : ''
                                }
                                onChange={(value) => {
                                    onChange(value)
                                    yandexAuthStore.setCampaignSelected(value)
                                    setValue(
                                        'name',
                                        `${value.name} [${value.deliveryType}]`
                                    )
                                }}
                                error={!!errors.campaign}
                                helperText={errors?.campaign?.id.message}
                            >
                                {({option, selected, active}) => (
                                    <div className={'flex'}>
                                        {option.name}
                                        <Badge rounded className={'ml-2'}>
                                            {option.deliveryType}
                                        </Badge>
                                    </div>
                                )}
                            </Select>
                        )}
                    />
                    <TextField
                        {...register('name')}
                        className={'mb-4'}
                        label={'Имя личного кабинета'}
                        error={!!errors.name}
                        onClick={onClickField}
                        helperText={errors?.name?.message}
                    />
                    <TextField
                        {...register('marketUrl')}
                        className={'mb-4'}
                        label={'Ссылка на ваш магазин'}
                        error={!!errors.marketUrl}
                        helperText={errors?.marketUrl?.message}
                    />
                    {shopUrl ? (
                        <div className={'mb-4'}>
                            <a
                                className='underline text-sm cursor-pointer text-blue-500 hover:text-blue-400 dark:text-blue-400 dark:hover:text-blue-300'
                                target={'_blank'}
                                href={shopUrl}
                            >
                                Ваша ссылка на магазин
                            </a>
                        </div>
                    ) : null}
                    <HelperLabel
                        label={'Как подключить Яндекс.Маркет?'}
                        onOpen={handleClickHelp}
                    />
                    <FooterButtons
                        connectedButton={renderButton}
                        onCancel={onCancel}
                    />
                </form>
                <Overlay
                    open={helpOpen}
                    setOpen={setHelpOpen}
                    title={'Как подключить Яндекс.Маркет?'}
                    size={'lg'}
                >
                    <YandexHelp />
                </Overlay>
            </>
        )
    }
)

YandexConnectorsForm.displayName = 'YandexConnectorsForm'
