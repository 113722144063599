import classNames from 'classnames'

export const Dot = ({color, size}) => {
    const colors = {
        red: 'bg-red-200 dark:bg-red-800/50',
        green: 'bg-green-200 dark:bg-green-400/50',
        gray: 'bg-gray-200 dark:bg-gray-500',
        yellow: 'bg-yellow-200 dark:bg-yellow-500/75',
    }

    const sizeClasses = {
        small: 'w-2 h-2',
        medium: 'w-4 h-4',
        large: 'w-6 h-6',
    }

    return (
        <div
            className={classNames(
                'rounded-full',
                colors[color],
                sizeClasses[size]
            )}
        />
    )
}
