import {observer} from 'mobx-react'
import React from 'react'
import {Button, MarketIcon} from '@components/elements'
import {Loader} from '@components/elements/Loader'
import {MultiSelect} from '@components/forms/Selects'
import {useDebouncedFunction} from '@hooks'

export const FileDownload = observer(({store, downloadFileStore}) => {
    const handleChangeInstanceChecked = (array) => {
        const instanceKey = array.map((item) => item.key)
        store.cabinets.setSelectedInstance(array)
        store.cabinets.setSelectedInstanceKey(instanceKey)
    }

    const handleChangeInstanceCheckedDebounced = useDebouncedFunction(
        handleChangeInstanceChecked,
        50
    )
    return (
        <div className={'py-6 pr-6 space-y-4 flex flex-col'}>
            <div className={'w-full xl:w-1/2'}>
                <MultiSelect
                    selectedInHelper={false}
                    options={store.cabinets.options}
                    selected={store.cabinets.selectedOptions}
                    className={'w-full z-50'}
                    placeholder={'По всем кабинетам'}
                    onChange={handleChangeInstanceCheckedDebounced}
                >
                    {({option, selected, active}) => (
                        <div className={'flex items-center'}>
                            <MarketIcon
                                iconClassName={'w-4x5 h-4x5 '}
                                size={20}
                                name={option.market}
                                className={'mr-3 flex-shrink-0'}
                            />
                            <span className={'truncate'}>{option.name}</span>
                        </div>
                    )}
                </MultiSelect>
            </div>
            <Button
                className={'w-full xl:w-1/2'}
                disabled={
                    !store.isSelectedInstance ||
                    downloadFileStore.downloadFileInfoLoaderStore.loading
                }
                onClick={() =>
                    downloadFileStore.getDownloadFile(
                        store.cabinets.selectedInstanceKey
                    )
                }
            >
                {downloadFileStore.downloadFileInfoLoaderStore.loading ? (
                    <>
                        <Loader sm overlayed={false} className={'mr-2'} />
                        Идёт скачивание файла...
                    </>
                ) : (
                    'Скачать'
                )}
            </Button>
        </div>
    )
})
