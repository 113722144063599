//libs
import {makeObservable} from 'mobx'

//stores
import {ProductsImportStore} from '../Import/store/ProductsImportStore'
import {ProductsListStore} from '../List/store/ProductsListStore'
import {ReviewsStore} from '@views/products/Reviews/store/ReviewsStore'
import {CatalogStore} from '@views/products/Categories/store/CatalogStore'
import {WarehousesStore} from '@views/products/Warehouses/store/WarehousesStore'
import {StockStore} from '@views/products/Stock/store/StockStore'

class ProductsStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        this.reset()
        makeObservable(this, {})
    }

    reset = () => {
        this.stores = {
            productsImportStore: new ProductsImportStore(this.rootStore),
            productsListStore: new ProductsListStore(this.rootStore),
            reviewsStore: new ReviewsStore(this.rootStore),
            catalogStore: new CatalogStore(this.rootStore),
            warehousesStore: new WarehousesStore(this.rootStore),
            stockStore: new StockStore(this.rootStore),
        }
    }

    headerTabs = [
        {
            id: 'list',
            name: 'Товары',
            disabled: false,
            visible: true,
        },
        {
            id: 'import',
            name: 'Импорт товаров',
            disabled: false,
            visible: true,
        },
        {
            id: 'reviews',
            name: 'Отзывы',
            disabled: false,
            visible: true,
        },
        {
            id: 'warehouses',
            name: 'Склады FBS',
            disabled: false,
            visible: true,
        },
        {
            id: 'categories',
            name: 'Категории',
            disabled: false,
            visible: false,
        },
        {
            id: 'stock',
            name: 'Обновление цен',
            count: 'скоро',
            disabled: true,
            visible: true,
        },
    ]
}

export default ProductsStore
