import React, {useRef, useState, useMemo, useEffect} from 'react'
import cn from 'classnames'

const CollapsePanel = (props) => {
    const {renderHeader, children, onClick, isOpen, isRollUp, ...rest} = props
    const [isOpenState, setIsOpenState] = useState(false)
    const [clientHeight, setClientHeight] = useState(0)
    const ref = useRef()
    const open = useMemo(
        () => (isOpen !== undefined ? isOpen : isOpenState),
        [isOpen, isOpenState]
    )

    useEffect(() => {
        if (ref.current) {
            setClientHeight(ref.current.scrollHeight)
        }
    }, [ref.current, ref.current?.scrollHeight])

    const handleClick = () => {
        if (!isRollUp) return
        setIsOpenState(!isOpenState)
        if (onClick) onClick(!isOpenState)
    }

    return (
        <div {...rest}>
            <div onClick={handleClick} onKeyDown={handleClick}>
                {renderHeader(isOpen)}
            </div>
            <div
                ref={ref}
                style={open ? {height: clientHeight + 'px'} : {height: 0}}
                className={cn(
                    'overflow-hidden transition-all duration-500 ease-in-out'
                )}
            >
                {children}
            </div>
        </div>
    )
}

export default CollapsePanel
