import React from 'react'
import {ContainerFullOnMobile} from '@components/layout'
import {Loader} from '@components/elements/Loader'
import classNames from 'classnames'

const UpdateProcess = (titleSize) => (
    <div className={'mb-10'}>
        <h2
            className={classNames(
                'font-medium text-gray-800 dark:text-gray-200 mb-4',
                {
                    [`${titleSize}`]: titleSize,
                    'text-2xl': !titleSize,
                }
            )}
        >
            Импорт данных
        </h2>
        <div className={'flex'}>
            <Loader sm overlayed={false} />
            <span className={'ml-4'}>Идёт процесс обновления цен</span>
        </div>
    </div>
)

export default UpdateProcess
