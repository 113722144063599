import React from 'react'
import {observer} from 'mobx-react'
import {Link} from 'react-router-dom'
import classNames from 'classnames'

export const LinkData = observer(({className, title, to, linkText}) => {
    return (
        <span
            className={classNames(
                className,
                'lg:flex lg:flex-nowrap space-x-2'
            )}
        >
            <span>{title}</span>
            <Link
                to={to}
                className={
                    'cursor-pointer whitespace-normal truncate text-blue-500 dark:text-blue-400 hover:opacity-80'
                }
            >
                {linkText}
            </Link>
        </span>
    )
})
