import {action, makeObservable, observable, toJS} from 'mobx'
import ProductCategoriesService from '@services/ProductCategoriesService/ProductCategoriesService'
import {toast} from 'react-toastify'
import {timeout} from '@utils/helpers'
import {isArray} from 'chart.js/helpers'
import {PaginationStore} from '../../../../store/PaginationStore'

const initCategories = {
    1: {
        options: [],
        selected: {},
        paginationStore: new PaginationStore(),
        loading: false,
    },
    2: {
        options: [],
        selected: {},
        paginationStore: new PaginationStore(),
        loading: false,
    },
    3: {
        options: [],
        selected: {},
        paginationStore: new PaginationStore(),
        loading: false,
    },
    4: {
        options: [],
        selected: {},
        paginationStore: new PaginationStore(),
        loading: false,
    },
}

export class CatalogStore {
    constructor(rootStore) {
        this.rootStore = rootStore

        makeObservable(this, {
            filters: observable,
            feeds: observable,
            categories: observable,
            masterCategories: observable,
            catId: observable,
            masterCatId: observable,
            productList: observable,
            catsAttr: observable,
            isEmptyMasterCatsChild: observable,
            selectedProducts: observable,
            catsAttrLoader: observable,
            productsLoader: observable,
            feedsLoader: observable,
            setFilters: action,
            setCatId: action,
            resetFilters: action,
            setFeedsOptions: action,
            setFeedsSelected: action,
            setCategories: action,
            setCategoryPagination: action,
            setCategoryLoading: action,
            setSelectedCategory: action,
            setMasterCategories: action,
            resetCategories: action,
            setProductList: action,
            setCatsAttr: action,
            setSelectedMasterCategory: action,
            resetMasterCategories: action,
            setMasterCatId: action,
            setIsEmptyMasterCatsChild: action,
            setSelectedProducts: action,
            resetFeeds: action,
            setCatsAttrLoader: action,
            setProductsLoader: action,
            setFeedsLoader: action,
        })
        this.paginationStore = new PaginationStore()
        this.feedsSourcePagination = new PaginationStore()
    }

    filters = []
    productList = []
    selectedProducts = []
    catsAttr = []
    catId = 0
    masterCatId = 0
    categories = initCategories
    masterCategories = initCategories
    isEmptyMasterCatsChild = false
    catsAttrLoader = false
    productsLoader = false
    feedsLoader = false

    feeds = {
        options: [],
        selected: {},
    }

    setFilters = (filter = {}) => {
        const currentFilterIdx = this.filters.findIndex(
            (item) => item.attr_id === filter.attr_id
        )
        if (currentFilterIdx !== -1) {
            const currentFilter = {...this.filters[currentFilterIdx]}

            this.filters[currentFilterIdx] = {
                ...currentFilter,
                ...filter,
            }
        } else {
            this.filters.push(filter)
        }

        this.getProducts()
    }

    resetFilters = () => {
        this.filters = []
    }

    setFeedsOptions = (options = []) => {
        this.feeds.options = options
    }

    resetFeeds = () => {
        this.feeds = {
            options: [],
            selected: {},
        }
    }

    setSelectedProducts = (products = []) => {
        this.selectedProducts = products
    }

    setFeedsSelected = (selected = {}) => {
        this.feeds.selected = selected
    }

    setCategories = (level, cats = []) => {
        this.categories[level].options = cats
    }

    setCategoryPagination = (level, pagination) => {
        this.categories[level].paginationStore.setPagination(pagination)
    }

    setCategoryLoading = (level, loading) => {
        this.categories[level].loading = loading
    }

    setSelectedCategory = (level, category = {}) => {
        this.categories[level].selected = category
    }

    resetCategories = () => {
        this.categories = initCategories
        this.setCatId()
        this.setCatsAttr()
        this.setProductList()
    }

    resetOtherCategories = (level) => {
        Object.keys(this.categories).forEach((item) => {
            if (Number(item) > level) {
                this.setSelectedCategory(item, {options: [], selected: {}})
            }
        })
    }

    setMasterCategories = (level, cats = []) => {
        this.masterCategories[level].options = cats
    }

    setSelectedMasterCategory = (level, category = {}) => {
        this.masterCategories[level].selected = category
    }

    resetMasterCategories = () => {
        this.masterCategories = initCategories
        this.setMasterCatId()
        this.setIsEmptyMasterCatsChild()
    }

    setMasterCatId = (catId = 0) => {
        this.masterCatId = catId
    }

    setIsEmptyMasterCatsChild = (value = false) => {
        this.isEmptyMasterCatsChild = value
    }

    setCatsAttrLoader = (value = false) => {
        this.catsAttrLoader = value
    }

    setProductsLoader = (value = false) => {
        this.productsLoader = value
    }

    setFeedsLoader = (value = false) => {
        this.feedsLoader = value
    }

    resetOtherMasterCategories = (level) => {
        Object.keys(this.masterCategories).forEach((item) => {
            if (Number(item) > level) {
                this.setSelectedMasterCategory(item, {
                    options: [],
                    selected: {},
                })
            }
        })
    }

    setCatsAttr = (value = []) => {
        this.catsAttr = value
    }

    setProductList = (list = []) => {
        this.productList = list
    }

    setCatId = (catId = 0) => {
        this.catId = catId
    }

    reset = () => {
        this.resetMasterCategories()
        this.resetCategories()
        this.resetFeeds()
        this.paginationStore.resetPagination()
        this.resetFilters()
    }

    collectProductPayload = (page) => {
        const {pagination} = this.paginationStore
        return {
            page: page || pagination.page,
            limit: pagination.limit,
            cat_id: this.catId,
            feed_uuid: this.feeds.selected?.feedUuid,
            filters: this.filters,
        }
    }

    mergeCategories = (level, childs) => {
        this.setCategories(level, childs)
        if (!childs.length) {
            this.getCatsAttr()
            this.getProducts()
        }
    }

    mergeMasterCategories = (level, childs) => {
        this.setMasterCategories(level, childs)
        if (!childs.length) {
            this.setIsEmptyMasterCatsChild(true)
        }
    }

    gettingSelectedCats = async (tree) => {
        for (const [idx, category] of tree.entries()) {
            this.setCatId(category.id)
            await this.getFeedCatsChild(idx + 2)
            this.setSelectedCategory(idx + 1, category)
        }
    }

    getFeeds = async (value = '', page = 1) => {
        const payload = {
            limit: page ? page * 10 : 10,
            page: 1,
            value: value,
        }
        this.setFeedsLoader(true)
        try {
            const {
                data: {items, limit, page, total, totalPages},
            } = await ProductCategoriesService.getFeedSource(payload)
            this.setFeedsOptions(items)
            this.feedsSourcePagination.setPagination({
                limit,
                page,
                total,
                totalPages,
            })
        } catch (e) {
            Array.isArray(e.response.data)
                ? e.response.data.map((error) =>
                      toast(
                          `Ошибка №${error.code} при получения списка фидов.`,
                          {
                              type: 'error',
                          }
                      )
                  )
                : toast(`Ошибка при получения списка фидов.`, {
                      type: 'error',
                  })
        } finally {
            this.setFeedsLoader(false)
        }
    }

    getMasterCats = async () => {
        try {
            const {data} = await ProductCategoriesService.getMasterCats()
            this.mergeMasterCategories(1, data.childs)
        } catch (e) {
        } finally {
            this.setCategoryLoading(1, false)
        }
    }

    getMasterCatsChild = async (level) => {
        try {
            const {data} = await ProductCategoriesService.getMasterCatsChild(
                this.masterCatId
            )
            this.mergeMasterCategories(level, data.childs)
        } catch (e) {
        } finally {
        }
    }

    getFeedCats = async (value = '', page = 1) => {
        const payload = {
            limit: page ? page * 10 : 10,
            page: 1,
            value: value,
        }
        const uuid = this.feeds.selected?.feedUuid
        if (!uuid) return console.error('getFeedCats, feed_uuid is empty ')
        this.setCategoryLoading(1, true)
        try {
            const {data} = await ProductCategoriesService.getFeedCats(
                payload,
                uuid
            )
            const {limit, page, total, totalPages} = data
            this.setCategoryPagination(1, {limit, page, total, totalPages})
            this.mergeCategories(1, data.childs)
            if (data.unparsed.id) {
                this.gettingSelectedCats(data.unparsed.tree)
            }
        } catch (e) {
        } finally {
            this.setCategoryLoading(1, false)
        }
    }

    getFeedCatsChild = async (level, value = '', page = 1) => {
        const payload = {
            limit: page ? page * 10 : 10,
            page: 1,
            value: value,
        }
        const uuid = this.feeds.selected?.feedUuid
        if (!uuid) return console.error('getFeedCats, feed_uuid is empty ')
        this.setCategoryLoading(level, true)
        try {
            const {data} = await ProductCategoriesService.getFeedCatsChild(
                payload,
                uuid,
                this.catId
            )
            const {limit, page, total, totalPages} = data
            this.setCategoryPagination(level, {limit, page, total, totalPages})
            this.mergeCategories(level, data.childs)
        } catch (e) {
        } finally {
            this.setCategoryLoading(level, false)
        }
    }

    getCatsAttr = async () => {
        const uuid = this.feeds.selected?.feedUuid
        if (!uuid) return console.error('getFeedCats, feed_uuid is empty ')
        this.setCatsAttrLoader(true)
        try {
            const {data} = await ProductCategoriesService.getCatsAttr(
                uuid,
                this.catId
            )
            this.setCatsAttr(data.attrs)
        } catch (e) {
        } finally {
            this.setCatsAttrLoader(false)
        }
    }

    getProducts = async (page = 1) => {
        const payload = this.collectProductPayload(page)
        if (!payload.cat_id && !payload.feed_uuid) return
        this.setProductsLoader(true)
        try {
            const {data} = await ProductCategoriesService.getProducts(payload)
            this.setProductList(data.products)
            this.paginationStore.setPagination({
                page: data?.page,
                total: data?.total,
                totalPages: data?.totalPages,
            })
        } catch (e) {
        } finally {
            this.setProductsLoader(false)
        }
    }

    assignCats = async () => {
        const payload = {
            filters: {
                cat_id: this.catId,
                feed_uuid: this.feeds.selected?.feedUuid,
                filters: this.filters,
            },
            master_cat_id: this.masterCatId,
            product_id: this.selectedProducts,
        }
        try {
            const {data} = await ProductCategoriesService.putAssignCats(payload)
            return Promise.resolve()
        } catch (e) {
            return Promise.reject()
        } finally {
        }
    }
}
