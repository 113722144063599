import {action, computed, makeObservable, observable} from 'mobx'
import ReviewsAbstractStore from './ReviewsAbstractStore'
import ReviewsService from '@services/ReviewsService/ReviewsService'
import ReviewsFilterStore from './ReviewsFilterStore'
import {PaginationStore} from '../../../../store/PaginationStore'

export class ReviewsStore extends ReviewsAbstractStore {
    constructor(rootStore) {
        super()
        this.rootStore = rootStore
        makeObservable(this, {
            pending: observable,
            mergeReviewsData: action,
            setPending: action,
            isEmptyData: observable,
            setIsEmptyData: action,
            sortBy: computed,
            filter: computed,
        })

        this.reviewsFilterStore = new ReviewsFilterStore(rootStore)
        this.paginationStore = new PaginationStore()
    }

    pending = false
    isEmptyData = false

    setPending = (value = true) => {
        this.pending = value
    }

    setIsEmptyData = (value) => {
        this.isEmptyData = value
    }

    mergeReviewsData = (source: Array) => {
        const reviews = []
        for (let i = 0; i < source.length; i++) {
            const {
                advantages,
                disadvantages,
                comment,
                author,
                stars,
                reviewCreatedAt,
                product = {},
            } = source[i]
            const {id, productId, name, sku, imageThumb, url, marketplaceName} =
                product
            const review = {
                marketName: marketplaceName,
                rating: stars,
                author,
                createdAt: reviewCreatedAt,
                content: {
                    pros: advantages,
                    cons: disadvantages,
                    comment,
                },
                product: {
                    id,
                    productId: productId,
                    name,
                    sku,
                    thumbUrl: imageThumb,
                    url,
                },
            }

            reviews.push(review)
        }
        this.reviews = reviews
        this.setIsEmptyData(!reviews.length)
    }

    get sortBy() {
        return this.reviewsFilterStore?.sortBy?.selected?.key
    }

    get filter() {
        return this.reviewsFilterStore?.appliedFilter
    }

    changePage = (page: number = 1) => {
        this.requestReviews(page)
        this.paginationStore.setPagination({
            page: Number(page),
        })
    }

    changeLimit = (limit: number = 10) => {
        this.paginationStore.setPagination({limit})
        this.requestReviews()
    }

    collectListPayload = (page: number = 1) => {
        const {pagination} = this.paginationStore
        const body = {
            page: page ? page : pagination.page,
            limit: pagination.limit,
            sort_by: this.sortBy,
        }
        Object.assign(body, this.filter)
        return body
    }

    onSuccessRequest = ({data}) => {
        const {page, limit, total, totalPages, reviews} = data

        this.mergeReviewsData(reviews)
        this.paginationStore.setPagination({
            total,
            page,
            totalPages,
            limit: Number(limit) || 10,
        })
    }

    requestReviews = async (page: number = 1) => {
        const body = this.collectListPayload(page)
        this.setPending(true)
        try {
            const resp = await ReviewsService.getReviews(body)
            this.onSuccessRequest(resp)
        } catch (e) {
            console.log(e)
        } finally {
            this.setPending(false)
        }
    }
}
