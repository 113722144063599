import cn from 'classnames'
import {InformationCircleIcon} from '@heroicons/react/outline'
import {Card} from '@components/layout'
import React from 'react'

const CustomModuleWarn = ({children, icon, className, background}) => {
    return (
        <Card className={className} background={background}>
            <div
                className={cn(
                    'flex flex-col space-y-2 text-center sm:flex-row items-center',
                    'sm:space-y-0 sm:space-x-2 sm:text-left'
                )}
            >
                {icon ? (
                    icon
                ) : (
                    <InformationCircleIcon
                        className={'w-16 h-16 flex-shrink-0 text-gray-400'}
                    />
                )}
                {children}
            </div>
        </Card>
    )
}

export {CustomModuleWarn}
