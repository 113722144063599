import React, {useEffect, useState} from 'react'
import classNames from 'classnames'

import {usePopper} from 'react-popper'

import {useOnClickOutside} from '@hooks'
import {DropdownProvider} from './DropdownContext'

export const Dropdown = (props) => {
    const {trigger, content, popperOptions, className, active} = props

    const [opened, setOpened] = useState(active)
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const {styles, attributes} = usePopper(
        referenceElement,
        popperElement,
        Object.assign(
            {
                placement: 'bottom',
            },
            popperOptions
        )
    )

    useEffect(() => {
        setOpened(active)
    }, [active])

    const handleTriggerClick = () => {
        setOpened(!opened)
    }

    useOnClickOutside({current: referenceElement}, () => {
        setOpened(false)
    })

    const isOpened = props?.opened !== undefined ? props.opened : opened

    return (
        <div ref={setReferenceElement}>
            <div onClick={handleTriggerClick}>{trigger(isOpened)}</div>
            {isOpened && (
                <div
                    className={classNames(
                        'absolute bg-white rounded-lg shadow-lg border border-gray-100 overflow-hidden z-[9999]',
                        'dark:bg-gray-700 dark:border-gray-600',
                        className
                    )}
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                >
                    <DropdownProvider
                        value={{
                            triggerClick: handleTriggerClick,
                            opened,
                            setOpened,
                        }}
                    >
                        {content}
                    </DropdownProvider>
                </div>
            )}
        </div>
    )
}
