import React from 'react'
import {observer} from 'mobx-react'
import {useSelector} from '@hooks'
import {blockedTariff} from '@utils/constants'
import {BlockedInfo} from '@components/layout/ConfirmBanner/components/BlockedInfo'
import {ConfirmBannerWrapper} from '@components/layout/ConfirmBanner/components/ConfirmBannerWrapper'
import {ConfirmData} from '@components/layout/ConfirmBanner/components/ConfirmData'
import {LimitedInfo} from '@components/layout/ConfirmBanner/components/LimitedInfo'
import {useLimitContext} from '@context/LimtiBlockedContext'

export const ConfirmBanner = observer(() => {
    const userStore = useSelector((store) => store.userStore)
    const {exceedLimitCabinets, exceedLimitProducts} = useLimitContext(true)
    const {userData} = userStore
    if (blockedTariff[userData?.tariff?.slug] && userData.parent_user_uuid)
        return (
            <ConfirmBannerWrapper red>
                <BlockedInfo tariff={userData?.tariff?.slug} />
            </ConfirmBannerWrapper>
        )

    if (userStore.isEmulation && (exceedLimitCabinets || exceedLimitProducts))
        return (
            <ConfirmBannerWrapper red>
                <LimitedInfo />
            </ConfirmBannerWrapper>
        )

    return !userData.is_phone_confirmed || !userData.is_email_confirmed ? (
        <ConfirmBannerWrapper>
            <ConfirmData userData={userData} />
        </ConfirmBannerWrapper>
    ) : null
})

ConfirmBanner.displayName = 'ConfirmBanner'
