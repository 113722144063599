import {ApiFactory} from '@services/ApiFactory'
import {baseConfig} from '@services/apiConfigs'
import {MarketUrl} from '../../routes-enums'

const marketInstance = ApiFactory.createApi(baseConfig)

class MarketService {
    static postLoginOzon = async (payload, id) => {
        return await marketInstance.post(
            id ? MarketUrl.editOzonId(id) : MarketUrl.loginOzon,
            payload
        )
    }
    static postLoginWb = async (payload, id) => {
        return await marketInstance.post(
            id ? MarketUrl.editWbId(id) : MarketUrl.loginWb,
            payload
        )
    }
    static postSsoYandex = async () => {
        return await marketInstance.post(MarketUrl.ssoYandex)
    }
    static getCheckSsoYandex = async (query) => {
        return await marketInstance.get(MarketUrl.checkSsoYandex, query)
    }
    static postCampaignsYandex = async (payload) => {
        return await marketInstance.post(MarketUrl.getCampaignsYandex, payload)
    }
    static postLoginYandex = async (payload, id) => {
        return await marketInstance.post(
            id ? MarketUrl.editYandexId(id) : MarketUrl.loginYandex,
            payload
        )
    }
    static getCredentialList = async () => {
        return await marketInstance.get(MarketUrl.credentialList)
    }
    static deleteCredentialPim = async (query) => {
        const config = {
            params: query,
        }
        return await marketInstance.delete(
            MarketUrl.deleteCredential,
            undefined,
            config
        )
    }
    static deleteUnsubscribe = async (id) => {
        return await marketInstance.delete(MarketUrl.deleteUnsubscribe(id))
    }
    static getCredentialStatuses = async (query) => {
        return await marketInstance.get(MarketUrl.credentialsStatuses, query)
    }

    static deleteUnsubscribeDemoAccByIds = async (query) => {
        const config = {
            params: query,
        }
        return await marketInstance.delete(
            MarketUrl.deleteUnsubscribes,
            undefined,
            config
        )
    }

    static postSsoWb = async (id) => {
        return await marketInstance.post(
            id ? MarketUrl.ssoWbEdit(id) : MarketUrl.ssoWb
        )
    }
    static getCheckSsoWb = async (query) => {
        return await marketInstance.get(MarketUrl.checkSsoWb, query)
    }
}

export default MarketService
