import React from 'react'
import NavigationRow from '../components/NavigationRow'
import {Seller24Icon} from '@components/elements/Icon/Icon'
import {SwitchControl} from '@components/navigation/sidebar/components/SwitchControl'
import {Link} from 'react-router-dom'
import {AppPath} from '../../../../routes-enums'

const SidebarDesktop = ({navigation, toggleSidebar, homePage}) => {
    return (
        <div
            className={
                'hidden transition-all md:flex-shrink-0 md:flex md:w-64 md:flex-col md:fixed md:inset-y-0'
            }
        >
            <div className='flex relative flex-col flex-grow bg-gray-800 dark:bg-gray-700 overflow-y-auto'>
                <Link
                    to={homePage}
                    className='flex sticky top-0 pt-5 pb-8 items-center bg-gray-800 dark:bg-gray-700 flex-shrink-0 px-4 cursor-pointer'
                >
                    <Seller24Icon className='h-7 w-auto fill-current text-white' />
                </Link>
                <div className='flex-1 flex flex-col'>
                    <nav className='flex-1 px-2 pb-4 space-y-1'>
                        {Object.keys(navigation).map((key, index) => (
                            <React.Fragment key={key}>
                                {navigation[key].map((row) => (
                                    <NavigationRow
                                        key={row.name}
                                        row={row}
                                        onClick={row.onClick}
                                    />
                                ))}
                                {index < Object.keys(navigation).length - 1 && (
                                    <div key={index} className={'p-2 w-full'}>
                                        <div
                                            className={
                                                'border-b border-gray-900'
                                            }
                                        />
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </nav>
                </div>
                <SwitchControl toggleSidebar={toggleSidebar} />
            </div>
        </div>
    )
}

export {SidebarDesktop}
