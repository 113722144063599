import {action, computed, makeObservable, observable} from 'mobx'
import AuthService from '@services/AuthService/AuthService'
import {toast} from 'react-toastify'
import {ProvidersStore} from '@views/auth/Providers/store/ProvidersStore'

export class ProvidersFieldStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        this.providersStore = new ProvidersStore(rootStore.authStore)
        makeObservable(this, {
            profileProviders: observable,
            linkedProviders: observable,

            setProfileProviders: action,
            setLinkedProviders: action,

            otherProviders: computed,
        })
    }

    profileProviders = []
    linkedProviders = []

    setProfileProviders = (value = []) => {
        this.profileProviders = value
    }
    setLinkedProviders = (value = []) => {
        this.linkedProviders = value
    }

    get otherProviders() {
        if (this.profileProviders.length) {
            return this.profileProviders.filter((provider) => {
                return !this.linkedProviders.find(
                    (lProvider) =>
                        lProvider.serviceName === provider.providerName
                )
            })
        }
        return []
    }

    checkCacheProviders = () => {
        const providers = this.providersStore.providers
        if (providers.length) {
            this.setProfileProviders(providers)
            return true
        }
        return false
    }

    _getMatchingProviderFields = (providerName, data) => {
        switch (providerName) {
            case 'vk': {
                return {
                    name: `${data.firstName} ${data.lastName}`,
                    emails: [],
                }
            }
            case 'yandex': {
                return {
                    name: data.realName,
                    emails: data.emails,
                }
            }
            case 'mailru': {
                return {
                    name: data.name,
                    emails: [data.email],
                }
            }
        }
    }

    matchingLinkProviders = (linkedProvider) => {
        const newLinkedProvider = []
        linkedProvider.forEach(({serviceDataOfUser, ...providerRest}) => {
            const newProvider = {
                ...providerRest,
                data: {
                    ...this._getMatchingProviderFields(
                        providerRest.serviceName,
                        serviceDataOfUser
                    ),
                },
            }
            newLinkedProvider.push(newProvider)
        })
        this.setLinkedProviders(newLinkedProvider)
    }

    onLocationChange = (providerName) => {
        const code = localStorage.getItem('provider_code')
        const name = localStorage.getItem('provider_name')
        if (code) {
            clearInterval(this.wndInterval)
            this.getConnectedNewProvider(providerName || name, code)

            setTimeout(() => {
                localStorage.removeItem('provider_code')
                localStorage.removeItem('provider_name')
                this.wnd.close()
            }, 10)
        }
    }

    openProvidersAuth = (authLink, providerName) => {
        this.wnd = window.open(
            authLink,
            'Login',
            'width=600,height=700 top=' +
                (window.screen.height - 700) / 2 +
                ',left=' +
                (window.screen.width - 600) / 2 +
                ', resizable=no'
        )
        //window.location.href = authLink

        if (this.wnd) {
            this.wndInterval = setInterval(
                () => this.onLocationChange(providerName),
                1000
            )
        }
    }

    getProviders = async () => {
        if (this.checkCacheProviders()) return

        try {
            const {data} = await this.providersStore.getProviders()
            this.setProfileProviders(data)
        } catch (e) {
            console.log(e)
        }
    }

    getConnectedNewProvider = async (providerName, code) => {
        const payload = {
            code,
        }
        try {
            const {data} = await AuthService.getAuthByProvider(
                payload,
                providerName
            )
            this.rootStore.authStore.setTokens(data)
            await this.rootStore.accountStore.stores.profileStore.getProfile()
        } catch (e) {
            console.log(e)
            toast(e.response.data.message, {type: 'error'})
        }
    }
}
