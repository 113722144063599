import {useEffect, useState, useRef, useCallback} from 'react'
import {usePopper} from 'react-popper'
import {useOnClickOutside} from '@hooks'
const ANIMATION_DURATION = 100

const applyArrowHide = {
    name: 'applyArrowHide',
    enabled: true,
    phase: 'write',
    fn({state}) {
        const {arrow} = state.elements

        if (arrow) {
            if (state.modifiersData.arrow.centerOffset !== 0) {
                arrow.setAttribute('data-hide', '')
            } else {
                arrow.removeAttribute('data-hide')
            }
        }
    },
}

const useTooltipLite = ({
    placement = 'right',
    active = false,
    offset = [0, 10],
    isArrow,
}) => {
    const [isOpen, setIsOpen] = useState(active)
    const hoverTimeout = useRef(0)
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const [arrowElement, setArrowElement] = useState(null)
    const blockTimeout = useState(0)

    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset,
                },
            },
            {
                name: 'arrow',
                options: {
                    element: arrowElement,
                },
            },
            {
                name: 'flip',
                enabled: true,
                options: {
                    fallbackPlacements: ['top', 'bottom', 'left', 'right'],
                },
            },
            applyArrowHide,
        ],
    })

    const defaultStyles = {
        opacity: 0,
        transition: `opacity ${ANIMATION_DURATION}ms ease-in-out`,
    }

    const transitionStyles = {
        entering: {opacity: 0},
        entered: {opacity: 1},
        exiting: {opacity: 0},
        exited: {opacity: 0},
    }

    const handleMouseEnter = useCallback(() => {
        if (blockTimeout.current !== null) {
            clearTimeout(blockTimeout.current)
        }

        hoverTimeout.current = setTimeout(() => {
            setIsOpen(Boolean(hoverTimeout.current))
        }, 150)
    }, [blockTimeout, setIsOpen])

    const handleMouseLeave = useCallback(() => {
        if (!active) {
            if (hoverTimeout.current) clearTimeout(hoverTimeout.current)

            blockTimeout.current = setTimeout(() => {
                setIsOpen(false)
            }, 100)
        }
    }, [active, blockTimeout, setIsOpen])

    // useOnClickOutside({current: referenceElement}, () => {
    //     setIsOpen(false)
    // })

    useEffect(() => {
        setIsOpen(active)
    }, [active])

    useEffect(() => {
        popperElement?.addEventListener('mouseenter', handleMouseEnter)
        popperElement?.addEventListener('mouseleave', handleMouseLeave)

        return () => {
            popperElement?.removeEventListener('mouseenter', handleMouseEnter)
            popperElement?.removeEventListener('mouseleave', handleMouseLeave)
            if (hoverTimeout.current) {
                clearTimeout(hoverTimeout.current)
            }
            if (blockTimeout.current) {
                clearTimeout(blockTimeout.current)
            }
        }
    }, [popperElement, handleMouseEnter, handleMouseLeave])

    useEffect(() => {
        referenceElement?.addEventListener('mouseleave', handleMouseLeave)
        referenceElement?.addEventListener('mouseenter', handleMouseEnter)

        return () => {
            referenceElement?.removeEventListener(
                'mouseleave',
                handleMouseLeave
            )
            referenceElement?.removeEventListener(
                'mouseenter',
                handleMouseEnter
            )
        }
    }, [referenceElement, handleMouseEnter, handleMouseLeave])

    const getSide = (placement, attributes) => {
        const realPlacement = attributes?.popper?.['data-popper-placement']
        const sides = ['bottom', 'top', 'right', 'left']

        if (realPlacement) {
            return sides.find((side) => realPlacement.includes(side))
        }

        return sides.find((side) => placement.includes(side))
    }

    return {
        setReferenceElement,
        isOpen,
        ANIMATION_DURATION,
        setPopperElement,
        styles,
        defaultStyles,
        transitionStyles,
        attributes,
        setArrowElement,
        isArrow,
        side: getSide(placement, attributes),
    }
}

export default useTooltipLite
