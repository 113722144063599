import {action, makeObservable, observable} from 'mobx'
import ProductsService from '@services/ProductsService/ProductsService'
import {toast} from 'react-toastify'

class ProductStore {
    dictionarySource = {}

    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            product: observable,
            pending: observable,
            productStatusPollingInterval: observable,
            clearProduct: action,
            setProduct: action,
            setPending: action,
            setAttributes: action,
            stopProductStatusPolling: action,
            startProductStatusPolling: action,
            reset: action,
        })
    }

    product = {}

    pending = false

    productStatusPollingInterval = undefined

    reset = () => {
        this.clearProduct()
    }

    setProduct = (obj) => {
        this.product = Object.assign({}, this.product, obj)
    }

    clearProduct = () => {
        this.product = {}
    }

    setPending = (value = true) => {
        this.pending = value
    }

    setPrice = (array = []) => {
        this.product.price = array
    }

    setAttributes = (source) => {
        this.product.relAttrs = source.relAttrs
        this.product.mpCategory = source.mpCategory
    }

    getProduct = async (id, withLoader = true) => {
        if (withLoader) this.setPending(true)
        try {
            const {data} = await ProductsService.getProduct(id)
            this.stopProductStatusPolling()
            this.clearProduct()
            this.setProduct(data)
            if (withLoader) this.setPending(false)
            if (withLoader) {
                this.setPending(false)
            }
        } catch (error) {
            console.log(error)
            if (withLoader) this.setPending(false)
        }
    }

    getRelativeAttributes = async () => {
        if (!this.product.id) return
        try {
            const {data} = await ProductsService.getProduct(this.product.id)
            this.setAttributes(data)
            this.setProduct(data)
            return data
        } catch (error) {
            console.log(error)
        }
    }

    getProductStatus = async () => {
        if (!this.product?.id) return
        try {
            const {data} = await ProductsService.getProduct(this.product.id)
            if (data.status) this.setProduct({status: data.status})
        } catch (error) {
            console.log(error)
        }
    }

    getProductPackages = async () => {
        if (!this.product?.id) return
        try {
            const {data} = await ProductsService.getPackages(this.product.id)
            if (data.package) this.setProduct({package: data.package})
        } catch (error) {
            console.log(error)
            toast('Ошибка создания упаковки', {type: 'error'})
        }
    }

    putProductPackages = async (payload) => {
        if (!this.product?.id) return
        try {
            const {data} = await ProductsService.putPackages(
                this.product.id,
                payload
            )
            delete data.MOCK
            if (data) this.setProduct({package: data})
        } catch (error) {
            console.log(error)
            toast('Ошибка создания упаковки', {type: 'error'})
        }
    }

    getFeedCats = async () => {
        try {
            const {data} = await ProductsService.getFeedCats()
        } catch (error) {
            console.log(error)
        }
    }

    exportProduct = async (payload) => {
        try {
            const {data} = await ProductsService.putExportProducts(payload)
            this.stopProductStatusPolling()
            this.startProductStatusPolling()
            return Promise.resolve({data})
        } catch (error) {
            console.log(error)
        }
    }

    getDictionary = async (body) => {
        try {
            const {data} = await ProductsService.getDictionary(
                body,
                this.dictionarySource
            )
            this.dictionarySource = {}
            return Promise.resolve({data})
        } catch (e) {
            return Promise.reject(e)
        }
    }

    startProductStatusPolling = () => {
        this.productStatusPollingInterval = setInterval(() => {
            this.getProductStatus()
        }, 60 * 1000)
    }

    stopProductStatusPolling = () => {
        if (this.productStatusPollingInterval) {
            this.productStatusPollingInterval = clearInterval(
                this.productStatusPollingInterval
            )
        }
    }
}

export default ProductStore
