import {makeAutoObservable, reaction} from 'mobx'
import {SelectedStore} from '../../../../../store/SelectStore'
import {wbFilter} from '@utils/helpers'
import {priorityData} from '@views/marketplaces/constants'
import {ImportWbService} from '@services/ImportWbService'
import {LoaderStore} from '../../../../../store/LoaderStore'
import {toast} from 'react-toastify'
import {format} from 'date-fns'

export class ImportWbFilterStore {
    constructor(marketStore) {
        this.marketStore = marketStore
        makeAutoObservable(this)
        this.initialCabinet(marketStore.credentialsOptions)

        this.disposerCabinet = reaction(
            () => this.cabinet.selected,
            () => {
                this.initialPriorityData(this.cabinet.selected.key)
            }
        )
    }

    cabinet = new SelectedStore()
    priorityData = new SelectedStore(priorityData)
    sourceLoading = new LoaderStore()
    recalcLoading = new LoaderStore()
    importWbSearch = ''

    initialCabinet = (credentialsOptions) => {
        const cabinet = credentialsOptions.filter(wbFilter)
        this.cabinet.setOptions(cabinet)
    }

    initialPriorityData = (cabinetId) => {
        const dataSource = this.marketStore.getDataSourceById(cabinetId)
        this.priorityData.setOptionById(dataSource)
    }

    setImportWbSearch = (value = '') => {
        this.importWbSearch = value
    }

    get cabinetCompanyName() {
        const cabinet = this.marketStore.getCredentialByID(
            this.cabinet.selected?.key
        )
        return cabinet?.companyValid ? cabinet?.companyName : ''
    }

    get validLegalEntity() {
        const cabinet = this.marketStore.getCredentialByID(
            this.cabinet.selected?.key
        )
        return !!cabinet?.companyValid
    }

    get isRecalcDisabled() {
        return this.sourceLoading.loading || this.recalcLoading.loading
    }

    collectImportWbPayload = () => {
        return {
            client_filter: this.cabinet.selected.key
                ? [this.cabinet.selected.key]
                : [],
            report_search_input: this.importWbSearch,
        }
    }

    patchSource = async () => {
        if (!this.cabinet.selected.key) return
        const paylaod = {
            cabinet: this.cabinet.selected.key,
            data_source: this.priorityData.selected.id,
        }
        this.sourceLoading.start()
        try {
            const {data} = await ImportWbService.patchSource(paylaod)
            this.marketStore.getList()
        } catch (e) {
            toast(e.response.data.message, {type: 'error'})
        } finally {
            this.sourceLoading.stop()
        }
    }

    postRecalc = async (startDate, endDate) => {
        if (!this.cabinet.selected.key) return
        const paylaod = {
            cabinet: this.cabinet.selected.key,
            date_start: format(startDate, 'yyyy-MM-dd'),
            date_end: format(endDate, 'yyyy-MM-dd'),
        }
        this.recalcLoading.start()
        try {
            const {data} = await ImportWbService.postRecalc(paylaod)
            return Promise.resolve(data)
        } catch (e) {
            toast(e.response.data.message, {type: 'error'})
            return Promise.reject(e)
        } finally {
            this.recalcLoading.stop()
        }
    }

    destroy = () => {
        this.disposerCabinet()
    }
}
