import React from 'react'
import SelectBody from '@components/forms/Selects/Select/SelectBody'
import {Portal} from '@components/elements'

export const SelectPortal = (props) => {
    return (
        <Portal portalContainerId={'select'}>
            <SelectBody {...props} />
        </Portal>
    )
}
