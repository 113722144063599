import React from 'react'
import cn from 'classnames'
import {Popover} from '@headlessui/react'
import {ExternalLinkIcon} from '@heroicons/react/outline'
import {ImageLink} from '@components/elements'

export const SkuDropdownBody = ({
    setPopperElement,
    styles,
    attributes,
    skyItems,
    url,
}) => {
    return (
        <Popover.Panel
            className='absolute z-10 w-[280px] max-w-sm px-4 mt-1 sm:px-0 lg:max-w-3xl'
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}
        >
            <div
                className={cn(
                    'bg-white rounded-lg shadow-lg border border-gray-100 overflow-hidden z-[9999]',
                    'dark:bg-gray-700 dark:border-gray-600'
                )}
            >
                <div className='relative grid gap-8 p-7'>
                    <ul className={'sku-dropdown__list'}>
                        {skyItems.list.map((item, idx) => {
                            return (
                                <li key={idx} className={'mb-2 text-xs'}>
                                    <div
                                        className={
                                            'text-gray-500 dark:text-gray-400'
                                        }
                                    >
                                        {item.name}
                                    </div>
                                    <span
                                        className={
                                            'text-gray-900 dark:text-gray-100'
                                        }
                                    >
                                        {item.value}
                                    </span>
                                </li>
                            )
                        })}
                        {url ? (
                            <li className={'mb-2 text-xs'}>
                                <ImageLink url={url} />
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>
        </Popover.Panel>
    )
}
