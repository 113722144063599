import React from 'react'
import NavigationRowSlim from '@components/navigation/sidebar/components/NavigationRowSlim'
import {LogoMiniIcon} from '@components/elements/Icon/Icon'
import {SwitchControl} from '@components/navigation/sidebar/components/SwitchControl'
import {AppPath} from '../../../../routes-enums'
import {Link} from 'react-router-dom'

const SlimSidebar = ({navigation, toggleSidebar, homePage}) => {
    return (
        <div className='hidden md:flex md:w-16 md:flex-col md:fixed md:inset-y-0'>
            <div className='flex relative flex-col flex-grow  bg-gray-800 dark:bg-gray-700 overflow-y-auto'>
                <Link
                    to={homePage}
                    className={
                        'sticky top-0 flex items-center pt-5 pb-8 bg-gray-800 dark:bg-gray-700 flex-shrink-0 px-2 cursor-pointer'
                    }
                >
                    <LogoMiniIcon className='h-7 w-auto fill-current text-white' />
                </Link>
                <div className='flex-1 flex flex-col'>
                    <nav className='flex-1 px-2 pb-4 space-y-1'>
                        {Object.keys(navigation).map((groupTabKey, index) => (
                            <React.Fragment key={groupTabKey}>
                                {navigation[groupTabKey].map((row) => (
                                    <NavigationRowSlim
                                        key={row.name}
                                        row={row}
                                        onClick={row.onClick}
                                    />
                                ))}
                                {index < Object.keys(navigation).length - 1 && (
                                    <div key={index} className={'p-2 w-full'}>
                                        <div
                                            className={
                                                'border-b border-gray-900'
                                            }
                                        />
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </nav>
                </div>
                <SwitchControl toggleSidebar={toggleSidebar} isSlim={true} />
            </div>
        </div>
    )
}

export {SlimSidebar}
