//libs
import {makeObservable, observable, action} from 'mobx'

//services
import AttrsMatchingService from '@services/AttrsMatchingService/AttrsMatchingService'

//constants
const initialCat = {
    id: 0,
    name: 'Выберите категорию',
}

const initialAttr = {
    id: 0,
    name: 'Выберите свойство',
}

const initialPagination = {
    total: 0,
    page: 1,
}

class MasterAttrsStore {
    constructor(rootStore, parentStore) {
        this.rootStore = rootStore
        this.parentStore = parentStore

        makeObservable(this, {
            edit: observable,
            editableItemId: observable,
            masterCats: observable,
            masterAttr: observable,
            masterAttrDict: observable,
            matched: observable,
            setEdit: action,
            setEditableItemId: action,
            setMasterCats: action,
            pushMasterCats: action,
            spliceMasterCats: action,
            onMasterCatChange: action,
            setMasterAttrOptions: action,
            changeMasterAttr: action,
            handleMasterDictSearch: action,
            onChangeMasterDictItem: action,
            mergeCategories: action,
            setMatched: action,
            clearSelectedMasterDictItem: action,
        })
    }

    edit = false

    editableItemId = undefined

    masterCats = []

    masterAttr = {
        options: [],
        searchString: '',
        selected: initialAttr,
    }

    masterAttrDict = {
        options: [],
        selected: undefined,
        pagination: initialPagination,
    }

    matched = []

    setEdit = (value = true) => {
        this.edit = value
    }

    setEditableItemId = (value) => {
        this.editableItemId = value
    }

    onMasterEdit = async (itemId, linkType = 'market') => {
        this.setEdit(true)

        const {id, value, links} = await this.getMasterAttrItemLinks(itemId)
        this.setEditableItemId(id)

        this.onChangeMasterDictItem({id, links, name: value})

        if (linkType === 'market') {
            this.parentStore.stores.marketAttrsStore.setEdit(true)
            this.parentStore.stores.marketAttrsStore.setLinks(
                links?.marketplaces
            )
            this.parentStore.stores.marketAttrsStore.clearAllCheckedMarketAttrDictItems()
        } else if (linkType === 'feed') {
            this.parentStore.stores.feedAttrsStore.setEdit(true)
            this.parentStore.stores.feedAttrsStore.setLinks(links?.feeds)
        }
    }

    setMasterCats = (array = []) => {
        this.masterCats = array
    }

    pushMasterCats = (obj) => {
        this.masterCats.push(obj)
    }

    spliceMasterCats = (index) => {
        this.masterCats.splice(index, this.masterCats.length - index)
    }

    onMasterCatChange = (index, child, withCatsMerge = false) => {
        this.spliceMasterCats(index + 1)
        this.masterCats[index].selected = child
        this.getMasterCats(child.id, withCatsMerge)
    }

    setMasterAttrOptions = (array = []) => {
        this.masterAttr.options = array
    }

    changeMasterAttr = (value) => {
        this.masterAttr.selected = value
    }

    handleChangeMasterAttr = (value) => {
        this.changeMasterAttr(value)
        this.getMasterAttrDict()
    }

    handleMasterDictSearch = (e) => {
        this.masterAttr.searchString = e.target.value
        this.getMasterAttrDict(e.target.value)
    }

    setMasterAttrDictOptions = (array = []) => {
        this.masterAttrDict.options = array
    }

    onChangeMasterDictItem = (item) => {
        if (Number(this.masterAttrDict.selected?.id) === Number(item?.id)) {
            this.masterAttrDict.selected = undefined
        } else {
            this.masterAttrDict.selected = item
        }
    }

    clearSelectedMasterDictItem = () => {
        this.masterAttrDict.selected = undefined
    }

    setMatched = (array = []) => {
        this.matched = array
    }

    getMasterTopCats = async () => {
        this.setMatched([])
        this.setMasterCats([])
        this.setMasterAttrOptions([])
        this.changeMasterAttr(initialAttr)
        this.setMasterAttrDictOptions([])
        this.clearSelectedMasterDictItem()
        try {
            const {
                data: {childs},
            } = await AttrsMatchingService.getMasterTopCats()
            this.pushMasterCats({
                options: childs,
                selected: initialCat,
            })
        } catch (error) {
            console.log(error)
        }
    }

    getMasterCats = async (catId, withCatsMerge = false) => {
        this.setMatched([])
        this.setMasterAttrOptions([])
        this.changeMasterAttr(initialAttr)
        this.setMasterAttrDictOptions([])
        this.clearSelectedMasterDictItem()
        try {
            const {
                data: {id, parentId, childs, attrs, matchedCategories},
            } = await AttrsMatchingService.getMasterCats(catId)
            this.pushMasterCats({
                options: childs,
                selected: initialCat,
                id,
                parentId,
            })
            if (attrs?.length) {
                this.setMasterAttrOptions(attrs)
            }
            if (matchedCategories?.length && withCatsMerge) {
                this.mergeCategories(matchedCategories)
            }
        } catch (error) {
            console.log(error)
        }
    }

    mergeCategories = async (source) => {
        const marketplaces =
            this.parentStore.stores.marketAttrsStore.marketplaces
        if (!marketplaces?.length) await this.getMarketplaces()

        source.forEach((market) => {
            const marketIndex =
                this.parentStore.stores.marketAttrsStore.findMarketIndex(
                    market.marketplaceId
                )
            const tree = market.matchedCategory.tree

            if (marketIndex >= 0) {
                this.parentStore.stores.marketAttrsStore.marketplaces[
                    marketIndex
                ].marketCats = tree.map((cat) => ({
                    options: [],
                    selected: {id: cat.id, name: cat.name},
                }))
            }
        })

        try {
            for (const [marketIndex, market] of marketplaces.entries()) {
                this.parentStore.stores.marketAttrsStore.setMarketAttrDictOptions(
                    market.id,
                    []
                )
                const {
                    data: {childs: topChildren},
                } = await AttrsMatchingService.getMarketplaceTopCats(market.id)
                if (marketplaces[marketIndex].marketCats.length) {
                    this.parentStore.stores.marketAttrsStore.marketplaces[
                        marketIndex
                    ].marketCats[0].options = topChildren
                    for (const [catIndex, cat] of marketplaces[
                        marketIndex
                    ].marketCats.entries()) {
                        if (cat.selected?.id !== 0) {
                            const {
                                data: {childs: catChildren, attrs},
                            } = await AttrsMatchingService.getMarketplaceCats(
                                market.id,
                                cat.selected?.id
                            )
                            if (
                                marketplaces[marketIndex].marketCats[
                                    catIndex + 1
                                ]
                            ) {
                                this.parentStore.stores.marketAttrsStore.marketplaces[
                                    marketIndex
                                ].marketCats[catIndex + 1].options = catChildren
                            }
                            if (attrs?.length) {
                                this.parentStore.stores.marketAttrsStore.setMarketAttrOptions(
                                    market.id,
                                    attrs
                                )
                            } else {
                                this.parentStore.stores.marketAttrsStore.setMarketAttrOptions(
                                    market.id,
                                    []
                                )
                            }
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    getMasterAttrDict = async (search = '') => {
        this.clearSelectedMasterDictItem()

        const query = search
            ? {
                  filterByName: search,
              }
            : undefined

        const id = this.masterAttr.selected.id

        try {
            const {
                data: {items, linkedAttrs},
            } = await AttrsMatchingService.getMasterAttrDict(id, query)
            this.setMasterAttrDictOptions(
                items.map((item) => ({
                    id: item.id,
                    name: item.value,
                    links: item.links,
                }))
            )
            if (linkedAttrs) {
                this.setMatched(linkedAttrs)
            } else {
                this.setMatched([])
            }
        } catch (error) {
            console.log(error)
        }
    }

    getMasterAttrItemLinks = async (id) => {
        try {
            const {data} = await AttrsMatchingService.getMasterAttrItemLinks(id)
            return data
        } catch (error) {
            console.log(error)
        }
    }
}

export default MasterAttrsStore
