import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {BellButton} from './BellButton'
import {useNotificationsContext} from '@components/overlays/notifications'

export const NotificationCenter = observer(() => {
    const notificationsStore = useNotificationsContext()

    useEffect(() => {
        notificationsStore.getNotifications(1, true)
    }, [])

    return (
        <>
            <BellButton
                unreadCount={notificationsStore.unreadCount}
                onClick={notificationsStore.notifyControl.open}
            />
        </>
    )
})
