import {action, makeObservable, observable} from 'mobx'

class ThemeStore {
    constructor() {
        makeObservable(this, {
            isDarkTheme: observable,
            setDarkTheme: action,
            isSystemTheme: observable,
            setSystemTheme: action,
        })
        if (!localStorage.theme) this.listenSystem()
    }

    isSystemTheme = !localStorage.theme

    setSystemTheme = (value = true) => {
        this.isSystemTheme = value
        if (value) {
            localStorage.removeItem('theme')
            this.setDarkTheme(
                window.matchMedia('(prefers-color-scheme: dark)').matches
            )
            this.listenSystem()
        } else {
            localStorage.theme = this.isDarkTheme ? 'dark' : 'light'
            this.canselListener()
        }
    }

    toggleSystemTheme = () => {
        this.setSystemTheme(!this.isSystemTheme)
    }

    listenSystem = () => {
        window
            .matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', (e) => {
                this.setDarkTheme(e.matches)
            })
    }

    canselListener = () => {
        window
            .matchMedia('(prefers-color-scheme: dark)')
            .removeEventListener('change', (e) => {
                this.setDarkTheme(e.matches)
            })
    }

    isDarkTheme = document.documentElement.classList.contains('dark')

    setDarkTheme = (value = true) => {
        this.isDarkTheme = value
        if (value) {
            document.documentElement.classList.add('dark')
            if (!this.isSystemTheme) localStorage.theme = 'dark'
        } else {
            document.documentElement.classList.remove('dark')
            if (!this.isSystemTheme) localStorage.theme = 'light'
        }
    }

    toggleTheme = () => {
        this.setDarkTheme(!this.isDarkTheme)
    }
}

export default ThemeStore
