import {action, computed, makeObservable, observable} from 'mobx'

export class MultiSelectedStore {
    constructor(options = []) {
        this.options = options
        makeObservable(this, {
            selectedInstance: observable,
            options: observable,

            setSelectedInstance: action,
            setOptions: action,
            setSelectedItem: action,
            setSelectedInstanceKey: action,
            setOptionsByKey: action,
            reset: action,

            selectedInstanceKey: computed,
            selectedOptions: computed,
        })
    }

    selectedInstance = {
        selectedOptions: [],
        selectedInstanceKey: [],
    }

    options = []

    setSelectedInstance = (array = []) => {
        this.selectedInstance.selectedOptions = array
    }

    setSelectedInstanceKey = (array = []) => {
        this.selectedInstance.selectedInstanceKey = array
    }

    setOptions = (array = []) => {
        this.options = array
    }

    setOptionsByKey = (keys = []) => {
        const selectedOptions = this.options.filter((option) =>
            keys.find((key) => Number(key) === option.key)
        )
        this.setSelectedInstance(selectedOptions)
    }

    setSelectedItem = (array = []) => {
        const providerKey = array.map((item) => item.key)
        this.selectedInstance.selectedOptions = array
        this.selectedInstance.selectedInstanceKey = providerKey
    }

    get selectedInstanceKey() {
        return this.selectedInstance.selectedInstanceKey
    }

    get selectedOptions() {
        return this.selectedInstance.selectedOptions
    }

    reset = (): void => {
        this.selectedInstance.selectedOptions = []
        this.selectedInstance.selectedInstanceKey = []
    }
}
