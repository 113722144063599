export const carrotQuestInit = () => {
    function t(t, e) {
        return function () {
            window.carrotquestasync.push(t, arguments)
        }
    }
    if ('undefined' == typeof window.carrotquest) {
        const e = document.createElement('script')
        e.type = 'text/javascript'
        e.async = !0
        e.src = 'https://cdn.carrotquest.app/api.min.js'
        document.getElementsByTagName('head')[0].appendChild(e)
        window.carrotquest = {}
        window.carrotquestasync = []
        window.carrotquest.settings = {}
        for (
            let n = [
                    'connect',
                    'track',
                    'identify',
                    'auth',
                    'onReady',
                    'addCallback',
                    'removeCallback',
                    'trackMessageInteraction',
                ],
                a = 0;
            a < n.length;
            a++
        )
            window.carrotquest[n[a]] = t(n[a])
    }
    window.carrotquest.connect('57240-bb472b52c5a80c0e91b5481a9b')
}
