const regexes = {
    //email: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/ig,
    // eslint-disable-next-line no-useless-escape
    email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
    phone: /([0-9]{10})/g,
    phoneAllVariants: /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{10}$/,
    telRu: /\+7 [976][\d]{2} [\d]{3}-[\d]{2}-[\d]{2}/gs,
    formatKZ: /\+7([76])/,
    letters: /(?=.*[A-Z])(?=.*[a-z]).*/g,
    anyLetters: /(?=.*[A-Z])|(?=.*[a-z]).*/g,
    onlyChars: /^[a-zA-Z_а-яА-Я ]*$/,
    symbols: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/g,
    numbers: /[0-9]/g,
    onlyNumbers: /^\d+$/g,
    vehicleNumber: /^[A-Z]{1}[0-9]{3}[A-Z]{2}[0-9]{2,3}$/g,
    urls: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zа-я0-9]+([-.]{1}[a-z0-9а-я]+)*\.[a-zа-я]{2,5}(:[0-9]{1,5})?(\/.*)?$/g,
    withoutSpaces: /^(\S+$)/g,
    startPhone: /^(\+7|7|8)9/g,
    time: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/g,
    inn: /^([0-9]{10}|[0-9]{12})$/g,
}

export {regexes}
