import React, {useState} from 'react'
import {observer} from 'mobx-react'

import {ModalHeader} from '@components/overlays'
import {Button} from '@components/elements'
import {DatePicker} from '@components/forms/DatePicker'
import {
    getEndOfWeek,
    getLastSundayExcludingCurrentWeek,
    getMonday12WeeksAgo,
    getStartOfWeek,
} from '@views/marketplaces/pages/ImportWb/utils'

export const ImportWbReculcDataModal = observer(({onClose, onApply}) => {
    const [startDate, setStartDate] = useState(getMonday12WeeksAgo())
    const [endDate, setEndDate] = useState(getLastSundayExcludingCurrentWeek())
    const handleClickApply = () => {
        onApply(getStartOfWeek(startDate), getEndOfWeek(endDate))
    }
    return (
        <div className={'flex flex-col'}>
            <ModalHeader
                title={'Запустить пересчет данных'}
                onCancel={onClose}
            />

            <div className={'flex flex-col space-y-4'}>
                <DatePicker
                    label={'Дата начала'}
                    date={startDate}
                    minDate={getMonday12WeeksAgo()}
                    scrollEnabled={false}
                    maxDate={getLastSundayExcludingCurrentWeek()}
                    onChange={setStartDate}
                />
                <DatePicker
                    label={'Дата окончания'}
                    scrollEnabled={false}
                    date={endDate}
                    minDate={startDate}
                    maxDate={getLastSundayExcludingCurrentWeek()}
                    onChange={setEndDate}
                />
            </div>

            <div className={'flex items-center gap-4 mt-8'}>
                <Button onClick={handleClickApply}>Запустить</Button>
                <Button color={'white'} onClick={onClose}>
                    Отмена
                </Button>
            </div>
        </div>
    )
})

ImportWbReculcDataModal.displayName = 'ImportWbReculcDataModal'
