import axiosLib from 'axios'
import {TokenService} from '@services/TokenService'
import {
    getBrowser,
    getStandName,
    getUserEnvBrowser,
    keysToCamel,
} from '@utils/helpers'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import GoogleTagManager from '@utils/googleUtils/googleTagManager'
import {errors} from '@utils/constants'
import {rootStore} from '@'
const MAX_RETRY_COUNT = 3
class Api {
    service
    baseUrl
    retryCount = 0
    constructor(config) {
        let service = axiosLib.create(config)
        this.baseUrl = config.baseURL
        service.interceptors.response.use(this.handleSuccess, this.handleError)
        service.interceptors.request.use(
            (config) => {
                const token = TokenService.getToken()
                if (config.isAuth === false) {
                    return config
                }
                if (!config.headers.Authorization && token) {
                    config.headers.Authorization = `Bearer ${token}`
                }
                config.headers['x-browser-version'] = getBrowser()
                config.headers['X-User-Env'] = getUserEnvBrowser()
                return config
            },
            (error) => Promise.reject(error)
        )
        this.service = service
        createAuthRefreshInterceptor(this.service, this.refreshAuthLogic, {
            retryInstance: this.service,
            onRetry: (requestConfig) => {
                if (this.retryCount >= MAX_RETRY_COUNT) {
                    // Прекращаем повторные запросы после достижения лимита
                    this.retryCount = 0 // Сбрасываем счетчик
                    return Promise.reject(requestConfig)
                }
                this.retryCount += 1
                const token = TokenService.getToken()
                const data = requestConfig.data
                    ? JSON.parse(requestConfig.data)
                    : undefined
                delete requestConfig.cancelToken
                return {
                    ...requestConfig,
                    data: data,
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    baseURL: this.baseUrl,
                }
            },
        })
    }

    async get(url, query, config) {
        const newConfig = {
            params: query,
            ...config,
        }
        const {data, headers} = await this.service.get(url, newConfig)
        return {data: keysToCamel(data), headers}
    }

    async get_raw(url, query, config) {
        const newConfig = {
            params: query,
            ...config,
        }
        const {data, headers} = await this.service.get(url, newConfig)
        return {data: data, headers}
    }

    async post(url, body, config) {
        const {data, headers} = await this.service.post(url, body, config)
        return {data: keysToCamel(data), headers}
    }

    async getFile(url, body, config) {
        const newConfig = {
            responseType: 'blob',
            ...config,
        }
        const {data, headers} = await this.service.post(url, body, newConfig)
        return {data, headers}
    }

    async getFileWithGet(url, query, config) {
        const newConfig = {
            responseType: 'blob',
            ...config,
        }
        const {data, headers} = await this.get_raw(url, query, newConfig)
        return {data, headers}
    }

    async postFile(url, file, config, callBack, params = {}) {
        const newConfig = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            ...this._getProgress(callBack),
            ...config,
        }
        const fileData = new FormData()
        if (Array.isArray(file.file)) {
            file.file.forEach((item, index) => {
                fileData.append(`${file.name}`, item)
            })
        } else {
            fileData.append(file.name ? file.name : 'file', file.file)
        }

        const {data, headers} = await this.service.post(
            url,
            fileData,
            newConfig
        )
        return {data: keysToCamel(data), headers}
    }

    async put(url, body, config) {
        const {data, headers} = await this.service.put(url, body, config)
        return {data: keysToCamel(data), headers}
    }

    async patch(url, body, config) {
        const {data, headers} = await this.service.patch(url, body, config)
        return {data: keysToCamel(data), headers}
    }

    async delete(url, body, config) {
        const {data, headers} = await this.service.delete(url, {
            ...config,
            data: body,
        })
        return {data: keysToCamel(data), headers}
    }

    handleSuccess = (response) => {
        this.retryCount = 0
        return response
    }

    handleError = async (error) => {
        if (axiosLib.isCancel(error)) return Promise.reject(errors.cancelToken)
        if (error.response && error.response.status === 401) {
            this.retryCount += 1 // Увеличение счетчика при ошибке 401
        }
        if (
            error.response &&
            error.response.status === 500 &&
            !error.config.__retryAttempt
        ) {
            if (!error.response.data) {
                try {
                    error.config.__retryAttempt = true
                    const {config} = error
                    const data = await this.service(config)
                    return Promise.resolve(data)
                } catch (retryError) {
                    const errorText = getErrorText(retryError)
                    if (
                        retryError.response.status === 500 &&
                        getStandName() === 'prod'
                    ) {
                        rootStore.errorStore.sendErrorGTM(
                            errorText,
                            retryError.response
                        )
                        sendErrorToGTM(errorText)
                    }
                    return Promise.reject(keysToCamel(retryError))
                }
            } else {
                if (getStandName() === 'prod') {
                    const errorText = getErrorText(error)
                    rootStore.errorStore.sendErrorGTM(errorText, error.response)
                    sendErrorToGTM(errorText)
                }
            }
        }
        machiningError(error)
        return Promise.reject(keysToCamel(error))
    }

    refreshAuthLogic = async (failedRequest) => {
        const originalConfig = failedRequest.config
        if (!TokenService.isRefreshedTokenExists())
            return Promise.reject(new Error('Токен отсутствует'))
        try {
            const data = await rootStore.authStore.sendRefreshToken()
            const token = TokenService.getToken()
            originalConfig.headers.Authorization = `Bearer ${token}`
            return Promise.resolve(failedRequest)
        } catch (e) {
            return Promise.reject(e)
        }
    }

    _getProgress(callback) {
        return {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                )
                callback && callback(percentCompleted)
            },
        }
    }
}

const getErrorText = (error) => {
    const url = error.response.config.url
    const baseURL = error.response.config.baseURL
    const status =
        error.response && error.response.status ? error.response.status : ''
    return `Запрос ${baseURL}${url} вернул ошибку ${status}. Пожалуйста, обратитесь в поддержку на info@seller24.ru.`
}

const machiningError = (error) => {
    if (!error) return
    if (
        error.response &&
        (error.response.status === 401 || error.response.status === 500)
    ) {
        return
    }
    sendErrorToGTM(getErrorText(error))
}

const sendErrorToGTM = (errorText) => {
    if (TokenService.isEmulationExists()) return
    GoogleTagManager.dataLayer({
        event: 'mainEvent',
        eventCategory: 'Message - Server',
        eventAction: 'Message',
        eventLabel: errorText,
        email: rootStore.userStore?.userEmail || 'none',
        user_id: rootStore.userStore?.userData?.user_id || 'none',
    })
}

export function getCancelTokenSource() {
    return axiosLib.CancelToken.source()
}

export function updateCancelToken(data) {
    if (data === undefined || data === null) return {}
    if (data.source)
        data.source.cancel('Operation canceled due to new request.')
    data.source = getCancelTokenSource()
    return data.source.token
}

export {Api}
