import React from 'react'
import {Tab} from '@headlessui/react'
import cn from 'classnames'

const TabsGroup = ({children, className, onChange, ...props}) => {
    return (
        <div
            className={cn(
                className,
                'border-b border-gray-200 dark:border-gray-600'
            )}
        >
            <Tab.Group onChange={onChange} {...props}>
                {children}
            </Tab.Group>
        </div>
    )
}

export {TabsGroup}
