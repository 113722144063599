import {XIcon} from '@heroicons/react/outline'
import React from 'react'

const ModalHeader = ({title, onCancel}) => {
    return (
        <div
            className={
                'w-full flex items-center justify-between border-b border-gray-200 dark:border-gray-600 mb-6 pb-4'
            }
        >
            <h3
                className={
                    'text-xl font-medium text-gray-800 dark:text-gray-100'
                }
            >
                {title}
            </h3>
            <XIcon
                className={
                    'w-6 h-6 text-gray-600 dark:text-gray-400 cursor-pointer'
                }
                onClick={onCancel}
            />
        </div>
    )
}

export {ModalHeader}
