import {observer} from 'mobx-react'
import React from 'react'
import {useRouteMatch, useHistory} from 'react-router-dom'
import {AppPath} from '@routesEnums'
import {YandexOauth} from './YandexOauth'
import {WbOauth} from './WbOauth'

const OAuthCallback = observer((props) => {
    const history = useHistory()
    const match = useRouteMatch()
    const {marketplace} = match.params

    const renderPage = () => {
        switch (marketplace.toLowerCase()) {
            case 'yandex': {
                return <YandexOauth />
            }
            case 'wb': {
                return <WbOauth />
            }
            default: {
                history.push(AppPath.marketplaces)
                return null
            }
        }
    }

    return renderPage()
})

export default OAuthCallback
