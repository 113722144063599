import {getMonthAgo} from '@components/forms/DatePicker/DateRangePicker'
import {makeAutoObservable, reaction, runInAction} from 'mobx'
import {MultiSelectedStore} from './MultiSelectedStore'

const initDateRange = {
    startDate: getMonthAgo(),
    endDate: new Date(),
}

export class ThroughFiltersStore {
    constructor() {
        if (ThroughFiltersStore.exists) {
            return ThroughFiltersStore.instance
        }
        ThroughFiltersStore.instance = this
        ThroughFiltersStore.exists = true
        const isActive = JSON.parse(localStorage.getItem('isActive'))
        this.isActive =
            isActive !== null && typeof isActive === 'boolean' ? isActive : true
        makeAutoObservable(this)

        const disposer = reaction(
            () => this.isActive,
            (value) => {
                localStorage.setItem('isActive', value)
            }
        )

        this.instanceStore = new MultiSelectedStore()
    }
    dateRange = initDateRange

    isActive = true

    setDateRange = (value = initDateRange) => {
        this.dateRange = value
    }

    setIsActive = (value) => {
        this.isActive = value
    }

    setOptions = (options = []) => {
        this.instanceStore.setOptions(options)
    }

    setOptionsByKey = (keys = []) => {
        this.instanceStore.setOptionsByKey(keys)
    }

    setSelectedInstance = (array = []) => {
        this.instanceStore.setSelectedInstance(array)
    }

    setSelectedInstanceKey = (array = []) => {
        this.instanceStore.setSelectedInstanceKey(array)
    }

    get clientFilters() {
        return this.instanceStore.selectedInstance.selectedInstanceKey
    }

    get selectedOptions() {
        return this.instanceStore.selectedInstance.selectedOptions
    }

    get options() {
        return this.instanceStore.options
    }

    static resetFilters = () => {
        runInAction(() => {
            ThroughFiltersStore?.instance?.setDateRange()
            ThroughFiltersStore?.instance?.instanceStore?.setSelectedInstance()
            ThroughFiltersStore?.instance?.instanceStore?.setSelectedInstanceKey()
        })
    }
}
