import React, {useEffect, useLayoutEffect, useRef, useState} from 'react'
import {observer} from 'mobx-react'
import {TextField} from '@components/forms/TextFields'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as yup from 'yup'
import {regexes} from '@utils/regexes'
import {useOnClickOutside} from '@hooks'
import {Button} from '@components/elements'
import {CheckIcon} from '@heroicons/react/outline'

const filenameSchema = yup.object().shape({
    filename: yup.string(),
})

export const FileSharingInput = observer(({fileItemStore}) => {
    const formRef = useRef(null)
    const inputRef = useRef(null)
    const editFileNameRef = useRef(null)

    const [filename, setFilename] = useState(fileItemStore.filename)
    useOnClickOutside(editFileNameRef, () => fileItemStore.closeEdit())

    useEffect(() => {
        setFilename(fileItemStore.filename)
        setValue('filename', fileItemStore.filename)
    }, [fileItemStore.filename])

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {errors, isDirty},
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(filenameSchema),
        defaultValues: {
            filename: fileItemStore.filename,
        },
    })

    const handleClickSaveFileName = () => {
        formRef.current.click()
    }

    const handleChangeTimeStart = (e) => {
        setFilename(e.target.value)
    }

    const onSubmit = async ({filename}) => {
        try {
            const data = await fileItemStore.patchEditFile(filename)
            fileItemStore.closeEdit()
        } catch (e) {}
    }

    return (
        <div ref={editFileNameRef} className={'flex items-center space-x-4'}>
            <form
                onSubmit={handleSubmit(onSubmit)}
                className='flex items-center space-x-4'
            >
                <TextField
                    {...register('filename', {onChange: handleChangeTimeStart})}
                    type='text'
                    inputClassName={'text-center py-1 px-1'}
                    error={!!errors.timeStart}
                />
                <input ref={formRef} className={'hidden'} type={'submit'} />
            </form>
            {isDirty && (
                <CheckIcon
                    className='h-6 w-6 text-green-600 hover:opacity-80 cursor-pointer'
                    aria-hidden='true'
                    onClick={handleClickSaveFileName}
                />
            )}
        </div>
    )
})
