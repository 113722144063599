import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {OnboardingWrapper} from '@components/navigation/header/Onboarding/components/OnboardingWrapper'
import {Link, useHistory} from 'react-router-dom'
import {AppPath} from '../../../../../../routes-enums'
import {ONBOARD_STEP} from '@utils/constants'
import {toast} from 'react-toastify'
import {TOAST_ONBOARDING_MAP} from '@components/navigation/header/Onboarding/constants'

export const CoastOnboard = observer(({onboardingStepStore}) => {
    const history = useHistory()
    const handleOnboardSuccess = async () => {
        try {
            await onboardingStepStore.putOnboardSetStep(
                ONBOARD_STEP.costsAdded,
                true
            )
            toast(TOAST_ONBOARDING_MAP.coastAdded, {
                type: 'success',
                autoClose: 60000,
            })
            history.push(AppPath.profitMargin)
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (onboardingStepStore.isInsertCoast) {
            handleOnboardSuccess()
        }
    }, [onboardingStepStore.isInsertCoast])

    function handleClick() {
        toast(TOAST_ONBOARDING_MAP.importSuccess, {
            type: 'success',
            autoClose: 60000,
        })
    }

    return (
        <OnboardingWrapper.BarContainer>
            <OnboardingWrapper.BarContainer
                className={'flex-col space-y-2 w-full'}
            >
                <OnboardingWrapper.Label
                    onClick={handleClick}
                    className={'text-blue-500 text-sm'}
                >
                    <Link
                        to={AppPath.profitMargin}
                        className={
                            'cursor-pointer whitespace-normal truncate underline text-blue-500 dark:text-blue-400 hover:opacity-80'
                        }
                    >
                        Внести себестоимость по любому товару
                    </Link>
                </OnboardingWrapper.Label>
                <OnboardingWrapper.Progress value={23} />
            </OnboardingWrapper.BarContainer>
        </OnboardingWrapper.BarContainer>
    )
})

CoastOnboard.displayName = 'CoastOnboard'
