import {useLocation, useRouteMatch} from 'react-router-dom'

export const useTabs = (tabs) => {
    const match = useRouteMatch()
    const location = useLocation()

    const getTabPathname = (id) => {
        return `${match.url}/${id}/`
    }
    const onChangeTabs = (id) => {}

    const getDefaultIndex = () => {
        return tabs.findIndex((tab) => {
            return location.pathname === getTabPathname(tab.id)
        })
    }

    return {
        getTabPathname,
        onChangeTabs,
        getDefaultIndex,
    }
}
