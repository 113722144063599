import React from 'react'
import {LockClosedIcon} from '@heroicons/react/outline'
import {
    TooltipLite,
    LockedTooltipContent,
    LockedLimitTooltipContent,
} from '@components/elements'
import classNames from 'classnames'
import {TooltipContentWrapper} from '@components/applicationUi/Tooltip'

export const LockedWrapperIcon = ({
    className,
    isBlocked,
    children,
    exceedProducts,
    exceedCabinets,
}) => {
    return isBlocked || exceedProducts || exceedCabinets ? (
        <TooltipLite
            offset={[0, 10]}
            content={
                <TooltipContentWrapper>
                    {isBlocked && <LockedTooltipContent />}
                    {exceedProducts && <LockedLimitTooltipContent isProducts />}
                    {exceedCabinets && <LockedLimitTooltipContent />}
                </TooltipContentWrapper>
            }
            classNameBody={'md:p-2 max-w-lg'}
        >
            {(ref) => (
                <div ref={ref} className={classNames(className, 'relative')}>
                    {children}

                    <div className={'absolute top-0 right-0'}>
                        <LockClosedIcon
                            className={
                                'flex-shrink-0 h-4 w-4 text-gray-400 dark:text-gray-400'
                            }
                        />
                    </div>
                </div>
            )}
        </TooltipLite>
    ) : (
        children
    )
}
