import {ApiFactory} from '@services/ApiFactory'
import {AuthUrl} from '../../routes-enums'
import {baseConfig} from '@services/apiConfigs'

const authInstance = ApiFactory.createApi(baseConfig)

class AuthService {
    static postLogin = async (payload) => {
        // const newConfig = {
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded',
        //     },
        // }
        return await authInstance.post(AuthUrl.postLogin, payload)
    }

    static postSignUp = async (payload) => {
        return await authInstance.post(AuthUrl.postSignUp, payload)
    }

    static getConfirmSms = async (payload) => {
        return await authInstance.get(AuthUrl.confirmSms, payload)
    }

    static postConfirmSms = async (payload) => {
        return await authInstance.post(AuthUrl.confirmSms, payload)
    }

    static getConfirmEmail = async (token) => {
        return await authInstance.get(AuthUrl.confirmEmail(token))
    }

    static getProviders = async () => {
        return await authInstance.get(AuthUrl.getProviders)
    }

    static getAuthByProvider = async (payload, providerName) => {
        return await authInstance.get(
            AuthUrl.getCallbackProviders(providerName),
            payload
        )
    }

    static getLogout = async () => {
        return await authInstance.get(AuthUrl.getLogout)
    }

    static postResetPasswordByEmail = async (payload) => {
        return await authInstance.post(
            AuthUrl.postResetPasswordByEmail,
            payload
        )
    }

    static postResetPasswordByPhone = async (payload) => {
        return await authInstance.post(
            AuthUrl.postResetPasswordByPhone,
            payload
        )
    }

    static getResetPasswordByPhone = async (payload) => {
        return await authInstance.get(AuthUrl.postResetPasswordByPhone, payload)
    }

    static getResetPassword = async (resetToken) => {
        return await authInstance.get(AuthUrl.resetPassword(resetToken))
    }

    static postResetPassword = async (payload, resetToken) => {
        return await authInstance.post(
            AuthUrl.resetPassword(resetToken),
            payload
        )
    }

    static postRefreshToken = async (payload) => {
        const config = {
            isAuth: false,
            skipAuthRefresh: true,
        }
        return await authInstance.post(AuthUrl.postRefresh, payload, config)
    }

    static getValidateToken = async (payload, userUuid) => {
        return await authInstance.get(AuthUrl.validateToken(userUuid), payload)
    }
}

export default AuthService
