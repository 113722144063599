import {useLocation} from 'react-router-dom'
import {useSelector} from '@hooks'
import GoogleTagManager from '@utils/googleUtils/googleTagManager'
import YandexAnalytics from '@utils/yandexAnalytics'
import {getCookie} from '@utils/helpers'
import {CONNECTED_MP_ACTIONS} from '@utils/constants'

const useAnalyticEventsHook = () => {
    const {userData} = useSelector((store) => store.userStore)
    const location = useLocation()

    const pageView = () => {
        GoogleTagManager.dataLayer({
            event: 'react_pageview',
            email: userData.email,
            user_id: userData.user_uuid,
            page_url: location.pathname,
        })
    }

    const blockedPageView = () => {
        GoogleTagManager.dataLayer({
            event: 'popup_acc_blocked',
            email: userData.email,
            user_id: userData.user_uuid,
        })
    }

    const messageFront = (text) => {
        GoogleTagManager.dataLayer({
            event: 'mainEvent',
            eventCategory: 'Message - Front',
            eventAction: 'message',
            eventLabel: text,
            email: userData.email || 'none',
            user_id: userData.user_uuid || 'none',
        })
    }

    const addProductToMonitoring = () => {
        GoogleTagManager.dataLayer({
            event: 'monitor_item',
            email: userData.email,
            user_id: userData.user_uuid,
        })
    }

    const errorCategoryMonitoring = () => {
        GoogleTagManager.dataLayer({
            event: 'ItemNotFound',
            email: userData.email,
            user_id: userData.user_uuid,
        })
    }

    const registrationEvent = (action = 'Click', label = 'LoginPassword') => {
        GoogleTagManager.dataLayer({
            event: 'mainEvent',
            eventCategory: 'Registration',
            eventAction: action,
            eventLabel: label,
            email: 'none',
            user_id: 'none',
        })
    }

    const authorizationEvent = (action = 'Click', label = 'LoginPassword') => {
        GoogleTagManager.dataLayer({
            event: 'mainEvent',
            eventCategory: 'Authorization',
            eventAction: action,
            eventLabel: label,
            email: userData.email,
            user_id: userData.user_uuid,
        })
    }

    const errorMessageEvent = (text, isRegistration = false) => {
        const label = isRegistration
            ? `RegistrationError "${text}"`
            : `AuthorizationError "${text}"`
        GoogleTagManager.dataLayer({
            event: 'mainEvent',
            eventCategory: 'Message - Front',
            eventAction: 'message',
            eventLabel: label,
            email: 'none',
            user_id: 'none',
        })
    }

    const tariffCouponEvent = (action = 'Click') => {
        GoogleTagManager.dataLayer({
            event: 'mainEvent',
            eventCategory: 'Billing',
            eventAction: action,
            eventLabel: 'Promocode',
            email: userData.email,
            user_id: userData.user_uuid,
        })
    }

    const tariffClickEvent = (label = 'TariffAccounting') => {
        GoogleTagManager.dataLayer({
            event: 'mainEvent',
            eventCategory: 'Billing',
            eventAction: 'Click',
            eventLabel: label,
            email: userData.email,
            user_id: userData.user_uuid,
        })
    }

    const tariffChooseClickEvent = (period) => {
        GoogleTagManager.dataLayer({
            event: 'mainEvent',
            eventCategory: 'Billing',
            eventAction: 'Choose',
            eventLabel: `TariffPeriod${period}`,
            email: userData.email,
            user_id: userData.user_uuid,
        })
    }

    const connectedMpEvent = (action = CONNECTED_MP_ACTIONS.open, label) => {
        const ga = getCookie('_ga')
        GoogleTagManager.dataLayer({
            event: 'mainEvent',
            eventCategory: 'APIKeyConnect',
            eventAction: action,
            eventLabel: label,
            email: userData.email,
            user_id: userData.user_uuid,
            cid: ga ? ga.slice(6) : undefined,
            cid_new: ga ? `f'GA4_${ga.slice(6)}` : undefined,
        })
    }

    const clickHackingMp = () => {
        GoogleTagManager.dataLayer({
            event: 'mainEvent',
            eventCategory: 'TelegramInvite',
            eventAction: 'Open',
            eventLabel: 'ConnectionsPage',
            email: userData.email,
            user_id: userData.user_uuid,
        })
    }

    const ymRegistration = (key) => {
        YandexAnalytics.ym('reachGoal', [
            `ym_registration${key ? `_${key}` : ''}`,
        ])
    }

    const ymAuthorization = () => {
        YandexAnalytics.ym('reachGoal', ['ym_authorization'])
    }

    return {
        pageView,
        blockedPageView,
        messageFront,
        addProductToMonitoring,
        errorCategoryMonitoring,
        registrationEvent,
        authorizationEvent,
        errorMessageEvent,
        ymRegistration,
        ymAuthorization,
        tariffCouponEvent,
        tariffClickEvent,
        tariffChooseClickEvent,
        connectedMpEvent,
        clickHackingMp,
    }
}

export {useAnalyticEventsHook}
