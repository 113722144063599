import React from 'react'
import cn from 'classnames'
import {
    ExclamationIcon,
    XCircleIcon,
    CheckCircleIcon,
    InformationCircleIcon,
} from '@heroicons/react/solid'

const Alert = ({
    warning = false,
    error = false,
    success = false,
    title = '',
    description = '',
    className,
}) => {
    return (
        <div
            className={cn(
                'rounded-md p-4',
                {
                    'bg-red-50 dark:bg-red-800/50': error,
                    'bg-green-50 dark:bg-green-800/50': success,
                    'bg-yellow-50 dark:bg-yellow-800/50': warning,
                    'bg-blue-50 dark:bg-blue-800/50': !(
                        error ||
                        warning ||
                        success
                    ),
                },
                className
            )}
        >
            <div className='flex'>
                <div className='flex-shrink-0'>
                    {error && (
                        <XCircleIcon
                            className='h-5 w-5 text-red-400 dark:text-red-300'
                            aria-hidden='true'
                        />
                    )}
                    {warning && (
                        <ExclamationIcon
                            className='h-5 w-5 text-yellow-400 dark:text-yellow-300'
                            aria-hidden='true'
                        />
                    )}
                    {success && (
                        <CheckCircleIcon
                            className='h-5 w-5 text-green-400 dark:text-green-300'
                            aria-hidden='true'
                        />
                    )}
                    {!(error || warning || success) && (
                        <InformationCircleIcon
                            className='h-5 w-5 text-blue-400 dark:text-blue-300'
                            aria-hidden='true'
                        />
                    )}
                </div>
                <div className='ml-3'>
                    {!!title && (
                        <h3
                            className={cn('text-sm font-medium', {
                                'text-red-800 dark:text-red-400': error,
                                'text-green-800 dark:text-green-400': success,
                                'text-yellow-800 dark:text-yellow-400': warning,
                                'text-blue-700 dark:text-blue-300': !(
                                    error ||
                                    warning ||
                                    success
                                ),
                            })}
                        >
                            {title}
                        </h3>
                    )}
                    {!!description && (
                        <div
                            className={cn('mt-2 text-sm', {
                                'text-red-700 dark:text-red-400/80': error,
                                'text-green-700 dark:text-green-400/80':
                                    success,
                                'text-yellow-700 dark:text-yellow-400/80':
                                    warning,
                                'text-blue-500 dark:text-blue-300/80': !(
                                    error ||
                                    warning ||
                                    success
                                ),
                            })}
                        >
                            {description}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export {Alert}
