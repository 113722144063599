import React, {useEffect, useState} from 'react'
import {CheckIcon} from '@heroicons/react/outline'
import {useLocation, useHistory} from 'react-router-dom'
import {Loader} from '@components/elements/Loader'
import * as qs from 'qs'
import {XCircleIcon} from '@heroicons/react/solid'
import {observer} from 'mobx-react'
import {useSelector} from '@hooks'
import {AppPath} from '../../routes-enums'
import {runInAction} from 'mobx'

export const EmailConfirm = observer(() => {
    const [token, setToken] = useState('')
    const [error, setError] = useState(false)
    const {getConfirmEmail} = useSelector((store) => store.authStore)
    const location = useLocation()
    const history = useHistory()

    useEffect(() => {
        if (location.search) {
            getStateFromUrl()
        } else {
            setError(true)
        }
    }, [])

    const getStateFromUrl = () => {
        try {
            const {token, code} = qs.parse(location.search, {
                ignoreQueryPrefix: true,
            })
            runInAction(() => {
                setToken(token)
                getConfirmEmail(token)
            })
        } catch (e) {
            setError(true)
        }
    }

    return (
        <div className={'flex flex-col w-full items-center justify-center'}>
            {!token && !error && <Loader overlayed={false} />}
            {token && error && (
                <>
                    <XCircleIcon
                        className='w-20 h-20 text-red-400 dark:text-red-300'
                        aria-hidden='true'
                    />
                    <span>{`Подтверждения почты прошло с ошибкой. Закройте страницу и попробуйте снова`}</span>
                </>
            )}{' '}
            {token && !error && (
                <>
                    <CheckIcon className={'w-20 h-20'} />
                    <span>{`Подтверждения почты прошло успешно`}</span>
                </>
            )}
        </div>
    )
})
