import {makeObservable, observable} from 'mobx'

class AdminStatusStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            services: observable,
        })
    }

    services = [
        [
            {
                title: 'Обмен данными по продуктам Ozon',
                status: 'Сервис работает',
                color: 'green',
            },
            {
                title: 'Обмен данными по заказам Ozon',
                status: 'Сервис работает',
                color: 'green',
            },
            {
                title: 'Мониторинг товаров Ozon',
                status: 'Сервис работает',
                color: 'green',
            },
            {
                title: 'Сбор отзывов Ozon',
                status: 'Сервис работает',
                color: 'green',
            },
        ],
        [
            {
                title: 'Обмен данными по продуктам WB',
                status: 'Сервис работает',
                color: 'green',
            },
            {
                title: 'Обмен данными по заказам WB',
                status: 'Сервис работает',
                color: 'green',
            },
            {
                title: 'Мониторинг товаров WB',
                status: 'Есть ошибки',
                color: 'yellow',
            },
            {
                title: 'Сбор отзывов WB',
                status: 'Есть ошибки',
                color: 'yellow',
            },
        ],
        [
            {
                title: 'Почтовый сервис',
                status: 'Сервис работает',
                color: 'green',
            },
            {
                title: 'Интеграционный модуль с AmoCRM',
                status: 'Сервис не работает',
                color: 'red',
            },
        ],
    ]
}

export default AdminStatusStore
