import React from 'react'
import classNames from 'classnames'

export const Title = ({title, className}) => {
    return (
        <h2
            className={classNames(
                className,
                'text-2xl font-medium text-gray-800 dark:text-gray-200 mb-4'
            )}
        >
            {title}
        </h2>
    )
}
