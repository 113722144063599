import React from 'react'
import {EmptyOrdersIcon} from '@components/elements/Icon/Icon'
import {observer} from 'mobx-react'

export const EmptyTableResult = observer(({icon, label}) => {
    return (
        <div className={'w-full h-full flex flex-col justify-center pb-16'}>
            <div className={'flex w-full items-center justify-center mb-12'}>
                {icon ? icon : <EmptyOrdersIcon />}
            </div>
            <div
                className={
                    'flex flex-col items-center mb-10 text-gray-600 dark:text-gray-400'
                }
            >
                {label ? label : 'Список пуст'}
            </div>
        </div>
    )
})
