//stores
import {StatisticsStore} from '../AdminStats/Statistics/store/StatisticsStore'
import {StatisticsProductsStore} from '../AdminStats/StatisticsProducts/store/StatisticsProductsStore'
import {MRStatisticsStore} from '@views/adminStats/AdminStats/MRStatistics/store/MRStatisticsStore'

class AdminStatsStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        this.reset()
    }

    reset = () => {
        this.stores = {
            statisticsStore: new StatisticsStore(this.rootStore),
            statisticsProductsStore: new StatisticsProductsStore(
                this.rootStore
            ),
            mrStatisticsStore: new MRStatisticsStore(this.rootStore),
        }
    }

    headerTabs = [
        {
            id: 'general',
            name: 'Статистика',
            disabled: false,
            visible: true,
        },
        {
            id: 'products',
            name: 'Товары и GMV',
            disabled: false,
            visible: true,
        },
        {
            id: 'raw-data',
            name: 'Выгрузка raw data',
            disabled: true,
            visible: true,
        },
        {
            id: 'mr-stats',
            name: 'Мониторинг и отзывы',
            disabled: false,
            visible: true,
        },
    ]
}

export default AdminStatsStore
