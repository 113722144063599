import React from 'react'
import {Card} from '@components/layout'
import {Skeleton} from '@components/elements'
import {
    CheckIcon,
    ExclamationIcon,
    QuestionMarkCircleIcon,
} from '@heroicons/react/outline'
import {WithTooltipWrapper} from '@components/applicationUi'

export const TotalCard = ({
    title,
    className,
    content,
    isWarn,
    withTooltip,
    tooltip,
    footerContent,
    loader,
}) => {
    return (
        <Card className={className}>
            <div className={'flex flex-col'}>
                <div className={'text-md text-gray-700 dark:text-gray-400'}>
                    {loader ? (
                        <Skeleton />
                    ) : (
                        <div className={'flex justify-between'}>
                            {title}
                            {isWarn && !withTooltip ? (
                                <WithTooltipWrapper tooltip={tooltip}>
                                    <ExclamationIcon
                                        className={
                                            'w-6 h-6 flex-shrink-0 text-yellow-400'
                                        }
                                    />
                                </WithTooltipWrapper>
                            ) : withTooltip ? (
                                <WithTooltipWrapper tooltip={tooltip}>
                                    <QuestionMarkCircleIcon
                                        className={
                                            'h-5 w-5 text-gray-500 dark:text-gray-400'
                                        }
                                    />
                                </WithTooltipWrapper>
                            ) : (
                                <CheckIcon
                                    className={
                                        'flex-shrink-0 h-5 w-5 text-green-500'
                                    }
                                />
                            )}
                        </div>
                    )}
                </div>
                <div
                    className={
                        'flex flex-col mt-2 text-gray-700 dark:text-gray-100 pb-2 border-b-2 border-gray-100 dark:border-gray-600'
                    }
                >
                    <div className={'text-2xl'}>
                        {loader ? <Skeleton /> : content}
                    </div>
                </div>
                <div
                    className={
                        'flex flex-col mt-2 text-gray-700 dark:text-gray-100'
                    }
                >
                    {loader ? (
                        <div className={'w-full'}>
                            <Skeleton />
                        </div>
                    ) : (
                        footerContent
                    )}
                </div>
            </div>
        </Card>
    )
}
