import {parseRutubeVideoUrl} from '@utils/helpers'
import {useMemo} from 'react'

export const RutubePlayer = ({
    src,
    width = '100%',
    height = '320px',
    autoplay,
}) => {
    const videoId = useMemo(() => parseRutubeVideoUrl(src), [src])

    return (
        <iframe
            id='my-player'
            src={`https://rutube.ru/play/embed/${videoId}?getPlayOptions=${
                autoplay ? 'player:play' : ''
            }`}
            width={width}
            height={height}
            allow='autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;'
            frameBorder='0'
            allowFullScreen
        ></iframe>
    )
}
