import React, {useEffect, useMemo} from 'react'
import {observer} from 'mobx-react'
import {CustomModuleWarn} from '@components/applicationUi'
import {useSelector, useBlocked} from '@hooks'
import {useRouteMatch} from 'react-router-dom'
import {ExclamationIcon} from '@heroicons/react/outline'
import {AppPath} from '@routesEnums'
import {NewImports} from '@components/applicationUi/InformerBlock/NewImports'
import {LinkData} from '@components/applicationUi/InformerBlock/LinkData'
import {InvalidAcc} from '@components/applicationUi/InformerBlock/invalidAcc'
import {ExpiredWbAcc} from '@components/applicationUi/InformerBlock/ExpiredWbAcc'
import {WbXlsImports} from '@components/applicationUi/InformerBlock/WbXlsImports'
import {useLimitContext} from '@context/LimtiBlockedContext'
import {ExceedLimitProducts} from '@components/applicationUi/InformerBlock/ExceedLimitProducts'
import {ExceedLimitCabinets} from '@components/applicationUi/InformerBlock/ExceedLimitCabinets'

export const InformerBlock = observer(
    ({children, icon, onlyChild, className, ...props}) => {
        const match = useRouteMatch()
        const isBlocked = useBlocked()
        const {exceedLimitProducts, exceedLimitCabinets} = useLimitContext()
        const {informer, getInformerData} = useSelector(
            (store) => store.informerStore
        )
        const marketStore = useSelector((store) => store.marketStore)
        useEffect(async () => {
            statusesRequest()
            await getInformerData()
        }, [])

        const statusesRequest = () => {
            if (marketStore.importStatusesPolling.isPooling) return
            marketStore.importStatusesPolling.startPolling()
        }

        const {informerText, isInformer} = useMemo(() => {
            const informerObj = {
                informerText: [],
                isInformer: false,
            }
            if (onlyChild) {
                informerObj.isInformer = true
                informerObj.informerText.push(children)
                return informerObj
            }
            if (isBlocked) {
                const informer = (
                    <LinkData
                        title={
                            'Полная версия отчета доступна при покупке тарифа.'
                        }
                        to={AppPath.accountTariff}
                        linkText={'Перейти к выбору тарифа'}
                    />
                )
                informerObj.isInformer = true
                informerObj.informerText.push(informer)
                return informerObj
            }
            if (exceedLimitProducts || exceedLimitCabinets) {
                const informer = []
                if (exceedLimitProducts) {
                    informer.push(<ExceedLimitProducts />)
                }
                if (exceedLimitCabinets) {
                    informer.push(<ExceedLimitCabinets />)
                }
                informerObj.isInformer = true
                informerObj.informerText.unshift(...informer)
            }
            if (marketStore.expiringKeyWb.length) {
                const informer = (
                    <ExpiredWbAcc credentials={marketStore.expiringKeyWb} />
                )
                informerObj.isInformer = true
                informerObj.informerText.push(informer)
            }
            if (marketStore.invalidAcc.length) {
                const informer = (
                    <InvalidAcc credentials={marketStore.invalidAcc} />
                )
                informerObj.isInformer = true
                informerObj.informerText.push(informer)
            }
            if (marketStore.xlsImports.length) {
                const informer = (
                    <WbXlsImports
                        wbImports={marketStore.xlsImports}
                        getCredentialByID={marketStore.getCredentialByID}
                    />
                )
                informerObj.isInformer = true
                informerObj.informerText.push(informer)
            }
            if (informer[match.url]) {
                informerObj.isInformer = true
                //informerObj.informerText = informer[match.url]
                informerObj.informerText.unshift(informer[match.url])
                return informerObj
            }
            if (marketStore.newStatusesImport.length) {
                const informer = (
                    <NewImports
                        credentials={marketStore.credentialsNewImports}
                    />
                )
                informerObj.isInformer = true
                informerObj.informerText.unshift(informer)
                return informerObj
            }
            if (children) {
                informerObj.isInformer = true
                informerObj.informerText.push(children)
                return informerObj
            }
            return informerObj
        }, [
            informer,
            isBlocked,
            onlyChild,
            marketStore.expiringKeyWb,
            marketStore.newStatusesImport,
            marketStore.credentialsNewImports,
            marketStore.invalidAcc,
            marketStore.xlsImports,
            exceedLimitCabinets,
            exceedLimitProducts,
        ])
        return (
            isInformer && (
                <CustomModuleWarn
                    {...props}
                    icon={
                        icon ? (
                            icon
                        ) : (
                            <ExclamationIcon
                                className={
                                    'w-10 h-10 flex-shrink-0 text-yellow-400'
                                }
                            />
                        )
                    }
                >
                    {!!informerText.length && (
                        <div className={'flex flex-col space-y-2 w-full'}>
                            {informerText.map((informer, idx) => (
                                <div
                                    key={idx}
                                    className={
                                        'text-gray-800 dark:text-gray-300'
                                    }
                                >
                                    {informer}
                                </div>
                            ))}
                        </div>
                    )}
                </CustomModuleWarn>
            )
        )
    }
)

InformerBlock.displayName = 'InformerBlock'
