let serverDomain = ''
if (process.env.REACT_APP_API_URL && process.env.REACT_APP_API_URL.length) {
    serverDomain = process.env.REACT_APP_API_URL
    //console.log('REACT_APP_API_URL', process.env.REACT_APP_API_URL)
} else {
    //console.log('no env')
    let parts = window.location.host.split('.')
    serverDomain = 'https://dev.seller24.ru'
    if (parts[0] === 'app') serverDomain = 'https://app.seller24.ru'
    console.log('server domain', serverDomain)
}

//console.log('NODE_ENV', process.env.NODE_ENV)
//console.log('REACT_APP_AUTH_BASE_URL', process.env.REACT_APP_AUTH_BASE_URL)
const url =
    process.env.NODE_ENV === 'development'
        ? `${serverDomain}`
        : process.env.REACT_APP_AUTH_BASE_URL

console.log('URL', url)
export default url
