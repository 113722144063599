const ERROR_MAP = {
    1: 'Ошибка авторизации, в headers не проставлен "User"',
    2: 'Неверный формат ввода - в поле body должен быть JSON формат',
    3: 'Ошибка в форме (валидация)',
    11: 'Сайт Wildberries не отвечает более 10 сек',
    12: 'Проблема с сайтом Wildberries - отдает 500 ошибку',
    13: 'Необходимо проверить правильность внесенного телефонного номера',
    14: 'Необходимо проверить правильность внесенного "Api-key"',
    21: 'Сайт Ozon не отвечает более 10 сек',
    22: 'Проблема с сайтом Ozon - отдает 500 ошибку',
    31: 'Сайт Яндекс.Маркет не отвечает более 10 сек',
    32: 'Проблема с сайтом Яндекс.Маркет - отдает 500 ошибку',
    33: 'Недостаточно данных - необходим код после авторизации в Yandex',
    34: 'Недостаточно данных - необходима state информация после авторизации в Yandex',
    35: 'Предоставлены неверные дефолтные данные для получения токена по коду в Yandex',
    36: 'Введен неверный код после атворизации в Yandex',
    41: 'Проблема соединения (необходимо проверить интернет соединение)',
    51: 'Возникновение ошибки на сайте маркетплейса при отправке запроса', // - выводит данные об ошибке с сайта (динамический)
    61: 'Ошибка при сохранении данных в БД',
    71: 'Информации по данному запросу нет в БД',
}
export const geErrorMessage = (e, forceMessage) => {
    const {code, message, result} = e
    return code === 51
        ? forceMessage || message
        : ERROR_MAP[code] ||
              'Произошла ошибка, попробуйте снова или обратитесь в поддержку'
}
