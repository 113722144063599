import React from 'react'
import {observer} from 'mobx-react'
import {FileSharingTitle} from './FileSharingTitle'
import {FileSharingItem} from './FileSharingItem'
import {Button} from '@components/elements'

export const FileSharingList = observer(({store}) => {
    const paginationStore = store.paginationStore
    const handleClickHistoryNext = () => {
        store.getFileList({limit: paginationStore.pagination.limit + 10})
    }
    const handleClickHistoryUpdate = () => {
        store.getFileList({limit: 10})
    }
    return (
        <div className={'flex flex-col space-y-4'}>
            <FileSharingTitle title={'Доступные для скачивания документы'} />
            {!store.list.length ? (
                <div className={'flex flex-col space-y-4'}>
                    <div
                        className={
                            'flex font-medium text-xl text-gray-600 dark:text-gray-400 justify-center'
                        }
                    >
                        <span>Файлы отсутствуют</span>
                    </div>
                </div>
            ) : null}
            {store.list.map((item) => {
                return <FileSharingItem key={item.id} fileItemStore={item} />
            })}
            {store.list.length ? (
                <Button
                    disabled={
                        paginationStore.pagination.limit >
                        paginationStore.pagination.total
                    }
                    onClick={handleClickHistoryNext}
                >
                    Показать еще
                </Button>
            ) : null}
            <Button onClick={handleClickHistoryUpdate}>Обновить список</Button>
        </div>
    )
})
