import React, {useState, useMemo} from 'react'
import {usePopper} from 'react-popper'
import {Popover, Transition} from '@headlessui/react'
import cn from 'classnames'
import {SkuDropdownBody} from '@components/applicationUi/SkuDropdown/SkuDropdownBody'
import {SkuDropdownPortal} from '@components/applicationUi/SkuDropdown/SkuDropdownPortal'
import {InformationCircleIcon} from '@heroicons/react/outline'

const DEFAULT_VALUE = {
    sku: 'Артикул маркетплейса',
    productId: 'Уникальный идентификатор модели',
    offerId: 'Артикул продавца',
}

export const SkuDropdown = ({
    sku,
    offerId,
    productId,
    codes = [],
    url,
    portal = true,
    cellStyleWidth = 90,
    className,
    classNameValue,
}) => {
    const [referenceElement, setReferenceElement] = useState(null)
    const [popperElement, setPopperElement] = useState(null)
    const {styles, attributes} = usePopper(referenceElement, popperElement, {})

    const collectSkuData = (codes = []) => {
        const data = []
        codes.forEach((item) => {
            if (item.value) {
                const dataItem = {
                    name: DEFAULT_VALUE[item.key],
                    value: item.value,
                    isHeader: false,
                }
                if (data[0]?.isHeader) {
                    data.push(dataItem)
                } else {
                    data.push({...dataItem, isHeader: true})
                }
            }
        })

        return data
    }

    const getConvertObject = (arr) => {
        const list = arr.map((item) => {
            if (Array.isArray(item.value)) {
                return {...item, value: item.value.join(', ')}
            }
            return item
        })
        let title = arr.find((item) => item?.isHeader)?.value
        if (Array.isArray(title)) title = title[0]
        if (!title) title = arr[0]?.value
        return {
            list,
            title,
        }
    }

    const skyItems = useMemo(() => {
        if (codes.length) {
            return getConvertObject(codes)
        }
        const skuData = collectSkuData([
            {key: 'sku', value: sku},
            {key: 'productId', value: productId},
            {key: 'offerId', value: offerId},
        ])

        return getConvertObject(skuData)
    }, [sku, offerId, productId, codes])

    const skuDropdownBodyProps = {
        setPopperElement,
        styles,
        attributes,
        skyItems,
        url,
    }

    return skyItems.title ? (
        <>
            <Popover className='relative'>
                {({open}) => (
                    <>
                        <Popover.Button
                            ref={setReferenceElement}
                            className={'outline-none'}
                        >
                            <div
                                className={cn(
                                    'flex items-center space-x-1 cursor-pointer text-gray-800 dark:text-gray-300'
                                )}
                            >
                                <InformationCircleIcon
                                    className={
                                        'flex-shrink-0 w-5 h-5 text-gray-500'
                                    }
                                />
                                <span
                                    className={cn(
                                        classNameValue,
                                        `text-left text-xs truncate w-full`
                                    )}
                                >
                                    {skyItems.title}
                                </span>
                            </div>
                        </Popover.Button>
                        <Transition
                            show={open}
                            as={'div'}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            {portal ? (
                                <SkuDropdownPortal {...skuDropdownBodyProps} />
                            ) : (
                                <SkuDropdownBody {...skuDropdownBodyProps} />
                            )}
                        </Transition>
                    </>
                )}
            </Popover>
        </>
    ) : (
        <span className={'text-gray-500'}>Неизвестно</span>
    )
}
