//stores
import StockXlsStore from '../StockXls/store/StockXlsStore'
import StockYmlStore from '../StockYml/store/StockYmlStore'

export class StockStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        this.stores = {
            stockXlsStore: new StockXlsStore(rootStore),
            stockYmlStore: new StockYmlStore(rootStore),
        }
    }

    tabs = [
        {
            id: 'xls',
            name: 'Через XLS файл',
            disabled: false,
            visible: true,
        },
        {
            id: 'yml',
            name: 'Через YML фид',
            disabled: false,
            visible: false,
        },
    ]
}
