import {Amount, Locked, Ndash, Skeleton} from '@components/elements'
import {SIGNS_KEY} from '@components/elements/Amount/convertAmount'
import classNames from 'classnames'

export const StructureField = ({
    name,
    negative,
    value,
    rate,
    loader,
    isBlocked,
    color,
}) => {
    if (loader) return <Skeleton />
    return (
        <div className={'flex text-xs justify-between items-center'}>
            <span className={'text-gray-600 dark:text-gray-400'}>{name}</span>
            {isBlocked ? (
                <div className={'w-36'}>
                    <Locked />
                </div>
            ) : (
                <div
                    className={classNames(
                        'flex space-x-1 flex-shrink-0 font-medium text-gray-800 dark:text-gray-200',
                        color
                    )}
                >
                    <div className={'flex space-x-1 items-center'}>
                        {negative && value ? <Ndash /> : null}
                        <span>
                            <Amount value={value} />
                        </span>
                    </div>
                    <span>/</span>
                    <span>
                        <Amount value={rate} currency={SIGNS_KEY.PER} />
                    </span>
                </div>
            )}
        </div>
    )
}
