import Rainbow from 'rainbowvis.js'

class Gradient {
    midPoints = 10

    constructor() {
        this.rainbow = new Rainbow()
    }

    setMidpoint = (value = 10) => {
        this.midPoints = value
        this.rainbow.setNumberRange(
            1,
            this.midPoints === 1 ? 2 : this.midPoints
        )
    }

    setGradient() {
        this.rainbow.setSpectrumByArray(arguments)
    }

    setGradientArray = (array) => {
        this.rainbow.setSpectrumByArray(array)
    }

    colourAt = (index) => {
        return this.rainbow.colourAt(index)
    }

    getArray = () => {
        const arr = []
        if (this.midPoints === 1) {
            const hex = `#${this.rainbow.colourAt(0)}`
            return [hex]
        }
        for (let i = 1; i <= this.midPoints; i++) {
            arr.push(`#${this.rainbow.colourAt(i)}`)
        }

        return arr
    }
}

export default Gradient
