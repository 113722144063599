import React from 'react'
import {Tab} from '@headlessui/react'
import cn from 'classnames'
const TabsList = ({children, className, ...props}) => {
    return (
        <Tab.List className={cn(className, '-mb-px flex space-x-8')} {...props}>
            {children}
        </Tab.List>
    )
}

export {TabsList}
