import React, {useEffect} from 'react'

function createRootElement(id) {
    const rootContainer = document.createElement('div')
    rootContainer.setAttribute('id', id)
    return rootContainer
}

function usePortal(id) {
    const rootElemRef = React.useRef(document.createElement('div'))

    function addRootElement(rootElem) {
        document.body.insertBefore(
            rootElem,
            document.body.lastElementChild.nextElementSibling
        )
    }

    useEffect(
        function setupElement() {
            // Ищите существующий целевой элемент dom для добавления к
            const existingParent = document.querySelector(`#${id}`)
            // Родитель - это либо новый корень, либо существующий элемент dom.
            const parentElem = existingParent || createRootElement(id)
            // Если нет существующего DOM-элемента, добавьте новый.
            if (!existingParent) {
                addRootElement(parentElem)
            }

            // Добавить выделенный элемент в родительский
            parentElem.appendChild(rootElemRef.current)
            // Эта функция работает на немонтируемых
            return function removeElement() {
                rootElemRef.current.remove()
            }
        },
        [id]
    )

    function getRootElem() {
        if (!rootElemRef.current) {
            rootElemRef.current = document.createElement('div')
        }
        return rootElemRef.current
    }

    return getRootElem()
}

export {usePortal, createRootElement}
