import React from 'react'
import {Portal} from '@components/elements'
import {SkuDropdownBody} from '@components/applicationUi/SkuDropdown/SkuDropdownBody'

export const SkuDropdownPortal = (props) => {
    return (
        <Portal portalContainerId={'select'}>
            <SkuDropdownBody {...props} />
        </Portal>
    )
}
