import {action, computed, makeObservable, observable} from 'mobx'
import qs from 'qs'
import OrdersService from '@services/OrdersService/OrdersService'
import {FileDownload} from '@utils/download/download'
import {getFirstWeekDay} from '@components/forms/DatePicker/DateRangePicker'
import ReviewsService from '@services/ReviewsService/ReviewsService'
import {WarehousesService} from '@services/WarehousesService/WarehousesService'
import {PaginationStore} from '../../../store/PaginationStore'

const initSortBy = [
    {
        id: 'shipmentDate',
        key: 'shipment_date',
        dir: 'asc',
        active: false,
    },
    {
        id: 'orderInfo',
        key: 'in_process_at',
        dir: 'asc',
        active: false,
    },
]

class OrdersStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            params: observable,
            ordersTabs: observable,
            isStatuses: observable,
            statusesTabs: observable,
            ordersLoader: observable,
            orders: observable,
            filterOpen: observable,
            currentOrder: observable,
            filterState: observable,
            isTested: observable,
            appliedFilter: observable,
            filterLoader: observable,
            testFilterResults: observable,
            openTrackingNumber: observable,
            openOrderSplit: observable,
            openSplitWarning: observable,
            openPrint: observable,
            splitOrders: observable,
            docs: observable,
            sortBy: observable,
            allStatuses: observable,
            warehousesNeedVerify: observable,
            selectedOrders: observable,
            setTabsCount: action,
            setStatusesTabs: action,
            updateParams: action,
            setIsStatuses: action,
            parseInitialParams: action,
            setOrdersLoader: action,
            setOrders: action,
            setFilterOpen: action,
            setCurrentOrder: action,
            setIsTested: action,
            setFilterLoading: action,
            setTestFilterResults: action,
            setApplyFilter: action,
            resetApplyFilter: action,
            setOpenTrackingNumber: action,
            setOpenOrderSplit: action,
            setSplitOrders: action,
            setOpenPrint: action,
            setOpenSplitWarning: action,
            setDocs: action,
            setAllStatuses: action,
            reset: action,
            changeSortBy: action,
            resetSortBy: action,
            activeListSort: computed,
            setSelectedOrders: action,
            resetSelectedOrders: action,
            setWarehousesNeedVerify: action,
            isWarehousesNeedVerify: computed,
        })
        this.params = this.parseInitialParams()
        this.paginationStore = new PaginationStore()
    }

    ordersTabs = [
        {
            id: 'fbs',
            name: 'С моего склада',
            disabled: false,
            visible: true,
            count: 0,
            warn: false,
        },
        {
            id: 'fbo',
            name: 'Со склада площадок',
            disabled: false,
            visible: true,
            count: 0,
            warn: false,
        },
    ]
    filterState = {
        open: false,
        active: false,
    }
    isStatuses = false
    statusesTabs = []
    selectedOrders = {}
    allStatuses = {}
    ordersLoader = false
    filterOpen = false
    openTrackingNumber = false
    openOrderSplit = false
    openPrint = false
    openSplitWarning = false
    docs = []
    splitOrders = ''
    currentOrder = {id: 0, number: ''}
    params = {}
    orders = []
    sortBy = initSortBy
    warehousesNeedVerify = []

    //filter
    filterLoader = false
    isTested = false
    testFilterResults = 0
    appliedFilter = {}

    reset = () => {
        this.setStatusesTabs()
        this.setOrders()
        this.setCurrentOrder()
        this.setIsStatuses()
        this.setAllStatuses()
        this.resetFilter()
    }

    changeSortBy = (sortKey, value) => {
        const newSortBy = this.sortBy.map((item) => {
            const newItem = {...item}
            if (newItem.id === sortKey) {
                newItem.dir = value.dir
                newItem.active = true
            } else {
                newItem.active = false
            }
            return newItem
        })

        this.sortBy = newSortBy
    }

    resetSortBy = () => {
        this.sortBy = initSortBy
    }

    parseInitialParams = () => {
        const {search} = window.location
        const parsed = qs.parse(search, {ignoreQueryPrefix: true})
        return {
            page: parsed.page ? Number(parsed.page) : 1,
            limit: parsed.limit ? Number(parsed.limit) : 10,
            shipment_type: parsed.shipment_type || 'fbs',
            states: parsed.states || 'new',
            number: parsed.number || null,
            from: parsed.from || null,
            to: parsed.to || null,
            mp_cred_ids: parsed.mp_cred_ids
                ? parsed.mp_cred_ids.split(',')
                : [],
        }
    }

    getNewParams = (
        {
            page,
            limit,
            shipment_type,
            states,
            number,
            from,
            to,
            mp_cred_ids,
        } = {},
        params
    ) => {
        return {
            page: page || params.page,
            limit: limit || params.limit,
            shipment_type: shipment_type || params.shipment_type,
            states: states || params.states,
            number: number !== undefined ? number : params.number,
            from: from !== undefined ? from : params.from,
            to: to !== undefined ? to : params.to,
            mp_cred_ids:
                mp_cred_ids !== undefined ? mp_cred_ids : params.mp_cred_ids,
        }
    }

    updateParams = (data = {}) => {
        this.params = this.getNewParams(data, this.params)
    }

    setStatusesTabs = (tabs = []) => {
        this.statusesTabs = tabs
    }

    setOrders = (orders = []) => {
        this.orders = orders
    }

    setWarehousesNeedVerify = (warehouses = []) => {
        this.warehousesNeedVerify = warehouses
    }

    get isWarehousesNeedVerify() {
        return !!this.warehousesNeedVerify.filter(
            (item) => !!item.warehouses.length
        ).length
    }

    setCurrentOrder = (value = {id: 0, number: '', bayerData: {}}) => {
        this.currentOrder = value
    }

    getOrdersTabsById = (id) => {
        return this.ordersTabs.find((tab) => tab.id === id)
    }

    setIsStatuses = (value = false) => {
        this.isStatuses = value
    }

    setAllStatuses = (source = {}) => {
        this.allStatuses = source
    }

    setOrdersLoader = (value = false) => {
        this.ordersLoader = value
    }

    setOpenPrint = (value = false) => {
        this.openPrint = value
    }

    setOpenSplitWarning = (value = false) => {
        this.openSplitWarning = value
    }

    setTabsCount = (type, count) => {
        const tab = this.getOrdersTabsById(type)
        tab.count = count
        tab.warn = false
    }

    setFilterOpen = (value = false) => {
        this.filterState.open = value
    }

    setOpenTrackingNumber = (value = false) => {
        this.openTrackingNumber = value
    }

    setOpenOrderSplit = (value = false) => {
        this.openOrderSplit = value
    }

    setSplitOrders = (value) => {
        this.splitOrders = value
    }

    setDocs = (docs) => {
        this.docs = docs
    }

    setSelectedOrders = (states, products = []) => {
        this.selectedOrders[states] = products
    }

    resetSelectedOrders = ({}) => {
        this.selectedOrders = {}
    }

    resetFilter = () => {
        this.updateParams({
            number: null,
            from: null,
            to: null,
            mp_cred_ids: [],
        })
        this.resetApplyFilter()
        this.setIsTested(false)
    }

    setIsTested = (value = false) => {
        this.isTested = value
    }

    setFilterLoading = (value = false) => {
        this.filterLoader = value
    }

    setTestFilterResults = (value: number = 0) => {
        this.testFilterResults = value
    }

    setApplyFilter = (obj) => {
        this.appliedFilter = Object.assign({}, this.appliedFilter, obj)
    }

    resetApplyFilter = () => {
        this.appliedFilter = {}
    }

    applyFilter = () => {
        this.updateParams(this.appliedFilter)
    }

    get activeListSort() {
        return this.sortBy.find((item) => item.active)
    }

    getStatusColor = (key, marketName) => {
        if (
            !Object.keys(this.allStatuses).length ||
            !this.allStatuses[marketName] ||
            !this.allStatuses[marketName].length
        )
            return ''
        const statusObj = this.allStatuses[marketName].find(
            (status) => status.key === key
        )
        if (statusObj) {
            return statusObj.color
        } else {
            return 'gray'
        }
    }

    getStatusName = (key, marketName) => {
        if (
            !Object.keys(this.allStatuses).length ||
            !this.allStatuses[marketName] ||
            !this.allStatuses[marketName].length
        )
            return ''
        const statusObj = this.allStatuses[marketName].find(
            (status) => status.key === key
        )
        if (statusObj) {
            return statusObj[`ruName`]
        } else {
            return 'unknown'
        }
    }

    mergeOrdersTabs = (filtered) => {
        const statusesTabs = {}
        Object.entries(filtered).forEach(([key, value]) => {
            const tabs = []
            Object.values(value.items).forEach((statuses) => {
                const tab = {
                    id: statuses.code,
                    name: statuses.nameFew,
                    disabled: false,
                    visible: true,
                }
                tabs.push(tab)
            })
            tabs.push({
                id: 'all',
                name: 'Все',
                disabled: false,
                visible: true,
            })
            statusesTabs[key] = tabs
        })
        this.setStatusesTabs(statusesTabs)
    }

    mergeOrders = (source) => {
        const {page, pages, total, orders} = source

        const paginationData = {
            page: page,
            total: total,
            totalPages: pages,
        }
        this.setOrders(orders)
        this.updateParams({
            page: page,
        })
        this.paginationStore.setPagination(paginationData)
    }

    collectListPayload = () => {
        const body = {
            ...this.params,
        }
        delete body.states

        if (this.params.states !== 'all') body.states = this.params.states

        if (this.activeListSort) {
            body['sort_key'] = this.activeListSort.key
            body['sort_dir'] = this.activeListSort.dir
        }
        return body
    }

    getOrdersStates = async () => {
        try {
            const {data} = await OrdersService.getStates()
            this.mergeOrdersTabs(data.states)
            this.setIsStatuses(true)
        } catch (e) {
            console.log(e)
        }
    }

    getOrdersAllStates = async () => {
        try {
            const {data} = await OrdersService.getAllStates()
            this.setAllStatuses(data.statesAll)
        } catch (e) {
            console.log(e)
        }
    }

    getOrdersList = async (params) => {
        this.updateParams(params)
        const body = this.collectListPayload()
        this.setOrdersLoader(true)
        try {
            const {data} = await OrdersService.getOrders(body)
            this.mergeOrders(data)
        } catch (e) {
            console.log(e)
        } finally {
            this.setOrdersLoader(false)
        }
    }

    testFilter = async () => {
        this.setIsTested(true)
        this.setFilterLoading(true)
        const body = {
            ...this.collectListPayload(),
            ...this.appliedFilter,
            limit: 1,
            page: 1,
        }
        try {
            const {data} = await OrdersService.getOrders(body)
            this.setTestFilterResults(data.total)
        } catch (e) {
            console.log(e)
        } finally {
            this.setFilterLoading(false)
        }
    }

    orderActionRun = async (payload) => {
        try {
            const {data} = await OrdersService.postOrderRun(payload)
            await this.getOrdersList()
        } catch (e) {
            console.log(e)
        } finally {
            this.setOrdersLoader(false)
        }
    }

    orderArbitration = async (orderId) => {
        this.setOrdersLoader(true)
        try {
            const {data} = await OrdersService.postOrderArbitration(orderId, {})
            await this.getOrdersList()
        } catch (e) {
            console.log(e)
        } finally {
            this.setOrdersLoader(false)
        }
    }

    dwlMarks = async (payload, name) => {
        try {
            const {data} = await OrdersService.postDwlMarks(payload)
            FileDownload(data, name)
        } catch (e) {
            console.log(e)
        }
    }

    orderTrackingNumber = async (id, payload) => {
        try {
            const {data} = await OrdersService.postOrderTrackingNumber(
                id,
                payload
            )
        } catch (e) {
            console.log(e)
        }
    }

    postBulkUpdate = async (validate, orderIds, oldState, newState) => {
        const payload = {
            validate: validate,
            order_ids: orderIds,
            old_state: oldState,
            new_state: newState,
        }
        try {
            const {data} = await OrdersService.postBulkUpdate(payload)
            return data
        } catch (e) {
            console.log(e)
        }
    }

    getDwlDocs = async (attachmentId) => {
        try {
        } catch (e) {}
    }

    getDownloadDocument = async (url, name) => {
        try {
            const {data} = await OrdersService.getDownloadDocument(url)
            FileDownload(data, name)
        } catch (e) {
            console.log(e)
        }
    }

    getWarehousesNeedVerify = async () => {
        const payload = {state: 'need_verify'}
        try {
            const {
                data: {result},
            } = await WarehousesService.getWarehouses(payload)
            this.setWarehousesNeedVerify(result.marketplacesWithWarehouses)
        } catch (e) {
            console.log(e)
        }
    }
}

export default OrdersStore
