import {useTimer} from 'react-timer-hook'

const Timer = ({delay = 59, children}) => {
    const time = new Date()
    time.setSeconds(new Date().getSeconds() + delay)
    const timerData = useTimer({
        expiryTimestamp: time,
    })

    return children(timerData)
}

export {Timer}
