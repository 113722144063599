import {observer} from 'mobx-react'
import Screen1 from '../../images/screen_1.png'
import {ImportWbFileUpload} from '../ImportWbFileUpload'
import React from 'react'

export const ImportWbLegalFiles = observer(
    ({xlsUploadFileStore, zipUploadFileStore}) => {
        return (
            <ul
                className={
                    'text-gray-700 dark:text-gray-300 list-decimal ml-4 mt-2 space-y-2'
                }
                role='list'
            >
                <li>
                    Выгрузите в Excel список финансовых отчетов
                    <a
                        className={
                            'ml-1 cursor-pointer text-blue-500 dark:text-blue-400 hover:opacity-80 items-center'
                        }
                        target={'_blank'}
                        href={
                            'https://seller.wildberries.ru/suppliers-mutual-settlements/reports-implementations/reports-weekly-new'
                        }
                    >
                        в разделе "Финансовые отчёты" Кабинета Продавца
                        Wildberries (кнопка Скачать Excel)
                    </a>
                </li>
                <div className={'flex items-center'}>
                    <img className={'max-w-4xl'} src={Screen1} alt={''} />
                </div>
                <li>
                    Загрузите полученный файл в формате Excel БЕЗ ИЗМЕНЕНИЙ.
                </li>
                <ImportWbFileUpload
                    uploadFileStore={xlsUploadFileStore}
                    extension={'xlsx'}
                    accept={[
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    ]}
                />

                <li>
                    Выгрузите детализации необходимых отчетов, которые попали в
                    выгруженный в Excel список на первом шаге.
                </li>
                <li>Загрузите детализации в формате ZIP БЕЗ ИЗМЕНЕНИЙ</li>
                <ImportWbFileUpload
                    uploadFileStore={zipUploadFileStore}
                    extension={'zip'}
                />
            </ul>
        )
    }
)
