import {observable, action, makeObservable} from 'mobx'
import FeedbackService from '@services/FeedbackService/FeedbackService'
import {toast} from 'react-toastify'

class PromotionStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            service: observable,
            pending: observable,
            error: observable,
            setService: action,
            setPending: action,
            setError: action,
        })
    }

    service = {
        options: [
            {
                name: 'SEO-оптимизация карточек товаров',
                key: 'seo',
            },
            {
                name: 'Настройка и корректировка внутренней рекламы',
                key: 'settings_internal_advertising_tools',
            },
            {
                name: 'Создание продающей инфографики и моушн-дизайн',
                key: 'perfect_product_card',
            },
            {
                name: 'Разработка Rich-контента и баннеров',
                key: 'rich_content',
            },
            {
                name: 'Организация фотосессий товара',
                key: 'organization_product_photo',
            },
        ],
        selected: {
            name: 'SEO-оптимизация карточек товаров',
            key: 'seo',
        },
    }

    pending = false
    error = ''

    setService = (value = undefined) => {
        this.service.selected = value
    }

    setPending = (value = true) => {
        this.pending = value
    }

    setError = (error = '') => {
        this.error = error
    }

    submitForm = async () => {
        const {userData} = this.rootStore.userStore
        const payload = {
            to: 'support@seller24.ru',
            request_type: 'request_service_promotion',
            title: `Заказ продвижения: ${this.service.selected.name}`,
            fields: [
                {
                    title: 'name',
                    value: userData.name || '',
                },
                {
                    title: 'phone',
                    value:
                        userData?.phone_number ||
                        userData?.new_phone_number ||
                        '',
                },
                {
                    title: 'email',
                    value: userData?.email || '',
                },
                {
                    title: 'promotion',
                    value: this.service.selected.name,
                },
            ],
        }
        this.setPending(true)
        this.setError()
        try {
            const {data} = await FeedbackService.postMessage(payload)
            if (data.result) {
                toast('Заявка отправлена', {type: 'success'})
            }
        } catch (e) {
            this.setError(
                'Что-то пошло не так. Попробуйте снова или обратитесь в поддержку.'
            )
        } finally {
            this.setPending(false)
        }
    }
}

export default PromotionStore
