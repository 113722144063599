import {ApiFactory} from '@services/ApiFactory'
import {AuthUrl, ProfileUrl} from '@routesEnums'
import {baseConfig} from '@services/apiConfigs'

const profileInstance = ApiFactory.createApi(baseConfig)

class ProfileService {
    static getProfile = async () => {
        return await profileInstance.get(ProfileUrl.profile)
    }

    static postProfile = async (payload, options) => {
        return await profileInstance.post(ProfileUrl.profile, payload, options)
    }

    static getAvatar = async () => {
        const newConfig = {
            responseType: 'blob',
        }
        return await profileInstance.get_raw(
            ProfileUrl.profileAvatar,
            undefined,
            newConfig
        )
    }

    static postAvatar = async (file) => {
        return await profileInstance.postFile(ProfileUrl.profileAvatar, file)
    }

    static getConfirmUser = async (userUuid) => {
        return await profileInstance.get(
            ProfileUrl.profileConfirmUser(userUuid)
        )
    }

    static getConfirmChangeEmail = async (token) => {
        return await profileInstance.get(
            ProfileUrl.profileConfirmChangeEmail(token)
        )
    }

    static getConfirmChangePhone = async (payload) => {
        return await profileInstance.get(
            ProfileUrl.profileConfirmChangePhone,
            payload
        )
    }

    static postConfirmChangePhone = async (payload) => {
        const config = {
            params: payload,
        }
        return await profileInstance.post(
            ProfileUrl.profileConfirmChangePhone,
            undefined,
            config
        )
    }
    static postConfirmEmailRepeat = async (payload) => {
        return await profileInstance.post(AuthUrl.confirmEmailRepeat, payload)
    }
}

export default ProfileService
