import {convertAmountValue} from '@components/elements'

export const GreenExtension = ({
    detailedAmount,
    detailedCommission,
    marginAmount,
    marginCommission,
}) => {
    if (
        !detailedAmount ||
        !detailedCommission ||
        !marginAmount ||
        !marginCommission
    )
        return null
    return (
        <div className={'flex flex-col gap-4'}>
            <span>
                {`Цена розничная с учетом согласованной скидки (фин.отчет эксель): ${convertAmountValue(
                    {value: detailedAmount, showCurrency: false}
                )} руб.`}
            </span>
            <span>
                {`Выручка (марж. приб.): ${convertAmountValue({
                    value: marginAmount,
                    showCurrency: false,
                })} руб.`}
            </span>
            <span>
                {`Комиссия  (фин.отчет эксель): ${convertAmountValue({
                    value: detailedCommission,
                    showCurrency: false,
                })} руб.`}
            </span>
            <span>
                {`Комиссия (марж. приб): ${convertAmountValue({
                    value: marginCommission,
                    showCurrency: false,
                })} руб.`}
            </span>
        </div>
    )
}
