import {ApiFactory} from '@services/ApiFactory'
import {baseConfig} from '@services/apiConfigs'
import {NotificationsUrl} from '../../routes-enums'

const notificationsInstance = ApiFactory.createApi(baseConfig)

class NotificationsService {
    static getNotifications = async (payload) => {
        return await notificationsInstance.get(
            NotificationsUrl.notifications,
            payload
        )
    }

    static patchNotification = async (payload) => {
        const config = {
            params: payload,
        }
        return await notificationsInstance.patch(
            NotificationsUrl.notifications,
            undefined,
            config
        )
    }

    static postNotification = async (payload) => {
        return await notificationsInstance.post(
            NotificationsUrl.notifications,
            payload
        )
    }

    static patchNotificationView = async (payload) => {
        return await notificationsInstance.patch(
            NotificationsUrl.notificationView,
            payload
        )
    }

    static deleteNotification = async (payload) => {
        const config = {
            params: payload,
        }
        return await notificationsInstance.delete(
            NotificationsUrl.notifications,
            undefined,
            config
        )
    }

    static postNotificationPrivate = async (payload) => {
        return await notificationsInstance.post(
            NotificationsUrl.notificationPrivate,
            payload
        )
    }
}

export default NotificationsService
