import React from 'react'
import {observer} from 'mobx-react'

export const FileSharingTitle = observer(({title}) => {
    return (
        <h2 className={'text-2xl font-medium text-gray-800 dark:text-gray-200'}>
            {title}
        </h2>
    )
})
