import AuthService from '@services/AuthService/AuthService'
import {action, makeObservable, observable} from 'mobx'
import {toast} from 'react-toastify'
import {getErrorAuthFields} from '@views/auth/utils'

export class ResetPasswordStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            loading: observable,
            loadingNewPassword: observable,
            phone: observable,
            userUuid: observable,
            isConfirmCodeBlock: observable,
            isValidReset: observable,
            isValidNewPassword: observable,
            confirmEmailModal: observable,
            resetToken: observable,
            errors: observable,
            setLoading: action,
            setLoadingNewPassword: action,
            setIsConfirmCodeBlock: action,
            setConfirmEmailModal: action,
            setPhone: action,
            setUserUuid: action,
            setIsValidReset: action,
            setIsValidNewPassword: action,
            setResetToken: action,
            addErrors: action,
            setErrors: action,
        })
    }

    loading = false
    isConfirmCodeBlock = false
    confirmEmailModal = false
    isValidReset = false
    isValidNewPassword = false
    loadingNewPassword = false
    phone = ''
    errors = {message: '', fields: {}}
    userUuid = ''
    resetToken = ''

    setLoading = (value = false) => {
        this.loading = value
    }

    setIsConfirmCodeBlock = (value = false) => {
        this.isConfirmCodeBlock = value
    }

    setConfirmEmailModal = (value = false) => {
        this.confirmEmailModal = value
    }

    setIsValidReset = (value = false) => {
        this.isValidReset = value
    }

    setIsValidNewPassword = (value = false) => {
        this.isValidNewPassword = value
    }

    setLoadingNewPassword = (value = false) => {
        this.loadingNewPassword = value
    }

    setPhone = (value = '') => {
        this.phone = value
    }

    setUserUuid = (value = '') => {
        this.userUuid = value
    }

    setResetToken = (value = '') => {
        this.resetToken = value
    }

    setErrors = (value = {message: '', fields: {}}) => {
        this.errors = value
    }

    addErrors = (key, value) => {
        this.errors[key] = value
    }

    reset = () => {
        this.setResetToken()
        this.setPhone()
        this.setUserUuid()
        this.setIsValidNewPassword()
        this.setIsValidReset()
        this.setConfirmEmailModal()
        this.setIsConfirmCodeBlock()
        this.setErrors()
    }

    postResetPasswordByEmail = async (email) => {
        this.setLoading(true)
        const payload = {email}
        try {
            const {data} = await AuthService.postResetPasswordByEmail(payload)
            if (data.result) {
                this.setConfirmEmailModal(true)
            }
        } catch (e) {
            console.log(e)
            if (e.response?.data?.result === false) {
                const errors = {
                    message: e.response.data.message,
                    fields: getErrorAuthFields(e.response.data.formError || {}),
                }
                return this.setErrors(errors)
            }
        } finally {
            this.setLoading(false)
        }
    }

    postResetPasswordByPhone = async (phone) => {
        this.setLoading(true)
        const payload = {phone_number: phone}
        this.setPhone(phone)
        try {
            const {data} = await AuthService.postResetPasswordByPhone(payload)
            if (data.result) {
                this.setUserUuid(data.userUuid)
                this.setIsConfirmCodeBlock(true)
            }
        } catch (e) {
            console.log(e)
            if (e.response.data.result === false) {
                const errors = {
                    message: e.response.data.message,
                    fields: getErrorAuthFields(e.response.data.formError || {}),
                }
                return this.setErrors(errors)
            }
            this.addErrors(
                'message',
                'Что-то пошло не так... Попробуйте снова или обратитесь в поддержку'
            )
        } finally {
            this.setLoading(false)
        }
    }

    getResetPasswordByPhone = async (code, userUuid) => {
        this.setErrors()
        const payload = {
            sms_code: code,
            user_uuid: userUuid,
        }
        this.addErrors('smsCode', '')
        try {
            const {data} = await AuthService.getResetPasswordByPhone(payload)
            if (data.result) {
                this.setIsConfirmCodeBlock(false)
                this.setResetToken(data.resetToken)
                this.getResetPassword(data.resetToken)
            }
        } catch (e) {
            console.log(e)
            this.addErrors(
                'smsCode',
                e.response.data.message || 'Ошибка сброса пароля'
            )
        }
    }

    getResetPassword = async (resetToken) => {
        if (!this.resetToken) {
            this.setResetToken(resetToken)
        }
        try {
            const {data} = await AuthService.getResetPassword(resetToken)
            if (data.result) {
                this.setIsValidReset(true)
                return Promise.resolve(data)
            }
        } catch (e) {
            console.log(e)
            return Promise.reject(e)
        }
    }

    postResetPassword = async (payload) => {
        this.setLoadingNewPassword(true)
        try {
            const {data} = await AuthService.postResetPassword(
                payload,
                this.resetToken
            )
            if (data.result) {
                this.reset()
                this.setIsValidReset(false)
                //this.setIsValidNewPassword(true)
                toast(data.message, {type: 'success'})
            }
            return Promise.resolve(data)
        } catch (e) {
            console.log(e)
            toast(e.response.data.message, {type: 'error'})
            return Promise.reject(e)
        } finally {
            this.setLoadingNewPassword(false)
        }
    }
}
