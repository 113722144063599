import React from 'react'
import classNames from 'classnames'

export const DescriptionField = ({title, value, className}) => {
    return (
        <div
            className={classNames(
                className,
                'flex space-x-2 text-gray-600 dark:text-gray-400'
            )}
        >
            <span>{title}:</span>
            <span>{value}</span>
        </div>
    )
}
