export const useDynamic = () => {
    const colors = (status, notColor) => {
        let colorLabel = 'gray'
        let color = '#C3CAD5'
        if (notColor) {
            return {
                colorLabel,
                color,
            }
        }
        if (status === 'positive') {
            colorLabel = 'green'
            color = '#4CDB96'
        } else if (status === 'negative') {
            colorLabel = 'red'
            color = '#EB5757'
        }
        return {colorLabel, color}
    }
    return {colors}
}
