import React, {useMemo} from 'react'
import {observer} from 'mobx-react'
import {xlsStatus} from '@utils/constants'
import {Accordion, DescriptionField, Mdash} from '@components/elements'
import {Loader} from '@components/elements/Loader'
import {useSelector} from '@hooks'

export const FileHistoryItem = observer(
    ({history, downloadXls, options = {}}) => {
        const {
            taskUuid,
            filename,
            statistics,
            createdAt,
            status,
            uploadMessage,
            actualTo,
            cabinets,
        } = history

        // const cabinetNames = useMemo(() => {
        //     return cabinets.map((item) => item.cabinetName)
        // }, [cabinets.length])
        const marketStore = useSelector((store) => store.marketStore)

        const cabinetsText = useMemo(() => {
            if (!cabinets) return ''
            return cabinets
                .map((cabinet) => marketStore.getCabinetNameById(cabinet))
                .join(', ')
        }, [cabinets])

        return (
            <div className={'flex flex-col text-gray-700 dark:text-gray-300'}>
                <span className={'text-blue-500 dark:text-blue-400'}>
                    {createdAt}
                </span>
                {/*<span>{`Кабинеты: ${cabinetNames.join(', ')}`}</span>*/}
                {status === xlsStatus.failed ? (
                    <span>Файл загружен с ошибкой</span>
                ) : status === xlsStatus.inProcess ? (
                    <div className={'flex space-x-2 items-center'}>
                        <span>{uploadMessage}</span>
                        <Loader sm overlayed={false} />
                    </div>
                ) : (
                    <span>{`Обновлено товаров: ${statistics?.updated}; Ошибок: ${statistics?.errors}`}</span>
                )}

                {statistics?.errorsText.length ? (
                    <Accordion triggerContent={'Ошибки обновления'}>
                        <div className={'flex flex-col'}>
                            <ul role='list'>
                                {statistics.errorsText.map((text, idx) => {
                                    return <li key={idx}>{text}</li>
                                })}
                            </ul>
                        </div>
                    </Accordion>
                ) : null}
                <span>
                    Файл:{' '}
                    <span
                        onClick={() => downloadXls(taskUuid)}
                        className={'underline cursor-pointer'}
                    >
                        {filename || 'Файл'}
                    </span>
                </span>
                {options.actual && (
                    <DescriptionField
                        className={'text-gray-700 dark:text-gray-300'}
                        title={'Действует с'}
                        value={actualTo || <Mdash />}
                    />
                )}
                <DescriptionField
                    className={'text-gray-700 dark:text-gray-300'}
                    title={'Кабинет'}
                    value={cabinetsText || <Mdash />}
                />
            </div>
        )
    }
)
