export const isNumber = (value) => {
    return typeof value === 'number' && isFinite(value)
}

export const rafThrottle = (cb) => {
    let rafId = null
    let latestArgs

    return function throttled(...args) {
        latestArgs = args
        if (isNumber(rafId)) {
            return
        }

        rafId = requestAnimationFrame(() => {
            cb(...latestArgs)
            rafId = null
        })
    }
}

let rafScheduled = false
const tasks = []

export const scheduledDOMUpdate = (cb) => {
    tasks.push(cb)
    if (rafScheduled) {
        return
    }

    rafScheduled = true
    requestAnimationFrame(() => {
        const taskToRun = tasks.slice()
        tasks.length = 0
        taskToRun.forEach((task) => task())
        rafScheduled = false
    })
}

export const findNearestBinarySearch = (low, high, input, getVal) => {
    while (low <= high) {
        const mid = ((low + high) / 2) | 0
        const val = getVal(mid)

        if (input < val) {
            high = mid - 1
        } else if (input > val) {
            low = mid + 1
        } else {
            return mid
        }
    }

    return low > 0 ? low - 1 : 0
}

export const shouldUpdate = (prev, next, skip = {}) => {
    if (prev.length !== next.length) return true
    for (let i = 0; i < prev.length; i += 1)
        if (
            Object.keys(prev[i]).some((key) => {
                const k = key
                return !skip[k] && prev[i][k] !== next[i][k]
            })
        )
            return true

    return false
}

export const now = () =>
    'performance' in window ? performance.now() : Date.now()
