import React from 'react'
import {Portal} from '@components/elements'
import {MultiSelectBody} from './MultiSelectBody'

export const MultiSelectPortal = (props) => {
    return (
        <Portal portalContainerId={'select'}>
            <MultiSelectBody {...props} />
        </Portal>
    )
}
