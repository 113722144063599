import {Api} from '@services/apiService'

export class ApiFactory {
    static apiInstance = {}
    static createApi(config, name = 'base') {
        if (ApiFactory.apiInstance[name]) return ApiFactory.apiInstance[name]
        ApiFactory.apiInstance[name] = new Api(config)
        return ApiFactory.apiInstance[name]
    }
}
