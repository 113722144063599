import {CheckIcon} from '@heroicons/react/outline'
import {Button} from '@components/elements'
import React from 'react'
import {observer} from 'mobx-react'
import {useAuditContext} from '@views/marketplaces/pages/Marketplaces/components/Audit/context/AuditContext'
import {ModalFooter} from '@components/overlays/modals/ModalFooter'
import FeedbackService from '@services/FeedbackService/FeedbackService'
import {useSelector} from '@hooks'

export const MpConnectedDialog = observer(({onClose}) => {
    const {auditStore} = useAuditContext()
    const userStore = useSelector((store) => store.userStore)

    const fetchEnroll = async () => {
        const payload = {
            to: 'support@seller24.ru',
            request_type: 'request_audit',
            title: `Запрос аудита`,
            fields: [
                {
                    title: 'name',
                    value: userStore.userData.name || '',
                },
                {
                    title: 'phone',
                    value: userStore.userData.phone_number?.replace(
                        /[ \-()]/g,
                        ''
                    ),
                },
                {
                    title: 'email',
                    value: userStore.userData?.email || '',
                },
            ],
        }
        await FeedbackService.postMessage(payload)
    }

    const handleClickEnroll = async () => {
        auditStore.postNeedAudit('lead')
        fetchEnroll()
        onClose()
    }

    const handleClickNextTime = async () => {
        auditStore.postNeedAudit('next_time')
        onClose()
    }

    return (
        <>
            <div className={'flex flex-col items-center gap-4'}>
                <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
                    <CheckIcon
                        className='h-6 w-6 text-green-600'
                        aria-hidden='true'
                    />
                </div>
                <div className='text-center '>
                    <h3 className='text-lg leading-6 font-medium text-gray-900 dark:text-gray-100'>
                        Маркетплейс подключен успешно!
                    </h3>
                </div>
                <div
                    className={
                        'flex flex-col gap-y-4 text-gray-600 dark:text-gray-400'
                    }
                >
                    <p>
                        Пока мы подгружаем ваши данные, вы можете ознакомиться с
                        отчетами платформы на демоданных. Подгрузка займет 10-20
                        минут.
                    </p>
                    <p>
                        А чтобы вам проще было разобраться с платформой - для
                        новых пользователей мы проводим{' '}
                        <strong className={'text-blue-500 dark:text-blue-300'}>
                            бесплатный
                        </strong>{' '}
                        аудит магазина.
                    </p>
                    <p>
                        Дадим свежий экспертный взгляд на точки роста ваших
                        продаж, и заодно покажем, как пользоваться платформой.
                        Обычно это занимает 15-30 минут.
                    </p>
                    <p>
                        Нажмите “записаться” и мы позвоним вам, чтобы
                        договориться по времени.
                    </p>
                </div>
            </div>
            <ModalFooter>
                <div className={'flex w-full space-x-2'}>
                    <Button className={'w-2/3'} onClick={handleClickEnroll}>
                        Записаться на аудит
                    </Button>
                    <Button
                        className={'w-1/3'}
                        color={'secondary'}
                        onClick={handleClickNextTime}
                    >
                        Не нужно
                    </Button>
                </div>
            </ModalFooter>
        </>
    )
})
