import React, {useCallback, useEffect, useState} from 'react'
import {observer} from 'mobx-react'
import {Alert} from '@components/feedback'
import YandexAuth from '../Yandex/store/YandexAuth'
import FooterButtons from '../../components/FooterButtons'
import {Button} from '@components/elements'
import {useDebouncedFunction} from '@hooks/customHooks'
import {Loader} from '@components/elements/Loader'
import {geErrorMessage} from '@utils/errors/marketpalcesError'
import {YandexConnectorsForm} from './components/YandexConnectorsForm'

export const YandexConnector = observer(
    ({onCancel, onSuccess, onError, connectorData}) => {
        const [connectedLoader, setConnectedLoader] = useState(false)
        const [responseError, setResponseError] = useState()
        const [yandexAuthStore] = useState(
            () => new YandexAuth(setResponseError)
        )

        useEffect(() => {
            if (connectorData?.credentials?.token) {
                yandexAuthStore.getCampaigns(connectorData?.credentials?.token)
            }
        }, [connectorData?.credentials?.token])

        const handleStartYandexAuth = async () => {
            setConnectedLoader(true)
            try {
                await yandexAuthStore.startYandexAuth()
            } catch (e) {
                if (!e?.response.data?.result && e?.response.data?.message) {
                    setResponseError(geErrorMessage(e?.response.data))
                } else {
                    setResponseError(geErrorMessage({code: -1}))
                }
                if (onError) onError()
            } finally {
                setConnectedLoader(false)
            }
        }

        const handleStartYandexAuthDebounced = useDebouncedFunction(
            handleStartYandexAuth,
            100
        )

        const renderButton = useCallback(
            (style) => {
                return (
                    <Button
                        className={style}
                        disabled={
                            connectedLoader || connectorData?.credentials?.token
                        }
                        onClick={handleStartYandexAuthDebounced}
                    >
                        {connectedLoader ||
                        connectorData?.credentials?.token ? (
                            <>
                                <Loader
                                    sm
                                    overlayed={false}
                                    className={'mr-2'}
                                />
                            </>
                        ) : (
                            'Авторизоваться'
                        )}
                    </Button>
                )
            },
            [connectedLoader]
        )

        return (
            <>
                {yandexAuthStore.campaigns.options.length ? (
                    <YandexConnectorsForm
                        yandexAuthStore={yandexAuthStore}
                        onCancel={onCancel}
                        onSuccess={onSuccess}
                        onError={onError}
                        connectorData={connectorData}
                    />
                ) : (
                    <div className={'w-full relative'}>
                        {responseError && (
                            <Alert
                                error
                                title={responseError}
                                className={'mb-6'}
                            />
                        )}
                        <FooterButtons
                            connectedButton={renderButton}
                            onCancel={onCancel}
                        />
                    </div>
                )}
            </>
        )
    }
)

YandexConnector.displayName = 'YandexConnector'
