import {ModalFooter} from '@components/overlays/modals/ModalFooter'
import {Button} from '@components/elements'
import React, {useMemo} from 'react'
import {useAuditContext} from '../../../context/AuditContext'
import * as yup from 'yup'
import {regexes} from '@utils/regexes'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {TextFieldMask} from '@components/forms/TextFields'
import FeedbackService from '@services/FeedbackService/FeedbackService'
import {useSelector} from '@hooks'

const schema = yup.object().shape({
    phoneNumber: yup
        .string()
        .matches(regexes.telRu, 'Неверный формат номера телефона')
        .required('Номер телефона не должен быть пустым'),
})
export const IndicatePhoneNumber = ({setIsIndicatePhoneNumber}) => {
    const {auditStore} = useAuditContext()
    const userStore = useSelector((store) => store.userStore)
    const {
        clearErrors,
        setError,
        register,
        handleSubmit,
        formState: {errors: formErrors},
    } = useForm({
        resolver: yupResolver(schema),
    })
    const onSubmit = async ({phoneNumber}) => {
        const payload = {
            to: 'support@seller24.ru',
            request_type: 'request_audit',
            title: `Запрос аудита`,
            fields: [
                {
                    title: 'name',
                    value: userStore.userData.name || '',
                },
                {
                    title: 'phone',
                    value: phoneNumber?.replace(/[ \-()]/g, ''),
                },
                {
                    title: 'email',
                    value: userStore.userData?.email || '',
                },
            ],
        }
        await FeedbackService.postMessage(payload)
        clearErrors()
        setIsIndicatePhoneNumber(false)
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div
                className={
                    'flex flex-col gap-y-4 text-gray-600 dark:text-gray-400'
                }
            >
                <p>
                    Пожалуйста, укажите номер телефона, чтобы получить звонок от
                    эксперта
                </p>

                <TextFieldMask
                    {...register('phoneNumber')}
                    mask={'+7 999 999-99-99'}
                    label={'Номер телефона'}
                    error={!!formErrors.phoneNumber}
                    helperText={formErrors?.phoneNumber?.message}
                />
            </div>
            <ModalFooter>
                <Button type={'submit'}>Записаться</Button>
            </ModalFooter>
        </form>
    )
}

IndicatePhoneNumber.displayName = 'IndicatePhoneNumber'
