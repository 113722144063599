import GoogleTagManager from '@utils/googleUtils/googleTagManager'
import {TokenService} from '@services/TokenService'

export const gtmAuthCompleted = (userStore) => {
    if (TokenService.isEmulationExists() || userStore.isEmulation) return
    if (
        userStore.userData.email ||
        userStore.userData.phone_number ||
        userStore.unverifiedUserData.email ||
        userStore.unverifiedUserData.phone_number
    ) {
        const userData =
            userStore.userData.email || userStore.userData.phone_number
                ? userStore.userData
                : userStore.unverifiedUserData
        const {
            user_uuid,
            first_auth_cohort_date,
            reg_cohort_date,
            reg_type,
            first_apikey_cohort_date,
            is_test_user,
            user_is_demo,
            email,
            phone_number,
            current_login_method,
            register_method,
            tariff: {slug},
            pim: {
                apikey_qty,
                apikey_qty_wb,
                apikey_qty_ozon,
                apikey_qty_ym,
                gmv_orders,
                gmv_sales,
                gmv_skus,
            },
        } = userData
        GoogleTagManager.dataLayer({
            event: 'mainEvent',
            eventName: 'set_dimensions',
            reg_cohort_date,
            current_login_method,
            register_method,
            first_auth_cohort_date,
            reg_type,
            first_apikey_cohort_date,
            apikey_qty,
            apikey_qty_wb,
            apikey_qty_ozon,
            apikey_qty_ym,
            is_test_user,
            gmv_orders,
            gmv_sales,
            gmv_skus,
            user_is_demo,
            current_plan: slug,
            user_id: user_uuid,
            email: email || phone_number,
        })
    }
}
