import React from 'react'
import {LockClosedIcon} from '@heroicons/react/outline'
import {TooltipLite, LockedTooltipContent} from '@components/elements'
import classNames from 'classnames'

export const LockedWrapperWithoutIcon = ({className, isBlocked, children}) => {
    return !isBlocked ? (
        children
    ) : (
        <TooltipLite
            offset={[0, 10]}
            content={<LockedTooltipContent />}
            classNameBody={'md:p-2 max-w-[250px]'}
        >
            {(ref) => (
                <div ref={ref} className={classNames(className, 'relative')}>
                    {children}
                </div>
            )}
        </TooltipLite>
    )
}
