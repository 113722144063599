import {observer} from 'mobx-react'
import {ContainerFullOnMobile} from '@components/layout'
import {ImportWbOverlayPanel} from './components/ImportWbOverlayPanel'
import {useEffect, useState} from 'react'
import {WbUploadFileStore} from './store/WbUploadFileStore'
import {WbImportFiltersStore} from '@views/marketplaces/pages/ImportWb/components/ImportWbOverlay/store/WbImportFiltersStore'
import {useSelector} from '@hooks'

export const ImportWbOverlay = observer(({cabinet, onCheckStatuses}) => {
    const marketStore = useSelector((store) => store.marketStore)
    const [wbImportFiltersStore] = useState(
        () => new WbImportFiltersStore(cabinet, marketStore)
    )
    const [xlsUploadFileStore] = useState(
        () =>
            new WbUploadFileStore(wbImportFiltersStore, 'xlsx', onCheckStatuses)
    )
    const [zipUploadFileStore] = useState(
        () =>
            new WbUploadFileStore(wbImportFiltersStore, 'zip', onCheckStatuses)
    )

    useEffect(() => {
        return () => {
            onCheckStatuses(wbImportFiltersStore.cabinet.selected.key)
            wbImportFiltersStore.destroy()
            xlsUploadFileStore.destroy()
            xlsUploadFileStore.destroy()
        }
    }, [])

    return (
        <ContainerFullOnMobile className='py-6 space-y-6'>
            <ImportWbOverlayPanel
                filtersStore={wbImportFiltersStore}
                xlsUploadFileStore={xlsUploadFileStore}
                zipUploadFileStore={zipUploadFileStore}
            />
        </ContainerFullOnMobile>
    )
})

ImportWbOverlay.displayName = 'ImportWbOverlay'
