import {useEffect, useRef, useState} from 'react'

export const useTableScrollTop = (tableRef) => {
    const headerScrollRef = useRef(null)
    const [offsetWidth, setOffsetWidth] = useState(0)

    // Слушатель события скролла для синхронизации скроллбаров
    const syncScroll = (e) => {
        if (headerScrollRef.current) {
            tableRef.current.scrollLeft = e.target.scrollLeft
            headerScrollRef.current.scrollLeft = e.target.scrollLeft
        }
    }

    useEffect(() => {
        setOffsetWidth(tableRef.current?.scrollWidth)
    }, [tableRef.current?.scrollWidth])

    return {
        syncScroll,
        offsetWidth,
        tableRef,
        headerScrollRef,
    }
}
