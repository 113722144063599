import {action, makeObservable, observable, reaction} from 'mobx'
import MarketService from '@services/MarketService/MarketService'
import {YANDEX_DELIVERY_TYPE} from '@utils/constants'
import {convertCampaigns} from '../utils'
import {geErrorMessage} from '@utils/errors/marketpalcesError'

class YandexAuth {
    constructor(setResponseError) {
        this.setResponseError = setResponseError
        makeObservable(this, {
            code: observable,
            state: observable,
            campaigns: observable,
            authToken: observable,
            setCode: action,
            setState: action,
            setCampaignSelected: action,
            setCampaignOptions: action,
            setAuthToken: action,
        })

        this.disposer = reaction(
            () => this.code,
            (code) => {
                if (code) {
                    this.getTokenOAuthToken()
                }
            }
        )
    }

    wnd = ''

    wndInterval = ''

    code = ''

    state = ''

    authToken = ''
    campaigns = {
        options: [],
        selected: undefined,
    }

    setCampaignSelected = (value = undefined) => {
        this.campaigns.selected = value
    }

    setCampaignOptions = (value = []) => {
        this.campaigns.options = value
    }

    setCode = (code) => {
        this.code = code
    }

    setState = (state) => {
        this.state = state
    }

    setAuthToken = (token) => {
        this.authToken = token
    }

    getCampaignsOptions = (id) => {
        return this.campaigns.options.find((campaign) => campaign.id === id)
    }

    onLocationChange = () => {
        const code = localStorage.getItem('yandex_code')
        if (code) {
            clearInterval(this.wndInterval)
            this.setCode(code)
            setTimeout(() => {
                this.wnd.close()
                localStorage.removeItem('yandex_code')
            }, 10)
        }
    }

    openYandexAuth(authLink) {
        this.wnd = window.open(
            authLink,
            'Login',
            'width=600,height=700 top=' +
                (window.screen.height - 700) / 2 +
                ',left=' +
                (window.screen.width - 600) / 2 +
                ', resizable=no'
        )
        if (this.wnd) {
            this.wndInterval = setInterval(this.onLocationChange, 1000)
        }
    }

    getTokenOAuthToken = async () => {
        try {
            const {data} = await MarketService.getCheckSsoYandex({
                code: this.code,
            })
            if (data.result) {
                this.setAuthToken(data.oauthToken)
                await this.getCampaigns(data.oauthToken)
            }
        } catch (e) {
            if (!e?.response.data?.result && e?.response.data?.message) {
                this.setResponseError(geErrorMessage(e?.response.data))
            } else {
                this.setResponseError(geErrorMessage({code: -1}))
            }
        }
    }

    getCampaigns = async (token) => {
        try {
            const {data} = await MarketService.postCampaignsYandex({token})
            if (!data.campaigns.length) {
                return this.setResponseError(
                    'В личном кабинете по данному email магазины отсутствуют. Авторизация должна быть под почтой, у которой есть доступ к магазину и настройкам API ( роль владелец магазина или администратор)'
                )
            }
            this.setCampaignOptions(data.campaigns.map(convertCampaigns))
        } catch (e) {
            if (!e?.response.data?.result && e?.response.data?.message) {
                this.setResponseError(geErrorMessage(e?.response.data))
            } else {
                this.setResponseError(geErrorMessage({code: -1}))
            }
        }
    }

    startYandexAuth = async () => {
        try {
            const {data} = await MarketService.postSsoYandex()
            const {redirectUrl} = data
            this.openYandexAuth(redirectUrl)
        } catch (e) {
            console.log(e)
        }
    }
}

export default YandexAuth
