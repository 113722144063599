import classNames from 'classnames'
import {Link} from 'react-router-dom'
import {AppPath} from '../../../routes-enums'
import React from 'react'

export const LockedTooltipContent = ({
    title = 'Полная версия отчета доступна при покупке тарифа.',
}) => {
    return (
        <span className={classNames('space-x-1')}>
            <span>{title}</span>
            <Link
                to={AppPath.accountTariff}
                className={
                    'cursor-pointer whitespace-normal truncate text-blue-500 dark:text-blue-400 hover:opacity-80'
                }
            >
                Перейти к выбору тарифа
            </Link>
        </span>
    )
}
