import React, {Suspense, useEffect, useMemo, useState} from 'react'
import {observer} from 'mobx-react'
import {SidebarMobile, SidebarDesktop} from '@components/navigation'
import {useSelector} from '@hooks'
import {SlimSidebar} from '@components/navigation/sidebar/SlimSidebar/SlimSidebar'
import {LoaderFull} from '@components/elements/Loader'
import cn from 'classnames'
import {LayoutContext} from '@views/layouts/context/LayoutContext'
import {AuditStore} from '@views/marketplaces/pages/Marketplaces/components/Audit/store/AuditStore'
import {AuditContext} from '@views/marketplaces/pages/Marketplaces/components/Audit/context/AuditContext'
import {Audit} from '@views/marketplaces/pages/Marketplaces/components/Audit'
import {TelegramChannelModal} from '@components/applicationUi/TelegramChannelModal'

const SidebarLayout = observer(({children, isBlocked}) => {
    const layoutSidebarStore = useSelector((store) => store.layoutSidebarStore)

    const marketStore = useSelector((store) => store.marketStore)
    const userStore = useSelector((store) => store.userStore)
    const authStore = useSelector((store) => store.authStore)
    const [auditStore] = useState(
        () => new AuditStore(authStore, userStore.userData)
    )
    const {
        navigation,
        setSidebarOpen,
        sidebarOpen,
        changeActiveTab,
        toggleSidebar,
        isFullSidebar,
        isOpenOnboarding,
        setIsOpenOnboarding,
        currentActionAudit,
    } = layoutSidebarStore
    useEffect(() => {
        layoutSidebarStore.initialSidebar()
    }, [
        userStore.isAdminRole,
        userStore.userData.role,
        userStore.isPredTrial,
        userStore.userUuid,
        marketStore.isRealAcc,
    ])
    // Запрет на вход в приложение с мобилки
    // useMobile(marketStore, userStore.userData)
    return (
        <LayoutContext.Provider
            value={useMemo(() => ({isBlocked}), [isBlocked])}
        >
            <div className={'flex'}>
                <SidebarMobile
                    navigation={navigation}
                    setSidebarOpen={setSidebarOpen}
                    sidebarOpen={sidebarOpen}
                />
                {/* Static sidebar for desktop */}
                {isFullSidebar ? (
                    <SidebarDesktop
                        homePage={userStore.homePage}
                        isFullSidebar={isFullSidebar}
                        navigation={navigation}
                        toggleSidebar={toggleSidebar}
                    />
                ) : (
                    <SlimSidebar
                        homePage={userStore.homePage}
                        isFullSidebar={isFullSidebar}
                        navigation={navigation}
                        changeActiveTab={changeActiveTab}
                        toggleSidebar={toggleSidebar}
                    />
                )}
                <AuditContext.Provider
                    value={useMemo(() => ({auditStore}), [auditStore])}
                >
                    <div
                        className={cn('h-screen w-full flex flex-col flex-1', {
                            'md:pl-16 md:w-[calc(100%-4rem)]': !isFullSidebar,
                            'md:pl-64 md:w-[calc(100%-16rem)]': isFullSidebar,
                        })}
                    >
                        <Suspense fallback={<LoaderFull />}>
                            {marketStore.loaderAllCreds ? (
                                <LoaderFull />
                            ) : (
                                children({setSidebarOpen})
                            )}
                        </Suspense>
                    </div>
                    {isOpenOnboarding ? (
                        <Audit
                            isOpen={isOpenOnboarding}
                            onClose={() => {
                                setIsOpenOnboarding(false)
                            }}
                        />
                    ) : null}
                </AuditContext.Provider>
            </div>
            <TelegramChannelModal
                open={layoutSidebarStore.telegramChannelControl.isOpen}
                onClose={layoutSidebarStore.telegramChannelControl.close}
            />
        </LayoutContext.Provider>
    )
})

SidebarLayout.displayName = 'SidebarLayout'

export default SidebarLayout
