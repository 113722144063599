import React from 'react'
import Markdown from 'markdown-to-jsx'

export const Viewer = ({children, wrapper}) => {
    const WrapperComponent = wrapper
        ? wrapper
        : ({children}) => (
              <div className='border border-gray-300 p-4 text-gray-800 dark:text-gray-200 space-y-4'>
                  {children}
              </div>
          )
    return (
        <Markdown
            options={{
                wrapper: WrapperComponent,
                forceWrapper: true,
                overrides: {
                    a: {
                        props: {
                            className: 'text-blue-500 hover:underline',
                        },
                    },
                    br: {
                        component: () => <div className='h-4'></div>,
                    },
                    h1: {
                        component: ({children, ...props}) => (
                            <h1
                                className={
                                    'w-full text-2xl font-medium dark:text-gray-100'
                                }
                            >
                                {children}
                            </h1>
                        ),
                    },
                    p: {
                        component: ({children, ...props}) => <p>{children}</p>,
                    },
                },
            }}
        >
            {children}
        </Markdown>
    )
}
