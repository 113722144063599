import {makeObservable, observable} from 'mobx'

export class StatisticsStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            stats: observable,
            charts: observable,
        })
    }

    stats = [
        {
            title: 'Кабинеты по маркетплейсам',
            data: [
                {
                    market: 'ozon',
                    text: 'Ozon',
                    value: '417',
                },
                {
                    market: 'wildberries',
                    text: 'Wildberries',
                    value: '83',
                },
                {
                    market: 'aliexpress',
                    text: 'Aliexpress',
                    value: '52',
                },
                {
                    market: 'yandex',
                    text: 'Yandex',
                    value: '53',
                },
            ],
            total: {
                text: 'Всего кабинетов',
                value: '605',
            },
        },
        {
            title: 'Новые регистрации пользователей',
            data: [
                {
                    text: 'За сегодня:',
                    value: '0',
                },
                {
                    text: 'За 7 дней:',
                    value: '0',
                },
                {
                    text: 'За всё время:',
                    value: '427',
                },
            ],
        },
        {
            title: 'Новые кабинеты',
            data: [
                {
                    text: 'За сегодня:',
                    value: '0',
                },
                {
                    text: 'За 7 дней:',
                    value: '2',
                },
                {
                    text: 'За всё время:',
                    value: '614',
                },
            ],
        },
        {
            title: 'Активные пользователи',
            data: [
                {
                    text: 'Заходили хотя бы раз сегодня:',
                    value: '2',
                },
                {
                    text: 'Заходили хотя бы раз сегодня:',
                    value: '3',
                },
                {
                    text: 'Заходили хотя бы раз в течении месяца:',
                    value: '7',
                },
            ],
        },
        {
            title: 'Неактивные пользователи',
            data: [
                {
                    text: 'Не заходили более двух дней:',
                    value: '206',
                },
                {
                    text: 'Не заходили более недели:',
                    value: '205',
                },
                {
                    text: 'Не заходили более месяца:',
                    value: '201',
                },
            ],
        },
    ]

    chartsOptions = {
        dateRange: {
            startDate: new Date(Date.now() - 29 * 24 * 60 * 60 * 1000),
            endDate: new Date(),
        },
    }

    charts = [
        {
            title: 'Кабинеты Ozon',
            market: 'ozon',
            data: [
                5, 10, 8, 12, 12, 12, 14, 15, 14, 17, 18, 18, 16, 15, 14, 19,
                20, 20, 21, 22, 18, 18, 19, 17, 15, 16, 18, 19, 18, 20,
            ],
        },
        {
            title: 'Кабинеты Wildberries',
            market: 'wildberries',
            data: [
                17, 18, 18, 16, 15, 14, 19, 20, 20, 21, 22, 18, 18, 19, 17, 15,
                16, 5, 10, 8, 12, 12, 12, 14, 15, 14, 18, 19, 18, 20,
            ],
        },
        {
            title: 'Кабинеты Yandex',
            market: 'yandex',
            data: [
                5, 10, 12, 14, 15, 14, 17, 18, 8, 20, 20, 21, 22, 14, 19, 19,
                17, 15, 16, 18, 18, 12, 12, 18, 16, 15, 18, 19, 18, 20,
            ],
        },
        {
            title: 'Кабинеты Aliexpress',
            market: 'aliexpress',
            data: [
                15, 14, 19, 20, 16, 18, 19, 22, 18, 18, 19, 17, 15, 12, 12, 14,
                15, 5, 10, 8, 12, 20, 21, 18, 16, 14, 17, 18, 18, 20,
            ],
        },
    ]
}
