import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {useAuditContext} from './../../../context/AuditContext'
import {ModalFooter} from '@components/overlays/modals/ModalFooter'
import {Button} from '@components/elements'
import {useHistory} from 'react-router-dom'
import {AppPath} from '../../../../../../../../../routes-enums'

export const CompletionPage = observer(({onClose}) => {
    const {auditStore} = useAuditContext()
    const history = useHistory()

    useEffect(() => {
        auditStore.setAuditType('lead')
    }, [])

    const handleClose = () => {
        auditStore.modalControl.close()
        typeof onClose === 'function' && onClose()
        history.push(AppPath.marketplaces)
    }

    return (
        <>
            <div
                className={
                    'flex flex-col gap-y-4 text-gray-600 dark:text-gray-400'
                }
            >
                <p>
                    Ждите звонка от нашего эксперта-аналитика, который поможет
                    найти точки роста продаж!
                </p>
            </div>
            <ModalFooter>
                <Button onClick={handleClose}>Подключить кабинеты</Button>
            </ModalFooter>
        </>
    )
})

CompletionPage.displayName = 'CompletionPage'
