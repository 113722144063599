import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {Card, ContainerFullOnMobile} from '@components/layout'
import cn from 'classnames'
import {Button} from '@components/elements'
import {AppPath} from '../../../routes-enums'
import {Loader} from '@components/elements/Loader'
import {ServicesSlider} from '@components/applicationUi/sections'
import {useAnalyticEventsHook, useSelector} from '@hooks'
import {useHistory, useLocation} from 'react-router-dom'

export const Blocked = observer(() => {
    const {sendRefreshToken, refreshLoader} = useSelector(
        (store) => store.authStore
    )

    const history = useHistory()
    const location = useLocation()
    const {blockedPageView} = useAnalyticEventsHook()

    useEffect(() => {
        blockedPageView()
    }, [])

    const handleUpdateTariff = async () => {
        try {
            await sendRefreshToken()
            history.push(location.pathname)
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <ContainerFullOnMobile className='py-6 flex-grow space-y-6'>
            <Card>
                <div
                    className={cn(
                        'flex flex-col space-y-6 text-gray-600 px-4 max-w-full',
                        'dark:text-gray-400'
                    )}
                >
                    <h2
                        className={
                            'text-2xl font-medium text-gray-800 dark:text-gray-200'
                        }
                    >
                        Раздел заблокирован
                    </h2>
                    <p className='text-lg text-gray-500 dark:text-gray-200'>
                        Для восстановления доступа, пожалуйста, оплатите доступ
                        к платформе
                    </p>
                    <div
                        className={
                            'flex flex-col space-y-4 space-x-0 md:flex-row md:space-y-0 md:space-x-4'
                        }
                    >
                        <Button
                            onClick={() => history.push(AppPath.accountTariff)}
                        >
                            Перейти к оплате
                        </Button>
                        <Button
                            color={'white'}
                            disabled={refreshLoader}
                            onClick={handleUpdateTariff}
                        >
                            {refreshLoader ? (
                                <>
                                    <Loader
                                        sm
                                        overlayed={false}
                                        className={'mr-2'}
                                    />
                                    Идёт проверка оплаты...
                                </>
                            ) : (
                                'Проверить оплату'
                            )}
                        </Button>
                    </div>
                </div>
            </Card>
            <ServicesSlider />
        </ContainerFullOnMobile>
    )
})

Blocked.displayName = 'Blocked'
