import React, {useState} from 'react'
import {useResponsive, useSelector} from '@hooks'
import {Card} from '@components/layout'
import {MarketIcon, Button} from '@components/elements'
import {Modal} from '@components/overlays'
import ConnectedStatus from './ConnectedStatus'
import {
    LogoutIcon,
    PencilAltIcon,
    XIcon,
    CheckIcon,
    XCircleIcon,
} from '@heroicons/react/outline'
import MarketService from '@services/MarketService/MarketService'
import {ModalConfirm, ModalSuccess} from '@components/overlays'
import {observer} from 'mobx-react'
import classNames from 'classnames'
import {toast} from 'react-toastify'

const ConnectedCard = observer(
    ({
        credId,
        marketId,
        marketName,
        label,
        isValid,
        statuses,
        canManage,
        connector,
        canUnsubscribe,
    }) => {
        const {sm} = useResponsive()
        const marketStore = useSelector((store) => store.marketStore)
        const authStore = useSelector((store) => store.authStore)
        const [connectorOpen, setConnectorOpen] = useState(false)
        const [successConnector, setSuccessConnector] = useState(false)
        const [deletionDialog, setDeletionDialog] = useState(false)
        const [deletedModal, setDeletedModal] = useState(false)
        const [unsubscribeModal, setUnsubscribeModal] = useState(false)
        const [successUnsubscribeModal, setSuccessUnsubscribeModal] =
            useState(false)

        const onCancelConnector = () => {
            setConnectorOpen(false)
        }

        const onSuccessConnector = (id) => {
            marketStore.getList()
            setSuccessConnector(true)
            setTimeout(() => {
                setConnectorOpen(false)
                setSuccessConnector(false)
            }, 3000)
        }

        const onDeleteCredential = (id) => {
            setDeletionDialog(true)
        }

        const handleDeleteCredentials = async () => {
            const payload = {
                mp_acc_id: credId,
            }
            return await MarketService.deleteCredentialPim(payload)
        }

        const deleteUnsubscribe = async (query) => {
            return await MarketService.deleteUnsubscribeDemoAccByIds(query)
        }

        const onConfirmDelete = async () => {
            try {
                marketStore.removeStatus(credId)
                await handleDeleteCredentials()
                setDeletionDialog(false)
                setDeletedModal(true)
                setTimeout(() => {
                    setDeletedModal(false)
                }, 3000)
                await authStore.sendRefreshToken()
                await marketStore.getList()
            } catch (e) {
                console.log(e)
            }
        }

        const onConfirmUnsubscribe = async () => {
            const payload = {
                account_ids: [credId],
            }
            try {
                const {data} = await deleteUnsubscribe(payload)
                if (data.result) {
                    marketStore.removeStatus(credId)
                    setUnsubscribeModal(false)
                    setSuccessUnsubscribeModal(true)
                    setTimeout(() => {
                        setSuccessUnsubscribeModal(false)
                    }, 3000)
                    await authStore.sendRefreshToken()
                    await marketStore.getList()
                } else {
                    toast(data.message || 'Ошибка отписки кабинета', {
                        type: 'error',
                    })
                }
            } catch (e) {
                console.log(e)
                if (e.response.data.result === false) {
                    toast(
                        e.response.data.message || 'Ошибка отписки кабинета',
                        {type: 'error'}
                    )
                }
            }
        }

        const handleUnsubscribe = async () => {
            setUnsubscribeModal(true)
        }

        const handleClickEditFromDelete = () => {
            setDeletionDialog(false)
            setTimeout(() => setConnectorOpen(true), 200)
        }

        return (
            <Card
                innerClassName={
                    'flex items-center justify-between overflow-hidden'
                }
            >
                <div className={'flex items-center overflow-hidden'}>
                    <MarketIcon
                        name={marketName}
                        className={'mr-3 sm:mr-4'}
                        size={sm ? 40 : 30}
                    />
                    <h3
                        className={
                            'text-base sm:text-lg font-medium text-gray-800 dark:text-gray-200 truncate'
                        }
                    >
                        {label}
                    </h3>
                </div>
                <div className={'flex items-center'}>
                    <ConnectedStatus
                        isValid={isValid}
                        statuses={statuses}
                        marketId={marketId}
                    />
                    <div className='flex items-center gap-4 ml-4 sm:ml-8'>
                        <PencilAltIcon
                            // disabled={
                            //     marketName !== marketplaceName.Wildberries
                            // }
                            className={classNames(
                                'h-5 w-5 sm:h-6 sm:w-6 text-gray-400 cursor-pointer',
                                canManage ? 'visible' : 'invisible',
                                {
                                    // 'opacity-25':
                                    //     marketName !==
                                    //     marketplaceName.Wildberries,
                                    // 'opacity-100':
                                    //     marketName ===
                                    //     marketplaceName.Wildberries,
                                }
                            )}
                            onClick={() => setConnectorOpen(true)}
                        />

                        {canUnsubscribe ? (
                            <XCircleIcon
                                className={
                                    'h-5 w-5 sm:h-6 sm:w-6 text-gray-400 cursor-pointer'
                                }
                                onClick={handleUnsubscribe}
                            />
                        ) : (
                            <LogoutIcon
                                className={
                                    'h-5 w-5 sm:h-6 sm:w-6 text-gray-400 cursor-pointer'
                                }
                                onClick={onDeleteCredential}
                            />
                        )}
                    </div>
                </div>

                {canManage ? (
                    <Modal open={connectorOpen} onClose={onCancelConnector}>
                        {!successConnector ? (
                            <div className='flex flex-col items-center'>
                                <div className='w-full flex items-center justify-between border-b border-gray-200 dark:border-gray-600 mb-6 pb-4'>
                                    <h3 className='text-xl font-medium text-gray-800 dark:text-gray-100'>
                                        Редактирование {label}
                                    </h3>
                                    <XIcon
                                        className='w-6 h-6 text-gray-600 dark:text-gray-400 cursor-pointer'
                                        onClick={onCancelConnector}
                                    />
                                </div>
                                <MarketIcon
                                    name={marketName}
                                    size={100}
                                    className={'mb-6'}
                                />
                                {connector(
                                    onCancelConnector,
                                    onSuccessConnector
                                )}
                            </div>
                        ) : (
                            <div className='flex flex-col items-center'>
                                <div>
                                    <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100'>
                                        <CheckIcon
                                            className='h-6 w-6 text-green-600'
                                            aria-hidden='true'
                                        />
                                    </div>
                                    <div className='mt-3 text-center sm:mt-5'>
                                        <h3 className='text-lg leading-6 font-medium text-gray-900 dark:text-gray-100'>
                                            Маркетплейс успешно изменён!
                                        </h3>
                                    </div>
                                </div>
                                <div className='mt-5 sm:mt-6'>
                                    <Button onClick={onCancelConnector}>
                                        Закрыть
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Modal>
                ) : null}
                <ModalConfirm
                    open={deletionDialog}
                    title={`Кабинет ${label} будет удалён`}
                    description={
                        <div className={'flex flex-col space-y-2'}>
                            <p>
                                Внимание! При удалении кабинета вся история
                                внесения себестоимости товаров, ставки налога, а
                                также другие данные будут удалены из платформы.
                            </p>
                            <p>
                                Если вам необходимо заменить API-ключ в рамках
                                того же кабинета, воспользуйтесь
                                <span
                                    onClick={handleClickEditFromDelete}
                                    className={
                                        'ml-2 cursor-pointer whitespace-normal truncate text-blue-500 dark:text-blue-400 hover:opacity-80'
                                    }
                                >
                                    Редактированием
                                </span>
                                .
                            </p>
                            <p>Вы действительно хотите удалить кабинет?</p>
                        </div>
                    }
                    accept={'Удалить'}
                    onClose={() => setDeletionDialog(false)}
                    onAccept={onConfirmDelete}
                />
                <ModalSuccess
                    open={deletedModal}
                    onClose={() => setDeletedModal(false)}
                    title={`Кабинет ${label} успешно удалён`}
                />
                <ModalConfirm
                    open={unsubscribeModal}
                    title={`Отписка от кабинета ${label}`}
                    description={
                        'Вы действительно хотите отключить демонстрационный кабинет? \nДля повторного подключения обратитесь в поддержку'
                    }
                    accept={'Отключить'}
                    onClose={() => setUnsubscribeModal(false)}
                    onAccept={onConfirmUnsubscribe}
                />
                <ModalSuccess
                    open={successUnsubscribeModal}
                    onClose={() => setSuccessUnsubscribeModal(false)}
                    title={`Кабинет ${label} успешно удалён`}
                />
            </Card>
        )
    }
)

ConnectedCard.displayName = 'ConnectedCard'

export default ConnectedCard
