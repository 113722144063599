import React from 'react'
import {observer} from 'mobx-react'
import {useSelector} from '@hooks'
import {Header} from '@components/navigation'
import {Blocked} from '@components/applicationUi'

const BlockedAccessContent = observer(({setSidebarOpen}) => {
    const {myProfileOptions} = useSelector((store) => store.layoutSidebarStore)

    return (
        <>
            <Header
                setSidebarOpen={setSidebarOpen}
                menuOptions={myProfileOptions}
                title={'Раздел заблокирован'}
            />
            <Blocked />
        </>
    )
})

export default BlockedAccessContent
