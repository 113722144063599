import React from 'react'

export const DoublePane = ({children}) => {
    return (
        <div
            className={
                'grid grid-cols-1 divide-y xl:grid-cols-2 xl:divide-x xl:divide-y-0 divide-gray-500 '
            }
        >
            {children}
        </div>
    )
}
