import {makeObservable, observable, action} from 'mobx'
import AdminServices from '@services/AdminServices/AdminServices'
import {toast} from 'react-toastify'
import {adminFilterStatus} from '@utils/constants'
import {actionUser} from '@views/adminUsers/constants'
import {PaginationStore} from '@store/PaginationStore'
import {LoaderStore} from '@store/LoaderStore'

class AdminUsersStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            users: observable,
            filterOpen: observable,
            filterLoader: observable,
            isTested: observable,
            testFilterResults: observable,
            searchEmail: observable,
            searchManager: observable,
            searchPhoneNumber: observable,
            filterOptions: observable,
            searchProvidersKey: observable,
            searchMarketPlacesKey: observable,
            searchIsSelfRegistration: observable,
            searchIsTestUser: observable,
            filterStatus: observable,
            searchTariffsKey: observable,
            searchInn: observable,
            searchFull: observable,
            searchIsCompany: observable,
            searchIsTinkoff: observable,
            searchIsOzonTechpartner: observable,

            setUsers: action,
            setFilterOpen: action,
            setFilterLoading: action,
            setIsTested: action,
            setTestFilterResults: action,
            setSearchEmail: action,
            setSearchManager: action,
            setSearchPhoneNumber: action,
            setSearchIsSelfRegistration: action,
            setSearchIsTestUser: action,
            setFilterOption: action,
            setSearchProvidersKey: action,
            setSearchMarketPlacesKey: action,
            setSearchTariffsKey: action,
            setFilterStatus: action,
            setSearchInn: action,
            setSearchFull: action,
            setSearchIsCompany: action,
            setSearchIsTinkoff: action,
            setSearchIsAlpha: action,
            setSearchIsOzonTechpartner: action,
        })

        this.paginationStore = new PaginationStore()
    }

    users = []

    filterStatus = adminFilterStatus.main

    actions = [
        {
            id: actionUser.login,
            name: 'Войти под пользователем',
            onClick: (row) => {
                this.getUsersLogin(row.original.userUuid)
            },
        },
        // {
        //     id: 'send-letters',
        //     name: 'Отправить приветственные письма',
        //     onClick: () =>
        //         console.log('action - Отправить приветственные письма'),
        // },
        // {
        //     id: actionUser.createSubscription,
        //     name: 'Добавить подписку',
        //     // onClick: (payload) => {
        //     //     this.postUsersSetTariff(payload)
        //     // },
        // },
        {
            id: actionUser.sendNotifications,
            name: 'Отправить нотификацию',
        },
        // {
        //     id: 'block-user',
        //     name: 'Заблокировать пользователя',
        //     onClick: () => console.log('action - Заблокировать пользователя'),
        // },
        // {
        //     id: 'make-test',
        //     name: 'Сделать тестовым',
        //     onClick: () => console.log('action - Сделать тестовым'),
        // },
        {
            id: actionUser.addDemoAccount,
            name: 'Добавить демо-кабинет',
            onClick: (row) => {
                this.postSubscribeToDemo(row.original.userUuid)
            },
        },
        {
            id: actionUser.confirmEmail,
            name: 'Подтвердить почту',
        },
        {
            id: actionUser.confirmPhone,
            name: 'Подтвердить номер',
        },
        {
            id: actionUser.dataImport,
            name: 'Импорт данных',
        },
        {
            id: actionUser.fileSharingAdmin,
            name: 'Файлы',
        },
        {
            id: actionUser.deleteCosts,
            name: 'Удалить всю себестоимость',
        },
        {
            id: actionUser.edit,
            name: 'Редактировать',
        },
    ]

    loaderStore = new LoaderStore()
    filterOpen = false
    filterLoader = false
    isTested = false
    testFilterResults = 0

    searchEmail = undefined
    searchManager = undefined
    searchPhoneNumber = undefined
    searchIsSelfRegistration = undefined
    searchIsTestUser = undefined
    searchInn = undefined
    searchIsCompany = undefined
    searchIsTinkoff = undefined
    searchIsAlpha = undefined
    searchFull = undefined
    searchIsOzonTechpartner = undefined
    filterOptions = {
        options: [
            {
                name: 'Без фильтра',
                key: 'no_filter',
            },
            {
                name: 'Нет подключенных МП',
                key: 'no_mp_connected',
            },
            {
                name: 'Проблемы синхронизации',
                key: 'synchronization_problems',
            },
            {
                name: 'Заблокированы',
                key: 'blocked',
            },
            {
                name: 'Триальные',
                key: 'trial',
            },
            {
                name: 'Про',
                key: 'pro',
            },
            {
                name: 'Боевые',
                key: 'production',
            },
            {
                name: 'Тестовые',
                key: 'test',
            },
        ],
        selected: {
            name: 'Без фильтра',
            key: 'no_filter',
        },
    }

    searchProvidersKey = []
    searchMarketPlacesKey = []
    searchTariffsKey = []

    managersOptions = []

    setSearchProvidersKey = (array = []) => {
        this.searchProvidersKey = array
    }
    setSearchMarketPlacesKey = (array = []) => {
        this.searchMarketPlacesKey = array
    }
    setSearchTariffsKey = (array = []) => {
        this.searchTariffsKey = array
    }

    setFilterOpen = (value = false) => {
        this.filterOpen = value
    }

    setFilterLoading = (value = false) => {
        this.filterLoader = value
    }

    setIsTested = (value = false) => {
        this.isTested = value
    }

    setTestFilterResults = (value = 0) => {
        this.testFilterResults = value
    }

    setSearchEmail = (value = undefined) => {
        this.searchEmail = value
    }

    setSearchManager = (value = undefined) => {
        this.searchManager = value
    }

    setSearchInn = (value = undefined) => {
        this.searchInn = value
    }

    setSearchIsCompany = (value = undefined) => {
        this.searchIsCompany = value
    }

    setSearchIsTinkoff = (value = undefined) => {
        this.searchIsTinkoff = value
    }

    setSearchIsAlpha = (value = undefined) => {
        this.searchIsAlpha = value
    }

    setSearchFull = (value = undefined) => {
        this.searchFull = value
    }

    setSearchIsOzonTechpartner = (value = undefined) => {
        this.searchIsOzonTechpartner = value
    }

    setSearchPhoneNumber = (value = undefined) => {
        this.searchPhoneNumber = value
    }
    setSearchIsSelfRegistration = (value = undefined) => {
        this.searchIsSelfRegistration = value
    }

    setSearchIsTestUser = (value = undefined) => {
        this.searchIsTestUser = value
    }

    setFilterOption = (value) => {
        this.filterOptions.selected = value
    }

    setUsers = (value = []) => {
        this.users = value
    }

    setFilterStatus = (value = adminFilterStatus.main) => {
        this.filterStatus = value
    }

    setManagersOptions = (value = []) => {
        this.managersOptions = value
    }

    resetFilter = () => {
        this.paginationStore.resetPagination()
        this.setSearchEmail()
        this.setSearchManager()
        this.setSearchPhoneNumber()
        this.setSearchProvidersKey()
        this.setSearchTariffsKey()
        this.setSearchMarketPlacesKey()
        this.setSearchIsSelfRegistration()
        this.setSearchIsTestUser()
        this.setSearchInn()
        this.setSearchIsCompany()
        this.setSearchIsTinkoff()
        this.setSearchIsAlpha()
        this.setSearchFull()
        this.setSearchIsOzonTechpartner()
        this.setFilterOption({
            name: 'Без фильтра',
            key: 'no_filter',
        })
        this.setIsTested(false)
    }

    mergeUsers = ({pagination, users}) => {
        this.paginationStore.setPagination({
            page: pagination.page,
            total: pagination.totalItems,
            limit: pagination.itemsPerPage,
            totalPages: Math.ceil(
                pagination.totalItems / pagination.itemsPerPage
            ),
        })
        this.setUsers(users)
    }

    getUsers = async ({page = 1, limit} = {}) => {
        const payload = {
            sort_order: 'desc',
            items_per_page: limit
                ? limit
                : this.paginationStore.pagination.limit,
            sort_by: 'created_at',
            email: this.searchEmail,
            manager: this.searchManager,
            inn: this.searchInn,
            is_company: this.searchIsCompany,
            is_tinkoff: this.searchIsTinkoff,
            is_alpha: this.searchIsAlpha,
            full: this.searchFull,
            is_ozon_techpartner: this.searchIsOzonTechpartner,
            phone_number: this.searchPhoneNumber,
            is_test_user: this.searchIsTestUser,
            is_self_registration: this.searchIsSelfRegistration,
            provider_auth: this.searchProvidersKey.join(','),
        }
        this.loaderStore.start()
        try {
            const {data} = await AdminServices.getUsers(page, payload)
            this.mergeUsers(data)
        } catch (e) {
            if (e?.response?.data?.result === false) {
                toast(e.response.data.message, {type: 'error'})
            }
        } finally {
            this.loaderStore.stop()
        }
    }

    getMpAccounts = async ({page = 1, limit} = {}) => {
        this.setIsTested(true)
        this.setFilterLoading(true)
        const payload = {
            marketplace_id: this.searchMarketPlacesKey,
            items_per_page: limit
                ? limit
                : this.paginationStore.pagination.limit,
            sort_order: 'desc',
            sort_by: 'created_at',
            page: page ? page : 1,
        }
        this.loaderStore.start()
        try {
            const {data} = await AdminServices.getMpAccount(payload)
            this.mergeUsers(data)
        } catch (e) {
            if (e?.response?.data?.result === false) {
                toast(e.response.data.message, {type: 'error'})
            }
        } finally {
            this.loaderStore.start()
            this.setFilterLoading(false)
        }
    }

    getTariffs = async ({page = 1, limit} = {}) => {
        this.setIsTested(true)
        this.setFilterLoading(true)
        const payload = {
            slugs: this.searchTariffsKey,
            items_per_page: limit
                ? limit
                : this.paginationStore.pagination.limit,
            exclude: false,
            sort_order: 'desc',
            sort_by: 'created_at',
            page: page ? page : 1,
        }
        this.loaderStore.start()
        try {
            const {data} = await AdminServices.getTariffs(payload)
            this.mergeUsers(data)
        } catch (e) {
            if (e?.response?.data?.result === false) {
                toast(e.response.data.message, {type: 'error'})
            }
        } finally {
            this.loaderStore.stop()
            this.setFilterLoading(false)
        }
    }

    testFilter = async ({
        inn,
        isCompany,
        isTinkoff,
        isAlpha,
        isOzonTechpartner,
        email,
        full,
        manager,
        phoneNumber,
        providerAuth,
        isTestUser,
        isSelfRegistration,
    }) => {
        this.setIsTested(true)
        this.setFilterLoading(true)
        const payload = {
            sort_order: 'desc',
            items_per_page: 1,
            sort_by: 'created_at',
            phone_number: phoneNumber,
            provider_auth: providerAuth.join(','),
            is_test_user: isTestUser,
            is_self_registration: isSelfRegistration,
            is_company: isCompany,
            is_tinkoff: isTinkoff,
            full: full,
            is_alpha: isAlpha,
            is_ozon_techpartner: isOzonTechpartner,
            inn,
            email,
            manager,
        }
        try {
            const {data} = await AdminServices.getUsers(1, payload)
            this.setTestFilterResults(data.pagination.totalItems)
        } catch (e) {
            if (e?.response?.data?.result === false) {
                toast(e.response.data.message, {type: 'error'})
            }
        } finally {
            this.setFilterLoading(false)
        }
    }

    testGetMpAccounts = async ({marketplaceId}) => {
        this.setIsTested(true)
        this.setFilterLoading(true)
        const payload = {
            marketplace_id: marketplaceId,
            items_per_page: 1,
            sort_order: 'desc',
            sort_by: 'created_at',
            page: 1,
        }
        try {
            const {data} = await AdminServices.getMpAccount(payload)
            this.setTestFilterResults(data.pagination.totalItems)
        } catch (e) {
            if (e?.response?.data?.result === false) {
                toast(e.response.data.message, {type: 'error'})
            }
        } finally {
            this.setFilterLoading(false)
        }
    }

    testGetTariffs = async ({slugs}) => {
        this.setIsTested(true)
        this.setFilterLoading(true)
        const payload = {
            slugs,
            page: 1,
            items_per_page: 1,
        }
        try {
            const {data} = await AdminServices.getTariffs(payload)
            this.setTestFilterResults(data.pagination.totalItems)
        } catch (e) {
            if (e?.response?.data?.result === false) {
                toast(e.response.data.message, {type: 'error'})
            }
        } finally {
            this.setFilterLoading(false)
        }
    }

    getUsersLogin = async (userUuid) => {
        try {
            const {data} = await AdminServices.getUsersLogin(userUuid)
            if (data.result) {
                toast(data.message, {type: 'success'})
                await this.rootStore.authStore.appendExternalUserToken(data)
            } else {
                toast(data.message, {type: 'error'})
            }
        } catch (e) {
            console.log(e)
            if (e?.response?.data?.result === false) {
                toast(e.response.data.message, {type: 'error'})
            }
        } finally {
        }
    }

    getUsersLogout = async () => {
        try {
            const {data} = await AdminServices.getUsersLogout()
            if (data.result) {
                toast(data.message, {type: 'success'})
                return Promise.resolve({data})
            } else {
                toast(data.message, {type: 'error'})
            }
        } catch (e) {
            console.log(e)
            if (e?.response?.data?.result === false) {
                toast(e.response.data.message, {type: 'error'})
            }
        } finally {
        }
    }

    postSubscribeToDemo = async (userUuid) => {
        const payload = {
            client_uuid: userUuid,
        }
        try {
            const {data} = await AdminServices.postSubscribeToDemo(payload)
            if (data.result) {
                toast(data.message, {type: 'success'})
            } else {
                toast(data.message, {type: 'error'})
            }
        } catch (e) {
            console.log(e)
            if (e?.response?.data?.result === false) {
                toast(e.response.data.message, {type: 'error'})
            }
        }
    }

    getManagers = async () => {
        const payload = {
            page: 1,
            items_per_page: 100,
        }

        try {
            const {data} = await AdminServices.getManagers(payload)
            this.setManagersOptions(
                data.managers?.map((item) => ({
                    key: item.id,
                    name: item.name,
                }))
            )
        } catch (e) {
            console.log(e)
        }
    }
}

export default AdminUsersStore
