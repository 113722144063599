import React from 'react'
import Screen1 from './images/screen-adv-1.jpg'
import Screen2 from './images/screen-adv-2.jpg'
import Screen3 from './images/screen-adv-3.png'
import Screen4 from './images/screen-adv-4.png'
import Screen5 from './images/screen-adv-5.png'
import {ImagesPreview} from '@components/elements'

const OzonAdvHelp = () => {
    return (
        <ImagesPreview>
            <div className={'w-full'}>
                <p className={'mb-4 dark:text-gray-200'}>
                    Чтобы найти рекламный Client ID и Client Secret, перейдите
                    по этой ссылке:
                    <br />
                    <a
                        className={'text-blue-500 hover:text-blue-400'}
                        href={
                            'https://seller.ozon.ru/app/settings/api-keys?currentTab=performanceApi'
                        }
                        target={'_blank'}
                    >
                        https://seller.ozon.ru/app/settings/api-keys?currentTab=performanceApi
                    </a>
                    .<br />В открывшемся окне нажмите на кнопку «Создать
                    аккаунт»
                </p>
                <img src={Screen1} className={'mb-6'} />
                <p className={'mb-4 dark:text-gray-200'}>
                    или кликните на набор цифр в графе «Сервисный аккаунт», если
                    он уже создан.
                </p>
                <img src={Screen2} className={'mb-6'} />
                <p className={'mb-4 dark:text-gray-200'}>
                    Нажмите на кнопку «Добавить ключ».
                </p>
                <img src={Screen3} className={'mb-6'} />
                <p className={'mb-4 dark:text-gray-200'}>
                    Введите название ключа. Например, «Новый ключ». Нажмите на
                    кнопку «Добавить».
                </p>
                <img src={Screen4} className={'mb-6'} />
                <p className={'mb-4 dark:text-gray-200'}>
                    После этого автоматически откроется раздел «Сервисный
                    аккаунт». Выберите созданный ключ и поочередно скопируйте
                    значения колонок Client ID и Client Secret и вставьте их в
                    соответствующие поля на платформе.
                </p>
                <img src={Screen5} className={'mb-6'} />
            </div>
        </ImagesPreview>
    )
}

export default OzonAdvHelp
