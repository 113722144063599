import React from 'react'
import {ArrowLeftIcon} from '@heroicons/react/outline'
import {useHistory} from 'react-router-dom'

const GoBack = ({label = 'Назад'}) => {
    const history = useHistory()
    return (
        <div
            className={'flex items-center space-x-2 px-4 cursor-pointer'}
            onClick={history.goBack}
        >
            <ArrowLeftIcon
                className={'h-6 w-6 text-blue-500 dark:text-blue-400'}
            />
            <span
                className={
                    'flex items-center text-lg font-medium text-gray-800 dark:text-gray-200 ml-4 hover:text-blue-500 dark:hover:text-blue-400 transition-all'
                }
            >
                {label}
            </span>
        </div>
    )
}

export {GoBack}
