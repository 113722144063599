import React from 'react'
import cn from 'classnames'
import {ModalOverlay} from '@components/overlays/modals/Modal/ModalOverlay'

const ModalBody = ({open, onClose, children, className}) => {
    return (
        <ModalOverlay open={open} onClose={onClose}>
            <div
                className={cn(
                    className ? className : 'sm:max-w-sm',
                    'inline-block align-bottom bg-white w-full dark:bg-gray-700 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6'
                )}
            >
                {children}
            </div>
        </ModalOverlay>
    )
}

export {ModalBody}
