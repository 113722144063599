import {ApiFactory} from '@services/ApiFactory'
import {sellerConfig} from '@services/apiConfigs'
import {InformerUrl} from '@routesEnums'
import {getRandomNumber} from '@utils/helpers'

const informerInstance = ApiFactory.createApi(sellerConfig, 'informer')

class InformerService {
    static getInformerData = async () => {
        return await informerInstance.get(
            InformerUrl.informerData(getRandomNumber(10, 9999))
        )
    }
}

export default InformerService
