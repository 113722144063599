import {Modal, ModalHeader} from '@components/overlays'
import React from 'react'
import {SuccessLegalDialog} from './components/SuccessLegalDialog'

export const SuccessLegalModal = ({onClose, open, ...props}) => {
    return (
        <Modal className={'sm:max-w-lg'} open={open} onClose={onClose}>
            <SuccessLegalDialog {...props} />
        </Modal>
    )
}
