import {makeAutoObservable} from 'mobx'

export class ModalControl {
    constructor(isOpen: boolean = false) {
        this.isOpen = isOpen
        makeAutoObservable(this)
    }

    props = {}

    open = () => {
        this.isOpen = true
    }

    close = () => {
        this.isOpen = false
    }

    toggle = () => {
        this.isOpen = !this.isOpen
    }

    setIsOpen = (value) => {
        this.isOpen = value
    }

    setProps = (props) => {
        this.props = props
    }
}
