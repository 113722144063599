import {observer} from 'mobx-react'
import {Button} from '@components/elements'
import React, {useState} from 'react'
import {TextField} from '@components/forms/TextFields'
import FooterButtons from '../../../../components/FooterButtons'
import * as yup from 'yup'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import {useAnalyticEventsHook, useDebouncedFunction} from '@hooks'
import {Alert} from '@components/feedback'
import {CONNECTED_MP_ACTIONS} from '@utils/constants'
import {useStartField} from '@hooks/customHooks/useStartField'
import {LoaderStore} from '@store/LoaderStore'
import {Loader} from '@components/elements/Loader'
import {useOnboardingContext} from '@components/navigation/header/Onboarding/context/OnboardingContext'

const wbOAuthSchema = yup.object().shape({
    name: yup
        .string()
        .max(50, 'Имя личного кабинета не должно превышать 50 символов'),
})

export const WildberriesOAuthForm = observer(
    ({wbAuthStore, onCancel, onSuccess, onError, connectorData}) => {
        const [loaderStore] = useState(() => new LoaderStore())
        const {connectedMpEvent} = useAnalyticEventsHook()
        const {onboardingStepStore} = useOnboardingContext()

        const {
            register,
            clearErrors,
            handleSubmit,
            formState: {errors},
        } = useForm({
            defaultValues: {name: connectorData ? connectorData.name : ''},
            mode: 'all',
            resolver: yupResolver(wbOAuthSchema),
        })

        const onSubmit = async ({name}) => {
            loaderStore.start()
            clearErrors()
            wbAuthStore.setResponseError()
            try {
                const data = await wbAuthStore.getCheckSsoWb(name)
                if (data.result) {
                    if (onboardingStepStore.isOnboarding) {
                        onboardingStepStore.setIsConnectedCabinet(true)
                    }
                    if (onSuccess) onSuccess(connectorData?.id)
                }
            } catch (e) {
                console.log(e)
                if (onError) onError()
            } finally {
                loaderStore.stop()
            }
        }

        const handleClickField = () => {
            connectedMpEvent(CONNECTED_MP_ACTIONS.fill, 'MP_formWildberries')
        }

        const onClickField = useStartField(handleClickField)

        const onSubmitDebounced = useDebouncedFunction(onSubmit, 200)

        return (
            <form className={'w-full'}>
                {wbAuthStore.responseError && (
                    <Alert
                        error
                        title={wbAuthStore.responseError}
                        className={'mb-6'}
                    />
                )}
                <TextField
                    {...register('name')}
                    className={'mb-4'}
                    label={'Имя личного кабинета'}
                    error={!!errors.name}
                    onClick={onClickField}
                    helperText={errors?.name?.message}
                />
                <FooterButtons
                    connectedButton={(style) => (
                        <Button
                            className={style}
                            disabled={loaderStore.loading}
                            onClick={handleSubmit(onSubmitDebounced)}
                        >
                            {connectorData ? 'Сохранить' : 'Подключить'}
                            {!!loaderStore.loading && <Loader sm />}
                        </Button>
                    )}
                    onCancel={onCancel}
                />
            </form>
        )
    }
)

WildberriesOAuthForm.displayName = 'WildberriesOAuthForm'
