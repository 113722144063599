import React from 'react'

export const LimitedInfo = () => {
    return (
        <p className='text-sm leading-6 text-gray-900'>
            Пользователь не может полноценно работать с отчетом, т.к. превышены
            лимиты тарифа
        </p>
    )
}
