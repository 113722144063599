import {ExternalLinkIcon} from '@heroicons/react/outline'
import React from 'react'

export const ImageLink = ({url, title = 'Товар на витрине'}) => {
    return (
        <a
            className={
                'flex space-x-2 cursor-pointer text-blue-500 dark:text-blue-400 hover:opacity-80 items-center'
            }
            target={'_blank'}
            href={url}
        >
            <span>{title}</span>
            <ExternalLinkIcon className={'h-5 w-5'} />
        </a>
    )
}
