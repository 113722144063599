import React from 'react'
import classNames from 'classnames'

export const ModalFooter = ({children, className}) => {
    return (
        <div
            className={classNames(
                className,
                'w-full flex items-center justify-between border-t border-gray-200 dark:border-gray-600 mt-6 pt-4'
            )}
        >
            {children}
        </div>
    )
}
