import React from 'react'
import {Loader} from '@components/elements/Loader'
import {observer} from 'mobx-react'

const DATA_SOURCE = {
    xls: 'Фин. отчеты Excel',
    api: 'API Wildberries',
}

export const WbXlsImports = observer(({wbImports, getCredentialByID}) => {
    return (
        <div className={'flex flex-col space-y-4'}>
            {wbImports.map(({value}) => {
                const dataSource = getCredentialByID(value.cabinetId).dataSource
                return (
                    <span className={'flex items-center space-x-4 '}>
                        <Loader sm overlayed={false} />
                        <span key={value.cabinetId} className={'space-x-4'}>
                            <span className={'space-x-4'}>
                                <span className={'truncate'}>
                                    {value.cabinetName} :
                                </span>
                            </span>
                            <span>
                                {`пересчет данных по источнику ${DATA_SOURCE[dataSource]} в процессе. Дождитесь окончания процесса для работы с отчетами.`}
                            </span>
                        </span>
                    </span>
                )
            })}
        </div>
    )
})
