import {action, makeObservable, observable} from 'mobx'
import qs from 'qs'
import MonitoringService from '@services/MonitoringService/MonitoringService'
import {formatISO, startOfYesterday, isValid} from 'date-fns'
import {PaginationStore} from '../../../../../../store/PaginationStore'

class ProductsPositionStore {
    constructor(rootStore) {
        this.rootStore = rootStore

        makeObservable(this, {
            url: observable,
            isOpenProductsOverlay: observable,
            firstRequest: observable,
            positionLoading: observable,
            params: observable,
            maxCount: observable,
            minCount: observable,
            period: observable,
            positionByUrlLoading: observable,
            list: observable,
            setMaxCount: action,
            setMinCount: action,
            setUrl: action,
            setIsOpenProductsOverlay: action,
            setPositionLoading: action,
            setFirstRequest: action,
            parseInitialParams: action,
            setPositionList: action,
            setProductToPositionList: action,
            updateParams: action,
            setPeriod: action,
            setPositionByUrlLoading: action,
        })
        this.updateParams(this.parseInitialParams())
        this.initPeriod()
        this.paginationStore = new PaginationStore()
    }

    url = ''
    isOpenProductsOverlay = false
    firstRequest = false
    positionLoading = false
    positionByUrlLoading = false
    params = {}
    list = []
    maxCount = 100
    minCount = 0

    period = {
        startDate: startOfYesterday(),
        endDate: new Date(),
    }

    setPeriod = (value = {startDate: '', endDate: ''}) => {
        this.period = value
    }

    setMaxCount = (value = 0) => {
        this.maxCount = value
    }

    setMinCount = (value = 0) => {
        this.minCount = value
    }

    setUrl = (url = '') => {
        this.url = url
    }

    setIsOpenProductsOverlay = (value = false) => {
        this.isOpenProductsOverlay = value
    }

    setPositionLoading = (value = false) => {
        this.positionLoading = value
    }

    setPositionByUrlLoading = (value = false) => {
        this.positionByUrlLoading = value
    }

    setFirstRequest = (value = false) => {
        this.firstRequest = value
    }

    parseInitialParams = () => {
        const search = window.location.search
        const parsed = qs.parse(search, {ignoreQueryPrefix: true})

        return {
            page: parsed.page ? Number(parsed.page) : 1,
            limit: parsed.limit ? Number(parsed.limit) : 10,
            marketplace_credential_ids:
                parsed.marketplace_credential_ids || null,
        }
    }

    setPositionList = (data = []) => {
        this.list = data
    }

    setProductToPositionList = (product) => {
        this.list.push(product)
    }

    initPeriod = () => {
        const lsDate = localStorage.getItem('m_date')
        const startDate = lsDate ? new Date(lsDate) : null
        if (isValid(startDate)) {
            this.setPeriod({startDate, endDate: new Date()})
        }
    }

    updateParams = (data = {}) => {
        this.params = this.getNewParams(data, this.params)

        //this.updateSortList(data.sort_by)
    }

    getNewParams = (
        {
            page,
            limit,
            sort_by,
            price_less_than,
            price_greater_than,
            marketplace_credential_ids,
        } = {},
        params
    ) => {
        return {
            page: page || 1,
            limit: limit || params.limit,
            marketplace_credential_ids:
                marketplace_credential_ids !== undefined
                    ? marketplace_credential_ids
                    : params.marketplace_credential_ids,
        }
    }
    mergeMonitoringList = (source) => {
        const paginationData = {
            page: source?.page,
            limit: source?.limit,
            total: source?.total,
            totalPages: source?.totalPages,
        }

        this.setPositionList(source?.products)
        this.setMinCount(paginationData.total)
        this.updateParams({
            page: paginationData.page,
        })
        this.paginationStore.setPagination(paginationData)
    }

    collectPositionPayload = () => {
        const payload = {
            ...this.params,
        }

        if (this.period.startDate)
            payload['position_created_at_after'] = formatISO(
                this.period.startDate
            )
        if (this.period.endDate)
            payload['position_created_at_before'] = formatISO(
                this.period.endDate
            )

        return payload
    }

    sendUrlPosition = async () => {
        const payload = {
            url: this.url,
        }

        if (this.minCount >= this.maxCount) return console.log('Превышен лимит')
        this.setPositionByUrlLoading(true)
        try {
            const {data, headers} = await MonitoringService.postProductByUrl(
                payload
            )
            this.mergeMonitoringList(data, headers)
            this.setUrl()
            return Promise.resolve()
        } catch (e) {
            console.log(e)
            return Promise.reject(e)
        } finally {
            this.setPositionByUrlLoading(false)
        }
    }

    getPositionList = async (payload) => {
        this.updateParams(payload)
        const body = this.collectPositionPayload()
        this.setPositionLoading(true)

        try {
            const {data, headers} = await MonitoringService.getProductPositions(
                body
            )
            this.mergeMonitoringList(data, headers)
        } catch (e) {
            console.log(e)
        } finally {
            this.setPositionLoading(false)
            if (!this.firstRequest) this.setFirstRequest(true)
        }
    }

    deletePositions = async (payload) => {
        this.setPositionLoading(true)

        try {
            const {data, headers} = await MonitoringService.deleteProducts(
                payload
            )
            this.getPositionList()
        } catch (e) {
            console.log(e)
        } finally {
            this.setPositionLoading(false)
        }
    }
}

export default ProductsPositionStore
