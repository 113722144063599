import React from 'react'
import {observer} from 'mobx-react'
import {OnboardingWrapper} from '@components/navigation/header/Onboarding/components/OnboardingWrapper'
import {Link} from 'react-router-dom'
import {AppPath} from '../../../../../../routes-enums'
import {ONBOARD_STEP} from '@utils/constants'

export const SalesRegionOnboard = observer(({onboardingStepStore}) => {
    const handleClickNextStep = async () => {
        try {
            await onboardingStepStore.putOnboardSetStep(
                ONBOARD_STEP.regionalChecked,
                true
            )
            window.carrotquest?.track('onboarding_regional_checked')
        } catch (e) {
            console.log(e)
        }
    }
    return (
        <OnboardingWrapper.BarContainer className={'justify-between space-x-4'}>
            <OnboardingWrapper.BarContainer
                className={'flex-col space-y-2 w-full'}
            >
                <OnboardingWrapper.Label className={'text-blue-500 text-sm'}>
                    <Link
                        to={AppPath.deliveriesRegionsSalesRegionByRegions}
                        className={
                            'cursor-pointer whitespace-normal truncate underline text-blue-500 dark:text-blue-400 hover:opacity-80'
                        }
                    >
                        Оценить свои продажи в разрезе регионов
                    </Link>
                </OnboardingWrapper.Label>
                <OnboardingWrapper.Progress value={87} />
            </OnboardingWrapper.BarContainer>
            <OnboardingWrapper.ButtonAction onClick={handleClickNextStep}>
                Продолжить
            </OnboardingWrapper.ButtonAction>
        </OnboardingWrapper.BarContainer>
    )
})

SalesRegionOnboard.displayName = 'SalesRegionOnboard'
