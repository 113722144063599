import {ApiFactory} from '@services/ApiFactory'
import {baseConfig} from '@services/apiConfigs'
import {FeedbackUrl} from '../../routes-enums'

const feedbackInstance = ApiFactory.createApi(baseConfig)

class FeedbackService {
    static postMessage = async (payload) => {
        return await feedbackInstance.post(FeedbackUrl.message, payload)
    }
}

export default FeedbackService
