import {useMemo, useState} from 'react'
import {observer} from 'mobx-react'

import {WildberriesAuthStore} from './store/WildberriesAuthStore'
import {wbAuthVariant} from './constants'
import {WildberriesApiKey} from './components/WildberriesApiKey'
import {WildberriesOAuth} from './components/WildberriesOAuth'

const WB_MODAL_VARIANT = {
    [wbAuthVariant.apiKey]: WildberriesApiKey,
    [wbAuthVariant.oAuth]: WildberriesOAuth,
}

export const WildberriesConnector = observer(
    ({onCancel, onSuccess, onError, connectorData}) => {
        const [wbAuthStore] = useState(
            () =>
                new WildberriesAuthStore(
                    !!connectorData,
                    connectorData?.credentials?.wbBackUrl
                )
        )
        const WbVariantModal = useMemo(
            () => WB_MODAL_VARIANT[wbAuthStore.authVariant],
            [wbAuthStore.authVariant]
        )
        return (
            <WbVariantModal
                wbAuthStore={wbAuthStore}
                onCancel={onCancel}
                onSuccess={onSuccess}
                onError={onError}
                connectorData={connectorData}
            />
        )
    }
)

WildberriesConnector.displayName = 'WildberriesConnector'
