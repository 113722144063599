//libs
import {makeObservable} from 'mobx'

//stores
import CatalogListStore from '../List/store/CatalogListStore'
import CatalogHistoryStore from '@views/catalog/History/store/CatalogHistoryStore'

class CatalogStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        this.reset()
    }

    reset = () => {
        this.stores = {
            catalogListStore: new CatalogListStore(this.rootStore),
            catalogHistoryStore: new CatalogHistoryStore(this.rootStore),
        }
    }

    headerTabs = [
        {
            id: 'list',
            name: 'Список',
            disabled: false,
            visible: true,
        },
        {
            id: 'history',
            name: 'История изменений',
            count: 'скоро',
            disabled: true,
            visible: true,
        },
    ]
}

export default CatalogStore
