import {action, makeObservable, observable} from 'mobx'
import AdminStatistics from '@services/AdminServices/AdminServices'
import {getFirstYearDay} from '@components/forms/DatePicker/DateRangePicker'

export class MRStatisticsStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            dateRange: observable,
            setDateRange: action,
        })
    }

    dateRange = {
        startDate: getFirstYearDay(),
        endDate: new Date(),
    }

    setDateRange = (value) => {
        this.dateRange = value
    }

    collectStatisticsQuery = () => {
        return {
            date_from: this.dateRange.startDate,
            date_to: this.dateRange.endDate,
        }
    }

    fitchMRStatistics = () => {
        this.getMonitoringStatistics()
        this.getReviewsStatistics()
    }

    getMonitoringStatistics = async () => {
        const query = this.collectStatisticsQuery()
        try {
            const {data} = await AdminStatistics.getStatisticsMonitoring(query)
        } catch (e) {
            console.log(e)
        } finally {
        }
    }

    getReviewsStatistics = async () => {
        const query = this.collectStatisticsQuery()
        try {
            const {data} = await AdminStatistics.getStatisticsReviews(query)
        } catch (e) {
            console.log(e)
        } finally {
        }
    }
}
