import url from '@services/url'

export const baseURL = `${url}/gw/api/v2`
const dadataURL = `https://suggestions.dadata.ru/suggestions/api/4_1/rs`
const categoriesURL = `${baseURL}/pim/sa-workspace`
const sellerURL = `https://files.seller24.ru`
const errorUrl = `https://api.telegram.org/${process.env.REACT_APP_ERROR_TOKEN}`

export const baseConfig = {
    baseURL: baseURL,
    headers: {
        'Content-Type': 'application/json',
    },
}

export const categoriesConfig = {
    baseURL: categoriesURL,
    headers: {
        'Content-Type': 'application/json',
    },
}
export const dadataConfig = {
    baseURL: dadataURL,
    headers: {
        'Content-Type': 'application/json',
        Authorization: process.env.REACT_APP_DADATA_TOKEN,
    },
}
export const errorConfig = {
    baseURL: errorUrl,
    isAuth: false,
    headers: {
        'Content-Type': 'application/json',
    },
}

export const sellerConfig = {
    baseURL: sellerURL,
    isAuth: false,
    headers: {
        'Content-Type': 'application/json',
    },
}
