import {action, makeObservable, observable} from 'mobx'

class ReviewsAbstractStore {
    constructor() {
        makeObservable(this, {
            reviews: observable,
            reviewsPending: observable,
            setReviewsPending: action,
        })
    }

    reviews = []

    reviewsPending = false

    setReviewsPending(value: boolean = false) {
        this.reviewsPending = value
    }
}

export default ReviewsAbstractStore
