import {PathBlockedContext} from './PathBlockedContext'
import {PathBlockedStore} from './store/PathBlockedStore'
import {useEffect, useState} from 'react'

export const PathBlockedProvider = ({children, userStore}) => {
    const [pathBlockedStore] = useState(() => new PathBlockedStore())

    useEffect(() => {
        pathBlockedStore.getPathData()
    }, [])
    return (
        <PathBlockedContext.Provider value={{userStore, pathBlockedStore}}>
            {children}
        </PathBlockedContext.Provider>
    )
}
