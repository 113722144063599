//libs
import React from 'react'
import {observer} from 'mobx-react'

//components
import {Button} from '@components/elements'
import {Link} from 'react-router-dom'

//icons
import {CubeTransparentIcon} from '@heroicons/react/outline'

export const NoCredentials = observer(
    ({
        className,
        iconNode,
        title = 'У вас нет подключенных маркетплейсов',
        summary = 'На их основе будут отображаться данные',
        cta = 'Подключить площадки',
        to = '/Marketplaces',
    }) => {
        return (
            <>
                <div
                    className={`relative h-full w-full \
                    flex flex-col items-center text-center \
                    ${className}`}
                >
                    {!iconNode ? (
                        <CubeTransparentIcon
                            className={
                                'w-1/2 h-full max-w-[250px] max-h-[250px] \
                            text-gray-300 dark:text-gray-600 mb-4'
                            }
                        />
                    ) : (
                        iconNode
                    )}
                    {!!title?.length && (
                        <p
                            className={
                                'text-2xl dark:text-gray-100 font-semibold whitespace-pre-line'
                            }
                        >
                            {title}
                        </p>
                    )}
                    {!!summary?.length && (
                        <p
                            className={
                                'text-lg text-gray-600 dark:text-gray-400 whitespace-pre-line mt-4'
                            }
                        >
                            {summary}
                        </p>
                    )}
                    <Link to={to} className={'mt-6'}>
                        <Button>{cta}</Button>
                    </Link>
                </div>
            </>
        )
    }
)

NoCredentials.displayName = 'NoCredentials'
