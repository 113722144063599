import React from 'react'
import SimpleReactLightbox, {SRLWrapper} from 'simple-react-lightbox'

export const ImagesPreview = ({children}) => {
    return (
        <SimpleReactLightbox>
            <SRLWrapper>{children}</SRLWrapper>
        </SimpleReactLightbox>
    )
}
