import React, {forwardRef} from 'react'
import classNames from 'classnames'

export const AvailableTitle = forwardRef(({title, className}, ref) => {
    return (
        <h3
            ref={ref}
            className={classNames(
                className,
                'text-base sm:text-xl text-gray-800 dark:text-gray-200 font-medium sm:mb-4 truncate'
            )}
        >
            {title}
        </h3>
    )
})

AvailableTitle.displayName = 'AvailableTitle'
