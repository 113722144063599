//libs
import React, {useEffect, useState} from 'react'

//components
import {CollapsePanel, ChevTrigger} from '@components/elements'

//modules
import ServiceCard from './ServiceCard'
import cn from 'classnames'

//content
import {services} from '@assets/texts/services/services'

const ServicesSection = ({servicesSection, isRollUp = true}) => {
    const [openState, setOpenState] = useState(servicesSection)

    const renderCollapseHeader = (isActive) => {
        return (
            <ChevTrigger
                className={'flex-start text-gray-800 dark:text-gray-300 mb-4'}
                classNameValue={'text-2xl font-medium w-auto'}
                classNameChev={'w-7 h-7 ml-3'}
                value={'Наши услуги'}
                opened={isActive}
            />
        )
    }

    const handleClick = () => {
        localStorage.setItem('servicesSection', !openState)
        setOpenState(!openState)
    }

    return (
        <>
            <CollapsePanel
                isRollUp={isRollUp}
                renderHeader={renderCollapseHeader}
                isOpen={openState}
                onClick={handleClick}
            >
                <div
                    className={
                        'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4'
                    }
                >
                    {services.map((service, idx) => {
                        return <ServiceCard key={idx} data={service} />
                    })}
                </div>
            </CollapsePanel>
        </>
    )
}

export default ServicesSection
