import {useRef} from 'react'

export const useStartField = (cb) => {
    const isStartRegRef = useRef(false)

    return function onClickField() {
        if (isStartRegRef.current) return
        cb()
        isStartRegRef.current = true
    }
}
