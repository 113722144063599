import {Card} from '@components/layout'
import classNames from 'classnames'
import {observer} from 'mobx-react'

export const StructureCard = observer(
    ({title, variant, sizeSm, children, className}) => {
        return (
            <Card
                className={className}
                innerClassName={'flex flex-col space-y-4'}
            >
                <h4
                    className={classNames(
                        'font-medium text-gray-600 dark:text-gray-400',
                        {
                            'text-base': !sizeSm,
                            'text-sm': sizeSm,
                            'text-red-400 dark:text-red-500':
                                variant === 'negative',
                            'text-green-400 dark:text-green-500':
                                variant === 'positive',
                        }
                    )}
                >
                    {title}
                </h4>
                <div className={'flex flex-col space-y-4'}>{children}</div>
            </Card>
        )
    }
)
