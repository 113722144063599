//libs
import React from 'react'

//hooks
import {useSelector} from '@hooks'

//services
import FeedbackService from '@services/FeedbackService/FeedbackService'

//components
import {Card} from '@components/layout'
import {Button} from '@components/elements'
import {ModalCaller} from '@components/overlays'
import {CheckIcon} from '@heroicons/react/outline'
import {Loader} from '@components/elements/Loader'
import {toast} from 'react-toastify'
import {SUCCESS_SUPPORT_MESSAGE_SEND} from '@utils/constants'

const ServiceCard = ({data}) => {
    const {userData} = useSelector((store) => store.userStore)

    let modal = null
    let status = ''

    const setStatus = (value, cb) => {
        status = value
        if (cb) cb()
    }

    const sendMessage = async () => {
        const payload = {
            to: 'support@seller24.ru',
            request_type: data.requestType,
            title: `Заказ услуги: ${data?.title}`,
            fields: [
                {
                    title: 'name',
                    value: userData.name || '',
                },
                {
                    title: 'phone',
                    value:
                        userData?.phone_number ||
                        userData?.new_phone_number ||
                        '',
                },
                {
                    title: 'email',
                    value: userData?.email || '',
                },
            ],
        }
        setStatus('pending', updateModalContent)

        try {
            await FeedbackService.postMessage(payload)
            toast(SUCCESS_SUPPORT_MESSAGE_SEND, {
                type: 'success',
                autoClose: 10000,
            })
            setStatus('success', updateModalContent)
        } catch (error) {
            setStatus('error', updateModalContent)
        }
    }

    const updateModalContent = () => {
        modal.modalProps.children = modalContent()
        modal.setContent()
    }

    const handleReadMore = () => {
        setStatus('')
        modal = new ModalCaller({
            className: 'sm:max-w-[400px] pb-0 mb-0',
            children: modalContent(),
        })
    }

    const modalContent = () => {
        return (
            <div className={'space-y-8'}>
                <div className={'space-y-4'}>
                    <h2
                        className={
                            'text-gray-800 dark:text-gray-100 text-xl font-medium leading-5'
                        }
                    >
                        {data?.title}
                    </h2>
                    <p
                        className={
                            'text-gray-600 dark:text-gray-400 text-sm font-medium leading-5 whitespace-pre-line'
                        }
                    >
                        {data?.modal?.description}
                    </p>
                </div>
                <Button
                    disabled={status === 'pending' || status === 'success'}
                    onClick={sendMessage}
                >
                    {data?.modal?.cta}
                </Button>
                {status === 'pending' && (
                    <p
                        className={
                            'text-sm dark:text-gray-400 flex items-center'
                        }
                    >
                        <Loader sm overlayed={false} className={'mr-2'} />
                        Идёт отправка сообщения...
                    </p>
                )}
                {status === 'success' && (
                    <p className={'text-sm text-green-500 flex items-center'}>
                        <CheckIcon className={'h-6 w-6 mr-2'} />
                        Заявка успешно отправлена!
                    </p>
                )}
                {status === 'error' && (
                    <p className={'text-sm text-red-500 dark:text-red-300'}>
                        Что-то пошло не так, попробуйте ещё раз...
                    </p>
                )}
            </div>
        )
    }

    return (
        <>
            <Card innerClassName={'flex items-stretch relative z-0'}>
                <div
                    className={
                        'flex flex-col space-y-3 w-2/3 justify-between z-10'
                    }
                >
                    <div className={'flex flex-col space-y-2'}>
                        <h3
                            className={
                                'text-gray-800 dark:text-gray-100 text-md font-medium leading-5'
                            }
                        >
                            {data?.title}
                        </h3>
                        <div
                            className={
                                'flex flex-col text-gray-600 dark:text-gray-400 text-sm font-medium leading-5 space-y-2'
                            }
                        >
                            {data?.description.map((item, idx) => (
                                <span key={idx}>{item}</span>
                            ))}
                        </div>
                    </div>
                    <div>
                        <Button
                            className={'inline w-auto'}
                            color={'white'}
                            size={'sm'}
                            onClick={handleReadMore}
                        >
                            Подробнее
                        </Button>
                    </div>
                </div>
                <img
                    className={`z-0 absolute max-w-full max-h-full right-0 bottom-0 object-contain object-right-bottom ${data?.imgClass}`}
                    src={data?.img}
                    alt={''}
                />
            </Card>
        </>
    )
}

export default ServiceCard
