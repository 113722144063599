import React from 'react'
import {HeaderTabsItem} from './HeaderTabsItem'

const HeaderTabs = ({tabs, onClickTab}) => {
    return (
        <div className='hidden lg:ml-4 lg:flex lg:space-x-8'>
            {tabs.map((tab) => (
                <HeaderTabsItem
                    key={tab.id}
                    tab={tab}
                    onClickTab={onClickTab}
                />
            ))}
        </div>
    )
}

export {HeaderTabs}
