import {TooltipLite} from '@components/elements'
import {ExclamationIcon, QuestionMarkCircleIcon} from '@heroicons/react/outline'
import React from 'react'
import classNames from 'classnames'

export const TableHeaderCell = ({className, title, content, warn}) => {
    return (
        <div className={classNames(className, 'flex space-x-2 items-center')}>
            <span>{title}</span>
            {content && (
                <TooltipLite
                    offset={[0, 10]}
                    content={content}
                    classNameBody={'md:p-2 max-w-[250px]'}
                >
                    {(ref) => (
                        <span ref={ref}>
                            {warn ? (
                                <ExclamationIcon
                                    className={
                                        'w-4 h-4 text-yellow-500 dark:text-yellow-400'
                                    }
                                />
                            ) : (
                                <QuestionMarkCircleIcon
                                    className={
                                        'w-4 h-4 text-gray-500 dark:text-gray-400'
                                    }
                                />
                            )}
                        </span>
                    )}
                </TooltipLite>
            )}
        </div>
    )
}
