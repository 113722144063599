import React from 'react'
import {InfoField} from '@components/applicationUi'
import {Observer} from 'mobx-react'
import {ToggleLabeled} from '@components/forms'
import {TooltipLite} from '@components/elements'

export const DisabledIsActiveToggle = ({helpContent}) => {
    return (
        <InfoField label={'Только активные товары:'} helpContent={helpContent}>
            <Observer>
                {() => (
                    <TooltipLite
                        offset={[0, 10]}
                        content={
                            'Если необходимо, чтобы товары присутствовали в инструментах, их надо вернуть из архива'
                        }
                        classNameBody={'md:p-2 max-w-[250px]'}
                    >
                        {(ref) => (
                            <span ref={ref}>
                                <ToggleLabeled disabled={true} checked={true} />
                            </span>
                        )}
                    </TooltipLite>
                )}
            </Observer>
        </InfoField>
    )
}
