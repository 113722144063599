import {makeAutoObservable, reaction} from 'mobx'
import {oAuthVariant, wbAuthVariant} from '../constants'
import MarketService from '@services/MarketService/MarketService'
import {geErrorMessage} from '@utils/errors/marketpalcesError'

export class WildberriesAuthStore {
    constructor(isEdit, wbBackUrl) {
        makeAutoObservable(this)

        if (isEdit && !wbBackUrl) {
            this.setAuthVariant(wbAuthVariant.apiKey)
        }

        this.disposer = reaction(
            () => this.code,
            (code) => {
                if (code) {
                    this.setOAuthVariant(oAuthVariant.form)
                }
            }
        )
    }

    authVariant = wbAuthVariant.oAuth
    oAuthVariant = oAuthVariant.start
    responseError = ''
    code = null
    state = null

    setAuthVariant = (value = wbAuthVariant.oAuth) => {
        this.authVariant = value
    }

    setOAuthVariant = (value = oAuthVariant.start) => {
        this.oAuthVariant = value
    }

    setResponseError = (value = '') => {
        this.responseError = value
    }

    onLocationChange = () => {
        const code = localStorage.getItem('wb_code')
        const state = localStorage.getItem('wb_state')
        if (code && state) {
            clearInterval(this.wndInterval)
            this.code = code
            this.state = state
            setTimeout(() => {
                this.wnd.close()
                localStorage.removeItem('wb_code')
                localStorage.removeItem('wb_state')
            }, 10)
        }
    }

    getCheckSsoWb = async (name = '') => {
        try {
            const {data} = await MarketService.getCheckSsoWb({
                code: this.code,
                state: this.state,
                name,
            })
            if (data.result) {
                return data
            }
            return Promise.reject()
        } catch (e) {
            if (!e?.response.data?.result && e?.response.data?.message) {
                this.setResponseError(geErrorMessage(e?.response.data))
            } else {
                this.setResponseError(geErrorMessage({code: -1}))
            }
            return e
        }
    }

    openWbAuth(authLink) {
        this.wnd = window.open(
            authLink,
            'Login',
            'width=600,height=700 top=' +
                (window.screen.height - 700) / 2 +
                ',left=' +
                (window.screen.width - 600) / 2 +
                ', resizable=no'
        )
        if (this.wnd) {
            this.wndInterval = setInterval(this.onLocationChange, 1000)
        }
    }

    startWbAuth = async (id) => {
        try {
            const {data} = await MarketService.postSsoWb(id)
            const {redirectUrl} = data
            const decode = decodeURIComponent(redirectUrl)
            this.openWbAuth(decode)
        } catch (e) {
            console.log(e)
        }
    }
}
