import qs from 'qs'

export class TokenService {
    /* Save a token in Local Storage
     *
     * @param {string} token
     */

    static storage = localStorage

    static setStorage(remember = false) {
        TokenService.storage = remember ? localStorage : sessionStorage
    }

    static setToken(token) {
        TokenService.storage.setItem('token', token)
    }
    static setRegisterToken(tokens) {
        TokenService.storage.setItem('register-token', JSON.stringify(tokens))
    }
    static setRefreshedToken(token) {
        TokenService.storage.setItem('refresh-token', token)
    }
    static setIsEmulation() {
        TokenService.storage.setItem('_EdsSd5__dd', true)
    }

    static isTokenExists() {
        return TokenService.storage.getItem('token') !== null
    }
    static isRegisterTokenExists() {
        return TokenService.storage.getItem('register-token') !== null
    }
    static isTokenExistsSessionStorage() {
        return sessionStorage.getItem('token') !== null
    }
    static isRefreshedTokenExists() {
        return TokenService.storage.getItem('refresh-token') !== null
    }

    static isEmulationExists() {
        return (
            TokenService.storage.getItem('_EdsSd5__dd') !== null &&
            JSON.parse(TokenService.storage.getItem('_EdsSd5__dd')) !== false
        )
    }
    /* Remove a token from Local Storage.
     *
     */
    static removeToken() {
        TokenService.storage.removeItem('token')
    }
    static removeRegisterToken() {
        TokenService.storage.removeItem('register-token')
    }
    static removeRefreshedToken() {
        TokenService.storage.removeItem('refresh-token')
    }
    static removeIsEmulation() {
        TokenService.storage.removeItem('_EdsSd5__dd')
    }

    /**
     * Get a token value.
     *
     * @returns {string}
     */

    static getToken() {
        return TokenService.storage.getItem('token') || ''
    }

    static getRegisterToken() {
        return JSON.parse(TokenService.storage.getItem('register-token')) || ''
    }

    static getRefreshedToken() {
        return TokenService.storage.getItem('refresh-token') || ''
    }
    static getIsEmulation() {
        return JSON.parse(TokenService.storage.getItem('_EdsSd5__dd')) || ''
    }
}
