import {observable, action, computed, toJS, makeObservable} from 'mobx'
import ReviewsService from '@services/ReviewsService/ReviewsService'
import {getFirstWeekDay} from '@components/forms/DatePicker/DateRangePicker'
import {formatISO, addDays} from 'date-fns'

class ReviewsFilterStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            sortBy: observable,
            filterState: observable,
            credentialOptions: observable,
            credentials: observable,
            stars: observable,
            sku: observable,
            isTested: observable,
            loading: observable,
            testFilterResults: observable,
            appliedFilter: observable,
            dateRange: observable,
            setDateRange: action,
            changeSortBy: action,
            toggleFilterOpening: action,
            setCredentialOptions: action,
            setCredentials: action,
            setStars: action,
            setSku: action,
            setIsTested: action,
            setLoading: action,
            setTestFilterResults: action,
            applyFilter: action,
            credentialIds: computed,
            filterObj: computed,
        })
    }

    filterState = {
        open: false,
        active: false,
    }

    credentialOptions = []
    credentials = []
    sku = ''
    starsOptions = ['1', '2', '3', '4', '5']
    stars = []
    loading = false
    isTested = false
    testFilterResults = 0
    appliedFilter = {}

    sortBy = {
        options: [
            {
                name: 'Сначала новые',
                key: 'review_created_at_desc',
            },
            {
                name: 'Сначала старые',
                key: 'review_created_at_asc',
            },
            {
                name: 'Высокие оценки',
                key: 'stars_desc',
            },
            {
                name: 'Низкие оценки',
                key: 'stars_asc',
            },
        ],
        selected: {
            name: 'Сначала новые',
            key: 'review_created_at_desc',
        },
    }

    dateRange = {
        startDate: addDays(new Date(), -7),
        endDate: new Date(),
    }

    setDateRange = (value = {startDate: '', endDate: ''}) => {
        this.dateRange = value
    }

    toggleFilterOpening = (value = null) => {
        if (value !== null && typeof value === 'boolean') {
            return (this.filterState.open = value)
        }
        this.filterState.open = !this.filterState.open
    }

    changeSortBy = (selected) => {
        this.sortBy.selected = selected
    }

    setCredentialOptions = (options = []) => {
        this.credentialOptions = options
    }

    setStars = (array: Array = []) => {
        this.stars = array
    }

    setSku = (value = '') => {
        this.sku = value
    }

    setCredentials = (array: Array = []) => {
        this.credentials = array
    }

    setIsTested = (value: boolean = true) => {
        this.isTested = value
    }

    setLoading = (value: boolean = true) => {
        this.loading = value
    }

    setTestFilterResults = (value: number = 0) => {
        this.testFilterResults = value
    }

    applyFilter = () => {
        this.appliedFilter = this.filterObj
    }

    get credentialIds() {
        const array = []
        this.credentials.forEach((cred) => {
            array.push(cred?.key)
        })
        return array
    }

    setInitialOptions = () => {
        this.setCredentialOptions(this.rootStore.marketStore.credentialsOptions)
    }

    //
    // @action setPeriod = (period = { from: '', to: '' }) => {
    //     this.period = period
    // }
    //
    // getPeriod = (from, to) =>
    //     from && to
    //         ? `${String(from.getDate()).padStart(2, '0')}.${String(
    //               from.getMonth() + 1
    //           ).padStart(2, '0')}.${from.getFullYear()} - ${String(
    //               to.getDate()
    //           ).padStart(2, '0')}.${String(to.getMonth() + 1).padStart(
    //               2,
    //               '0'
    //           )}.${to.getFullYear()}`
    //         : ''
    //
    //
    //
    //

    //

    //
    //
    resetFilter = () => {
        this.setCredentials()
        this.setDateRange()
        this.setStars()
        this.setSku()
        this.applyFilter()
        this.setIsTested(false)
    }

    get filterObj() {
        const filters = {}
        if (this.stars.length) Object.assign(filters, {stars: this.stars})
        if (this.sku) Object.assign(filters, {skus: this.sku})
        if (this.dateRange.startDate)
            Object.assign(filters, {
                review_created_at_after: formatISO(this.dateRange.startDate),
            })
        if (this.dateRange.endDate)
            Object.assign(filters, {
                review_created_at_before: formatISO(this.dateRange.endDate),
            })
        if (this.credentialIds.length)
            Object.assign(filters, {
                marketplace_credential_ids: this.credentialIds,
            })
        return filters
    }

    testFilter = async () => {
        this.setIsTested()
        this.setLoading()
        const body = {
            limit: 1,
            page: 1,
        }
        Object.assign(body, this.filterObj)
        try {
            const {data} = await ReviewsService.getReviews(body)
            this.setTestFilterResults(data.total)
        } catch (e) {
            this.setTestFilterResults(0)
            console.log(e)
        } finally {
            this.setLoading(false)
        }
    }
}

export default ReviewsFilterStore
