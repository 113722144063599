import {makeAutoObservable, reaction} from 'mobx'
import {reportsStatus} from '@utils/constants'
import {PollingV2} from '@utils/polling/pollingv2'
import {PaginationStore} from '../../../../../store/PaginationStore'
import {LoaderStore} from '../../../../../store/LoaderStore'
import {ImportWbService} from '@services/ImportWbService'

export class ImpostWbStore {
    constructor(filters) {
        this.filters = filters
        makeAutoObservable(this)
        this.paginationStore = new PaginationStore()
        this.importWbListPolling = this.createImportWbListPolling()

        this.disposer = reaction(
            () => [this.filters.importWbSearch, this.filters.cabinet.selected],
            () => {
                if (!this.filters.cabinet.selected.key) return
                this.importWbListPolling.startPolling()
            }
        )
    }

    importWbList = []
    importWbLoading = new LoaderStore()

    postImportWbList = async (query = {}) => {
        const {limit, page} = query
        const payload = this.filters.collectImportWbPayload()
        payload.pagination = {
            limit: limit ? limit : this.paginationStore.pagination.limit,
            page: page ? page : this.paginationStore.pagination.page,
        }
        try {
            const {data} = await ImportWbService.postImportWbList(payload)
            return Promise.resolve(data)
        } catch (e) {
            return Promise.reject(e)
        }
    }

    getStatuses = () => {}

    onResult = (data) => {
        if (data.status === reportsStatus.failure) {
            this.filters.isEmptyData = true
            return false
        } else if (data.status === reportsStatus.success) {
            this.paginationStore.setPagination(data.result.pagination)
            this.importWbList = data.result.reports
            return false
        }
        return true
    }

    createImportWbListPolling() {
        return new PollingV2({
            name: 'importWbListPolling',
            pollingFunction: this.postImportWbList,
            setProcessing: this.importWbLoading.setLoading,
            onCheckResult: (data) => this.onResult(data),
            delay: 5000,
            isControlPolling: true,
        })
    }

    destroy = () => {
        this.disposer()
        this.importWbListPolling.stopPolling()
    }
}
