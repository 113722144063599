import React, {useEffect, useRef} from 'react'
import cn from 'classnames'
import ApexChart from 'react-apexcharts'

export const Chart = ({className, theme, data, chartOptions}) => {
    const chartRef = useRef(null)

    useEffect(() => {
        if (chartRef) {
            updateTheme()
        }
    }, [theme])

    const updateTheme = async () => {
        await chartRef.current.chart.updateOptions({
            theme: {mode: theme ? 'dark' : 'light'},
        })
    }
    return (
        <ApexChart
            ref={chartRef}
            options={chartOptions?.options}
            type={chartOptions.options.chart.type || 'bar'}
            series={data}
            height='100%'
            className={cn(className, 'grafik')}
        />
    )
}
