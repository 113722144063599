import React from 'react'

export const ErrorUnknownZipContent = ({unknownList, cabinetName}) => {
    return (
        <div className={'flex flex-col space-y-4'}>
            <p>{`При последней загрузке zip архива, для кабинета ${cabinetName} произошла ошибка.`}</p>
            <p>
                Текст ошибки: <strong>{unknownList.join(', ')}</strong>
            </p>
            <p>Убедитесь, что загружаете корректный файл.</p>
        </div>
    )
}
