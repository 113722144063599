import React, {useMemo} from 'react'
import {Badge} from '@components/elements'

const TabsBadge = ({count, active}) => {
    const {value, warn} = useMemo(() => {
        if (typeof count === 'number' || typeof count === 'string') {
            return {
                value: count,
                warn: false,
            }
        }
        return {
            ...count,
            value: Number(count.value),
        }
    }, [count])

    return (
        <Badge className={'ml-3'} rounded bright color={warn ? 'red' : 'gray'}>
            {value}
        </Badge>
    )
}

export default TabsBadge
