import cn from 'classnames'
import React from 'react'

const Card = ({
    children,
    className,
    innerClassName,
    isFull = true,
    background = 'bg-white dark:bg-gray-700',
    defaultPadding = true,
}) => {
    return (
        <div
            className={cn(
                'overflow-hidden shadow rounded-lg',
                background,
                className
            )}
        >
            <div
                className={cn(innerClassName, {
                    'px-4 py-5 sm:p-6': defaultPadding,
                    'h-full': isFull,
                })}
            >
                {children}
            </div>
        </div>
    )
}

export {Card}
