import React from 'react'
import {observer} from 'mobx-react'
import {CheckIcon} from '@heroicons/react/outline'
import {TimeLoaderIcon} from '@components/elements/Icon/Icon'

export const Status = observer(({status}) => {
    const getText = () => {
        switch (status) {
            case 'published':
                return (
                    <div
                        className={
                            'whitespace-normal text-green-500 flex items-center'
                        }
                    >
                        <CheckIcon className={'flex-shrink-0 h-5 w-5 mr-2'} />
                        Опубликован
                    </div>
                )
            case 'on_moderation':
                return (
                    <div
                        className={
                            'whitespace-normal text-gray-500 dark:text-gray-400 flex items-center'
                        }
                    >
                        <TimeLoaderIcon
                            className={'flex-shrink-0 mr-2'}
                            width={18}
                            height={18}
                        />
                        На модерации
                    </div>
                )
            case 'failed_moderation':
                return (
                    <div
                        className={
                            'whitespace-normal text-red-500 dark:text-red-400'
                        }
                    >
                        Не прошел модерацию
                    </div>
                )
            case 'unpublished':
                return (
                    <div
                        className={
                            'whitespace-normal text-gray-500 dark:text-gray-400'
                        }
                    >
                        Не опубликован
                    </div>
                )
            default:
                return (
                    <div
                        className={
                            'whitespace-normal text-gray-500 dark:text-gray-400'
                        }
                    >
                        Данные отсутствуют
                    </div>
                )
        }
    }
    return getText()
})
