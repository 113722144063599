import React from 'react'
import {observer} from 'mobx-react'
import classNames from 'classnames'

export const FileSharingItemField = observer(
    ({name, value, className, valueClassName, onClick}) => {
        return (
            <div className={classNames(className, 'flex space-x-2')}>
                <span>{name}</span>
                <span
                    onClick={onClick ? onClick : undefined}
                    className={valueClassName}
                >
                    {value}
                </span>
            </div>
        )
    }
)
