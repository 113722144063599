export class CallConfirmModalService {
    static key = 'confirm_call'
    static setOpenedCall = () => {
        localStorage.setItem(CallConfirmModalService.key, true)
    }

    static getOpenedCall = () => {
        return !!localStorage.getItem(CallConfirmModalService.key)
    }

    static resetOpenedCall = () => {
        localStorage.removeItem(CallConfirmModalService.key)
    }
}
