import React, {useEffect} from 'react'
import {observer} from 'mobx-react'
import {OnboardingWrapper} from '@components/navigation/header/Onboarding/components/OnboardingWrapper'
import {Link, useHistory} from 'react-router-dom'
import {AppPath} from '@routesEnums'
import {ONBOARD_STEP} from '@utils/constants'
import {toast} from 'react-toastify'
import {TOAST_ONBOARDING_MAP} from '@components/navigation/header/Onboarding/constants'

export const MarketOnboard = observer(({onboardingStepStore}) => {
    const history = useHistory()

    useEffect(() => {
        if (onboardingStepStore.isConnectedCabinet) {
            nextStep()
        }
    }, [onboardingStepStore.isConnectedCabinet])

    const nextStep = async () => {
        try {
            await onboardingStepStore.putOnboardSetStep(
                ONBOARD_STEP.mpAdded,
                true
            )
            handleOpenToast(TOAST_ONBOARDING_MAP.marketAdded)
            history.push(AppPath.marketplaces)
        } catch (e) {
            console.log(e)
        }
    }

    function handleOpenToast(text) {
        toast(text, {type: 'success', autoClose: 60000})
    }

    return (
        <OnboardingWrapper.BarContainer>
            <OnboardingWrapper.BarContainer
                className={'flex-col space-y-2 w-full'}
            >
                <OnboardingWrapper.Label className={'text-blue-500 text-sm'}>
                    <Link
                        to={AppPath.marketplaces}
                        className={
                            'cursor-pointer whitespace-normal truncate underline text-blue-500 dark:text-blue-400 hover:opacity-80'
                        }
                    >
                        Подключить кабинеты
                    </Link>
                </OnboardingWrapper.Label>
                <OnboardingWrapper.Progress value={0} />
            </OnboardingWrapper.BarContainer>
        </OnboardingWrapper.BarContainer>
    )
})

MarketOnboard.displayName = 'MarketOnboard'
