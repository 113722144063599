import React from 'react'
import {observer} from 'mobx-react'
import {Link} from 'react-router-dom'
import {format} from 'date-fns'
import {ru} from 'date-fns/locale'
import {AppPath} from '../../../../routes-enums'
import {useSelector} from '@hooks'

export const InvalidAcc = observer(({credentials}) => {
    const supportOverlayStore = useSelector(
        (store) => store.supportOverlayStore
    )
    return (
        <span className={'space-x-2'}>
            <span>Возникла проблема с API-подключением по кабинету</span>
            <span>
                {credentials
                    .map((cred) => {
                        return `${cred.name.trim()}, данные не обновлены с ${format(
                            new Date(cred.updatedAt),
                            `dd.MM.yyyy`,
                            {
                                locale: ru,
                            }
                        )}`
                    })
                    .join(', ')}
                .
            </span>
            <span>
                <Link
                    to={AppPath.marketplaces}
                    className={
                        'cursor-pointer whitespace-normal truncate text-blue-500 dark:text-blue-400 hover:opacity-80'
                    }
                >
                    Обновите API-ключ
                </Link>
                , <span>либо</span>{' '}
                <span
                    className={
                        'cursor-pointer whitespace-normal truncate text-blue-500 dark:text-blue-400 hover:opacity-80'
                    }
                    onClick={supportOverlayStore.overlayControl.toggle}
                >
                    обратитесь в службу поддержки
                </span>
            </span>
        </span>
    )
})
