import {ApiFactory} from '@services/ApiFactory'
import {sellerConfig} from '@services/apiConfigs'
import {UrlDisabledUrl} from '@routesEnums'
import {getRandomNumber} from '@utils/helpers'
const urlDisabledInstance = ApiFactory.createApi(sellerConfig, 'urlDisabled')

export class UrlDisabledService {
    static getUrlDisableData = async () => {
        return await urlDisabledInstance.get(
            UrlDisabledUrl.urlDisabledData(getRandomNumber(10, 9999))
        )
    }
}
