import React from 'react'
import {observer} from 'mobx-react'
import {Header} from '@components/navigation'
import {useSelector} from '@hooks'

import {Redirect, Route, Switch, useLocation} from 'react-router-dom'
import {AppPath} from '@routesEnums'
import {Marketplaces} from '@views/marketplaces/pages/Marketplaces'
import {marketplacesHeaderTabs} from '@views/marketplaces/constants'
import {ImportWb} from '@views/marketplaces/pages/ImportWb'

const MarketplacesPage = observer(({setSidebarOpen}) => {
    const {myProfileOptions} = useSelector((store) => store.layoutSidebarStore)
    const location = useLocation()

    return (
        <>
            <Header
                setSidebarOpen={setSidebarOpen}
                menuOptions={myProfileOptions}
                tabs={marketplacesHeaderTabs}
            />
            <Switch>
                <Route path={AppPath.marketplacesConnected}>
                    <Marketplaces />
                </Route>
                <Route path={AppPath.marketplacesImportWb}>
                    <ImportWb />
                </Route>
                <Route path={AppPath.marketplaces}>
                    <Redirect
                        to={{
                            pathname: AppPath.marketplacesConnected,
                            state: location.state,
                        }}
                    />
                </Route>
            </Switch>
        </>
    )
})

MarketplacesPage.displayName = 'MarketplacesPage'

export default MarketplacesPage
