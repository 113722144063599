import React from 'react'
import {Button} from '@components/elements'
import {Loader} from '@components/elements/Loader'

const FooterButtons = ({
    connectedButton,
    onConnected,
    onCancel,
    success,
    pending,
    disabled,
}) => {
    const firsBtnStyle = 'w-full flex-1'
    return (
        <div
            className={
                'flex flex-col items-center sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4'
            }
        >
            {connectedButton ? (
                connectedButton(firsBtnStyle)
            ) : (
                <Button
                    className={firsBtnStyle}
                    onClick={onConnected}
                    disabled={pending || disabled}
                >
                    Войти в аккаунт
                    {!!pending && <Loader sm />}
                </Button>
            )}
            {!success ? (
                <Button
                    className={'w-full flex-1'}
                    color={'white'}
                    onClick={onCancel}
                >
                    Отменить
                </Button>
            ) : (
                ''
            )}
        </div>
    )
}

export default FooterButtons
