import {useModalContext} from '@components/overlays'
import {XIcon} from '@heroicons/react/outline'
import React from 'react'
import {Viewer} from '@components/applicationUi/Editor'
import {ViewerWrapper} from './ViewerWrapper'

export const NotificationDialog = ({body, title}) => {
    const {onClose} = useModalContext()

    return (
        <div
            className={
                'flex flex-col relative gap-4 text-gray-800 dark:text-gray-200'
            }
        >
            <span onClick={onClose} className={'absolute -top-2 -right-1'}>
                <XIcon
                    className={
                        'w-6 h-6 text-gray-600 dark:text-gray-400 cursor-pointer'
                    }
                />
            </span>
            <h2 className={'text-2xl mt-6 font-medium dark:text-gray-100'}>
                {title}
            </h2>
            <div className={'flex flex-col overflow-auto w-full max-h-[550px]'}>
                <Viewer wrapper={ViewerWrapper}>{body}</Viewer>
            </div>
        </div>
    )
}
