import {useMemo} from 'react'
import {observer} from 'mobx-react'
import {ModalHeader} from '@components/overlays'
import {StartPage} from './Screens/StartPage'
import {EnrollPage} from './Screens/EnrollPage'
import {NextTimePage} from './Screens/NextTimePage'
import {DontAudit} from './Screens/DontAudit'
import {useAuditContext} from './../context/AuditContext'

const COMPONENT_MODAL_PHONE = {
    start: StartPage,
    enroll: EnrollPage,
    nextTime: NextTimePage,
    dontAudit: DontAudit,
}

export const AuditModal = observer(({onClose}) => {
    const {auditStore} = useAuditContext()
    const ActivePageComponent = useMemo(() => {
        return COMPONENT_MODAL_PHONE[auditStore.currentAction]
    }, [auditStore.currentAction])

    return (
        <div className={'flex flex-col'}>
            <ModalHeader
                title={'Аудит ваших кабинетов на маркетплейсах'}
                onCancel={onClose}
            />
            <ActivePageComponent onClose={onClose} />
        </div>
    )
})

AuditModal.displayName = 'AuditModal'
