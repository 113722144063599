import {observer} from 'mobx-react'
import classNames from 'classnames'
import {Link} from 'react-router-dom'
import {AppPath} from '../../../../routes-enums'
import React from 'react'

export const ExceedLimitProducts = observer(() => {
    return (
        <span className={classNames('space-x-1')}>
            <span>
                Внимание! Вы превысили лимит текущего тарифа по товарам.
            </span>
            <span>Пожалуйста,</span>
            <Link
                to={AppPath.accountTariff}
                className={
                    'cursor-pointer whitespace-normal truncate text-blue-500 dark:text-blue-400 hover:opacity-80'
                }
            >
                смените тариф,
            </Link>
            <span>чтобы полноценно работать с отчетами.</span>
        </span>
    )
})

ExceedLimitProducts.displayName = 'ExceedLimitProducts'
