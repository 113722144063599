module.exports = {
    options: {
        chart: {
            type: 'area',
            sparkline: {
                enabled: true,
            },
        },
        stroke: {
            width: 2,
        },
        markers: {
            size: 0,
            hover: {
                size: undefined,
                sizeOffset: 3,
            },
        },
        tooltip: {
            fixed: {
                enabled: false,
            },
            x: {
                show: false,
            },
            y: {
                formatter: function (value, options) {
                    return Number(value)
                },
                title: {
                    formatter: function (seriesName) {
                        return ''
                    },
                },
            },
            marker: {
                show: false,
            },
        },
    },
}
