import React, {useCallback, useMemo} from 'react'
import cn from 'classnames'
import {StarIcon} from '@heroicons/react/solid'

const Rating = ({
    value,
    numbered = false,
    customStarClassName,
    className,
    size = 'md',
}) => {
    const collectStars = useCallback(
        (rating) =>
            Array(5)
                .fill({active: false})
                .map((item, idx) => {
                    if (idx <= rating - 1) {
                        return {
                            id: idx,
                            active: true,
                        }
                    }
                    return {id: idx, ...item}
                }),
        []
    )

    const stars = useMemo(() => {
        const roundRating = Math.floor(value)
        return collectStars(roundRating)
    }, [value, collectStars])
    return (
        <div className={`flex ${className}`}>
            {stars.map((item) => {
                return (
                    <StarIcon
                        key={item.id}
                        className={cn(
                            `${
                                item.active
                                    ? customStarClassName
                                        ? customStarClassName
                                        : 'text-gray-800 dark:text-gray-400'
                                    : 'text-gray-400 dark:text-gray-600'
                            }`,
                            {
                                'w-[10px] h-[10px]': size === 'sm',
                                'w-[18px] h-[18px]': size === 'md',
                                'w-5 h-5': size === 'base',
                                'w-8 h-8': size === 'lg',
                            }
                        )}
                    />
                )
            })}
            {numbered && <span className={'ml-2'}>{value}</span>}
        </div>
    )
}

export {Rating}
