import React from 'react'

import {InnerPagination} from '.'

export const InnerTablePagination = ({tableInstance, className, loader}) => {
    const {
        state: {pageSize, total},
        setPageSize,
    } = tableInstance

    return (
        <>
            <InnerPagination
                limit={pageSize}
                total={total}
                onChangeLimit={setPageSize}
                className={className}
                loader={loader}
            />
        </>
    )
}
