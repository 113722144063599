import React from 'react'
import {QuestionMarkCircleIcon} from '@heroicons/react/outline'

const HelperLabel = ({label, onOpen}) => {
    return (
        <div className={'mb-4'}>
            <span
                className={
                    'flex items-center cursor-pointer text-blue-500 hover:text-blue-400 dark:text-blue-400 dark:hover:text-blue-300 text-base'
                }
                onClick={() => onOpen(true)}
            >
                <QuestionMarkCircleIcon
                    className={'flex-shrink-0 w-5 h-5 mr-2'}
                />
                {label}
            </span>
        </div>
    )
}

export default HelperLabel
