import {action, makeObservable, observable} from 'mobx'
import {WarehousesService} from '@services/WarehousesService/WarehousesService'
import {toast} from 'react-toastify'

class WarehousesStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            warehousesLoader: observable,
            warehouses: observable,
            warehousesUpdate: observable,
            setWarehousesLoader: action,
            setWarehousesUpdate: action,
            setWarehouses: action,
            changeWarehousesByCredId: action,
            updateWarehousesByCredId: action,
        })
    }

    warehousesLoader = false
    warehouses = []
    warehousesUpdate = false

    setWarehousesLoader = (value = false) => {
        this.warehousesLoader = value
    }

    setWarehouses = (value = []) => {
        this.warehouses = value
    }

    setWarehousesUpdate = (value = false) => {
        this.warehousesUpdate = value
    }

    changeWarehousesByCredId = (credId, newWarehouses) => {
        newWarehouses.forEach((newWarehouse) => {
            const warehousesIdx = this.warehouses.findIndex(
                (warehouse) => warehouse.id === newWarehouse.id
            )
            if (warehousesIdx > -1) {
                this.warehouses[warehousesIdx] = newWarehouse
            }
        })
    }

    updateWarehousesByCredId = (credIds, newWarehouses) => {
        credIds.forEach((id) => {
            this.warehouses.forEach((warehouse, idx) => {
                const warehousesIdx = warehouse.warehouses.findIndex((item) => {
                    return item.id === id
                })

                if (warehousesIdx > -1) {
                    this.warehouses[idx].warehouses[warehousesIdx] =
                        newWarehouses.find((warehouse) => {
                            return warehouse.id === id
                        })
                }
            })
        })
    }

    getWarehouses = async (payload) => {
        this.setWarehousesLoader(true)
        try {
            const {
                data: {result},
            } = await WarehousesService.getWarehouses()
            this.setWarehouses(result.marketplacesWithWarehouses)
        } catch (e) {
            console.log(e)
        } finally {
            this.setWarehousesLoader(false)
        }
    }

    postWarehousesParse = async (id) => {
        const payload = {marketplace_credential_id: id}
        try {
            const {
                data: {result},
            } = await WarehousesService.postWarehousesParse(payload)
            if (Array.isArray(result)) {
                this.changeWarehousesByCredId(
                    id,
                    result.marketplacesWithWarehouses
                )
                toast(
                    `Склады для ${this.rootStore.marketStore.getCabinetNameById(
                        id
                    )} обновлены успешно`,
                    {type: 'success'}
                )
            }
        } catch (e) {
            console.log(e)
            return Promise.reject(e)
        }
    }

    postUpdateWarehouses = async (ids, cutoff) => {
        const payload = {marketplace_warehouse_ids: ids, cutoff}
        this.setWarehousesUpdate(true)
        try {
            const {
                data: {result},
            } = await WarehousesService.postUpdateWarehouses(payload)
            this.updateWarehousesByCredId(ids, result)
            toast(`Обновление склада прошло успешно`, {type: 'success'})
        } catch (e) {
            console.log(e)
            toast(`Обновление склада прошло c ошибкой`, {type: 'error'})
        } finally {
            this.setWarehousesUpdate(false)
        }
    }

    getWarehouse = async () => {
        const payload = {marketplace_warehouse_id: 106}
        try {
            const {data} = await WarehousesService.getWarehouse(payload)
        } catch (e) {
            console.log(e)
        }
    }
}

export {WarehousesStore}
