import React from 'react'
import {Select} from '@components/forms/Selects'
import cn from 'classnames'
import {TextField} from '@components/forms/TextFields'

const TextFieldSelect = ({
    className,
    select,
    textField,
    error,
    label,
    labelClassName,
    disabled,
}) => {
    return (
        <div className={className}>
            {!!label && (
                <label
                    className={cn(
                        'block text-sm font-medium text-gray-700 dark:text-gray-300',
                        labelClassName,
                        {
                            'opacity-50': disabled,
                        }
                    )}
                >
                    {label}
                </label>
            )}
            <div className='mt-1 relative rounded-md'>
                <div
                    className={cn(
                        'flex items-center',
                        'shadow-sm disabled:opacity-50 w-full sm:text-sm border border-gray-300 rounded-md',
                        'focus:border-blue-400 placeholder-gray-300',
                        'dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600 dark:border-gray-600',
                        {
                            'border-red-500 dark:border-red-300': error,
                        }
                    )}
                >
                    <Select
                        className={cn('w-[fit-content]', select?.className)}
                        selectClassName={
                            'border-transparent rounded-l-md rounded-r-none border-r-0 border-0 bg-gray-200 dark:bg-gray-500'
                        }
                        placeholder={select?.placeholder || ''}
                        options={select?.options || []}
                        selected={select?.selected || {}}
                        onChange={select?.onChange}
                        disabled={disabled}
                        {...select}
                    />

                    <TextField
                        className={cn('w-full')}
                        inputClassName={
                            'border-transparent rounded-r-md rounded-l-none border-0 border-l-0'
                        }
                        value={textField?.value}
                        onChange={textField?.onChange}
                        disabled={disabled}
                        {...textField}
                    />
                </div>
            </div>
        </div>
    )
}

export {TextFieldSelect}
