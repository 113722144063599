import {Amount} from '@components/elements'
import React from 'react'

export const EmptyCostField = ({onChangeCost}) => {
    return (
        <div
            className={
                'flex cursor-pointer justify-end text-yellow-500 dark:text-yellow-300'
            }
            onClick={onChangeCost}
        >
            <Amount value={0} decimalLength={0} />
        </div>
    )
}

export const EmptyCostLabel = ({onChangeCost}) => {
    return (
        <div
            className={
                'text-sm text-yellow-500 dark:text-yellow-300 hover:opacity-75 cursor-pointer'
            }
            onClick={onChangeCost}
        >
            Внесите себест.
        </div>
    )
}
