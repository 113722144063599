import {Button} from '@components/elements'

import {Card} from '@components/layout'
import React from 'react'

import {clickToUrl} from '@utils/helpers'
import {useAnalyticEventsHook} from '@hooks'
import TgPng from '@assets/images/tg.png'
import {PaperAirplaneIcon} from '@heroicons/react/outline'

export const HackingMp = () => {
    const {clickHackingMp} = useAnalyticEventsHook()
    const sendMessage = () => {
        clickHackingMp()
        clickToUrl('https://t.me/hacking_marketplaces', true)
    }
    return (
        <Card className={'relative'}>
            {/*<div*/}
            {/*    className={*/}
            {/*        'absolute top-0 left-0 right-0 bottom-0 bg-no-repeat bg-center bg-cover opacity-20'*/}
            {/*    }*/}
            {/*    style={{*/}
            {/*        backgroundImage: `url(${TgPng})`,*/}
            {/*    }}*/}
            {/*/>*/}
            <div
                className={`relative flex flex-col md:flex-row md:items-center md:justify-between gap-4 z-[1]`}
            >
                <div className={'flex flex-col gap-1'}>
                    <h2
                        className={
                            'text-2xl font-bold mb-4 text-gray-800 dark:text-gray-200'
                        }
                    >
                        Наш канал в Telegram
                    </h2>
                    <p
                        className={
                            'text-base font-medium text-gray-700 dark:text-gray-300'
                        }
                    >
                        Узнавайте о новостях маркетплейсов, берите на вооружение
                        новые фишки и читайте кейсы других селлеров в нашем
                        телеграм канале
                    </p>
                </div>
                <Button color={'white'} onClick={sendMessage}>
                    <div className={'flex items-center gap-3'}>
                        <PaperAirplaneIcon className={'h-5 w-5 rotate-90'} />
                        <span>Перейти</span>
                    </div>
                </Button>
            </div>
        </Card>
    )
}
