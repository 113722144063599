import card1 from '@assets/images/tariffBaners/card-1.svg'
import card2 from '@assets/images/tariffBaners/card-2.svg'
import card3 from '@assets/images/tariffBaners/card-3.svg'
import card4 from '@assets/images/tariffBaners/card-4.svg'
import card5 from '@assets/images/tariffBaners/card-5.svg'

export const data = [
    {
        title: 'До 10 кабинетов в 1 интерфейсе',
        img: card1,
    },
    {
        title: 'Маржинальная прибыль и unit-экономика',
        img: card2,
    },
    {
        title: 'Поставки: вовремя и в нужном объеме',
        img: card3,
    },
    {
        title: 'ABC-анализ и оборачиваемость запасов',
        img: card4,
    },
    {
        title: 'Важные уведомления в Телеграм-боте',
        img: card5,
    },
    {
        title: 'Региональные продажи и рекомендации складов',
        img: card2,
    },
]
