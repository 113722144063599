import React, {useRef} from 'react'
import classNames from 'classnames'
import {Loader} from '@components/elements/Loader'

export const InnerPagination = ({
    limit,
    total,
    onChangeLimit = () => {},
    className,
    loader,
}) => {
    const step = useRef(limit)
    const newLimit = useRef(limit)

    const handleChangeLimit = () => {
        const limit = getLimit()
        newLimit.current += limit
        onChangeLimit(newLimit.current)
    }

    const handleClickAllLimit = () => {
        newLimit.current = total
        onChangeLimit(newLimit.current)
    }

    const handleClickBackLimit = () => {
        newLimit.current = 5
        step.current = 5
        onChangeLimit(newLimit.current)
    }

    const getLimit = () => {
        if (total - newLimit.current >= step.current) {
            return step.current
        } else {
            step.current = total - newLimit.current
            return step.current
        }
    }
    const getTotal = () => {
        if (total > getLimit()) {
            return total - newLimit.current
        }
    }

    // if (total < limit || total < 1 || !step.current) return null

    const checkEndLimit = () => {
        return total < limit || total < 1 || !step.current
    }

    return (
        <div
            className={classNames(
                className,
                'bg-transparent px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6',
                'dark:dark:bg-[#404454] dark:border-gray-600'
            )}
        >
            <div className='sm:flex-1 md:flex md:items-center md:justify-between'>
                <div className='flex space-x-2 text-sm text-gray-700 dark:text-gray-400'>
                    {!checkEndLimit() ? (
                        <>
                            <div
                                className={'flex space-x-2  cursor-pointer '}
                                onClick={
                                    !loader ? handleChangeLimit : undefined
                                }
                            >
                                <span>Показать еще</span>
                                <span className='font-medium'>
                                    {getLimit()}
                                </span>
                                <span>из</span>
                                <span className='font-medium'>
                                    {getTotal()}
                                </span>
                                <span>шт >></span>
                            </div>
                            <span
                                className={'cursor-pointer'}
                                onClick={
                                    !loader ? handleClickAllLimit : undefined
                                }
                            >
                                Показать все
                            </span>
                        </>
                    ) : (
                        <span
                            className={'cursor-pointer'}
                            onClick={!loader ? handleClickBackLimit : undefined}
                        >
                            В начало списка
                        </span>
                    )}
                    {loader ? <Loader sm overlayed={false} /> : null}
                </div>
            </div>
        </div>
    )
}
