import {makeAutoObservable} from 'mobx'
export class FileStore {
    constructor() {
        makeAutoObservable(this)
    }
    ref = undefined
    fileName = ''

    setReference(ref = undefined) {
        this.ref = ref
    }

    setFileName(fileName = '') {
        this.fileName = fileName
    }
}
