import {createContext, useContext, useMemo} from 'react'

export const TrialBlockedContext = createContext()

export const useTrialContext = () => {
    const userStore = useContext(TrialBlockedContext)

    return useMemo(() => {
        if (userStore.tariff?.slug !== 'trial') return false
        return true
    }, [userStore.tariff, userStore.isEmulation])
}
