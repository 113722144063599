import {makeObservable, observable, action} from 'mobx'
import MpImportService from '@services/MpImportService/MpImportService'

export class ImportMarketplacesStore {
    statusPolling = 0
    mpImpostStatusRequestSource = {}

    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            statuses: observable,
            setStatuses: action,
            resetStatuses: action,
            removeStatus: action,
        })
    }

    statuses = {}

    setStatuses = (key, value) => {
        this.statuses[key] = value
    }

    resetStatuses = () => {
        this.statuses = {}
    }

    removeStatus = (key) => {
        delete this.statuses[key]
    }

    startStatusPolling = () => {
        if (!this.statusPolling) {
            this.statusPolling = setInterval(() => {
                this.getMpImpostStatus()
            }, 10000)
        }
    }

    stopStatusPolling = () => {
        if (this.statusPolling) {
            clearInterval(this.statusPolling)
            this.statusPolling = 0
        }
    }

    mergeMpImpostStatus = (status) => {
        status.forEach((status) => {
            status.cabinets.forEach((cabinet) => {
                if (
                    JSON.stringify(cabinet) !==
                    JSON.stringify(this.statuses[cabinet.cabinetId])
                ) {
                    this.setStatuses(cabinet.cabinetId, cabinet)
                }
            })
        })
    }

    getMpImpostStatus = async () => {
        const {credentialsOptions} = this.rootStore.marketStore

        const query = {
            cabinets: credentialsOptions.map((option) => option.key),
        }
        try {
            const {data} = await MpImportService.getMpImportStatus(
                query,
                this.mpImpostStatusRequestSource
            )
            this.mergeMpImpostStatus(data.status)
            this.mpImpostStatusRequestSource = {}
        } catch (error) {
            console.log(error)
        }
    }

    getMpImportStart = async (payload) => {
        try {
            const {data} = await MpImportService.postMpImportStart(payload)
            return Promise.resolve(data)
        } catch (error) {
            console.log(error)
            return Promise.reject(error)
        }
    }

    getMpImportStop = async (payload) => {
        try {
            const {data} = await MpImportService.postMpImportStop(payload)
            return Promise.resolve(data)
        } catch (error) {
            console.log(error)
            return Promise.reject(error)
        }
    }
}
