import React, {useEffect, useRef, useState} from 'react'

export const useAutocomplete = ({
    selected,
    onSearch,
    placeholder,
    disabled,
}) => {
    const inputRef = useRef()

    const [searchString, setSearchString] = useState(selected?.name || '')

    useEffect(() => {
        setSearchString(selected?.name || '')
    }, [selected])

    const handleSearch = () => {
        if (onSearch) onSearch(inputRef.current.value)

        setSearchString(inputRef.current.value)
    }

    const getSearchInput = () => {
        return (
            <input
                ref={inputRef}
                className={
                    'h-full w-full bg-transparent focus:outline-none focus:ring-0 focus:ring-offset-0'
                }
                value={searchString}
                onChange={handleSearch}
                onFocus={handleSearch}
                placeholder={placeholder}
                disabled={disabled}
            />
        )
    }

    return {
        getSearchInput,
        searchString,
        setSearchString,
        inputRef,
    }
}
