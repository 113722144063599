import React from 'react'
import {DateRangePicker} from '@components/forms'
import {InfoField} from '@components/applicationUi'

export const SettingsCalendar = ({label, dateRange, onChange, helpContent}) => {
    return (
        <InfoField label={label} helpContent={helpContent}>
            <DateRangePicker
                className={'text-xs w-full'}
                onChange={onChange}
                maxDate={new Date()}
                range={dateRange}
            />
        </InfoField>
    )
}
