import {ApiFactory} from '@services/ApiFactory'
import {baseConfig} from '@services/apiConfigs'
import {ReviewsUrl} from '@/routes-enums'

const reviewsInstance = ApiFactory.createApi(baseConfig)

class ReviewsService {
    static getReviews = async (query) => {
        return await reviewsInstance.get(ReviewsUrl.reviews, query)
    }
}

export default ReviewsService
