import {useState} from 'react'

export const useSectionInstructions = () => {
    const [linkOpen, setLinkOpen] = useState(false)

    const onClickLink = () => {
        setLinkOpen(true)
    }

    return {
        linkOpen,
        setLinkOpen,
        onClickLink,
    }
}
