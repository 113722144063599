import {ApiFactory} from '@services/ApiFactory'
import {baseConfig} from '@services/apiConfigs'
import {OnboardingUrl} from '../../routes-enums'

const onboardingInstance = ApiFactory.createApi(baseConfig)

class OnboardingService {
    static postNeedAudit = async (payload) => {
        return await onboardingInstance.post(OnboardingUrl.needAudit, payload)
    }
    static putOnboardSetStep = async (payload) => {
        return await onboardingInstance.put(
            OnboardingUrl.onboardSetStep,
            payload
        )
    }
    static getOnboardCurrentStep = async () => {
        return await onboardingInstance.get(OnboardingUrl.onboardCurrentStep)
    }
}

export default OnboardingService
