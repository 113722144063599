import React, {useEffect} from 'react'
import {useAnalyticEventsHook} from '@hooks'
import {EmptyDataStub} from '@components/applicationUi'

export const EmptyAnalyticsDataStub = (props) => {
    const {messageFront} = useAnalyticEventsHook()
    useEffect(() => {
        messageFront('Данных для отображения отчета нет')
    }, [])
    return <EmptyDataStub {...props} />
}
