export const resData = {
    ware_class: 'A',
    ware_position: 30,
    ware_position_changing: -3,
    stats: [
        {
            code: 'income',
            name: 'Выручка по заказам',
            measure: '₽',
            value: 3000,
            percent_diff: 10,
            status: 'good',
            chart: {
                '2021-09-12': 72,
                '2021-09-13': -85,
                '2021-09-14': -58,
                '2021-09-15': 36,
                '2021-09-16': 80,
            },
        },
        {
            code: 'profit',
            name: 'Зароботок',
            measure: '₽',
            value: 2000,
            percent_diff: 0,
            status: 'normal',
            chart: {
                '2021-09-12': -39,
                '2021-09-13': 46,
                '2021-09-14': 99,
                '2021-09-15': 5,
                '2021-09-16': 7,
            },
        },
        {
            code: 'count_orders',
            name: 'Количество заказов',
            measure: 'шт.',
            value: 20,
            percent_diff: 10,
            status: 'good',
            chart: {
                '2021-09-12': -54,
                '2021-09-13': 80,
                '2021-09-14': -30,
                '2021-09-15': -86,
                '2021-09-16': -40,
            },
        },
        {
            code: 'avg_orders',
            name: 'Средняя сумма заказа',
            measure: '₽',
            value: 999,
            percent_diff: -7,
            status: 'good',
            chart: {
                '2021-09-12': -71,
                '2021-09-13': -9,
                '2021-09-14': 35,
                '2021-09-15': 34,
                '2021-09-16': -38,
            },
        },
        {
            code: 'frequency_orders',
            name: 'Частота заказов',
            measure: 'шт./день',
            value: 4,
            percent_diff: -200,
            status: 'bad',
            chart: {
                '2021-09-12': 71,
                '2021-09-13': -97,
                '2021-09-14': -97,
                '2021-09-15': -50,
                '2021-09-16': 40,
            },
        },
        {
            code: 'count_cancels',
            name: 'Отменено заказов',
            measure: 'шт.',
            value: 0,
            percent_diff: -7,
            status: 'good',
            chart: {
                '2021-09-12': 22,
                '2021-09-13': -83,
                '2021-09-14': -97,
                '2021-09-15': 56,
                '2021-09-16': 9,
            },
        },
    ],
}
