import React from 'react'
import {ArrowSmDownIcon, ArrowSmUpIcon} from '@heroicons/react/outline'
import {useDynamic} from '@hooks'

export const StatusArrow = (status) => {
    const {colors} = useDynamic()
    const colorVariant = colors(status)

    if (status === 'positive')
        return (
            <ArrowSmUpIcon
                className={`flex-shrink-0 w-5 h-5 text-${colorVariant.colorLabel}-500 dark:text-${colorVariant.colorLabel}-300`}
            />
        )
    if (status === 'negative') {
        return (
            <ArrowSmDownIcon
                className={`flex-shrink-0 w-5 h-5 text-${colorVariant.colorLabel}-500 dark:text-${colorVariant.colorLabel}-300`}
            />
        )
    }
    return null
}
