import {useModalContext} from '@components/overlays'
import {Button} from '@components/elements'
import {ExclamationIcon} from '@heroicons/react/outline'
import QrImg from '@assets/images/qr/qr.png'
import React from 'react'

export const ConfirmCallDialog = ({}) => {
    const {onClose} = useModalContext()

    return (
        <div
            className={
                'flex flex-col gap-4 text-gray-800 dark:text-gray-200 items-center'
            }
        >
            <p className={'text-center'}>
                В течение 1 рабочего дня мы свяжемся с вами и уточним, сможем ли
                мы провести вам{' '}
                <strong className={'font-bold'}>
                    бесплатный аудит магазина
                </strong>{' '}
                для роста продаж и чистой прибыли.
            </p>
            <div className={'flex flex-col items-center gap-2'}>
                <img src={QrImg} alt={'hr'} />
                <span className={'text-2xl'}>+7 (495) 136 22-82</span>
            </div>
            <p className={'text-center'}>
                Добавьте нас в контакты, чтобы не пропустить звонок!
            </p>

            <Button className={'w-full'} onClick={onClose}>
                Жду звонка
            </Button>
        </div>
    )
}
