import React from 'react'

export const BlockedInfo = ({tariff}) => {
    return (
        <p className='text-sm leading-6 text-gray-900'>
            {tariff === 'internal'
                ? 'Внимание! Пользователь не имеет доступа к отчетам, так как находится на тарифе Внутренний'
                : 'Внимание! Пользователь не имеет доступа к отчетам, так как находится на тарифе Заблокирован. Данные по РК не загружаются!'}
        </p>
    )
}
