import {ApiFactory} from '@services/ApiFactory'
import {OrdersUrl} from '@/routes-enums'
import {baseConfig} from '@services/apiConfigs'
const ordersInstance = ApiFactory.createApi(baseConfig)

class OrdersService {
    // static getFilteredState = async () => {
    //     return await ordersInstance.get(OrdersUrl.)
    // }

    static getStates = async () => {
        return await ordersInstance.get(OrdersUrl.states)
    }

    static getOrders = async (query) => {
        return await ordersInstance.get(OrdersUrl.orders, query)
    }

    static postOrderRun = async (payload) => {
        return await ordersInstance.post(OrdersUrl.run, payload)
    }

    static postOrderArbitration = async (orderId, payload) => {
        return await ordersInstance.post(
            OrdersUrl.orderArbitration(orderId),
            payload
        )
    }

    static postOrderTrackingNumber = async (orderId, payload) => {
        return await ordersInstance.post(
            OrdersUrl.orderTrackingNumber(orderId),
            payload
        )
    }

    static postSetMandatoryMarks = async (payload) => {
        return await ordersInstance.post(OrdersUrl.setMandatoryMarks, payload)
    }

    static postValidateMandatoryMarks = async (payload) => {
        return await ordersInstance.post(
            OrdersUrl.mandatoryMarkValidate,
            payload
        )
    }

    static postDwlMarks = async (payload) => {
        const newConfig = {
            responseType: 'blob',
        }
        return await ordersInstance.getFile(
            OrdersUrl.dwlMarks,
            payload,
            newConfig
        )
    }

    static getDwlDocs = async (attachmentId) => {
        return await ordersInstance.get(OrdersUrl.downloadDocs(attachmentId))
    }

    static getOrdersDocs = async () => {
        return await ordersInstance.get(OrdersUrl.ordersDocs)
    }

    static getDownloadDocument = async (url) => {
        const newConfig = {
            baseUrl: '',
        }
        return await ordersInstance.getFileWithGet(url, null, newConfig)
    }

    static postOrdersDocs = async (payload) => {
        return await ordersInstance.post(OrdersUrl.ordersDocs, payload)
    }

    static getCancelReasons = async () => {
        return await ordersInstance.get(OrdersUrl.cancelReasons)
    }

    static getAllStates = async () => {
        return await ordersInstance.get(OrdersUrl.allStates)
    }

    static getOrder = async (orderId) => {
        return await ordersInstance.get(OrdersUrl.order(orderId))
    }

    static postBulkUpdate = async (payload) => {
        return await ordersInstance.post(OrdersUrl.bulkUpdate, payload)
    }

    static postPimProductFromOrder = async (payload) => {
        return await ordersInstance.post(OrdersUrl.productFromOrder, payload)
    }
}

export default OrdersService
