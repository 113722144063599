//libs
import {makeObservable} from 'mobx'

//stores
import AttrsMatchingStore from '../AttributesMatching/store/AttrsMatchingStore'
import CatsMatchingStore from '../CategoriesMatching/store/CatsMatchingStore'

class ProductsAdminStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        this.reset()
        makeObservable(this, {})
    }

    reset = () => {
        this.stores = {
            attrsMatchingStore: new AttrsMatchingStore(this.rootStore),
            catsMatchingStore: new CatsMatchingStore(this.rootStore),
        }
    }

    headerTabs = [
        {
            id: 'cats-matching',
            name: 'Категории \nS24 ⇹ MP',
            disabled: false,
            visible: true,
        },
        {
            id: 'attrs-feed',
            name: 'Свойства \nYML ⇹ S24',
            disabled: false,
            visible: true,
        },
        {
            id: 'attrs-market',
            name: 'Свойства \nS24 ⇹ MP',
            disabled: false,
            visible: true,
        },
    ]
}

export default ProductsAdminStore
