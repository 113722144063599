import {makeObservable, observable} from 'mobx'

export class StatisticsProductsStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            stats: observable,
            charts: observable,
        })
    }

    stats = [
        {
            title: 'Всего уникальных SKU',
            data: [
                {
                    market: 'ozon',
                    text: 'Ozon',
                    value: '12 976',
                },
                {
                    market: 'wildberries',
                    text: 'Wildberries',
                    value: '1 265',
                },
                {
                    market: 'aliexpress',
                    text: 'Aliexpress',
                    value: '313',
                },
                {
                    market: 'yandex',
                    text: 'Yandex',
                    value: '43 249',
                },
            ],
            total: {
                text: 'Всего товаров в базе Seller24',
                value: '57 803',
            },
        },
        {
            title: 'Клиенты по количеству уникальных SKU',
            data: [
                {
                    text: 'без единого SKU:',
                    value: '229',
                },
                {
                    text: 'до 300 SKU:',
                    value: '64',
                },
                {
                    text: 'от 301 до 1000 SKU:',
                    value: '56',
                },
                {
                    text: 'от 1001 до 5000 SKU:',
                    value: '76',
                },
                {
                    text: 'более 5000 SKU:',
                    value: '2',
                },
            ],
        },
        {
            title: 'Общий GMV клиентов, руб. (заказы)',
            data: [
                {
                    market: 'ozon',
                    text: 'Ozon',
                    value: '0',
                },
                {
                    market: 'wildberries',
                    text: 'Wildberries',
                    value: '0',
                },
                {
                    market: 'aliexpress',
                    text: 'Aliexpress',
                    value: '390 132',
                },
                {
                    market: 'yandex',
                    text: 'Yandex',
                    value: '135 555',
                },
            ],
            total: {
                text: 'Общий GMV клиентов, руб. за прошлый месяц',
                value: '525 687',
            },
        },
        {
            title: 'Общий GMV клиентов, руб.(заказы с учетом транзакций)',
            data: [
                {
                    market: 'ozon',
                    text: 'Ozon',
                    value: '377 776 960',
                },
                {
                    market: 'wildberries',
                    text: 'Wildberries',
                    value: '2 296',
                },
                {
                    market: 'aliexpress',
                    text: 'Aliexpress',
                    value: '211 248',
                },
                {
                    market: 'yandex',
                    text: 'Yandex',
                    value: '65 135',
                },
            ],
            total: {
                text: 'Общий GMV клиентов, руб. за прошлый месяц',
                value: '378 055 639',
            },
        },
        {
            title: 'Категоризация по GMV клиентов (заказы)',
            data: [
                {
                    text: 'Без единого заказа:',
                    value: '244',
                },
                {
                    text: 'Оборот до 500 000 рублей в месяц:',
                    value: '37',
                },
                {
                    text: 'Оборот от 500 000 до 1 млн рублей в месяц:',
                    value: '0',
                },
                {
                    text: 'Оборот от 1 млн до 3 млн рублей в месяц:',
                    value: '0',
                },
                {
                    text: 'Оборот более 3 млн рублей в месяц:',
                    value: '0',
                },
            ],
            links: [
                {
                    text: 'GMV с разбивкой по клиентам',
                    link: 'https://dev.seller24.ru/stats/users_with_gmv_by_orders_to_csv',
                },
                {
                    text: 'GMV с разбивкой по кабинетам МП',
                    link: 'https://dev.seller24.ru/stats/mpcred_with_gmv_by_orders_to_csv',
                },
            ],
        },
        {
            title: 'Категоризация по GMV клиентов (заказы с учетом транзакций)',
            data: [
                {
                    text: 'Оборот до 500 000 рублей в месяц:',
                    value: '33',
                },
                {
                    text: 'Оборот от 500 000 до 1 млн рублей в месяц:',
                    value: '28',
                },
                {
                    text: 'Оборот от 1 млн до 3 млн рублей в месяц:',
                    value: '35',
                },
                {
                    text: 'Оборот более 3 млн рублей в месяц:',
                    value: '27',
                },
            ],
            links: [
                {
                    text: 'GMV с разбивкой по клиентам',
                    link: 'https://dev.seller24.ru/stats/users_with_gmv_by_transactions_to_csv',
                },
                {
                    text: 'GMV с разбивкой по кабинетам МП',
                    link: 'https://dev.seller24.ru/stats/mpcred_with_gmv_by_transactions_to_csv',
                },
            ],
        },
    ]

    chartsOptions = {
        dateRange: {
            startDate: new Date(Date.now() - 29 * 24 * 60 * 60 * 1000),
            endDate: new Date(),
        },
    }

    charts = [
        {
            title: 'GMV по Ozon',
            market: 'ozon',
            data: [
                95, 120, 88, 62, 112, 102, 140, 105, 124, 117, 128, 138, 144,
                155, 154, 119, 200, 202, 211, 202, 180, 155, 197, 172, 158, 162,
                181, 198, 181, 202,
            ],
        },
        {
            title: 'GMV по Wildberries',
            market: 'wildberries',
            data: [
                16, 5, 10, 8, 12, 12, 12, 14, 15, 14, 18, 19, 18, 20, 17, 18,
                18, 16, 15, 14, 19, 20, 20, 21, 22, 18, 18, 19, 17, 15,
            ],
        },
        {
            title: 'GMV по Yandex',
            market: 'yandex',
            data: [
                17, 18, 8, 20, 20, 21, 22, 14, 19, 19, 5, 10, 12, 14, 15, 14,
                17, 15, 16, 18, 18, 12, 12, 18, 16, 15, 18, 19, 18, 20,
            ],
        },
        {
            title: 'GMV по Aliexpress',
            market: 'aliexpress',
            data: [
                10, 8, 12, 20, 21, 18, 16, 14, 17, 15, 14, 19, 20, 16, 18, 19,
                22, 18, 18, 19, 17, 15, 12, 12, 14, 15, 5, 18, 18, 20,
            ],
        },
    ]
}
