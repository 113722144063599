import React from 'react'
import {Portal} from '@components/elements'
import {ModalBody} from '@components/overlays/modals/Modal/ModalBody'
import {ModalContext} from '@components/overlays'

export const Modal = ({children, ...props}) => {
    return (
        <Portal>
            <ModalContext.Provider
                value={{open: props.open, onClose: props.onClose}}
            >
                <ModalBody {...props}>{children}</ModalBody>
            </ModalContext.Provider>
        </Portal>
    )
}
