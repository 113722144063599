import React from 'react'
import {ArrowLeftIcon, ArrowRightIcon} from '@heroicons/react/outline'
import cn from 'classnames'

const SwitchControl = ({toggleSidebar, isSlim}) => {
    return (
        <div
            className={'px-2 my-2 sticky bottom-0 bg-gray-800 dark:bg-gray-700'}
            onClick={toggleSidebar}
        >
            <div
                className={cn(
                    'px-2 py-2 rounded-md cursor-pointer',
                    'hover:bg-gray-600 dark:hover:bg-gray-800',
                    {
                        'flex justify-center items-center': isSlim,
                    }
                )}
            >
                {isSlim ? (
                    <ArrowRightIcon
                        className={
                            'flex-shrink-0 h-6 w-6 stroke-current text-white'
                        }
                    />
                ) : (
                    <ArrowLeftIcon
                        className={
                            'flex-shrink-0 h-6 w-6 stroke-current text-white'
                        }
                    />
                )}
            </div>
        </div>
    )
}

export {SwitchControl}
