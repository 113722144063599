import React from 'react'
import {observer} from 'mobx-react'
import {FileSharingItemField} from './FileSharingItemField'
import {FileSharingEdit} from '@components/applicationUi/FileSharing/FileSharingEdit'
import classNames from 'classnames'
import {format} from 'date-fns'
import {ru} from 'date-fns/locale'

export const FileSharingItem = observer(({fileItemStore}) => {
    return (
        <div
            className={classNames(
                'flex flex-col text-gray-700 dark:text-gray-300',
                {
                    'bg-red-400 bg-opacity-20': fileItemStore.isDeleted,
                }
            )}
        >
            {fileItemStore.isAdmin ? (
                <FileSharingEdit fileItemStore={fileItemStore} />
            ) : (
                <FileSharingItemField
                    name={'Файл:'}
                    valueClassName={'underline cursor-pointer'}
                    onClick={fileItemStore.getFile}
                    value={fileItemStore.filename || 'Файл'}
                />
            )}
            <FileSharingItemField
                valueClassName={'text-blue-500 dark:text-blue-400'}
                name={'Загружен:'}
                value={format(
                    new Date(fileItemStore.createdAt),
                    ` d MMMM yyyy 'г.' kk':'mm':'ss`,
                    {
                        locale: ru,
                    }
                )}
            />
            {/*<FileSharingItemField*/}
            {/*    name={'Тип:'}*/}
            {/*    value={fileItemStore.filetype}*/}
            {/*/>*/}
        </div>
    )
})
