import {TooltipLite} from '@components/elements'
import React from 'react'
import {ExclamationIcon} from '@heroicons/react/outline'

export const TableWarnTooltipWrapper = ({children, content}) => {
    return (
        <div className={'flex space-x-2 items-center justify-end'}>
            {children}
            {content && (
                <TooltipLite
                    offset={[0, 10]}
                    content={content}
                    classNameBody={'md:p-2 max-w-[350px]'}
                >
                    {(ref) => (
                        <span ref={ref}>
                            <ExclamationIcon
                                className={
                                    'w-6 h-6 text-yellow-500 dark:text-yellow-300'
                                }
                            />
                        </span>
                    )}
                </TooltipLite>
            )}
        </div>
    )
}
