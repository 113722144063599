import {action, makeObservable, observable} from 'mobx'
// import {extractUrlParams} from '@utils/helpers'
// import GoogleTagManager from '@utils/googleTagManager'
import OrdersService from '@services/OrdersService/OrdersService'
import {keysToSnake} from '@utils/helpers'

class OrderStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            pending: observable,
            order: observable,
            orderIsValid: observable,
            statesList: observable,
            shipment: observable,
            buyerData: observable,
            trackNumber: observable,
            cred: observable,
            createdDate: observable,
            isMandatoryMarK: observable,
            openOrderSplit: observable,
            openTrackingNumber: observable,
            setOrder: action,
            setProducts: action,
            setPending: action,
            setOrderStatesList: action,
            setOrderShipment: action,
            setBuyerData: action,
            setTrackNumber: action,
            setOrderIsValid: action,
            setCredByOrder: action,
            setOrderCreatedDate: action,
            setIsMandatoryMarK: action,
            setOpenOrderSplit: action,
            setOpenTrackingNumber: action,
            reset: action,
        })
    }

    pending = false
    orderIsValid = true
    order = {}
    statesList = []
    shipment = {
        type: '',
        date: '',
    }
    buyerData = {
        buyerName: '',
        phone: '',
        logisticsName: '',
        address: '',
    }
    trackNumber = ''
    cred = {}
    createdDate = ''
    isMandatoryMarK = false
    openOrderSplit = false
    openTrackingNumber = false

    reset = () => {
        this.initialOrderState()
        this.setCredByOrder()
        this.setOrderCreatedDate()
        this.setTrackNumber()
        this.setIsMandatoryMarK()
    }

    setOrder = (order = {}) => {
        this.order = order
    }
    setOrderStatesList = (list = []) => {
        const convertedList = []
        list.forEach((item) => {
            convertedList.push({
                state: item.state,
                status: item.status,
                date: new Date(item.time),
            })
        })
        this.statesList = convertedList
    }
    setOrderShipment = (date = '', type = '') => {
        this.shipment = {date: date ? new Date(date) : '', type}
    }

    setCredByOrder = (order = {}) => {
        const cred =
            this.rootStore.marketStore.getCredentialByID(order.instanceId) || {}
        cred.market = this.rootStore.marketStore.getMarketNameById(
            cred.marketplaceId
        )

        this.cred = cred
    }
    setOrderCreatedDate = (date = '') => {
        this.createdDate = date
    }

    setIsMandatoryMarK = (value = false) => {
        this.isMandatoryMarK = value
    }
    setPending = (value = false) => {
        this.pending = value
    }
    setOrderIsValid = (value = true) => {
        this.orderIsValid = value
    }
    setBuyerData = (value) => {
        this.buyerData = value
    }

    setTrackNumber = (value = '') => {
        this.trackNumber = value
    }

    setOpenOrderSplit = (value = false) => {
        this.openOrderSplit = value
    }

    setOpenTrackingNumber = (value = false) => {
        this.openTrackingNumber = value
    }

    setProducts = (products = []) => {
        this.order.products = products
    }

    mergeOrder = (order) => {
        if (order && Object.keys(order).length) {
            this.setOrderStatesList(order.stateTransitions || [])
            this.setOrderCreatedDate(new Date(order.inProcessAt))
            this.setOrderShipment(order.shipmentDate, order.shipmentType)
            this.setIsMandatoryMarK(order.mandatoryMarkIsValid)
            this.setCredByOrder(order)
            this.setBuyerData(this.collectBuyerData(order))
            this.setTrackNumber(
                (order.trackParams && order.trackParams?.trackNumber) || ''
            )
            this.setOrder(order)
        } else {
            this.setOrderIsValid(false)
            // const {location, match} = this.rootStore.routerStore
            // let routeParams = extractUrlParams(
            //     location.pathname,
            //     `${match.url}/:id`
            // )
            // GoogleTagManager.dataLayer({
            //     event: 'message_front',
            //     text: i18n.t('common.data.empty.label', { id: routeParams.id })
            // })
            // new AlertCaller({
            //     warn: true,
            //     children: i18n.t('common.data.empty.label', {
            //         id: routeParams.id
            //     })
            // })
        }
    }

    collectBuyerData = (source) => {
        return {
            buyerName: source.buyerName || '',
            phone: source.phone || '',
            logisticsName: source.logisticsName || '',
            address: source.address || '',
        }
    }

    initialOrderState = () => {
        this.setOrderIsValid()
        this.setOrderStatesList()
        this.setOrderShipment()
        this.setOrder()
    }

    getPimProductsFromOrder = async (products) => {
        const newProducts = []
        for (let product of products) {
            let pimProduct = {}
            try {
                pimProduct = await this.postProductsFromOrder(product.sku)
            } finally {
                newProducts.push({...product, externalProduct: pimProduct})
            }
        }
        this.setProducts(newProducts)
    }

    getOrder = async (orderId) => {
        this.setPending(true)
        try {
            const {
                data: {order},
            } = await OrdersService.getOrder(orderId)
            this.mergeOrder(order)
            this.getPimProductsFromOrder(order.products)
        } catch (e) {
            console.log('OrderStore: getOrder, error - ', e)
        } finally {
            this.setPending(false)
        }
    }

    postProductsFromOrder = async (vendorCode) => {
        const payload = {
            cabinet_id: this.cred.id,
            vendor_code: vendorCode,
            credentials: keysToSnake(this.cred.credentials),
            marketplace_id: this.cred.marketplaceId,
        }
        try {
            const {data} = await OrdersService.postPimProductFromOrder(payload)
            if (data.result) {
                return Promise.resolve(data)
            } else {
                return Promise.resolve({})
            }
        } catch (e) {
            console.log('OrderStore, postProductsFromOrder error: ', e)
            return Promise.resolve({})
        }
    }

    orderArbitration = async (orderId) => {
        this.setPending(true)

        try {
            const {data} = await OrdersService.postOrderArbitration(orderId, {})
            await this.getOrder(orderId)
        } catch (e) {
            console.log(e)
        } finally {
            this.setPending(false)
        }
    }

    orderActionRun = async (payload) => {
        this.setPending(true)
        try {
            const {
                data: {order},
            } = await OrdersService.postOrderRun(payload)
            this.mergeOrder(order)
        } catch (e) {
            console.log(e)
        } finally {
            this.setPending(false)
        }
    }

    orderTrackingNumber = async (payload) => {
        try {
            const {data} = await OrdersService.postOrderTrackingNumber(
                this.order.id,
                payload
            )
            await this.getOrder(this.order.id)
        } catch (e) {
            console.log(e)
        }
    }
}

export default OrderStore
