export const actionUser = {
    login: 'login',
    edit: 'edit',
    createSubscription: 'createSubscription',
    sendNotifications: 'sendNotifications',
    addDemoAccount: 'addDemoAccount',
    confirmEmail: 'confirmEmail',
    confirmPhone: 'confirmPhone',
    fileSharingAdmin: 'fileSharingAdmin',
    dataImport: 'dataImport',
    deleteCosts: 'deleteCosts',
}
