import React from 'react'
import cn from 'classnames'
import TabsBadge from './TabsBadge'

const TabItemDefault = ({tab}) => {
    return (
        <>
            {tab.icon && (
                <tab.icon
                    className={cn('-ml-0.5 mr-2 h-5 w-5')}
                    aria-hidden='true'
                />
            )}
            {tab.name}
            {tab.count && <TabsBadge count={tab.count} active={tab.active} />}
        </>
    )
}

export {TabItemDefault}
