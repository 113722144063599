import {makeObservable, observable, action} from 'mobx'
import ReviewsService from '@services/ReviewsService/ReviewsService'
import {PaginationStore} from '../../../../../store/PaginationStore'

class CardTabsReviewsStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            reviews: observable,
            pending: observable,
            mergeReviewsData: action,
            setPending: action,
        })
        this.paginationStore = new PaginationStore()
    }

    reviews = []

    pending = false

    setPending = (value = true) => {
        this.pending = value
    }

    mergeReviewsData = (sourceArr) => {
        const reviews = []
        for (let i = 0; i < sourceArr.length; i++) {
            const {
                advantages,
                disadvantages,
                comment,
                author,
                stars,
                reviewCreatedAt,
                product = {},
            } = sourceArr[i]
            const {
                id,
                productId,
                name,
                sku,
                imageThumb,
                marketplaceUrl,
                marketplaceName,
            } = product

            const review = {
                marketName: marketplaceName,
                rating: stars,
                author,
                createdAt: reviewCreatedAt,
                content: {
                    pros: advantages,
                    cons: disadvantages,
                    comment,
                },
                product: {
                    id,
                    productId: productId,
                    name,
                    sku,
                    thumbUrl: imageThumb,
                    url: marketplaceUrl,
                },
            }

            reviews.push(review)
        }
        this.reviews = reviews
    }

    changePage = (page = 1) => {
        this.requestReviews(page)
        this.paginationStore.setPagination({
            page: Number(page),
        })
    }

    requestReviews = async (page = 1) => {
        const {pagination} = this.paginationStore
        const body = {
            page: page ? page : pagination.page,
            limit: pagination.limit,
            product_id: this.rootStore.catalogCardStore.card.id,
        }

        this.setPending(true)
        try {
            const {data} = await ReviewsService.getReviews(body)

            this.mergeReviewsData(data?.reviews)
            this.paginationStore.setPagination({
                page: Number(data?.page),
                total: Number(data?.total),
                totalPages: Number(data?.totalPages),
                limit: Number(data?.limit),
            })
            this.setPending(false)
        } catch (error) {
            console.log(error)
            this.setPending(false)
        }
    }
}

export default CardTabsReviewsStore
