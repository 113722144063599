import React from 'react'
import classNames from 'classnames'

import './ButtonGroup.scss'

const ButtonGroup = (props) => {
    const {children, className} = props

    return (
        <span
            className={classNames(
                'relative z-0 inline-flex shadow-sm rounded-md',
                className,
                'button-group'
            )}
        >
            {children}
        </span>
    )
}

export {ButtonGroup}
