import React from 'react'
import {Switch} from '@headlessui/react'
import cn from 'classnames'

const Toggle = ({checked, onChange, className, togglerClassName}) => {
    return (
        <Switch
            checked={checked}
            onChange={onChange}
            className={cn(
                checked
                    ? 'bg-blue-500 dark:bg-blue-400'
                    : 'bg-gray-200 dark:bg-gray-900',
                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none',
                className
            )}
        >
            <span
                aria-hidden='true'
                className={cn(
                    checked ? 'translate-x-5' : 'translate-x-0',
                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                    togglerClassName
                )}
            />
        </Switch>
    )
}

export {Toggle}
