import React from 'react'
import {Link} from 'react-router-dom'
import {Button} from '@components/elements'
import {AppPath} from '../../../routes-enums'
import cn from 'classnames'

const NotificationPage = ({
    className,
    icon,
    title,
    description,
    link = AppPath.marketplaces,
    linkLabel,
    linkOnClick = () => {},
}) => {
    return (
        <div
            className={cn(
                className,
                'min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8'
            )}
        >
            <div className='max-w-max mx-auto'>
                <main className='sm:flex'>
                    {icon}
                    <div className='mt-4 sm:ml-6 sm:mt-0'>
                        <div className='text-center sm:text-left sm:border-l sm:border-gray-200 sm:pl-6'>
                            <h2 className='text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl dark:text-gray-100'>
                                {title}
                            </h2>
                            <p className='mt-1 text-base text-gray-500 dark:text-gray-300'>
                                {description}
                            </p>
                        </div>
                        <div className='mt-5 flex space-x-3 w-full sm:mt-10 sm:w-[fit-content] sm:border-l sm:border-transparent sm:pl-6'>
                            <Link
                                to={link}
                                className={'w-full'}
                                onClick={linkOnClick}
                            >
                                <Button className={'w-full'} color={'primary'}>
                                    {linkLabel}
                                </Button>
                            </Link>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export {NotificationPage}
