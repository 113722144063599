import {AnimatePresence, motion} from 'framer-motion'
import React from 'react'
import {useScrollLock} from '@hooks'

export const ModalOverlay = ({open, onClose, children}) => {
    useScrollLock(open)
    const overlayBg = {
        initial: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
            transition: {duration: 0.3, ease: 'easeOut'},
        },
        exit: {
            opacity: 0,
            transition: {duration: 0.2, ease: 'easeIn'},
        },
    }
    return (
        <AnimatePresence>
            {open && (
                <div className={'fixed z-50 inset-0 overflow-y-auto'}>
                    <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
                        <motion.div
                            onClick={onClose}
                            className='fixed inset-0 bg-gray-500 bg-opacity-75'
                            variants={overlayBg}
                            animate='visible'
                            initial='initial'
                            exit='exit'
                        />

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className='hidden sm:inline-block sm:align-middle sm:h-screen'
                            aria-hidden='true'
                        >
                            &#8203;
                        </span>
                        {children}
                    </div>
                </div>
            )}
        </AnimatePresence>
    )
}
