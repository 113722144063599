import {Modal, ModalHeader} from '@components/overlays'
import React from 'react'
import {ConfirmCallDialog} from './components/ConfirmCallDialog'

export const ConfirmCallModal = ({onClose, open, ...props}) => {
    return (
        <Modal className={'sm:max-w-lg'} open={open} onClose={onClose}>
            <ModalHeader
                title={
                    <h2
                        className={'text-2xl text-center font-medium space-x-2'}
                    >
                        <span>Спасибо за регистрацию в</span>
                        <strong className={'font-medium text-blue-500'}>
                            Seller24
                        </strong>
                        !
                    </h2>
                }
                onCancel={onClose}
            />
            <ConfirmCallDialog {...props} />
        </Modal>
    )
}
