//master categories store
import MasterCatsStore from '../MasterCats/store/MasterCatsStore'

//market categories stores
import {OzonCatsStore} from '../marketplaces/store/OzonCatsStore'
import {WBCatsStore} from '../marketplaces/store/WBCatsStore'

class CatsMatchingStore {
    constructor(rootStore) {
        this.rootStore = rootStore

        this.stores = {
            masterCatsStore: new MasterCatsStore(rootStore, this),
            wbCatsStore: new WBCatsStore(rootStore, this),
            ozonCatsStore: new OzonCatsStore(rootStore, this),
        }
    }
}

export default CatsMatchingStore
