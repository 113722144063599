import React from 'react'
import {BellIcon} from '@heroicons/react/outline'

export const BellButton = ({unreadCount, onClick}) => {
    return (
        <button
            type='button'
            className='relative flex items-center bg-white dark:bg-gray-800 p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none'
            onClick={onClick}
        >
            <span className='sr-only'>View notifications</span>
            <BellIcon className='h-6 w-6' aria-hidden='true' />
            {unreadCount > 0 && (
                <span
                    className={
                        'absolute rounded-full bg-red-500 z-[1] w-2 h-2 top-0 right-1'
                    }
                />
                // <Badge
                //     className={
                //         'text-[10px] px-1.5 py-px absolute -top-0.5 left-[50%]'
                //     }
                //     color={'red'}
                //     rounded
                //     bright
                // >
                //     {unreadCount >= 100 ? '99+' : unreadCount}
                // </Badge>
            )}
        </button>
    )
}
