import {action, computed, makeObservable, observable} from 'mobx'
import {debounce, toSnake} from '@utils/helpers'
import TelegramService from '@services/TelegramService/TelegramService'
import {toast} from 'react-toastify'

class TelegramStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            tgSettings: observable,
            tgLink: observable,
            isTgLoading: observable,
            tgQrCode: observable,
            codeLoading: observable,

            commonStatus: observable,
            cabinets: observable,
            tgAccount: observable,
            tgTypes: observable,
            timeSettings: observable,
            ntfctSettings: observable,
            setCommonStatus: action,
            setCabinets: action,
            setTgAccount: action,
            setTgTypes: action,
            setTimeSettings: action,
            setNtfctSettings: action,
            setTimeSet: action,
            setTimeStart: action,
            setTimeFinish: action,

            setTgLink: action,
            setTgQrCode: action,
            setCodeLoading: action,
            setTgSettings: action,
            changeMarketplaceChecked: action,
            changeIsOrdersEnabled: action,
            changeIsReviewsEnabled: action,
            changeIsWaresAboutToEmptyEnabled: action,
            changeIsWaresEmptyEnabled: action,
            changeReviewsType: action,
            changeWaresLeft: action,
            changeDaysBeforeOutOfStock: action,
            changeIsDaysBeforeOutOfStockEnabled: action,
            setIsTgLoading: action,
            isDisabled: computed,
        })
        this.debouncePutTgSettings = debounce(this.putTgSettings, 500)
    }

    tgSettings = {
        isOrdersEnabled: false,
        isReviewsEnabled: false,
        isTgEnabled: false,
        isWaresAboutToEmptyEnabled: false,
        isWaresEmptyEnabled: false,
        isDaysBeforeOutOfStockEnabled: false,
        marketplaceNotificationsState: [],
        reviewsType: 'all',
        waresLeft: 25,
        daysBeforeOutOfStock: 14,
        telegramUsername: '',
        telegramUsernames: [],
    }

    commonStatus = false
    cabinets = new Map()
    tgAccount = []
    tgTypes = []
    timeSettings = {timeSet: false, timeFinish: '19:00', timeStart: '09:00'}
    ntfctSettings = {}
    tgLink = ''

    isTgLoading = false

    tgQrCode = ''

    codeLoading = false

    setTgLink = (value = '') => {
        if (this.tgLink !== value) this.tgLink = value
    }

    setTgQrCode = (value = '') => {
        if (this.tgQrCode !== value)
            this.tgQrCode = 'data:image/png;base64,' + value
    }

    setCodeLoading = (value) => {
        this.codeLoading = value
    }

    setCommonStatus = (value = false) => {
        this.commonStatus = value
    }

    setCabinets = (id, value = false) => {
        this.cabinets.set(id, value)
    }

    setTgAccount = (value = []) => {
        this.tgAccount = value
    }

    setTgTypes = (value = []) => {
        this.tgTypes = value
    }

    setTimeSettings = (value = {}) => {
        this.tgAccount = value
    }

    setTgSettings = (timeSettings = {timeSet: false}) => {
        this.timeSettings = timeSettings
    }

    setTimeSet = (value = false) => {
        this.timeSettings.timeSet = value
    }

    setTimeStart = (value = '') => {
        this.timeSettings.timeStart = value
    }

    setTimeFinish = (value = '') => {
        this.timeSettings.timeFinish = value
    }

    setNtfctSettings = (key, value) => {
        this.ntfctSettings[key] = value
    }

    get isDisabled() {
        return !this.tgAccount.length
    }

    changeMarketplaceChecked = (mpId = 0, value) => {
        this.setCabinets(mpId, value)
        // if (!this.cabinets.has(mpId))
        //     return toast(
        //         'Произошла ошибка, попробуйте позже или обратитесь в нашу поддержку.',
        //         {
        //             type: 'error',
        //         }
        //     )

        this.patchTgNotificationMpChange(mpId)
    }

    changeCommonStatus = (value = false) => {
        this.setCommonStatus(value)
        this.patchTgCommonNotifications()
    }

    changeTimePeriod = (timeStart, timeFinish) => {
        this.setTimeStart(timeStart)
        this.setTimeFinish(timeFinish)
        this.patchTgTimeSet()
    }

    changeTimeSet = (value = false) => {
        this.setTimeSet(value)
        this.patchTgSwitchTimeSet()
    }

    changeTypes = (key, value = false) => {
        this.setNtfctSettings(key, value)
        this.patchTgNotificationSlug(key, value)
    }

    changeIsOrdersEnabled = (value = {}) => {
        this.tgSettings.isOrdersEnabled = value
        this.debouncePutTgSettings()
    }

    changeIsReviewsEnabled = (value = {}) => {
        this.tgSettings.isReviewsEnabled = value
        this.debouncePutTgSettings()
    }

    changeIsWaresAboutToEmptyEnabled = (value = {}) => {
        this.tgSettings.isWaresAboutToEmptyEnabled = value
        this.debouncePutTgSettings()
    }

    changeIsWaresEmptyEnabled = (value = {}) => {
        this.tgSettings.isWaresEmptyEnabled = value
        this.debouncePutTgSettings()
    }

    changeIsDaysBeforeOutOfStockEnabled = (value = {}) => {
        this.tgSettings.isDaysBeforeOutOfStockEnabled = value
        this.debouncePutTgSettings()
    }

    changeReviewsType = (value = {}) => {
        this.tgSettings.reviewsType = value
        this.debouncePutTgSettings()
    }

    changeWaresLeft = (value = 0) => {
        this.tgSettings.waresLeft = Number(value)
        this.debouncePutTgSettings()
    }

    changeDaysBeforeOutOfStock = (value = 0) => {
        this.tgSettings.daysBeforeOutOfStock = Number(value)
        this.debouncePutTgSettings()
    }

    setIsTgLoading = (value = false) => {
        this.isTgLoading = value
    }

    mergeCabinets = (mpAccounts) => {
        mpAccounts.forEach((account) => {
            this.cabinets.set(
                account.marketplaceAccount.id,
                account.notificationMp
            )
        })
    }

    mergeTgSettings = ({
        commonStatus,
        mpAccounts,
        timeSettings,
        ntfctSettings,
    }) => {
        this.setCommonStatus(commonStatus)
        this.mergeCabinets(mpAccounts)
        this.setTgSettings(timeSettings)
        ntfctSettings.forEach((item) => {
            for (let key in item) {
                this.setNtfctSettings(toSnake(key), item[key])
            }
        })
    }

    disconnectTgUser = async (nickname) => {
        const payload = {
            nickname,
        }
        try {
            const {data} = await TelegramService.deleteTgAccount(payload)
            this.getTgAccount()
            if (data.result) {
                toast(data.message, {
                    type: 'success',
                })
            }
        } catch (e) {
            console.log(e)
            toast(e.response.data.message, {type: 'error'})
        }
    }

    // -------Новые методы--------
    getTgUrl = async () => {
        try {
            const {data} = await TelegramService.getTgUrl()
            if (data.result) {
                this.setTgLink(data.message)
            }
        } catch (e) {
            console.log(e)
            if (e.response.status === 400) {
                return this.postTgToken()
            }
            if (e.response.status === 401) {
                toast(e.response.data.message, {type: 'error'})
            }
        }
    }

    getTgQrCode = async () => {
        this.setCodeLoading(true)
        try {
            const {data} = await TelegramService.getTgQr()
            if (data.result) {
                this.setTgQrCode(data.message)
            }
        } catch (e) {
            console.log(e)
        } finally {
            this.setCodeLoading(false)
        }
    }

    getTgAccount = async () => {
        this.setCodeLoading(true)
        try {
            const {data} = await TelegramService.getTgAccount()
            this.setTgAccount(data)
        } catch (e) {
            console.log(e)
        } finally {
            this.setCodeLoading(false)
        }
    }

    getTgSettings = async () => {
        this.setCodeLoading(true)
        try {
            const {data} = await TelegramService.getTgSettings()
            this.mergeTgSettings(data)
        } catch (e) {
            console.log(e)
        } finally {
            this.setCodeLoading(false)
        }
    }

    getTgNotificationTypes = async () => {
        try {
            const {data} = await TelegramService.getTgNotificationTypes()
            this.setTgTypes(data.results)
        } catch (e) {
            console.log(e)
        } finally {
        }
    }

    postTgToken = async () => {
        this.setCodeLoading(true)
        try {
            const {data} = await TelegramService.postTgToken()
            if (data.result) {
                this.initTelegramData()
            }
        } catch (e) {
            console.log(e)
        } finally {
            this.setCodeLoading(false)
        }
    }

    patchTgNotificationSlug = async (slug, setType) => {
        const payload = {
            set_type: setType,
        }
        try {
            await TelegramService.patchTgNotificationSlug(slug, payload)
        } catch (e) {
            console.log(e)
        } finally {
        }
    }

    patchTgCommonNotifications = async () => {
        const payload = {
            set_type: this.commonStatus,
        }
        try {
            const {data} = await TelegramService.patchTgCommonNotifications(
                payload
            )
            if (data.result) {
                toast(data.message, {
                    type: 'success',
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    patchTgNotificationMpChange = async (mpId) => {
        const payload = {
            mp_acc_id: mpId,
            set_type: this.cabinets.get(mpId),
        }
        try {
            const {data} = await TelegramService.patchTgNotificationMpChange(
                payload
            )
            if (data.result) {
                toast(data.message, {
                    type: 'success',
                })
            }
        } catch (e) {
            if (e.response.status === 400) {
                toast(e.response.data.message, {type: 'error'})
            }
            console.log(e)
        }
    }

    patchTgSwitchTimeSet = async () => {
        const payload = {
            set_type: this.timeSettings.timeSet,
        }
        try {
            const {data} = await TelegramService.patchTgSwitchTimeSet(payload)
            if (data.result) {
                toast(data.message, {
                    type: 'success',
                })
            }
        } catch (e) {
            if (e.response.status === 400) {
                toast(e.response.data.message, {type: 'error'})
            }
            console.log(e)
        }
    }

    patchTgTimeSet = async () => {
        const payload = {
            time_start: this.timeSettings.timeStart,
            time_finish: this.timeSettings.timeFinish,
        }
        try {
            const {data} = await TelegramService.patchTgTimeSet(payload)
            if (data.result) {
                toast(data.message, {
                    type: 'success',
                })
            }
        } catch (e) {
            if (e.response.status === 400) {
                toast(e.response.data.message, {type: 'error'})
            }
            console.log(e)
        }
    }

    initTelegramData = () => {
        this.getTgSettings()
        this.getTgUrl()
        this.getTgQrCode()
        this.getTgAccount()
        this.getTgNotificationTypes()
    }
}

export default TelegramStore
