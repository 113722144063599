import {useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {AccessControlService} from '@utils/access/AccessControlService'

export const useAdminPage = () => {
    const history = useHistory()
    useEffect(() => {
        if (!AccessControlService.isAdminRole()) {
            history.goBack()
        }
    }, [])
}
