import {makeObservable, observable, action} from 'mobx'
import CatalogService from '@services/CatalogService/CatalogService'

//data
import {resData} from './data'

const timeSecondsMonth = 2592000000

const monthNamesShort = [
    'Янв',
    'Фев',
    'Март',
    'Апр',
    'Май',
    'Июнь',
    'Июль',
    'Авг',
    'Сент',
    'Окт',
    'Ноя',
    'Дек',
]

const getDateLabel = (date) =>
    `${date.getDate()} ${
        monthNamesShort[date.getMonth()]
    } ${date.getFullYear()}`

class CardTabsStatsStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            stats: observable,
            dateRange: observable,
            pending: observable,
            setStats: action,
            setDateRange: action,
            setPending: action,
        })
        this.setInitialDateRange()
    }

    stats = []

    dateRange = {
        startDate: '',
        endDate: '',
    }

    pending = false

    setInitialDateRange() {
        const currentDate = new Date()
        this.setDateRange({
            startDate: new Date(currentDate - timeSecondsMonth),
            endDate: currentDate,
        })
    }

    setStats = (value = []) => {
        this.stats = value
    }

    setDateRange = (dateRange = {startDate: '', endDate: ''}) => {
        this.dateRange = dateRange
    }

    setPending = (value = true) => {
        this.pending = value
    }

    changeDateRange = (dateRange = {startDate: '', endDate: ''}) => {
        this.setDateRange(dateRange)
        this.requestCardStats()
    }

    convertChart = (chart = [], status) => {
        const categories = Object.keys(chart).map((item) => {
            return getDateLabel(new Date(item))
        })

        const data = Object.values(chart)

        if (!data.length) data.push(0)
        if (!categories.length) categories.push(getDateLabel(new Date()))

        let color = '#C3CAD5',
            colorKey = 'gray'
        if (status === 'good') {
            color = '#4CDB96'
            colorKey = 'green'
        } else if (status === 'bad') {
            color = '#EB5757'
            colorKey = 'red'
        }

        return {
            data,
            categories,
            color,
            colorKey,
        }
    }

    mergeStatsData = (stats = []) => {
        const convertedStats = stats.map((item) => {
            return {
                ...item,
                chart: this.convertChart(item.chart || [], item.status),
            }
        })
        this.setStats(convertedStats)
    }

    requestCardStats = async () => {
        const id = this.rootStore.catalogCardStore.card.id
        const body = {
            stats: {
                start: new Date(this.dateRange.startDate).toISOString(),
                end: new Date(this.dateRange.endDate).toISOString(),
            },
        }

        this.setPending(true)
        try {
            // const {data} = await CatalogService.getCardStats(id, body)
            const data = resData

            this.mergeStatsData(data?.stats)
            this.setPending(false)
        } catch (error) {
            console.log(error)
            this.setPending(false)
        }
    }
}

export default CardTabsStatsStore
