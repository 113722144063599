import React, {useEffect, useState} from 'react'
import {useLocation, useRouteMatch, useHistory} from 'react-router-dom'
import {Select} from '@components/forms/Selects'
import {Badge} from '@components/elements'

const TabsMobile = ({tabs, className, isUrl = false, onChange}) => {
    const [selected, setSelected] = useState({})

    const match = useRouteMatch()
    const {pathname, search} = useLocation()
    const history = useHistory()

    useEffect(() => {
        if (isUrl) searchActiveTabByUrl()
        else getActiveTab()
    }, [])

    const searchActiveTabByUrl = () => {
        let tab = tabs.find((tab) => `${match.url}/${tab.id}/` === pathname)
        if (!tab) tab = tabs.find((tab) => !tab.disabled)
        setSelected(tab || tabs[0])
    }

    const getActiveTab = () => {
        setSelected(tabs[0])
    }

    const getSelectedIndex = (value) => {
        const index = tabs.findIndex((item) => item.id === value.id)
        return index !== -1 ? index : 0
    }

    const handleChangeTabs = (value) => {
        setSelected(value)
        history.push({
            pathname: `${match.url}/${value.id}/`,
            search,
        })
        onChange && onChange(getSelectedIndex(value))
    }

    return (
        <Select
            className={className}
            options={tabs}
            selected={selected}
            onChange={handleChangeTabs}
        >
            {({option, selected, active}) => (
                <>
                    {option.name}
                    {option?.count ? (
                        <Badge rounded className={'ml-2'}>
                            {option.count}
                        </Badge>
                    ) : (
                        ''
                    )}
                </>
            )}
        </Select>
    )
}

export {TabsMobile}
