import {Menu} from '@headlessui/react'
import cn from 'classnames'
import React from 'react'
import {NavLink} from 'react-router-dom'
import {Button, Mdash} from '@components/elements'
import {
    MenuTelegramIcon,
    NoAvatarIcon,
    WhatsappIcon,
} from '@components/elements/Icon/Icon'
import PhoneIcon from '@heroicons/react/outline/PhoneIcon'
import FeedbackService from '@services/FeedbackService/FeedbackService'
import {getName} from '@utils/helpers'
import classNames from 'classnames'
import {toast} from 'react-toastify'
import {SUCCESS_SUPPORT_MESSAGE_SEND} from '@utils/constants'

export const ManagerCard = ({manager, user}) => {
    const requestCall = async () => {
        const payload = {
            to: 'support@seller24.ru',
            request_type: 'request_manager_call',
            title: `Пользователь запросил звонок менеджера из платформы`,
            fields: [
                {
                    title: 'phone',
                    value: user?.phoneNumber?.replace(/[ \-()]/g, ''),
                },
                {
                    title: 'email',
                    value: user?.email || '',
                },
            ],
        }
        try {
            await FeedbackService.postMessage(payload)
            toast(SUCCESS_SUPPORT_MESSAGE_SEND, {
                type: 'success',
                autoClose: 10000,
            })
        } catch (e) {
            console.log(e)
            toast('Заявка на звонок отправлена с ошибкой', {type: 'error'})
        }
    }
    const handleOpenChatTg = () => {
        if (
            window.carrotquest &&
            typeof window.carrotquest.openMessenger === 'function'
        ) {
            window.carrotquest.openMessenger()
        }
    }
    const handleOpenChatWhatsapp = () => {
        if (
            window.carrotquest &&
            typeof window.carrotquest.openMessenger === 'function'
        ) {
            window.carrotquest.openMessenger()
        }
    }

    return (
        <div
            className={cn(
                'bg-white p-4 rounded-lg shadow-lg border border-gray-100 overflow-hidden z-[9999]',
                'dark:bg-gray-700 dark:border-gray-600'
            )}
        >
            <div className={'flex flex-col space-y-4 divide-y'}>
                <div className={'flex space-x-4'}>
                    {manager.photo ? (
                        <img
                            className={'object-cover rounded h-16 w-16'}
                            src={manager.photo}
                            alt={'ава'}
                        />
                    ) : (
                        <div>
                            <NoAvatarIcon
                                className={classNames(
                                    'object-cover rounded h-16 w-16'
                                )}
                            />
                        </div>
                    )}
                    <div
                        className={
                            'flex flex-col text-gray-700 dark:text-gray-300'
                        }
                    >
                        <span
                            className={
                                'font-medium text-gray-800 dark:text-gray-200'
                            }
                        >
                            {getName(manager.firstName, manager.lastName)}
                        </span>
                        <a
                            className={
                                'text-sm font-medium cursor-pointer whitespace-pre text-blue-500 dark:text-blue-300 hover:text-blue-400 dark:hover:text-blue-200'
                            }
                            href={`mailto:${manager.email}`}
                        >
                            {manager.email}
                        </a>
                        <div className={'flex space-x-2 mt-1'}>
                            {manager.whatsappPhone && (
                                <a
                                    href={`https://chatapp.online/wa-redirect/?phone=${manager.whatsappPhone.replace(
                                        '+',
                                        ''
                                    )}`}
                                    target={'_blank'}
                                >
                                    <WhatsappIcon
                                        className={
                                            'w-4 h-4 text-gray-800 dark:text-gray-200 stroke-current  cursor-pointer dark:hover:text-green-500'
                                        }
                                    />
                                </a>
                            )}
                        </div>
                    </div>
                </div>
                <div className={'flex flex-col pt-2 space-y-2'}>
                    <div className={'flex flex-col space-y-1'}>
                        <div
                            className={
                                'flex space-x-2 font-medium items-center text-gray-800 dark:text-gray-200'
                            }
                        >
                            <PhoneIcon
                                className={
                                    'w-4 h-4 text-gray-800 dark:text-gray-200'
                                }
                            />
                            <span>{manager.workPhone || 'Отсутствует'}</span>
                        </div>
                        <div
                            className={
                                'flex space-x-2 text-xs text-gray-700 dark:text-gray-300'
                            }
                        >
                            <span>Добавочный номер:</span>
                            <span>{manager.subPhone || 'Отсутствует'}</span>
                        </div>
                    </div>
                    <Button onClick={requestCall}>Заказать звонок</Button>
                </div>
            </div>
        </div>
    )
}
