import React, {forwardRef, useRef, useMemo} from 'react'
import {nanoid} from 'nanoid'
import cn from 'classnames'
import GoogleTagManager from '@utils/googleUtils/googleTagManager'

const TextField = forwardRef(
    (
        {
            label,
            helperText,
            value,
            type = 'text',
            error,
            onChange,
            onBlur,
            onFocus,
            className,
            fieldClassName,
            inputClassName,
            inputDefaultAbsence = true,
            labelClassName,
            helperClassName,
            leftArea,
            rightArea,
            topArea,
            disabled,
            ...rest
        },
        ref
    ) => {
        const id = useRef(nanoid(5))

        useMemo(() => {
            if (error && helperText) {
                GoogleTagManager.dataLayer({
                    event: 'mainEvent',
                    eventCategory: 'Message - Front',
                    eventAction: 'message',
                    eventLabel: helperText,
                    // email: userData.email,
                    // user_id: userData.user_id,
                })
            }
        }, [error, helperText])

        return (
            <div className={className}>
                {!!(label || topArea) && (
                    <div className='flex justify-between mb-1'>
                        {!!label && (
                            <label
                                htmlFor={id.current}
                                className={cn(
                                    'block text-sm font-medium text-gray-700 dark:text-gray-300',
                                    labelClassName,
                                    {
                                        ['opacity-50']: disabled,
                                    }
                                )}
                            >
                                {label}
                            </label>
                        )}
                        {topArea}
                    </div>
                )}
                <div
                    className={cn('relative flex items-center', fieldClassName)}
                >
                    {leftArea && (
                        <div className='absolute inset-y-0 left-0 pl-3 flex items-center'>
                            {leftArea}
                        </div>
                    )}
                    <input
                        ref={ref}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        type={type}
                        disabled={disabled}
                        id={id.current}
                        className={cn(
                            inputClassName,
                            'shadow-sm disabled:opacity-50 block w-full sm:text-sm border-gray-300 rounded-lg',
                            'focus:border-blue-400 placeholder-gray-300',
                            'dark:bg-gray-700 dark:text-gray-200 dark:border-gray-600',
                            {
                                ['border-red-500 dark:border-red-300']: error,
                                ['dark:hover:bg-gray-500']: !disabled,
                                ['px-4 py-3']: inputDefaultAbsence,
                            }
                        )}
                        {...rest}
                    />
                    {rightArea && (
                        <div className='absolute inset-y-0 right-0 pr-3 flex items-center'>
                            {rightArea}
                        </div>
                    )}
                </div>
                {!!helperText && (
                    <p
                        className={cn(
                            'mt-1 text-sm',
                            {
                                ['text-red-500 dark:text-red-300']: error,
                                ['text-gray-500 dark:text-gray-400']: !error,
                            },
                            helperClassName
                        )}
                    >
                        {helperText}
                    </p>
                )}
            </div>
        )
    }
)

export {TextField}
