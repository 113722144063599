import React from 'react'
import {Modal} from './Modal'
import {Button} from '@components/elements'

import {CheckIcon} from '@heroicons/react/outline'

const ModalSuccess = (props) => {
    const {
        open,
        icon,
        onClose,
        onSuccessClose,
        title,
        description,
        closeButton = 'Закрыть',
        isModal = true,
    } = props

    const renderContent = () => {
        return (
            <div className={'flex flex-col items-center'}>
                <div>
                    <div className='mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 dark:bg-green-100/25'>
                        {icon ? (
                            icon
                        ) : (
                            <CheckIcon
                                className='h-6 w-6 text-green-600 dark:text-green-400'
                                aria-hidden='true'
                            />
                        )}
                    </div>
                    {(title || description) && (
                        <div className='mt-3 text-center sm:mt-5'>
                            {title && (
                                <h3 className='text-lg leading-6 font-medium text-gray-900 dark:text-gray-100 whitespace-pre-wrap'>
                                    {title}
                                </h3>
                            )}
                            {description && (
                                <div className='mt-2'>
                                    <p className='text-sm text-gray-600 dark:text-gray-400 whitespace-pre-wrap'>
                                        {description}
                                    </p>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <div className='mt-5 sm:mt-6'>
                    <Button onClick={onSuccessClose ? onSuccessClose : onClose}>
                        {closeButton}
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <>
            {!!isModal ? (
                <Modal
                    className={'sm:max-w-[450px]'}
                    open={open}
                    onClose={onClose}
                >
                    {renderContent()}
                </Modal>
            ) : (
                renderContent()
            )}
        </>
    )
}

export {ModalSuccess}
