import React from 'react'
import classNames from 'classnames'

export const ProgressBar = ({
    color = 'bg-blue-500 dark:bg-blue-400',
    bgColor = 'bg-gray-200 dark:bg-gray-500',
    value = 0,
    className,
}) => {
    return (
        <>
            <div
                className={classNames(
                    'w-full h-3 rounded-full overflow-hidden',
                    bgColor,
                    className
                )}
            >
                {value ? (
                    <div
                        className={classNames(
                            'h-3 rounded-full flex text-gray-300 dark:text-gray-700 shadow-md items-center justify-center',
                            color
                        )}
                        style={{width: `${value}%`}}
                    >
                        <span className={'py-0.5 text-[10px]'}>{value}%</span>
                    </div>
                ) : (
                    <div className={'h-3 text-gray-800 flex ml-2 items-center'}>
                        <span className={'py-0.5 text-[10px]'}>{value}%</span>
                    </div>
                )}
            </div>
        </>
    )
}
