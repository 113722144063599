import React, {useEffect, useState} from 'react'
import {CheckIcon} from '@heroicons/react/outline'
import {useLocation} from 'react-router-dom'
import {Loader} from '@components/elements/Loader'
import * as qs from 'qs'
import {XCircleIcon} from '@heroicons/react/solid'

export const WbOauth = () => {
    const [code, setCode] = useState('')
    const [state, setState] = useState('')
    const [error, setError] = useState(false)

    const location = useLocation()

    useEffect(() => {
        if (location.search) {
            getStateFromUrl()
        } else {
            setError(true)
        }
    }, [])

    const getStateFromUrl = () => {
        try {
            const {code, state} = qs.parse(location.search, {
                ignoreQueryPrefix: true,
            })
            setCode(code)
            setState(state)
            localStorage.setItem('wb_code', code)
            localStorage.setItem('wb_state', state)
        } catch (e) {
            setError(true)
        }
    }

    return (
        <div className={'flex flex-col w-full items-center text-green-500'}>
            {!code && !state && !error && <Loader overlayed={false} />}
            {(code || state) && error && (
                <>
                    <XCircleIcon
                        className='w-20 h-20 text-red-400 dark:text-red-300'
                        aria-hidden='true'
                    />
                    <span>{`Авторизация прошла с ошибкой. Закройте страницу и попробуйте снова`}</span>
                </>
            )}{' '}
            {code && state && !error && (
                <>
                    <CheckIcon className={'w-20 h-20'} />
                    <span>{`Аккаунт Wildberries подключен успешно`}</span>
                </>
            )}
        </div>
    )
}
