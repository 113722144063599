import {observable, action, toJS, makeObservable} from 'mobx'
import fieldsModel from '../models/billingFields'
//import {FileDownload} from '@utils/download'
import BillingService from '@services/BillingService/BillingService'
import {th} from 'react-date-range/dist/locale'
import {FileDownload} from '@utils/download/download'
import FeedbackService from '@services/FeedbackService/FeedbackService'
import {toast} from 'react-toastify'
import {keysToSnake} from '@utils/helpers'

const MAP_NAME_FIELDS = {
    billingLegalEntity: 'Форма собственности',
    billingCompanyName: 'Юридическое названине',
    billingCompanyAddress: 'Юридический адрес',
    billingInn: 'ИНН',
    billingOgrn: 'ОГРН',
    billingOgrnip: 'ОГРНИП',
    billingKpp: 'КПП',
    billingOkpo: 'ОКПО',
}

class BillStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            payload: observable,
            isPaySuccess: observable,
            requisites: observable,
            isBillingAttributesLoading: observable,
            isGenerateInvoiceLoading: observable,
            isOrderLoading: observable,
            setPayload: action,
            setRequisites: action,
            changeRequisitesValue: action,
            setIsBillingAttributesLoading: action,
            setIsGenerateInvoiceLoading: action,
            setIsOrderLoading: action,
            addedLegalEntityOption: action,
            reset: action,
            setIsPaySuccess: action,
        })
        this.setInitialStore()
    }

    setInitialStore = () => {
        this.setRequisites(fieldsModel)
    }

    payload = {}

    requisites = {}

    isBillingAttributesLoading = false
    isPaySuccess = null

    isGenerateInvoiceLoading = false

    isOrderLoading = false

    reset = () => {
        this.setPayload()
        this.setInitialStore()
        this.setIsPaySuccess()
    }

    setPayload = (value = {}) => {
        this.payload = value
    }

    setRequisites = (object = {}) => {
        this.requisites = object
    }

    changeRequisitesValue = (fieldKey, value) => {
        this.requisites[fieldKey].value = value
    }

    setIsBillingAttributesLoading = (value = false) => {
        this.isBillingAttributesLoading = value
    }

    setIsGenerateInvoiceLoading = (value = false) => {
        this.isGenerateInvoiceLoading = value
    }

    setIsOrderLoading = (value = false) => {
        this.isOrderLoading = value
    }

    setIsPaySuccess = (value = null) => {
        this.isPaySuccess = value
    }

    addedLegalEntityOption = (option) => {
        this.requisites.billingLegalEntity.options.push(option)
    }

    // collectRequisitesPayload = () => {
    //     const payload = {}
    //     Object.entries(this.requisites).forEach(([key, {value}]) => {
    //         if (key === 'billingLegalEntity') {
    //             payload[key] = value.id
    //         } else {
    //             payload[key] = value
    //         }
    //     })
    //
    //     return payload
    // }

    generateInvoiceAct = (billingFields, plan) => {
        const {months, id, price, name: planName} = plan
        const user = this.rootStore.userStore.userData
        const payload = {
            ...billingFields,
            months,
            plan_id: id,
        }

        const fields = Object.entries(payload).map(([key, value]) => {
            return {
                title: MAP_NAME_FIELDS[key] || key,
                value: value.toString(),
            }
        })

        const body = {
            to: 'b.oblomskiy@seller24.ru, s.lushin@seller24.ru, support@seller24.ru, a.gukacyan@seller24.ru, n.schegolkov@seller24.ru',
            request_type: 'request_invoice',
            title: 'Запрос на выставление счета',
            fields: [
                ...fields,
                {title: 'Количество месяцев', value: months.toString()},
                {title: 'Итоговая стоимость', value: price.toString()},
                {
                    title: 'Промокод',
                    value: 'Промокод отсутствует',
                    // value:
                    //     ref.length && ref[0].promocodeName
                    //         ? ref[0].promocodeName
                    //         : 'Промокод отсутствует',
                },
                {
                    title: 'email',
                    value: user?.email || user?.phone_number || user?.name,
                },
            ],
        }

        // const name = `${planName}_${months}_month_invoice.pdf`
        this.sendMailer(body)
    }

    getLegalEntityOption = (value) => {
        return this.requisites['billingLegalEntity'].options.find(
            (item) => item.name === value || item.id === value
        )
    }

    changeLegalEntity = (value) => {
        let option = this.getLegalEntityOption(value)
        if (!option) {
            const newOption = {
                id: value.toLowerCase(),
                name: value,
            }
            this.addedLegalEntityOption(newOption)
            option = newOption
        }
        this.changeRequisitesValue('billingLegalEntity', option)
        return option
    }

    mergeBillingAttributes = (billingAttributes) => {
        Object.entries(billingAttributes).forEach(([key, value]) => {
            if (key === 'billingLegalEntity') {
                this.changeLegalEntity(value)
            } else {
                this.changeRequisitesValue(key, value)
            }
        })
    }

    requestUserBillingAttributes = async () => {
        this.setIsBillingAttributesLoading(true)
        try {
            const {data} = await BillingService.getUserAttributes()
            const {billingAttributes} = data
            this.mergeBillingAttributes(billingAttributes)
        } catch (e) {
            console.log(e)
        } finally {
            this.setIsBillingAttributesLoading(false)
        }
    }

    sendMailer = async (payload) => {
        this.setIsGenerateInvoiceLoading(true)
        try {
            await FeedbackService.postMessage(payload)
            toast('Заявка на формирование счета отправлена.', {type: 'success'})
        } catch (e) {
            console.log(e)
            toast('Заявка на формирование счета прошел с ошибкой.', {
                type: 'error',
            })
        } finally {
            this.setIsGenerateInvoiceLoading(false)
        }
    }

    getPurchaseCallback = async (query) => {
        this.setIsOrderLoading(true)
        try {
            // const {data} = await BillingService.getPurchaseCallback(query)
            const {data} = await BillingService.getBillingCallbackTink(query)
            if (data.result) {
                this.setIsPaySuccess(true)
                await this.rootStore.authStore.sendRefreshToken()
            } else {
                this.setIsPaySuccess(false)
            }
        } catch (e) {
            console.log(e)
            this.setIsPaySuccess(false)
        } finally {
            this.setIsOrderLoading(false)
        }
    }
}

export default BillStore
