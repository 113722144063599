import {useModalContext} from '@components/overlays'
import {Button} from '@components/elements'
import {PaperAirplaneIcon} from '@heroicons/react/outline'
import {clickToUrl} from '@utils/helpers'

export const TelegramChannelDialog = () => {
    const {onClose} = useModalContext()
    const handleClick = () => {
        clickToUrl('https://t.me/hacking_marketplaces', true)
        onClose()
    }
    return (
        <div className={'flex flex-col gap-4 text-gray-800 dark:text-gray-200'}>
            <p>
                В телеграм-канале мы публикуем свежие новости маркетплейсов,
                кейсы других селлеров, обновления платформы и мемы.
            </p>
            <p>
                Подписывайтесь, чтобы быть в курсе всех новостей Ozon, WB и
                Я.Маркета, учиться на ошибках и успехах коллег, или просто
                посмеяться.
            </p>
            <div className={'flex justify-center'}>
                <Button
                    className={'flex items-center gap-3'}
                    onClick={handleClick}
                >
                    <PaperAirplaneIcon className={'w-5 h-5 rotate-90'} />
                    <span>Перейти</span>
                </Button>
            </div>
        </div>
    )
}
