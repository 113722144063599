import {Menu} from '@headlessui/react'
import cn from 'classnames'
import React from 'react'
import {NavLink} from 'react-router-dom'

const MenuItem = ({option}) => {
    const {href, label, logout, onLogout, disabled, iconClassName} = option

    const handleOnClick = () => {
        if (logout) onLogout()
    }

    return (
        <div className='py-1'>
            <Menu.Item>
                {({active}) => {
                    if (!href) {
                        return (
                            <div
                                onClick={handleOnClick}
                                className={cn(
                                    'text-gray-700 dark:text-gray-300 group flex items-center cursor-pointer px-4 py-2 text-sm group-hover:text-red-500',
                                    {
                                        'pointer-events-none text-gray-400':
                                            disabled,
                                    }
                                )}
                            >
                                {option.icon && (
                                    <option.icon
                                        className={cn(
                                            iconClassName,
                                            'mr-3 h-5 w-5 text-blue-500'
                                        )}
                                        aria-hidden='true'
                                    />
                                )}
                                {label}
                            </div>
                        )
                    }
                    return (
                        <NavLink
                            to={{
                                pathname: href,
                                //search: checkParams('godmode') ? '?godmode' : ''
                            }}
                            onClick={handleOnClick}
                            className={cn(
                                'text-gray-700 dark:text-gray-300 group cursor-pointer flex items-center px-4 py-2 text-sm group-hover:text-red-500',
                                {
                                    'pointer-events-none opacity-60': disabled,
                                }
                            )}
                            activeClassName={
                                'bg-gray-100 dark:bg-gray-500 text-gray-900 dark:text-gray-100'
                            }
                        >
                            {option.icon && (
                                <option.icon
                                    className={cn(
                                        iconClassName,
                                        'mr-3 h-5 w-5 text-blue-500'
                                    )}
                                    aria-hidden='true'
                                />
                            )}
                            {label}
                        </NavLink>
                    )
                }}
            </Menu.Item>
        </div>
    )
}

export default MenuItem
