import React from 'react'
import {Observer, observer} from 'mobx-react'
import classNames from 'classnames'
import {PencilIcon, TrashIcon} from '@heroicons/react/outline'
import {FileSharingInput} from '@components/applicationUi/FileSharing/FileSharingEdit/FileSharingInput'

export const FileSharingEdit = observer(({fileItemStore}) => {
    return (
        <div className={classNames('flex space-x-2')}>
            <span>Файл:</span>
            {fileItemStore.isEdit ? (
                <FileSharingInput fileItemStore={fileItemStore} />
            ) : (
                <>
                    <span
                        onClick={fileItemStore.getFile}
                        className={'underline cursor-pointer'}
                    >
                        {fileItemStore.filename || 'Файл'}
                    </span>
                    <div
                        onClick={fileItemStore.openEdit}
                        className={
                            'flex cursor-pointer items-center text-gray-400 hover:text-blue-400 hover:opacity-90'
                        }
                    >
                        <PencilIcon className={'w-5 h-5 '} />
                    </div>
                    {!fileItemStore.isDeleted ? (
                        <div
                            className={
                                'cursor-pointer text-gray-400 hover:text-red-500 dark:hover:text-red-400 transition-all'
                            }
                            onClick={fileItemStore.deleteFile}
                        >
                            <TrashIcon className={'w-5 h-5'} />
                        </div>
                    ) : null}
                </>
            )}
        </div>
    )
})
