import ReactPlayer from 'react-player/youtube'
import {Loader} from '@components/elements/Loader'
import React, {useMemo} from 'react'
import {Portal, RutubePlayer} from '@components/elements'
import {ModalOverlay} from '@components/overlays/modals/Modal/ModalOverlay'
import cn from 'classnames'
import {useResponsive} from '@hooks'
import {XIcon} from '@heroicons/react/outline'
import classNames from 'classnames'
import {useKeypress} from '@hooks/customHooks'

export const VideoPlayerModal = ({open, onClose, onApply}) => {
    const {sm, md, lg, xl} = useResponsive()
    useKeypress('Esc', onClose, open)
    const width = useMemo(() => {
        if (xl) return '1280px'
        if (lg) return '854px'
        if (md) return '640px'
        if (sm) return '426px'
        return '100%'
    }, [lg, xl, md, sm])
    const height = useMemo(() => {
        if (xl) return '720px'
        if (lg) return '480px'
        if (md) return '360px'
        if (sm) return '240px'
        return '100%'
    }, [lg, xl, md, sm])

    return (
        <Portal>
            <ModalOverlay open={open} onClose={onClose}>
                <div
                    className={cn(
                        `w-[${width}] h-[${height}]`,
                        'align-bottom text-left transform sm:my-8 sm:align-middle',
                        'inline-flex items-center justify-center'
                    )}
                >
                    <RutubePlayer
                        src={
                            'https://rutube.ru/video/32f900a1ab8d473af024cbe37a8d779a/'
                        }
                        width={width}
                        height={height}
                        autoplay
                    />
                    <XIcon
                        onClick={onClose}
                        className={classNames(
                            'absolute right-0 text-white cursor-pointer hover:text-blue-500 dark:hover:text-blue-500',
                            {
                                'w-8 h-8 -top-8': sm,
                                'w-6 h-6 -top-6': !sm,
                            }
                        )}
                    />
                </div>
            </ModalOverlay>
        </Portal>
    )
}
