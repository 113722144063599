import {action, makeObservable, observable, reaction} from 'mobx'
import OnboardingService from '@services/OnboardingService/OnboardingService'
import GoogleTagManager from '@utils/googleUtils/googleTagManager'
import {convertToTitleCase} from '@utils/helpers'
import {ACTION_MAP} from '@views/marketplaces/pages/Marketplaces/components/Audit/constants'
import {ModalControl} from '../../../../../../../store/ModalControl'

export class AuditStore {
    constructor(authStore, userData, isOpen) {
        this.authStore = authStore
        this.userData = userData
        makeObservable(this, {
            currentAction: observable,
            auditType: observable,
            dontSkip: observable,

            setCurrentAction: action,
            setDontSkip: action,
            setAuditType: action,
        })
        this.modalControl = new ModalControl(isOpen)
        reaction(
            () => this.auditType,
            (auditType, prev) => {
                if (!auditType || auditType === prev) return
                this.postNeedAudit()
            }
        )
    }

    currentAction = ACTION_MAP.start
    auditType = ''
    dontSkip = false

    setCurrentAction = (value = ACTION_MAP.start) => {
        this.currentAction = value
    }

    setAuditType = (value = '') => {
        this.auditType = value
    }

    setDontSkip = (value = false) => {
        this.dontSkip = value
    }

    postNeedAudit = async (auditType) => {
        const payload = {
            need_audit: auditType || this.auditType,
        }
        try {
            const {data} = await OnboardingService.postNeedAudit(payload)
            GoogleTagManager.dataLayer({
                event: 'mainEvent',
                eventCategory: 'PopUpAudit',
                eventAction: 'Choose',
                eventLabel: convertToTitleCase(this.auditType),
                email: this.userData.email,
                user_id: this.userData.user_id,
            })
            await this.authStore.sendRefreshToken()
        } catch (e) {
            console.log(e)
        }
    }

    destroy = () => {
        this.currentAction = ACTION_MAP.start
        this.auditType = ''
        this.dontSkip = false
    }
}
