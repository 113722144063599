import {makeAutoObservable} from 'mobx'

export class LoaderStore {
    constructor(isLoading) {
        makeAutoObservable(this)
        this.loading = isLoading
    }

    loading = false

    setLoading = (value = false) => {
        this.loading = value
    }

    start = () => {
        this.loading = true
    }

    stop = () => {
        this.loading = false
    }
}
