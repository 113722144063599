import {observer} from 'mobx-react'
import {Modal} from '@components/overlays'
import {useEffect} from 'react'
import {useAuditContext} from './context/AuditContext'
import GoogleTagManager from '@utils/googleUtils/googleTagManager'
import {AuditModal} from './AuditModal'

export const Audit = observer(({isOpen, onClose}) => {
    const {auditStore} = useAuditContext()
    useEffect(() => {
        auditStore.modalControl.setIsOpen(isOpen)
        GoogleTagManager.dataLayer({
            event: 'mainEvent',
            eventCategory: 'PopUpAudit',
            eventAction: 'Open',
            eventLabel: 'Form',
            email: auditStore.userData.email,
            user_id: auditStore.userData.user_id,
        })
        return () => {
            auditStore.destroy()
        }
    }, [])

    const handleClose = () => {
        auditStore.modalControl.close()
        if (!auditStore.dontSkip) {
            auditStore.setAuditType('skip')
        }
        onClose && onClose()
    }

    return (
        <>
            <Modal
                className={'sm:max-w-lg'}
                open={auditStore.modalControl.isOpen}
                onClose={handleClose}
            >
                <AuditModal onClose={handleClose} store={auditStore} />
            </Modal>
        </>
    )
})

Audit.displayName = 'Audit'
