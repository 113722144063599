import React, {useEffect, useState} from 'react'
import {SearchIcon} from '@heroicons/react/outline'
import {useDebouncedFunction} from '@hooks'

export const SelectSearch = ({value, onChange}) => {
    const [isStop, setIsStop] = useState(false)

    const [currentValue, setCurrentValue] = useState(value)
    useEffect(() => {
        return () => {
            setIsStop(true)
        }
    }, [])
    const setValueDebounce = (e) => {
        onChange(e)
    }

    const setValuePending = useDebouncedFunction(setValueDebounce, 500, isStop)

    const handleChangeValue = (e) => {
        setValuePending(e)
        setCurrentValue(e.target.value)
    }
    return (
        <div className={'sticky top-0 z-[100] bg-white dark:bg-gray-700'}>
            <div className={'relative inset-0 mb-1 '}>
                <SearchIcon
                    className={
                        'w-5 h-5 ml-4 absolute left-0 top-0 bottom-0 m-auto text-gray-500 dark:text-gray-400'
                    }
                />
                <input
                    className={
                        'w-full py-2 pl-12 border-b border-gray-300 dark:text-gray-200  dark:border-gray-600 outline-none focus:outline-none bg-transparent'
                    }
                    placeholder={'Поиск...'}
                    value={currentValue}
                    onChange={handleChangeValue}
                />
            </div>
        </div>
    )
}
