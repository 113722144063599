//libs
import {makeObservable, observable, action} from 'mobx'

//services
import AttrsMatchingService from '@services/AttrsMatchingService/AttrsMatchingService'

//constants
const initialCat = {
    id: 0,
    name: 'Выберите категорию',
}

const initialAttr = {
    id: 0,
    name: 'Выберите свойство',
}

const initialPagination = {
    total: 0,
    page: 1,
}

class MarketAttrsStore {
    constructor(rootStore, parentStore) {
        this.rootStore = rootStore
        this.parentStore = parentStore

        makeObservable(this, {
            edit: observable,
            links: observable,
            marketplaces: observable,
            setEdit: action,
            setLinks: action,
            setMarketplaces: action,
            setMarketCats: action,
            pushMarketCats: action,
            spliceMarketCats: action,
            setMarketAttrOptions: action,
            setMarketAttrDictOptions: action,
            handleCheckMarketAttrDictItem: action,
            isMarketAttrDictItemChecked: action,
            clearCheckedMarketAttrDictItems: action,
            clearAllCheckedMarketAttrDictItems: action,
        })
    }

    edit = false

    links = []

    marketplaces = []

    setEdit = (value = true) => {
        this.edit = value
    }

    setLinks = (array = []) => {
        this.links = array
    }

    onMarketEdit = (item) => {
        this.parentStore.stores.masterAttrsStore.onMasterEdit(
            item?.masterItemId,
            'market'
        )
    }

    setMarketplaces = (array = []) => {
        this.marketplaces = array
    }

    mergeMarketplacesData = (data) => {
        return data.marketplaces.map((market) => {
            return Object.assign({}, market, {
                marketCats: [
                    {options: market.topLevelCategories, selected: initialCat},
                ],
                marketAttr: {
                    options: [],
                    selected: initialAttr,
                },
                marketAttrDict: {
                    options: [],
                    checked: [],
                    searchString: '',
                    pagination: initialPagination,
                },
            })
        })
    }

    findMarketIndex = (marketId) => {
        return this.marketplaces.findIndex(
            (market) => Number(marketId) === Number(market.id)
        )
    }

    setMarketCats = (marketId, array = []) => {
        const index = this.findMarketIndex(marketId)
        this.marketplaces[index] = array
    }

    pushMarketCats = (marketId, obj) => {
        const index = this.findMarketIndex(marketId)
        this.marketplaces[index].marketCats.push(obj)
    }

    spliceMarketCats = (marketId, catIndex) => {
        const index = this.findMarketIndex(marketId)
        this.marketplaces[index].marketCats.splice(
            catIndex,
            this.marketplaces[index].marketCats.length - catIndex
        )
    }

    onMarketCatChange = (marketId, catIndex, child) => {
        const index = this.findMarketIndex(marketId)
        this.spliceMarketCats(marketId, catIndex + 1)
        this.marketplaces[index].marketCats[catIndex].selected = child
        this.getMarketplaceCats(marketId, child.id)
    }

    setMarketAttrOptions = (marketId, array = []) => {
        const index = this.findMarketIndex(marketId)
        this.marketplaces[index].marketAttr.options = array
    }

    changeMarketAttr = (marketId, value) => {
        const index = this.findMarketIndex(marketId)
        this.marketplaces[index].marketAttr.selected = value
    }

    handleChangeMarketAttr = (marketId, value) => {
        this.changeMarketAttr(marketId, value)
        if (value.id !== 0) {
            this.getMarketAttrDict(marketId)
        }
    }

    setMarketAttrDictOptions = (marketId, array = []) => {
        const index = this.findMarketIndex(marketId)
        this.marketplaces[index].marketAttrDict.options = array
    }

    handleCheckMarketAttrDictItem = (marketId, item, checked) => {
        if (this.edit) {
            const linksMarketIndex = this.links.findIndex(
                (market) => Number(market.marketplaceId) === Number(marketId)
            )
            const linkIndex = this.links[linksMarketIndex]?.linked?.findIndex(
                (linkedItem) => Number(linkedItem.id) === item.id
            )

            if (linkIndex >= 0) {
                const isDelete = Boolean(
                    this.links[linksMarketIndex].linked[linkIndex].delete
                )
                this.links[linksMarketIndex].linked[linkIndex].delete =
                    !isDelete
                return
            }
        }

        const marketIndex = this.findMarketIndex(marketId)

        if (
            !this.marketplaces[marketIndex].marketAttrDict.checked.includes(
                item
            )
        ) {
            if (checked) {
                this.marketplaces[marketIndex].marketAttrDict.checked.push(item)
            }
        } else {
            if (!checked) {
                const index = this.marketplaces[
                    marketIndex
                ].marketAttrDict.checked.findIndex(
                    (arrItem) => arrItem === item
                )
                this.marketplaces[marketIndex].marketAttrDict.checked.splice(
                    index,
                    1
                )
            }
        }
    }

    isMarketAttrDictItemChecked = (marketId, item) => {
        const marketIndex = this.findMarketIndex(marketId)
        if (this.edit) {
            const marketLinks = this.links.find(
                (market) => Number(market.marketplaceId) === Number(marketId)
            )?.linked
            const link = marketLinks?.find(
                (linkedItem) => Number(linkedItem.id) === item.id
            )

            return (
                this.marketplaces[marketIndex].marketAttrDict.checked.includes(
                    item
                ) ||
                (link && !link?.delete)
            )
        }
        return (
            this.marketplaces[marketIndex].marketAttrDict.checked.includes(
                item
            ) || item?.isLinked
        )
    }

    isMarketAttrDictItemDisabled = (marketId, item) => {
        if (this.edit) {
            const marketLinks = this.links.find(
                (market) => Number(market.marketplaceId) === Number(marketId)
            )?.linked
            const link = marketLinks?.find(
                (linkedItem) => Number(linkedItem.id) === item.id
            )

            return item?.isLinked && !link
        }
        return item?.isLinked
    }

    clearCheckedMarketAttrDictItems = (marketId) => {
        const index = this.findMarketIndex(marketId)
        this.marketplaces[index].marketAttrDict.checked = []
    }

    clearAllCheckedMarketAttrDictItems = () => {
        for (let i = 0; i < this.marketplaces.length; i++) {
            this.marketplaces[i].marketAttrDict.checked = []
        }
    }

    handleMarketAttrDictSearch = (marketId, e) => {
        const index = this.findMarketIndex(marketId)
        this.marketplaces[index].marketAttrDict.searchString = e.target.value
        this.getMarketAttrDict(marketId, e.target.value)
    }

    onClickMatchedAttr = (marketId, attrId) => {
        const marketIndex = this.findMarketIndex(marketId)
        const selectedOption = this.marketplaces[
            marketIndex
        ].marketAttr.options.find(
            (option) => Number(option.id) === Number(attrId)
        )
        if (selectedOption)
            this.handleChangeMarketAttr(marketId, selectedOption)
    }

    getMarketplaces = async () => {
        try {
            const {data} = await AttrsMatchingService.getMarketplaces()
            this.setMarketplaces(this.mergeMarketplacesData(data))
        } catch (error) {
            console.log(error)
        }
    }

    getMarketplaceCats = async (marketId, catId) => {
        this.clearCheckedMarketAttrDictItems(marketId)

        try {
            const {
                data: {id, parentId, childs, attrs},
            } = await AttrsMatchingService.getMarketplaceCats(marketId, catId)
            this.pushMarketCats(marketId, {
                options: childs,
                selected: initialCat,
                id,
                parentId,
            })
            this.setMarketAttrDictOptions(marketId, [])
            if (attrs?.length) {
                this.setMarketAttrOptions(marketId, attrs)
            } else {
                this.setMarketAttrOptions(marketId, [])
            }
        } catch (error) {
            console.log(error)
        }
    }

    getCatId = (index) => {
        const lastOption =
            this.marketplaces[index].marketCats[
                this.marketplaces[index].marketCats.length - 1
            ]
        return lastOption.id ? lastOption.id : lastOption.selected.id
    }

    getMarketAttrDict = async (marketId, search = '') => {
        this.clearCheckedMarketAttrDictItems(marketId)

        const index = this.findMarketIndex(marketId)
        const attrId = this.marketplaces[index].marketAttr.selected.id
        let catId = this.getCatId(index)

        const query = search
            ? {
                  filterByName: search,
              }
            : undefined

        try {
            const {
                data: {items},
            } = await AttrsMatchingService.getMarketAttrDict(
                marketId,
                catId,
                attrId,
                query
            )
            this.setMarketAttrDictOptions(marketId, items)
        } catch (error) {
            console.log(error)
        }
    }

    postSequentialMarketItemsMatching = async () => {
        if (this.edit) {
            const itemsId = []

            this.links.forEach((market) => {
                market.linked.forEach((item) => {
                    itemsId.push(item.id)
                })
            })

            const deleteQuery = {
                master_item_id:
                    this.parentStore.stores.masterAttrsStore.editableItemId,
                dictionary_items_id: itemsId,
            }
            try {
                await AttrsMatchingService.putModifyLinks(deleteQuery)
            } catch (error) {
                return Promise.reject(error)
            }
        }

        const body = {}

        if (this.parentStore.stores.masterAttrsStore.masterAttr.selected?.id)
            Object.assign(body, {
                master_attr:
                    this.parentStore.stores.masterAttrsStore.masterAttr.selected
                        .id,
            })
        if (
            this.parentStore.stores.masterAttrsStore.masterAttrDict.selected?.id
        )
            Object.assign(body, {
                master_item:
                    this.parentStore.stores.masterAttrsStore.masterAttrDict
                        .selected.id,
            })

        const getMarketAttrItemsCheckedId = (market) => {
            return market.marketAttrDict.checked.map((item) => item?.id)
        }

        const postMarketItemsMatching = async (market) => {
            const query = Object.assign({}, body, {
                marketplace_id: market.id,
                linker_attr_id: market.marketAttr.selected.id,
                linked_items_id: getMarketAttrItemsCheckedId(market),
            })

            try {
                await AttrsMatchingService.postAttrsMatching(query)
                await this.getMarketAttrDict(market.id)
                await this.parentStore.stores.masterAttrsStore.getMasterAttrDict()
                this.clearCheckedMarketAttrDictItems(market.id)
            } catch (error) {
                console.log(error)
            }
        }

        for (const market of this.marketplaces) {
            if (market.marketAttr.selected?.id) {
                await postMarketItemsMatching(market)
            }
        }

        this.setEdit(false)
        this.setLinks([])
        this.parentStore.stores.masterAttrsStore.setEdit(false)
    }
}

export default MarketAttrsStore
