import React from 'react'
import {observer} from 'mobx-react'
import {OnboardingWrapper} from '@components/navigation/header/Onboarding/components/OnboardingWrapper'
import {ONBOARD_STEP} from '@utils/constants'
import {useSelector} from '@hooks'
import {useAuditContext} from '@views/marketplaces/pages/Marketplaces/components/Audit/context/AuditContext'
import {ACTION_MAP} from '@views/marketplaces/pages/Marketplaces/components/Audit/constants'

export const EndOnboard = observer(({onboardingStepStore}) => {
    const layoutSidebarStore = useSelector((store) => store.layoutSidebarStore)
    const {auditStore} = useAuditContext()

    const handleClickEnroll = () => {
        layoutSidebarStore.setIsOpenOnboarding(true)
        auditStore.setDontSkip(true)
        auditStore.setCurrentAction(ACTION_MAP.enroll)
    }
    const handleClickNextStep = async () => {
        try {
            await onboardingStepStore.putOnboardSetStep(
                ONBOARD_STEP.finalClosed,
                true
            )
            window.carrotquest?.track('onboarding_final_closed')
        } catch (e) {
            console.log(e)
        }
    }
    const handleClickAudit = async () => {
        await handleClickNextStep()
        handleClickEnroll()
    }
    return (
        <OnboardingWrapper.BarContainer className={'justify-between space-x-4'}>
            <OnboardingWrapper.BarContainer
                className={'flex-col space-y-2 w-full'}
            >
                <OnboardingWrapper.Label className={'text-blue-500 text-sm'}>
                    <span
                        className={
                            'font-medium text-gray-800 dark:text-gray-200'
                        }
                    >
                        Бесплатный аудит магазина для новых пользователей
                    </span>
                </OnboardingWrapper.Label>
                <OnboardingWrapper.Progress value={100} />
            </OnboardingWrapper.BarContainer>
            <div className={'flex items-end'}>
                <OnboardingWrapper.ButtonAction
                    size={'md'}
                    onClick={handleClickAudit}
                >
                    Записаться
                </OnboardingWrapper.ButtonAction>
            </div>
            <div className={'flex items-end'}>
                <OnboardingWrapper.ButtonAction
                    size={'md'}
                    onClick={handleClickNextStep}
                    color={'white'}
                >
                    Позже
                </OnboardingWrapper.ButtonAction>
            </div>
        </OnboardingWrapper.BarContainer>
    )
})

EndOnboard.displayName = 'EndOnboard'
