import {format} from 'date-fns'
import {ru} from 'date-fns/locale'

export const NoLogisticsTooltipContent = ({
    lastLogisticDate,
    expectLogisticDate,
}) => {
    const getFormatDate = (date) => {
        return format(new Date(date), `dd.MM.yyyy`, {
            locale: ru,
        })
    }

    return (
        <div>{`Данные по логистике от Wildberries доступны до ${getFormatDate(
            lastLogisticDate
        )}. Данные за прошедшую неделю ожидаются в системе во вторник ${getFormatDate(
            expectLogisticDate
        )}. Для более точного расчета маржинальной прибыли с учетом логистики, выберите более ранний период`}</div>
    )
}
