import {makeObservable, observable, action} from 'mobx'
import ProductsService from '@services/ProductsService/ProductsService'
import CatalogService from '@services/CatalogService/CatalogService'
import qs from 'qs'
import {PaginationStore} from '../../../../store/PaginationStore'

export class ProductsListStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            products: observable,
            pending: observable,
            selectedProducts: observable,
            productsPollingInterval: observable,
            lastExport: observable,
            setProducts: action,
            setPending: action,
            setLastExport: action,
            setSelectedProducts: action,
            startProductsPolling: action,
            stopProductsPolling: action,

            //filters
            params: observable,
            filterOpen: observable,
            filterLoader: observable,
            isTested: observable,
            testFilterResults: observable,
            appliedFilter: observable,
            selectedInstance: observable,
            search: observable,
            setFilterOpen: action,
            setIsTested: action,
            setFilterLoading: action,
            setTestFilterResults: action,
            setApplyFilter: action,
            resetApplyFilter: action,
            updateParams: action,
            setClientStock: action,
            setMarketplaceStock: action,
            setProductStatus: action,
            setSelectedInstance: action,
            setSearch: action,
        })
        this.params = this.parseInitialParams()
        this.paginationStore = new PaginationStore()
    }

    products = []

    pending = false

    selectedProducts = []

    productsPollingInterval = undefined

    lastExport = {
        error: 0,
        exported: 0,
        pending: 0,
    }

    //=======FILTERS======
    params = {}
    selectedInstance = []
    search = ''
    filterOpen = false
    filterLoader = false
    isTested = false
    testFilterResults = 0
    appliedFilter = {}
    clientStockOptions = {
        // fbs
        options: [
            {
                name: 'Любые остатки на вашем складе',
                key: 'client_stock_all',
            },
            {
                name: 'Нет остатков',
                key: 'client_stock_zero',
            },
            {
                name: 'Есть остатки',
                key: 'client_stock_not_zero',
            },
        ],
        selected: {},
    }
    marketplaceStockOptions = {
        // fbo
        options: [
            {
                name: 'Любые остатки на складе площадки',
                key: 'marketplace_stock_all',
            },
            {
                name: 'Нет остатков',
                key: 'marketplace_stock_zero',
            },
            {
                name: 'Есть остатки',
                key: 'marketplace_stock_not_zero',
            },
        ],
        selected: {},
    }
    productStatusOptions = {
        options: [
            {
                name: 'Все статусы',
                key: 'all_states',
            },
            {
                name: 'Опубликован',
                key: 'published',
            },
            {
                name: 'Не опубликован',
                key: 'unpublished',
            },
        ],
        selected: {},
    }
    parseInitialParams = () => {
        const search = window.location.search
        const parsed = qs.parse(search, {ignoreQueryPrefix: true})

        return {
            page: parsed.page ? Number(parsed.page) : 1,
            limit: parsed.limit ? Number(parsed.limit) : 10,
            // price_greater_than: parsed.price_greater_than || null,
            // price_less_than: parsed.price_less_than || null,
            cabinet: parsed.cabinet || null,
            search: parsed.search || null,
            stocks_fbo: parsed.stocks_fbo || null,
            product_status: parsed.product_status || null,
            stocks_fbs: parsed.stocks_fbs || null,
        }
    }

    getNewParams = (
        {
            page,
            limit,
            // price_less_than,
            // price_greater_than,
            cabinet,
            search,
            stocks_fbo,
            stocks_fbs,
            product_status,
        } = {},
        params
    ) => {
        return {
            page: page || 1,
            limit: limit || params.limit,
            // price_less_than:
            //     price_less_than !== undefined
            //         ? price_less_than
            //         : params.price_less_than,
            // price_greater_than:
            //     price_greater_than !== undefined
            //         ? price_greater_than
            //         : params.price_greater_than,
            cabinet: cabinet !== undefined ? cabinet : params.cabinet,
            search: search !== undefined ? search : params.search,
            stocks_fbo:
                stocks_fbo !== undefined ? stocks_fbo : params.stocks_fbo,
            product_status:
                product_status !== undefined
                    ? product_status
                    : params.product_status,
            stocks_fbs:
                stocks_fbs !== undefined ? stocks_fbs : params.stocks_fbs,
        }
    }
    updateParams = (data = {}) => {
        this.params = this.getNewParams(data, this.params)
    }
    setSelectedInstance = (array = []) => {
        this.selectedInstance = array
    }
    setMarketplaceStock = (value = {}) => {
        this.marketplaceStockOptions.selected = value
    }

    setProductStatus = (value = {}) => {
        this.productStatusOptions.selected = value
    }
    setClientStock = (value = {}) => {
        this.clientStockOptions.selected = value
    }
    setFilterOpen = (value = false) => {
        this.filterOpen = value
    }
    setSearch = (value = '') => {
        this.search = value
    }
    setIsTested = (value = false) => {
        this.isTested = value
    }

    setFilterLoading = (value = false) => {
        this.filterLoader = value
    }

    setTestFilterResults = (value: number = 0) => {
        this.testFilterResults = value
    }

    setApplyFilter = (obj) => {
        this.appliedFilter = Object.assign({}, this.appliedFilter, obj)
    }
    applyFilter = () => {
        this.updateParams(this.appliedFilter)
    }
    resetApplyFilter = () => {
        this.appliedFilter = {}
    }
    resetFilter = () => {
        this.updateParams({
            // price_less_than: null,
            // price_greater_than: null,
            cabinet: null,
            search: null,
            stocks_fbo: null,
            product_status: null,
            stocks_fbs: null,
        })
        this.setSelectedInstance()
        this.setSearch()
        this.setClientStock()
        this.setMarketplaceStock()
        this.setProductStatus()
        this.resetApplyFilter()
        this.setIsTested(false)
    }
    testFilter = async () => {
        this.setIsTested(true)
        this.setFilterLoading(true)
        const filters = this.collectProductsPayload(this.appliedFilter)
        const body = {
            //...this.params,
            ...filters,
            prefetch: true,
        }
        try {
            const {data} = await ProductsService.getProducts(body)
            this.setTestFilterResults(data.total)
        } catch (e) {
            console.log(e)
        } finally {
            this.setFilterLoading(false)
        }
    }
    //=======FILTERS======

    setProducts = (array = []) => {
        this.products = array
    }

    setPending = (value = true) => {
        this.pending = value
    }

    setSelectedProducts = (array = []) => {
        this.selectedProducts = array
    }

    setLastExport = (object) => {
        this.lastExport = object
    }

    mergeProductsData = (sourceArr) => {
        const products = sourceArr.map((item, index) => {
            return {
                id: item?.id,
                name: item?.name,
                sku: item?.code,
                photo: item?.picture,
                price: {
                    minPrice: item?.MinPrice,
                    maxPrice: item?.MaxPrice,
                    prices: item?.PopUpPrices,
                },
                leftovers: {
                    totalLeftover: item?.totalLeftover,
                    leftovers: item?.leftovers,
                },
                status: item?.status,
            }
        })
        this.setProducts(products)
    }

    mergeLastExportData = (source) => {
        if (source) this.setLastExport(source)
    }

    collectProductsPayload = (params) => {
        const payload = {}
        Object.entries(params).forEach(([key, value]) => {
            if (value !== null) {
                payload[key] = value
            }
        })
        return payload
    }

    requestProducts = async (params, withLoader = true) => {
        this.updateParams(params)
        if (withLoader) this.setPending(true)
        const payload = this.collectProductsPayload(this.params)
        try {
            const {data} = await ProductsService.getProducts(payload)
            this.mergeProductsData(data?.data)
            this.paginationStore.setPagination({
                page: data?.page,
                limit: data?.limit,
                total: data?.total,
                totalPages: data?.totalPages,
            })
            this.mergeLastExportData(data.lastExport)
            this.setPending(false)
        } catch (error) {
            console.log(error)
            this.setPending(false)
        }
    }

    startProductsPolling = (query) => {
        this.productsPollingInterval = setInterval(() => {
            this.requestProducts(query, false)
        }, 5 * 60 * 1000)
    }

    stopProductsPolling = () => {
        if (this.productsPollingInterval)
            this.productsPollingInterval = clearInterval(
                this.productsPollingInterval
            )
    }

    putExportProducts = async (query) => {
        try {
            return await ProductsService.putExportProducts(query)
        } catch (error) {
            console.log(error)
        }
    }

    exportProductsSelected = async (payload) => {
        const {pagination} = this.paginationStore
        try {
            const {data} = await this.putExportProducts(payload)
            await this.requestProducts({page: this.pagination.page}, false)
            this.stopProductsPolling()
            this.startProductsPolling({page: pagination.page})
            return Promise.resolve({data})
        } catch (error) {
            console.log(error)
        }
    }
}
