import {useMemo} from 'react'

export const useRateStatus = (rate) => {
    return useMemo(() => {
        let status = 'still'
        const percent =
            typeof rate === 'string'
                ? Number(rate.replace('%', '').replace(',', '.'))
                : rate
        if (percent > 0) {
            status = 'positive'
        } else if (percent < 0) {
            status = 'negative'
        }
        return {percent, status}
    }, [rate])
}
