import {ApiFactory} from '@services/ApiFactory'
import {baseConfig} from '@services/apiConfigs'
import {WarehousesForecastsUrl} from '../../routes-enums'

const warehousesInstance = ApiFactory.createApi(baseConfig)

class WarehousesService {
    static getWarehouses = async (payload) => {
        return await warehousesInstance.get(
            WarehousesForecastsUrl.warehouses,
            payload
        )
    }

    static postWarehousesParse = async (payload) => {
        return await warehousesInstance.post(
            WarehousesForecastsUrl.warehousesParse,
            payload
        )
    }

    static postUpdateWarehouses = async (payload) => {
        return await warehousesInstance.post(
            WarehousesForecastsUrl.warehouse,
            payload
        )
    }

    static getWarehouse = async (payload) => {
        return await warehousesInstance.get(
            WarehousesForecastsUrl.warehouse,
            payload
        )
    }
}

export {WarehousesService}
