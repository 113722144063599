import {action, makeObservable, observable} from 'mobx'

import CatsMatchingService from '@services/CatsMatchingService'
import {de, tr} from 'date-fns/locale'
import {PaginationStore} from '../../../../../store/PaginationStore'

//constants
const initialCat = {
    id: 0,
    name: 'Выберите категорию',
}

class AbstractCatsStore {
    constructor(rootStore, parentStore) {
        this.rootStore = rootStore
        this.parentStore = parentStore

        makeObservable(this, {
            marketId: observable,
            categoriesCount: observable,
            marketName: observable,
            catsTree: observable,
            setMarketId: action,
            setCategoriesCount: action,
            setCatsTree: action,
            spliceCatsTree: action,
            setMarketName: action,
            pushCatsTree: action,
            onCatChange: action,
            setCatsLoading: action,
            setCatsPagination: action,
            setCatsOptions: action,
        })
    }

    marketId = undefined
    categoriesCount = undefined
    catsTree = []
    marketName = ''

    setMarketId = (id) => {
        this.marketId = id
    }
    setCategoriesCount = (id) => {
        this.categoriesCount = id
    }

    setCatsTree = (array = []) => {
        this.catsTree = array
    }

    spliceCatsTree = (index) => {
        this.catsTree.splice(index, this.catsTree.length - index)
    }

    pushCatsTree = (object) => {
        this.catsTree.push(object)
    }

    setCatsLoading = (index, loading) => {
        this.catsTree[index].loading = loading
    }

    setCatsPagination = (index, pagination) => {
        this.catsTree[index].paginationStore.setPagination(pagination)
    }

    setCatsOptions = (index, options) => {
        this.catsTree[index].options = options
    }

    setMarketName = (value = '') => {
        this.marketName = value
    }

    onCatChange = async (index, option) => {
        this.spliceCatsTree(index + 1)
        this.catsTree[index].selected = option
        const payload = {
            limit: 10,
            page: 1,
            value: '',
        }
        const data = await this.getMarketCatId(payload, option?.id)
        this.pushCatsTree({
            paginationStore: new PaginationStore(),
            loading: false,
            options: data?.childs,
            selected: initialCat,
            parentId: option?.id,
        })
    }

    onCatSearch = async (index, option, value, page) => {
        const payload = {
            limit: page ? page * 10 : 10,
            page: 1,
            value: value,
        }
        this.setCatsLoading(index, true)
        let data = {}
        try {
            if (option.parentId) {
                data = await this.getMarketCatId(payload, option.parentId)
            } else {
                data = await this.getMarketTopCats(payload)
            }
            const {limit, page, total, totalPages} = data
            this.setCatsPagination(index, {
                limit,
                page,
                total,
                totalPages,
            })
            this.setCatsOptions(index, data?.childs)
        } catch (e) {
            console.log(e)
        } finally {
            this.setCatsLoading(index, false)
        }
    }

    getCatsData = async () => {
        const payload = {
            limit: 10,
            page: 1,
            value: '',
        }
        this.setCatsTree([])
        try {
            const data = await this.getMarketTopCats(payload)
            this.pushCatsTree({
                options: data?.childs,
                selected: initialCat,
                paginationStore: new PaginationStore(),
                loading: false,
                parentId: null,
            })
        } catch (e) {
            console.log(e)
        } finally {
        }
    }

    getMarketplaces = async (marketName) => {
        this.setCatsTree([])
        const payload = {
            limit: 10,
            page: 1,
            value: '',
        }
        try {
            const {data} = await CatsMatchingService.postMarketplaces(payload)
            const mp = data?.marketplaces?.find(
                (market) => String(market?.name).toLowerCase() === marketName
            )
            this.setMarketName(marketName)
            if (mp?.id) {
                this.setMarketId(mp.id)
                this.setCategoriesCount(mp.categoriesCount)
            }
            await this.getCatsData()
        } catch (error) {
            console.log(error)
        }
    }

    getMarketTopCats = async (payload) => {
        try {
            const {data} = await CatsMatchingService.postMarketTopCats(
                payload,
                this.marketId
            )
            return Promise.resolve(data)
        } catch (error) {
            console.log(error)
            return Promise.reject(error)
        }
    }

    getMarketCatId = async (payload, catId) => {
        if (!payload) {
            payload = {
                limit: 10,
                page: 1,
                value: '',
            }
        }
        try {
            const {data} = await CatsMatchingService.postMarketCatId(
                payload,
                this.marketId,
                catId
            )
            return Promise.resolve(data)
        } catch (error) {
            console.log(error)
            return Promise.reject(error)
        }
    }

    postCatsAssign = async () => {
        const masterCatsTree =
            this.parentStore.stores.masterCatsStore.activeCatsTree
        const activeCatId = this.parentStore.stores.masterCatsStore.activeCatId

        if (this.catsTree.length < 2 || masterCatsTree.length < 2) return
        const body = {
            category: {
                category_id: Number(
                    this.catsTree[this.catsTree.length - 2].selected?.id
                ),
                marketplace_id: Number(this.marketId),
            },
            master_cat_id: activeCatId,
        }
        try {
            const res = await CatsMatchingService.postCatsAssign(body)
            await this.parentStore.stores.masterCatsStore.getMasterUnlinkedCats()
            await this.getMarketplaces(this.marketName)
            return Promise.resolve(res)
        } catch (error) {
            return Promise.reject(error)
        }
    }
}

export {AbstractCatsStore}
