import {makeAutoObservable} from 'mobx'

export class PaginationStore {
    _initialPagination = {
        page: 1,
        total: 0,
        totalPages: 0,
        limit: 10,
    }
    constructor({page = 1, total = 0, totalPages = 0, limit = 10} = {}) {
        this._initialPagination = {
            page,
            total,
            totalPages,
            limit,
        }
        makeAutoObservable(this, {
            _initialPagination: false,
        })
        this.setPagination(this._initialPagination)
    }

    pagination = this._initialPagination

    setPagination = (obj) => {
        this.pagination = Object.assign({}, this.pagination, obj)
    }

    resetPagination = () => {
        this.pagination = this._initialPagination
    }

    get page() {
        return this.pagination.page
    }

    get total() {
        return this.pagination.total
    }

    get totalPages() {
        return this.pagination.totalPages
    }

    get limit() {
        return this.pagination.limit
    }
}
