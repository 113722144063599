import {format} from 'date-fns'
import {ru} from 'date-fns/locale'

export const DelayLogisticsTooltipContent = ({lastLogisticDate, cabinets}) => {
    const getFormatDate = (date) => {
        return format(new Date(date), `dd.MM.yyyy`, {
            locale: ru,
        })
    }

    return (
        <div>{`Данные логистики доставки и возвратов по кабинетам: ${cabinets.join(
            ', '
        )} доступны в системе до ${getFormatDate(
            lastLogisticDate
        )} включительно. Расчет будет скорректирован, когда маркетплейсы предоставят данные о логистике по API`}</div>
    )
}
