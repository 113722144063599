import React, {useCallback, useEffect, useRef, useState} from 'react'
import {observer} from 'mobx-react'

import {NotificationItem} from '../NotificationItem'
import {useNotificationsContext} from '../../context'
import {InfiniteList} from '@components/elements'
import {Loader} from '@components/elements/Loader'
import {ModalControl} from '@store/ModalControl'
import {NotificationModal} from '@components/overlays/notifications/components/NotificationModal'
import {usePrev} from '@hooks'

export const NotificationsList = observer(({className}) => {
    const [modalControl] = useState(() => new ModalControl())
    const containerRef = useRef(null)
    const notificationStore = useNotificationsContext()
    const prevNotifications = usePrev(notificationStore.notifications.length)

    useEffect(() => {
        if (prevNotifications !== notificationStore.notifications.length) {
            const views = notificationStore.notifications.filter(
                (item) => !item.view
            )
            if (views.length)
                notificationStore.patchNotificationsView(
                    views.map((item) => item.id)
                )
        }
    }, [prevNotifications, notificationStore.notifications.length])

    const onRead = (id) => {
        notificationStore.patchNotification([id])
    }

    const onDelete = (id) => {
        notificationStore.deleteNotification([id])
    }

    const handleClickMoreDetails = ({title, body, read, id}) => {
        modalControl.setProps({title, body})
        modalControl.open()
        if (!read) {
            notificationStore.patchNotification([id])
        }
    }

    const hasMore =
        notificationStore.paginationStore.total -
            notificationStore.notifications.length >
        0

    const rowRenderer = (props, measureElement, index) => {
        const item = notificationStore.notifications[index]
        return (
            <NotificationItem
                ref={measureElement}
                {...props.attributes}
                style={props.style}
                key={item.id}
                notification={item}
                onClickMoreDetails={handleClickMoreDetails}
            />
        )
    }

    return (
        <div className={'flex flex-auto relative flex-col max-h-[1180px]'}>
            <InfiniteList
                ref={containerRef}
                overscan={3}
                loadMoreCount={5}
                scrollingDelay={150}
                isItemLoaded={(index) => {
                    return !!notificationStore.notifications[index]
                }}
                rowRenderer={rowRenderer}
                estimateRowHeight={useCallback(() => 78, [])}
                rowsCount={notificationStore.notifications.length}
                isLoaderDown={notificationStore.loaderStore.loading}
                onLoadMore={useCallback(
                    ({loadDown}) => {
                        if (
                            loadDown &&
                            hasMore &&
                            !notificationStore.loaderStore.loading
                        ) {
                            notificationStore.getMoreNotifications()
                        }
                    },
                    [hasMore, notificationStore.loaderStore.loading]
                )}
                loaderItem={
                    <div
                        className={
                            'w-full flex justify-center items-center dark:text-gray-400 px-4 py-4'
                        }
                    >
                        <Loader sm overlayed={false} className={'mr-4'} />
                        <span>Загрузка данных...</span>
                    </div>
                }
            />
            <NotificationModal
                open={modalControl.isOpen}
                onClose={modalControl.close}
                {...modalControl.props}
            />
        </div>
    )
})
