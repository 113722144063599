import {makeObservable, observable, action} from 'mobx'
import YmlParserService from '@services/YmlParserService/YmlParserService'

const Feed = {
    feedUrl: '',
    password: '',
    username: '',
    withAuth: false,
    frequency: undefined,
    isChanged: false,
}

export class YmlFeedStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            pending: observable,
            setPending: action,
            setFrequency: action,
            setFeedUrl: action,
            setWithAuth: action,
            setUsername: action,
            setPassword: action,
            setIsChanged: action,
            setStatus: action,
            feeds: observable,
            mergeFeedsData: action,
            setFeedByIndex: action,
            addFeed: action,
            removeFeed: action,
        })
    }

    feeds = []

    frequencyOptions = [
        {
            id: 6,
            name: 'Каждые 6 часов',
            value: '6h',
        },
        {
            id: 12,
            name: 'Каждые 12 часов',
            value: '12h',
        },
        {
            id: 24,
            name: 'Каждые 24 часа',
            value: '24h',
        },
    ]

    pending = false

    setPending = (value) => {
        this.pending = value
    }

    setFrequency = (value, feedIndex) => {
        this.feeds[feedIndex].frequency = value
    }

    onChangeFrequency = (value, feedIndex) => {
        this.setFrequency(value, feedIndex)
        this.setIsChanged(true, feedIndex)
    }

    setFeedUrl = (value, feedIndex) => {
        this.feeds[feedIndex].feedUrl = value
    }

    onChangeFeedUrl = (value, feedIndex) => {
        this.setFeedUrl(value, feedIndex)
        this.setIsChanged(true, feedIndex)
    }

    setWithAuth = (value, feedIndex) => {
        this.feeds[feedIndex].withAuth = value
    }

    onChangeWithAuth = (value, feedIndex) => {
        this.setWithAuth(value, feedIndex)
        this.setIsChanged(true, feedIndex)
    }

    setUsername = (value, feedIndex) => {
        this.feeds[feedIndex].username = value
    }

    onChangeUsername = (value, feedIndex) => {
        this.setUsername(value, feedIndex)
        this.setIsChanged(true, feedIndex)
    }

    setPassword = (value, feedIndex) => {
        this.feeds[feedIndex].password = value
    }

    onChangePassword = (value, feedIndex) => {
        this.setPassword(value, feedIndex)
        this.setIsChanged(true, feedIndex)
    }

    setIsChanged = (value = true, feedIndex) => {
        this.feeds[feedIndex].isChanged = value
    }

    setStatus = (value, feedIndex) => {
        this.feeds[feedIndex].status = value
    }

    mergeFeedsData = (data) => {
        let feeds = []
        for (let i = 0; i < data.length; i++) {
            feeds.push(this.mergeSingleFeedData(data[i]))
        }
        this.feeds = feeds
    }

    mergeSingleFeedData = (data) => {
        const frequency = this.frequencyOptions.find(
            (feed) => feed.value === data.schedule
        ) || {id: data.schedule, name: data.schedule, value: data.schedule}

        return {
            id: data.uuid,
            feedUrl: data.link,
            frequency,
            withAuth: data.credentials.login && data.credentials.password,
            username: data.credentials.login,
            password: data.credentials.password,
            isChanged: false,
            status: {
                status: data.status.status,
                description: data.status.description,
                success: data.status.success,
                lastRun: data.status.lastRun,
                nextRun: data.status.nextRun,
            },
        }
    }

    setFeedByIndex = (value, feedIndex) => {
        this.feeds[feedIndex] = this.mergeSingleFeedData(value)
    }

    getIndexById = (id) => {
        return this.feeds.findIndex((item) => item?.id === id)
    }

    addFeed = () => {
        this.feeds.unshift(Feed)
        this.feeds[0].frequency = this.frequencyOptions.length
            ? this.frequencyOptions[0]
            : undefined
    }

    removeFeed = (feedIndex) => {
        this.feeds.splice(feedIndex, 1)
    }

    onRemoveFeed = async (feedIndex, cb) => {
        const feed = this.feeds[feedIndex]

        if (!feed?.id) {
            this.removeFeed(feedIndex)
            return
        }

        try {
            await YmlParserService.deleteYmlLink(feed.id)
            if (cb) cb()
        } catch (error) {
            console.log(error)
        }
    }

    onSaveFeed = async (feedIndex, cb) => {
        const feed = this.feeds[feedIndex]

        const body = {
            uuid: feed.id,
            link: feed.feedUrl,
            credentials: {
                login: feed.username || null,
                password: feed.password || null,
            },
            schedule: feed.frequency.value,
        }

        try {
            let updatedFeed
            if (feed?.id) {
                const {data} = await YmlParserService.editYmlLink(feed.id, body)
                updatedFeed = data
            } else {
                const {data} = await YmlParserService.postYmlLink(body)
                updatedFeed = data
            }
            this.setFeedByIndex(updatedFeed, feedIndex)
            if (cb) cb()
        } catch (error) {
            console.log(error)
        }
    }

    requestFeeds = async () => {
        this.setPending(true)
        try {
            const {data} = await YmlParserService.getAllYmlLinks()
            this.mergeFeedsData(data)
            this.setPending(false)
        } catch (error) {
            this.setPending(false)
            console.log(error)
        }
    }

    getFeedStatus = async (feedIndex) => {
        const feed = this.feeds[feedIndex]

        try {
            const {data} = await YmlParserService.getLinkStatus(feed?.id)
            this.setStatus(
                {
                    status: data.status,
                    description: data.description,
                    success: data.success,
                    lastRun: data.lastRun,
                    nextRun: data.nextRun,
                },
                this.getIndexById(data.uuid)
            )
        } catch (error) {
            console.log(error)
            this.setStatus(false, this.getIndexById(feed?.id))
        }
    }
}
