import {TooltipContentWrapper} from '@components/applicationUi/Tooltip'
import {WithTooltipWrapper} from '@components/applicationUi'
import classNames from 'classnames'
import {ExclamationCircleIcon} from '@heroicons/react/outline'
import React from 'react'

export const InformationTooltip = ({content, size}) => {
    return (
        <WithTooltipWrapper
            tooltip={<TooltipContentWrapper>{content}</TooltipContentWrapper>}
        >
            <ExclamationCircleIcon
                className={classNames(
                    'text-gray-500 dark:text-gray-400',
                    size,
                    {
                        'w-6 h-6': !size,
                    }
                )}
            />
        </WithTooltipWrapper>
    )
}
