import React from 'react'
import PropTypes from 'prop-types'
import useTooltipLite from './useTooltipLite'
import {TooltipPortal} from './TooltipPortal'
import {TooltipBody} from './TooltipBody'
import {Transition} from 'react-transition-group'
import '../../../styles/components/tooltip-lite.scss'

const TooltipLite = ({
    content = '',
    children,
    classNameBody = '',
    portalContainerId = 'overlays',
    zIndex = 999999,
    isArrow = false,
    ...props
}) => {
    const {setReferenceElement, isOpen, ANIMATION_DURATION, ...tooltipProps} =
        useTooltipLite({...props, isArrow})

    const tooltipBodyProps = {
        classNameBody,
        zIndex,
        content,
        ...tooltipProps,
    }

    return (
        <>
            {children(setReferenceElement)}

            <Transition
                unmountOnExit
                in={isOpen}
                timeout={{
                    enter: 0,
                    exit: ANIMATION_DURATION,
                }}
            >
                {(state) =>
                    portalContainerId ? (
                        <TooltipPortal
                            {...tooltipBodyProps}
                            portalContainerId={portalContainerId}
                            state={state}
                        />
                    ) : (
                        <TooltipBody {...tooltipBodyProps} state={state} />
                    )
                }
            </Transition>
        </>
    )
}

TooltipLite.propTypes = {
    content: PropTypes.any,
    classNameBody: PropTypes.string,
    portalContainerId: PropTypes.string,
    zIndex: PropTypes.number,
    placement: PropTypes.oneOf([
        'top',
        'bottom',
        'left',
        'right',
        'top-start',
        'bottom-start',
        'left-start',
        'right-start',
        'top-end',
        'bottom-end',
        'left-end',
        'right-end',
    ]),
    active: PropTypes.bool,
    offset: PropTypes.array,
}

export {TooltipLite}
