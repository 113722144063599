import {observable, action, makeObservable} from 'mobx'

import CatalogService from '@services/CatalogService/CatalogService'
import {FileDownload} from '@utils/download/download'
import {PaginationStore} from '../../../../store/PaginationStore'

class CatalogHistoryStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        makeObservable(this, {
            pending: observable,
            resultsList: observable,
            setPending: action,
            cleanResultsList: action,
            setResultsList: action,
        })
        this.paginationStore = new PaginationStore()
    }

    pending = false
    resultsList = []

    setPending(value: boolean = true) {
        this.pending = value
    }

    cleanResultsList() {
        this.resultsList = []
    }

    setResultsList = (list = []) => {
        this.resultsList = list
    }

    mergeResultsData(source: Object) {
        const sourceResults = source.results
        let results = []
        for (let i = 0; i < sourceResults.length; i++) {
            const sourceResult = sourceResults[i]
            let result = {
                id: sourceResult.id,
                importDate: sourceResult.finishedAt,
                accounts: sourceResult.marketplaceCredentialIds.map((id) => {
                    const cred =
                        this.rootStore.marketStore.getCredentialByID(id)
                    const market = this.rootStore.marketStore.getMarketNameById(
                        cred?.marketplaceId
                    )
                    const name =
                        this.rootStore.marketStore.getCabinetNameById(id)
                    return {
                        name,
                        market,
                    }
                }),
                source: {
                    name: sourceResult.feedIrl || sourceResult.filename,
                    type: sourceResult.updateSource,
                },
                successCount: sourceResult.productsSuccess,
                errorCount: sourceResult.productsErrors,
            }
            results.push(result)
        }
        this.setResultsList(results)
    }

    setSourceData(source: Object) {
        const paginationData = {
            page: source?.page,
            //limit: source?.limit,
            total: source?.total,
            totalPages: source?.pages,
        }
        this.cleanResultsList()
        this.paginationStore.setPagination(paginationData)
        this.mergeResultsData(source)
    }

    getResultsList = async (page: number) => {
        const body = this.collectResultsPayload(page)
        this.setPending()
        try {
            const {data} = await CatalogService.getBatchUpdateResultsList(body)
            this.setSourceData(data)
        } catch (e) {
            console.log('getResultsList', e)
        } finally {
            this.setPending(false)
        }
    }

    collectResultsPayload(page: number = 0) {
        const {pagination} = this.paginationStore
        let body = {}
        body['page'] = page || pagination.page
        body['limit'] = pagination.limit
        return body
    }

    downloadBatchUpdateValidationErrorsFile = async (
        resultId: number,
        originalFilename: string
    ) => {
        const filename = `${
            originalFilename
                ? originalFilename.split('.')[0]
                : `prices_and_stocks_${resultId}`
        }_errors.xlsx`
        try {
            const {data} = await CatalogService.getBatchUpdateValidationErrors(
                resultId
            )
            FileDownload(data, filename)
        } catch (e) {
            console.log(e)
        }
    }
}

export default CatalogHistoryStore
