import {makeAutoObservable} from 'mobx'
import {ModalControl} from '../../../../../store/ModalControl'

export class ImportWbOverlayStore {
    constructor() {
        makeAutoObservable(this)
    }
    overlayControl = new ModalControl()
    isAddedPromotion = false
    promoId = ''
    cabinet = ''
    name = ''

    setIsAddedPromotion = (value) => {
        this.isAddedPromotion = value
    }

    setPromoId = (value) => {
        this.promoId = value
    }

    setCabinet = (value) => {
        this.cabinet = value
    }

    setName = (value) => {
        this.name = value
    }
}
