import {ApiFactory} from '@services/ApiFactory'
import {BillingUrl} from '../../routes-enums'
import {baseConfig} from '@services/apiConfigs'

const billingInstance = ApiFactory.createApi(baseConfig)

class BillingService {
    static getUserAttributes = async () => {
        return await billingInstance.get(BillingUrl.userAttr)
    }
    // -----------Новое API--------------

    static getListTariffs = async () => {
        return await billingInstance.get(BillingUrl.listTariffs)
    }

    static getTariffsDirectory = async () => {
        return await billingInstance.get(BillingUrl.tariffsDirectory)
    }

    static getTariffOld = async () => {
        return await billingInstance.get(BillingUrl.subscriptionView)
    }

    static getPurchaseCallback = async (query) => {
        return await billingInstance.get(BillingUrl.purchaseCallback, query)
    }

    static postMakePurchase = async (payload) => {
        if (!payload.slug) {
            return console.error('API: Create order errors. slug is empty')
        }

        return await billingInstance.post(BillingUrl.makePurchase, payload)
    }

    // Новейшие точки

    static postBillingDict = async (payload) => {
        return await billingInstance.post(BillingUrl.billingDict, payload)
    }

    static postBillingBundles = async (payload) => {
        return await billingInstance.post(BillingUrl.billingBundles, payload)
    }

    static postBillingPurchaseTink = async (payload) => {
        if (!payload.bundle_id) {
            return console.error('API: Create order errors. bundle_id is empty')
        }

        return await billingInstance.post(BillingUrl.purchaseTink, payload)
    }

    static getBillingCallbackTink = async (query) => {
        return await billingInstance.get(BillingUrl.callbackTink, query)
    }

    static getTariff = async () => {
        return await billingInstance.get(BillingUrl.subscriptionTariff)
    }
}

export default BillingService
