import {useMemo} from 'react'
const CATEGORY_MAP = {
    a: 'A',
    b: 'B',
    c: 'C',
}
export const useCategoryColor = (category) => {
    const getColor = (category) => {
        let color = 'text-grey-400 dark:text-grey-500'
        if (category === CATEGORY_MAP.a) {
            color = 'text-green-400 dark:text-green-500'
        }
        if (category === CATEGORY_MAP.b) {
            color = 'text-yellow-400 dark:text-yellow-500'
        }
        if (category === CATEGORY_MAP.c) {
            color = 'text-red-400 dark:text-red-500'
        }
        return color
    }
    const color = useMemo(() => {
        return getColor(category)
    }, [category])

    return {color, getColor}
}
