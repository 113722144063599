import {makeAutoObservable, reaction} from 'mobx'
import {STATUSES_FILE_MAP} from '@utils/constants'
import {ImportWbService} from '@services/ImportWbService'
import {LoaderStore} from '../../../../../../../store/LoaderStore'
import {FileStore} from '../../../../../../../store/FileStore'
import {toast} from 'react-toastify'

export class WbUploadFileStore {
    constructor(filtersStore, extension, onCheckStatuses) {
        this.filtersStore = filtersStore
        this.extension = extension
        this.onCheckStatuses = onCheckStatuses
        makeAutoObservable(this)

        this.disposer = reaction(
            () => this.filtersStore.cabinet.selected,
            () => {
                this.clearFiles()
                this.setStatuses(STATUSES_FILE_MAP.fileDrop)
            }
        )
    }
    statuses = STATUSES_FILE_MAP.fileDrop
    promoType = {}
    uploadFileLoaderStore = new LoaderStore()
    files = []

    setPromoType = (value) => {
        this.promoType = value
    }

    setStatuses = (value = STATUSES_FILE_MAP.fileDrop) => {
        this.statuses = value
    }

    getFileNameExt = (filename) => {
        return filename.split('.').pop()
    }

    addFileToImport = (file, formatCb) => {
        if (this.getFileNameExt(file.name).toLowerCase() !== this.extension) {
            if (typeof formatCb === 'function') formatCb()
            return
        }
        this.addFile('', file)
        this.setStatuses(STATUSES_FILE_MAP.fileAdded)
    }

    addFile(fileName = '', _file = undefined) {
        let file = new FileStore()
        file.setFileName(
            typeof _file !== 'undefined' ? _file.name : fileName || ''
        )
        file.setReference(_file)
        this.files.push(file)
    }

    deleteFile = (idx) => {
        if (idx >= 0 && idx < this.files.length) {
            this.files.splice(idx, 1)
        } else {
            console.log('Index out of bounds')
        }
    }

    clearFiles = () => {
        this.files = []
    }

    postFile = async (extension) => {
        if (!this.files.length) {
            return
        }
        if (!this.filtersStore.savedLegalEntity) {
            return this.filtersStore.setIsLegalEntityError(true)
        }
        this.filtersStore.setIsLegalEntityError(false)
        const fileData = new FormData()
        fileData.append('cabinet', this.filtersStore.cabinet.selected.key)
        fileData.append('company', this.filtersStore.savedLegalEntity)
        fileData.append('report_type', extension === 'xlsx' ? 'list' : 'report')
        this.files.forEach((file) => fileData.append('report_file', file.ref))

        this.uploadFileLoaderStore.setLoading(true)
        this.setStatuses(STATUSES_FILE_MAP.process)
        try {
            const {data} = await ImportWbService.postWbDetailed(fileData)
            if (data.result) {
                toast('Файл для загрузки успешно добавлен', {type: 'success'})
                if (this.extension === 'xlsx') {
                    const isConnectedLegal = !this.filtersStore.validLegalEntity
                    const legalName = this.filtersStore.savedLegalEntity
                    const props = []
                    if (isConnectedLegal) {
                        props[0] = isConnectedLegal
                        props[1] = legalName
                    }
                    this.onCheckStatuses(
                        this.filtersStore.cabinet.selected.key,
                        ...props
                    )
                } else {
                    this.onCheckStatuses(this.filtersStore.cabinet.selected.key)
                }
                this.clearFiles()
            }
        } catch (e) {
            this.setStatuses(STATUSES_FILE_MAP.fileAdded)
            toast(
                'Неверный формат файла. Убедитесь, что вы выбрали правильный файл фин. отчета от выбранного кабинета',
                {type: 'error'}
            )
            console.log(e)
        } finally {
            this.uploadFileLoaderStore.setLoading(false)
        }
    }

    destroy = () => {
        this.disposer()
    }
}
