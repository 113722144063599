export default {
    billingLegalEntity: {
        value: null,
        options: [
            {
                id: 'ip',
                name: 'ИП',
            },
            {
                id: 'ooo',
                name: 'ООО',
            },
            {
                id: 'ao',
                name: 'АО',
            },
            {
                id: 'pao',
                name: 'ПАО',
            },
            {
                id: 'pk',
                name: 'ПК',
            },
            {
                id: 'up',
                name: 'УП',
            },
            {
                id: 'nko',
                name: 'НКО',
            },
            {
                id: 'zao',
                name: 'ЗАО',
            },
        ],
    },
    billingCompanyName: {
        type: 'text',
        value: '',
    },
    billingCompanyAddress: {
        type: 'text',
        value: '',
    },
    billingInn: {
        value: '',
        mask: {
            ip: '999999999999',
            etc: '9999999999',
        },
        getMask(key) {
            if (!key) return this.mask.ip
            return this.mask[key]
        },
    },
    billingOgrn: {
        mask: '9999999999999',
        value: '',
    },
    billingOgrnip: {
        mask: '999999999999999',
        value: '',
    },
    billingKpp: {
        mask: '999999999',
        value: '',
    },
    billingOkpo: {
        mask: '99999999999999',
        value: '',
    },
}
