import {Modal} from '@components/overlays'
import React from 'react'
import {ErrorLegalDialog} from './components/ErrorLegalDialog'

export const ErrorLegalModal = ({onClose, open, ...props}) => {
    return (
        <Modal className={'sm:max-w-lg'} open={open} onClose={onClose}>
            <ErrorLegalDialog {...props} />
        </Modal>
    )
}
