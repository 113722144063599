import React from 'react'
import classNames from 'classnames'
import {LockClosedIcon} from '@heroicons/react/outline'
import {TooltipLite, LockedTooltipContent} from '@components/elements'

const LockedItem = ({isWarn}) => {
    return (
        <div
            className={classNames(
                `w-full h-full flex relative justify-center items-center rounded`,
                {
                    'bg-red-300': isWarn,
                    'bg-[#e5e7eb] dark:bg-[#929da6]': !isWarn,
                }
            )}
        >
            <div className='w-full h-6 rounded'></div>
            <div className={'absolute top-0 right-0'}>
                <LockClosedIcon
                    className={classNames('flex-shrink-0 h-4 w-4', {
                        'text-gray-400 dark:text-gray-500': isWarn,
                        'text-gray-400 dark:text-gray-700': !isWarn,
                    })}
                />
            </div>
        </div>
    )
}

export const Locked = ({isWarn}) => {
    return (
        <TooltipLite
            offset={[0, 10]}
            content={<LockedTooltipContent />}
            classNameBody={'md:p-2 max-w-[250px]'}
        >
            {(ref) => (
                <span ref={ref}>
                    <LockedItem isWarn={isWarn} />
                </span>
            )}
        </TooltipLite>
    )
}
