import React, {useMemo} from 'react'
import {observer} from 'mobx-react'
import {UnitOnboard} from '@components/navigation/header/Onboarding/pages/UnitOnboard'
import {MarginOnboard} from '@components/navigation/header/Onboarding/pages/MarginOnboard'
import {ImportMarketOnboard} from '@components/navigation/header/Onboarding/pages/ImportMarketOnboard'
import {CoastOnboard} from '@components/navigation/header/Onboarding/pages/CoastOnboard'
import {AbcOnboard} from '@components/navigation/header/Onboarding/pages/AbcOnboard'
import {SupplyForecastOnboard} from '@components/navigation/header/Onboarding/pages/SupplyForecastOnboard'
import {SalesRegionOnboard} from '@components/navigation/header/Onboarding/pages/SalesRegionOnboard'
import {EndOnboard} from '@components/navigation/header/Onboarding/pages/EndOnboard'
import {MarketOnboard} from '@components/navigation/header/Onboarding/pages/MarketOnboard'
import {ONBOARD_STEP} from '@utils/constants'
import {OnboardingWrapper} from '@components/navigation/header/Onboarding/components/OnboardingWrapper'
import {ModalConfirm} from '@components/overlays'

const ONBOARD_PAGE = {
    // [ONBOARD_STEP.mpAdded]: MarketOnboard,
    // [ONBOARD_STEP.mpImportComplete]: ImportMarketOnboard,
    // [ONBOARD_STEP.costsAdded]: CoastOnboard,
    // [ONBOARD_STEP.profitChecked]: MarginOnboard,
    // [ONBOARD_STEP.unitChecked]: UnitOnboard,
    // [ONBOARD_STEP.abcChecked]: AbcOnboard,
    // [ONBOARD_STEP.forecastChecked]: SupplyForecastOnboard,
    // [ONBOARD_STEP.regionalChecked]: SalesRegionOnboard,
    // [ONBOARD_STEP.finalClosed]: EndOnboard,
}

export const Onboarding = observer(({onboardingStepStore}) => {
    // const Component = useMemo(() => {
    //     return ONBOARD_PAGE[onboardingStepStore.step]
    // }, [onboardingStepStore.step])
    return (
        <div>
            {/*<OnboardingWrapper onClose={onboardingStepStore.onOpenConfirmClose}>*/}
            <ImportMarketOnboard onboardingStepStore={onboardingStepStore} />
            {/*</OnboardingWrapper>*/}
            {/*<ModalConfirm*/}
            {/*    open={onboardingStepStore.confirmCloseModalControl.isOpen}*/}
            {/*    title={`Обучение будет отключено!`}*/}
            {/*    description={*/}
            {/*        'Вы действительно хотите пропустить вводное обучение?'*/}
            {/*    }*/}
            {/*    accept={'Пропустить'}*/}
            {/*    decline={'Вернуться'}*/}
            {/*    onClose={onboardingStepStore.confirmCloseModalControl.close}*/}
            {/*    onAccept={onboardingStepStore.onClose}*/}
            {/*/>*/}
        </div>
    )
})

Onboarding.displayName = 'Onboarding'
