import React from 'react'
import {TooltipLite} from '@components/elements'
import {QuestionMarkCircleIcon} from '@heroicons/react/outline'
import classNames from 'classnames'

export const SettingsHelp = ({content, size, className}) => {
    return (
        <TooltipLite
            offset={[0, 10]}
            content={content}
            classNameBody={classNames(className, 'md:p-2 max-w-[250px]')}
        >
            {(ref) => (
                <span ref={ref}>
                    <QuestionMarkCircleIcon
                        className={classNames(
                            'text-gray-500 dark:text-gray-400 cursor-pointer',
                            size,
                            {
                                'w-6 h-6': !size,
                            }
                        )}
                    />
                </span>
            )}
        </TooltipLite>
    )
}
