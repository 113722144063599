import React, {Fragment} from 'react'
import {Menu, Transition} from '@headlessui/react'
import cn from 'classnames'
import ChevronDownIcon from '@heroicons/react/solid/ChevronDownIcon'
import {useResponsive} from '@hooks'
import {DotsVerticalIcon} from '@heroicons/react/solid'
import MenuItem from '@components/navigation/header/menuDropdown/MenuItem'
import DarkTheme from '@components/navigation/header/menuDropdown/DarkTheme'
import {EyeIcon} from '@heroicons/react/outline'
import classNames from 'classnames'

export const MenuDropdown = ({
    className = '',
    label = 'Мой профиль',
    header = false,
    options = [],
    isEmulations,
    children,
}) => {
    const {md} = useResponsive()
    return (
        <Menu
            as='div'
            className={cn(className, 'relative inline-block text-left')}
        >
            <div>
                {!md ? (
                    <Menu.Button className='rounded-full flex items-center text-gray-400 hover:text-gray-600 focus:outline-none'>
                        <span className='sr-only'>{label}</span>
                        <DotsVerticalIcon
                            className='h-6 w-6'
                            aria-hidden='true'
                        />
                    </Menu.Button>
                ) : (
                    <Menu.Button
                        className={classNames(
                            'inline-flex justify-center space-x-2 items-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700',
                            'hover:bg-gray-50 focus:outline-none',
                            'dark:border-gray-800 dark:text-gray-300 dark:bg-gray-700'
                        )}
                    >
                        {isEmulations && (
                            <EyeIcon className={'h-6 w-6 text-red-500'} />
                        )}
                        <span>{label}</span>
                        <ChevronDownIcon
                            className='-mr-1 ml-2 h-5 w-5'
                            aria-hidden='true'
                        />
                    </Menu.Button>
                )}
            </div>

            <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
            >
                {children ? (
                    children
                ) : (
                    <Menu.Items className='z-20 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white divide-y divide-gray-100 dark:bg-gray-700 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                        {header && (
                            <div className='px-4 py-3'>
                                <p className='text-sm'>Signed in as</p>
                                <p className='text-sm font-medium text-gray-900 truncate'>
                                    tom@example.com
                                </p>
                            </div>
                        )}
                        <div className='py-1'>
                            {options.map((item) => (
                                <MenuItem key={item.label} option={item} />
                            ))}
                        </div>
                        <div className='py-1'>
                            <DarkTheme />
                        </div>
                    </Menu.Items>
                )}
            </Transition>
        </Menu>
    )
}
