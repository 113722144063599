import {Select} from '@components/forms/Selects'
import {MarketIcon} from '@components/elements'
import React from 'react'

export const CabinetSelectedRequired = ({
    options,
    cabinet,
    setCabinet,
    label = 'Для получения отчета необходимо выбрать кабинет',
}) => {
    return (
        <div className={'flex h-full flex-col space-y-4'}>
            <p className={'text-2xl font-semibold dark:text-gray-100'}>
                {label}
            </p>
            <Select
                className={'w-1/2'}
                options={options}
                selected={cabinet}
                onChange={setCabinet}
                placeholder={'Выберите кабинет'}
            >
                {({option, selected, active}) => (
                    <div className={'flex items-center '}>
                        <MarketIcon
                            iconClassName={'w-4x5 h-4x5'}
                            size={20}
                            name={option.market}
                            className={'mr-3 flex-shrink-0'}
                        />
                        <span className={''}>{option.name}</span>
                    </div>
                )}
            </Select>
        </div>
    )
}
