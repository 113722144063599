import LayoutSidebarStore from '@views/layouts/store/LayoutSidebarStore'
import ThemeStore from './ThemeStore'
import AuthStore from '@views/auth/store/AuthStore'
import UserStore from './UserStore'
import MarketStore from '../views/marketplaces/pages/Marketplaces/store/MarketStore'
import AccountStore from '@views/account/store/AccountStore'
import BillStore from '@views/billing/store/BillStore'
import ProductsStore from '@views/products/store/ProductsStore'
import ProductStore from '@views/product/store/ProductStore'
import PromotionStore from '@views/promotion/store/PromotionStore'
import SupportOverlayStore from '@components/applicationUi/SupportOverlay/store/SupportOverlayStore'
import TelegramStore from '@views/telegram/store/TelegramStore'
import MonitoringStore from '@views/monitoring/store/MonitoringStore'
import OrdersStore from '@views/orders/store/OrdersStore'
import CatalogStore from '@views/catalog/store/CatalogStore'
import OrderStore from '@views/order/store/OrderStore'
import ErrorStore from './ErrorStore'
import CatalogCardStore from '@views/catalogCard/store/CatalogCardStore'
import ProductsAdminStore from '@views/productsAdmin/store/ProductsAdminStore'
import {InformerStore} from './InformerStore'
import HelpStore from '@views/help/store/HelpStore'
import AdminUsersStore from '@views/adminUsers/store/AdminUsersStore'
import AdminPartnersStore from '@views/adminPartners/store/AdminPartnersStore'
import AdminStatsStore from '@views/adminStats/store/AdminStatsStore'
import AdminStatusStore from '@views/adminStatus/store/AdminStatusStore'
import AcademyStore from '@views/academy/store/AcademyStore'
import {SettingsStore} from '@components/applicationUi/SettingsOverlay/store/SettingsStore'

class S24rootStore {
    constructor() {
        this.settingsStore = new SettingsStore()
        this.userStore = new UserStore(this)
        this.errorStore = new ErrorStore(this)
        this.authStore = new AuthStore(this)
        this.marketStore = new MarketStore(this)
        this.layoutSidebarStore = new LayoutSidebarStore(this)
        this.themeStore = new ThemeStore(this)
        this.ordersStore = new OrdersStore(this)
        this.orderStore = new OrderStore(this)
        this.accountStore = new AccountStore(this)
        this.billStore = new BillStore(this)
        this.productsStore = new ProductsStore(this)
        this.productsAdminStore = new ProductsAdminStore(this)
        this.productStore = new ProductStore(this)
        this.promotionStore = new PromotionStore(this)
        this.supportOverlayStore = new SupportOverlayStore(this)
        this.telegramStore = new TelegramStore(this)
        this.monitoringStore = new MonitoringStore(this)
        this.catalogStore = new CatalogStore(this)
        this.catalogCardStore = new CatalogCardStore(this)
        this.informerStore = new InformerStore(this)
        this.adminUsersStore = new AdminUsersStore(this)
        this.adminPartnersStore = new AdminPartnersStore(this)
        this.adminStatsStore = new AdminStatsStore(this)
        this.adminStatusStore = new AdminStatusStore(this)
        this.helpStore = new HelpStore(this)
        this.adminUsersStore = new AdminUsersStore(this)
        this.adminPartnersStore = new AdminPartnersStore(this)
        this.adminStatsStore = new AdminStatsStore(this)
        this.adminStatusStore = new AdminStatusStore(this)
        this.academyStore = new AcademyStore(this)
    }
}

export default S24rootStore
