import ProductsPositionStore from '@views/monitoring/Monitoring/Content/ProductsPosition/store/ProductsPositionStore'

class MonitoringStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        this.reset()
    }

    reset = () => {
        this.states = {
            productsPositionStore: new ProductsPositionStore(this.rootStore),
        }
    }

    monitoringTabs = [
        {
            id: 'product_position',
            name: 'Позиции товаров',
            disabled: false,
            visible: true,
        },
        {
            id: 'search_position',
            name: 'Поисковая выдача',
            disabled: true,
            visible: true,
            count: 'Скоро',
            warn: false,
        },
    ]
}

export default MonitoringStore
