import {useMediaQuery} from 'react-responsive'

export const useResponsive = () => {
    const s = useMediaQuery({query: '(min-width: 470px)'})
    const sm = useMediaQuery({query: '(min-width: 640px)'})
    const md = useMediaQuery({query: '(min-width: 768px)'})
    const lg = useMediaQuery({query: '(min-width: 1024px)'})
    const xl = useMediaQuery({query: '(min-width: 1280px)'})
    const xxl = useMediaQuery({query: '(min-width: 1536px)'})
    const mdh = useMediaQuery({query: '(min-height: 900px)'})

    return {
        s,
        sm,
        md,
        lg,
        xl,
        xxl,
        mdh,
    }
}
