import React from 'react'

export const YellowExtension = ({name}) => {
    if (name === 'api') return null
    return (
        <span>
            Детализации Excel загружены частично за этот период. Дозагрузите
            недостающие детализации
        </span>
    )
}
