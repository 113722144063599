import React, {useRef, useState, useEffect, forwardRef} from 'react'
import './TableScrollWrapper.scss'
import classNames from 'classnames'
import {useTableScrollTop} from '@components/applicationUi/Lists/Table/useTableScrollTop'
import {useDebouncedFunction} from '@hooks'

export const TableScrollWrapper = forwardRef(
    ({tableRef, syncScroll, offsetWidth}, ref) => {
        const [showHeaderScroll, setShowHeaderScroll] = useState(false)
        const [scrollWidth, setScrollWidth] = useState(0)

        const checkForScroll = () => {
            if (tableRef.current) {
                setScrollWidth(tableRef.current.scrollWidth)
                const hasHorizontalScroll =
                    tableRef.current.scrollWidth > tableRef.current.clientWidth
                setShowHeaderScroll(hasHorizontalScroll)
            }
        }

        const checkForScrollDebounce = useDebouncedFunction(checkForScroll, 50)

        useEffect(() => {
            const mo = new MutationObserver(checkForScrollDebounce)
            mo.observe(tableRef.current, {
                childList: true,
                subtree: true,
                attributes: true,
            })
            return () => {
                mo.disconnect()
            }
        }, [])

        return (
            showHeaderScroll && (
                <div
                    ref={ref}
                    onScroll={syncScroll}
                    className='scrollbar-macos'
                    style={{paddingBottom: '16px'}} // Высота скроллбара
                >
                    <div
                        style={{
                            width: `${scrollWidth}px`,
                            height: '1px',
                        }}
                    />
                </div>
            )
        )
    }
)
TableScrollWrapper.displayName = 'TableScrollWrapper'
