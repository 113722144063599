/**
 * Enum for common colors.
 * @readonly
 * @enum {string}
 */

export const AUTH_URLS = [
    '/sign_in/',
    '/sign_up/',
    '/sign_up/form/',
    '/sign_up/sms/',
    '/sign_up/qualification/',
    '/password_reset/',
    '/instructions-sent/',
    '/invite/',
    '/confirm/',
    '/quiz/',
    '/mobile/',
]

export const AppPath = Object.freeze({
    authUrl: AUTH_URLS,
    signIn: '/sign_in/',
    signUp: '/sign_up/',
    signUpForm: '/sign_up/form/',
    signUpSms: '/sign_up/sms/',
    signUpQualification: '/sign_up/qualification/',
    resetPassword: '/password_reset/',
    quickStart: '/quick-start/',
    account: '/account/',
    accountSettings: '/account/settings/',
    accountSettingsProfile: '/account/settings/profile/',
    accountSettingsPassword: '/account/settings/password/',
    accountTariff: '/account/tariff/',
    accountFileSharing: '/account/file_sharing/',
    billing: '/billing/',
    billingInvoice: '/billing/invoice/',
    billingOrderComplete: '/billing/order_complete/',
    billingOrderFail: '/billing/order_fail/',
    dashboard: '/dashboard/',
    products: '/products/',
    productsList: '/products/list/',
    productsImport: '/products/import/',
    productsAttributes: '/products/attributes/',
    productsReviews: '/products/reviews/',
    productsWarehouses: '/products/warehouses/',
    productsStock: '/products/stock/',
    productsStockXls: '/products/stock/xls/',
    productsStockFeed: '/products/stock/feed/',
    productsStockYml: '/products/stock/yml/',
    productsImportYml: '/products/import/yml/',
    productsImportMarketplaces: '/products/import/marketplaces/',
    productsCategories: '/products/categories/',
    productsAdmin: '/products-admin/',
    productsAdminAttrsMarket: '/products-admin/attrs-market/',
    productsAdminAttrsFeed: '/products-admin/attrs-feed/',
    productsAdminCatsMatching: '/products-admin/cats-matching/',
    product: '/product/:id/',
    reviews: '/reviews/',
    productId: (id) => `/product/${id}/`,
    catalog: '/catalog/',
    catalogId: (id) => `/card/${id}/`,
    catalogList: '/catalog/list/',
    catalogHistory: '/catalog/history/',
    catalogReviews: '/catalog/reviews/',
    catalogCard: '/card/:id/',
    marketplaces: '/marketplaces/',
    marketplacesConnected: '/marketplaces/connected/',
    marketplacesImportWb: '/marketplaces/import_wb/',
    orders: '/orders/',
    ordersFbs: '/orders/fbs/',
    ordersFbo: '/orders/fbo/',
    order: '/order/:id/',
    orderId: (id) => `/order/${id}/`,
    analytics: '/analytics/',

    productAnalytics: '/product_analytics/',
    productAnalyticsAbc: '/product_analytics/abc/',
    productAnalyticsAbcProducts: '/product_analytics/abc/products/',
    productAnalyticsAbcCompare: '/product_analytics/abc/compare/',
    productAnalyticsCommodityReport: '/product_analytics/commodity_report/',
    productAnalyticsProductsSettings: '/product_analytics/products/',
    productAnalyticsCohort: '/product_analytics/cohort_orders/',
    productAnalyticsProductInfo: '/product_analytics/product/:id',
    productAnalyticsActiveProducts: '/product_analytics/active_products',
    productAnalyticsProductInfoId: (id) => `/product_analytics/product/${id}`,

    profit: '/profit/',
    profitMargin: '/profit/margin_profit/',
    profitMarginProfitProducts: '/profit/margin_profit/products/',
    profitMarginProfitCompare: '/profit/margin_profit/compare/',
    profitUnit: '/profit/unit_economy/',
    profitUnitActual: '/profit/unit_economy/actual',
    profitUnitForecast: '/profit/unit_economy/forecast',
    profitUnitPromotion: '/profit/unit_economy_promotion/',
    profitAdv: '/profit/adv/',
    profitCosts: '/profit/costs/',

    deliveriesRegions: '/deliveries_regions/',
    deliveriesRegionsSupplyForecast: '/deliveries_regions/supply_forecast/',
    deliveriesRegionsSupplyForecastRegions:
        '/deliveries_regions/supply_forecast_regions/',
    deliveriesRegionsSalesRegion: '/deliveries_regions/sales_region/',
    deliveriesRegionsSalesRegionByRegions:
        '/deliveries_regions/sales_region/by_regions/',
    deliveriesRegionsSalesRegionByProducts:
        '/deliveries_regions/sales_region/by_products/',
    deliveriesRegionsSalesRegionRecommendedStocks:
        '/deliveries_regions/sales_region/recommended_stocks/',
    deliveriesRegionsRegionalStocks: '/deliveries_regions/regional_stocks/',

    support: '/support/',
    promotion: '/promotion/',
    integrations: '/integrations/',
    images: '/images/',
    richContent: '/rich-content/',
    notificationTelegram: '/notification_telegram/',
    help: '/help/',
    helpCategory: '/help/:categoryId/',
    helpCategoryId: (categoryId) => `/help/${categoryId}/`,
    helpDetail: '/help/:categoryId/:id/',
    helpDetailId: (categoryId, id) => `/help/${categoryId}/${id}/`,
    helpList: '/help-list/',
    helpListDetail: '/help-list/:id/',
    helpListDetailId: (id) => `/help-list/${id}/`,
    experience: '/experience/',
    academy: '/academy/',
    // monitoring: '/monitoring/',
    // monitoringProductPosition: '/monitoring/product_position/',
    // monitoringSearchPosition: '/monitoring/search_position/',
    marketAnalytics: '/market_analytics/',
    deliveryForecastRegions: '/delivery_forecast_regions/',
    oauth: '/oauth/:marketplace/',
    confirm: '/confirm/:confirm/',
    quiz: '/quiz/',
    mobile: '/mobile/',
    quizSurvey: '/quiz/survey',
    quizEnd: '/quiz/end',
    adminUsers: '/admin-users/',
    adminUsersFileSharing: '/admin-users/file_sharing/',
    adminUsersFileSharingFiles: '/admin-users/file_sharing/files/',
    adminUsersFileSharingLink: '/admin-users/file_sharing/link/',
    adminUsersDataImport: '/admin-users/data_import/',
    adminPartners: '/admin-partners/',
    adminStats: '/admin-stats/',
    adminStatsGeneral: '/admin-stats/general/',
    adminStatsProducts: '/admin-stats/products/',
    adminMRStats: '/admin-stats/mr-stats/',
    adminStatus: '/admin-status/',

    finance: '/finance/',
    financeStandard: '/finance/standard/',
    financeComparison: '/finance/comparison/',
    financeDynamics: '/finance/dynamics/',
})

export const AuthUrl = Object.freeze({
    postLogin: '/api/auth/login/',
    postSignUp: '/api/auth/signup/',
    confirmSms: '/api/auth/confirm_sms/',
    confirmEmail: (token) => `/api/auth/confirm_email/${token}/`,
    confirmEmailRepeat: '/api/auth/confirm_email/',
    getProviders: '/api/auth/providers/',
    getCallbackProviders: (providerName) =>
        `/api/auth/callback/${providerName}/`,
    getLogout: '/api/auth/logout/',
    postResetPasswordByEmail: '/api/auth/reset_password_by_email/',
    postResetPasswordByPhone: '/api/auth/reset_password_by_phone/',
    resetPassword: (token) => `/api/auth/reset_password/${token}/`,
    validateToken: (userUuid) => `/api/auth/validate_token/${userUuid}/`,
    postRefresh: '/api/auth/refresh/',
})

export const MarketUrl = Object.freeze({
    loginOzon: '/pim/api/marketplace/account/login_ozon/',
    editOzonId: (id) => `/pim/api/marketplace/account/login_ozon/${id}/`,
    loginWb: '/pim/api/marketplace/account/login_wb/',
    editWbId: (id) => `/pim/api/marketplace/account/login_wb/${id}/`,
    loginYandex: '/pim/api/marketplace/account/login_yandex/',
    editYandexId: (id) => `/pim/api/marketplace/account/login_yandex/${id}/`,
    ssoYandex: '/pim/api/marketplace/account/sso_yandex/',
    checkSsoYandex: '/pim/api/marketplace/account/check_sso/',
    getCampaignsYandex: '/pim/api/marketplace/account/get_campaigns/',
    credentialList: '/pim/api/marketplace/account/v2/get_list/',
    deleteCredential: '/pim/api/marketplace/account/delete/',
    deleteUnsubscribe: (id) =>
        `/pim/api/marketplace/account/unsubscribe/${id}/`,
    deleteUnsubscribes: `/pim/api/marketplace/account/unsubscribe/`,
    credentialsStatusesOld: '/pim/mp-import/v4/status/',
    credentialsStatuses: '/pim/mp-import/v5/status/',
    ssoWb: '/pim/api/marketplace/account/sso_wb/',
    ssoWbEdit: (id) => `/pim/api/marketplace/account/sso_wb/${id}/`,
    checkSsoWb: '/pim/api/marketplace/account/check_sso_wb/',
})

export const BillingUrl = Object.freeze({
    userAttr: '/billing/user_billing_attributes',

    listTariffs: '/pim/api/tariff/list-tariffs/',
    tariffsDirectory: '/pim/api/tariff/simple-list-tariffs/',
    subscriptionView: '/pim/api/tariff/subscription/subscription-view/',
    makePurchase: '/pim/api/tariff/purchase/make-purchase/tink/',
    purchaseCallback: '/pim/api/tariff/purchase/callback/tink/',
    purchaseTink: '/pim/api/tariff/billing/purchase/tink/',
    callbackTink: '/pim/api/tariff/billing/callback/tink/',
    subscriptionTariff: '/pim/api/tariff/billing/subscription-view/',

    billingDict: '/pim/api/tariff/billing/dict/',
    billingBundles: '/pim/api/tariff/billing/bundles/',
})

export const DadataUrl = Object.freeze({
    companySuggests: '/suggest/party',
    companyAddress: '/suggest/address',
    bankSuggests: (suggestType) => `/${suggestType}/bank`,
})
export const ErrorUrl = Object.freeze({
    error: () => '/sendMessage',
})

export const FeedbackUrl = Object.freeze({
    message: '/pim/api/mailer/send/',
})

export const TelegramUrl = Object.freeze({
    telegramToken: '/pim/api/telegram/token/',
    telegramUrl: '/pim/api/telegram/url/',
    telegramQr: '/pim/api/telegram/qr/',
    telegramCommonNotifications: '/pim/api/telegram/common-notifications/',
    telegramNotificationTypes: '/pim/api/telegram/notification_types/',
    telegramNotificationSlug: (slug) =>
        `/pim/api/telegram/notification/${slug}/`,
    telegramAccount: '/pim/api/telegram/account/',
    telegramNotificationMpChange: '/pim/api/telegram/notification-mp-change/',
    telegramSettings: '/pim/api/telegram/telegram-settings/',
    telegramSwitchTimeSet: '/pim/api/telegram/switch-time-set/',
    telegramTimeSet: '/pim/api/telegram/time-set/',
})

export const YmlParserUrl = Object.freeze({
    ymlLink: '/pim/pim-yml-links',
    ymlLinkId: (id) => `/pim/pim-yml-links/${id}`,
    ymlLinkStatusId: (id) => `/pim/pim-yml-links/status/${id}`,
})

export const MpImportUrl = Object.freeze({
    mpImportStatus: '/pim/mp-import/v3/status/',
    mpImportStart: '/pim/mp-import/start/',
    mpImportStop: '/pim/mp-import/stop/',
})

//Не используется
export const MonitoringUrl = Object.freeze({
    addProductByUrl: `/clients/product`,
    productPositions: `/clients/products_positions`,
    addProducts: `/clients/products`,
    deleteProducts: `/clients/products`,
})

export const NotificationsUrl = Object.freeze({
    notifications: '/pim/api/notifications/',
    notificationPrivate: '/pim/api/notifications/',
    notificationView: '/pim/api/notifications/view/',
})

// Продолжить
export const ProductsUrl = Object.freeze({
    products: '/pim/pim-products/products',
    productId: (id) => `/pim/pim-products/products/${id}`,
    productPricesById: (id) => `/pim/pim-products/products/${id}/prices`,
    productPackagesById: (id) => `/pim/pim-products/products/${id}/packages`,
    productVatById: (id) => `/pim/pim-products/products/${id}/vat`,
    productAttributesById: (id) =>
        `/pim/pim-products/products/${id}/card/attributes`,
    dictionary: '/pim/pim-products/dictionary',
    masterDictionary: '/pim/pim-products/master/dictionary',
    feedCats: `/pim/sa-workspace/pim-cats-assgn/feed-cats`,
    exportProducts: `/pim/pim-products/ozon-export/export`,
})

export const CategoriesUrl = Object.freeze({
    feedSource: '/pim/sa-workspace/cats-assgn/feed-source',
    masterCats: '/pim/sa-workspace/cats-assgn/master-cats',
    masterCatsChild: (catId) =>
        `/pim/sa-workspace/cats-assgn/master-cats/${catId}`,
    feedCats: (uuid) => `/pim/sa-workspace/cats-assgn/${uuid}/feed-cats`,
    feedCatsChild: (uuid, catId) =>
        `/pim/sa-workspace/cats-assgn/${uuid}/feed-cats/${catId}`,
    catsAttrs: (uuid, catId) =>
        `/pim/sa-workspace/cats-assgn/${uuid}/cat-attrs/${catId}`,
    getProduct: '/pim/sa-workspace/cats-assgn/products',
    assign: '/pim/sa-workspace/cats-assgn/assign',
})

export const AttrsMatchingUrl = Object.freeze({
    marketplace: '/pim/sa-workspace/attrs-assgn/marketplace',
    marketplaceTopCats: (marketId) =>
        `/pim/sa-workspace/attrs-assgn/marketplace/${marketId}/mp-cats`,
    marketplaceCats: (marketId, catId) =>
        `/pim/sa-workspace/attrs-assgn/marketplace/${marketId}/mp-cats/${catId}`,
    marketplaceAttrDict: (marketId, catId, attrId) =>
        `/pim/sa-workspace/attrs-assgn/marketplace/${marketId}/category/${catId}/attrs/${attrId}/items`,
    masterTopCats: '/pim/sa-workspace/attrs-assgn/master-cats',
    masterCats: (id) => `/pim/sa-workspace/attrs-assgn/master-cats/${id}`,
    masterAttrDict: (id) =>
        `/pim/sa-workspace/attrs-assgn/master-attrs/${id}/items`,
    masterAttrItemLinks: (id) =>
        `/pim/sa-workspace/attrs-assgn/master-items/${id}/links`,
    feeds: '/pim/sa-workspace/attrs-assgn/feed-source',
    feedTopCats: (id) =>
        `/pim/sa-workspace/attrs-assgn/feed-source/${id}/feed-cats`,
    feedCats: (feedId, catId) =>
        `/pim/sa-workspace/attrs-assgn/feed-source/${feedId}/feed-cats/${catId}`,
    feedAttrDict: (feedId, attrId) =>
        `/pim/sa-workspace/attrs-assgn/feed-source/${feedId}/attrs/${attrId}/items`,
    itemsAssign: '/pim/sa-workspace/attrs-assgn/items-assign',
    modifyLinks: '/pim/sa-workspace/attrs-assgn/modify-links',
})

export const CatsMatchingUrl = Object.freeze({
    masterUnlinkedCats:
        '/pim/sa-workspace/mp-master-assgn/master-cats/unlinked-cats',
    masterUnlinkedCatsV2:
        '/pim/sa-workspace/mp-master-assgn/master-cats/unlinked-cats-v2',
    masterUnlinkedHeader:
        '/pim/sa-workspace/mp-master-assgn/master-cats/unlinked-header',
    masterTopCats: '/pim/sa-workspace/mp-master-assgn/master-cats',
    masterCatId: (catId) =>
        `/pim/sa-workspace/mp-master-assgn/master-cats/${catId}`,
    market: '/pim/sa-workspace/mp-master-assgn/marketplace',
    marketTopCats: (marketId) =>
        `/pim/sa-workspace/mp-master-assgn/marketplace/${marketId}/mp-cats`,
    marketCatId: (marketId, catId) =>
        `/pim/sa-workspace/mp-master-assgn/marketplace/${marketId}/mp-cats/${catId}`,
    catsAssign: '/pim/sa-workspace/mp-master-assgn/cats-assign',
})

export const CatalogUrl = Object.freeze({
    catalog: '/wares',
    cardId: (id) => `/wares/show/${id}`,
    cardStats: (id) => `/wares/${id}/stats`,
    batchUpdateTemplateGenerate:
        '/products/batch_update_prices_and_stocks/xlsx/generate',
    batchUpdateTemplate:
        '/products/batch_update_prices_and_stocks/xlsx/download_template',
    batchUpdateTemplateStatus:
        '/products/batch_update_prices_and_stocks/xlsx/last',
    batchUpdateStatus:
        '/products/batch_update_prices_and_stocks/xlsx/batch_update_status',
    batchUpdateResultsList: '/products/batch_update_prices_and_stocks/results',
    batchUpdateFile:
        '/products/batch_update_prices_and_stocks/xlsx/upload_file',
    validationErrors: (resultId) =>
        `/products/batch_update_prices_and_stocks/xlsx/validation_errors/${resultId}`,
})

export const ReviewsUrl = Object.freeze({
    reviews: '/reviews',
    getWbUrl: (productId) =>
        `https://www.wildberries.ru/catalog/${productId}/detail.aspx?targetUrl=SP`,
})

export const OrdersUrl = Object.freeze({
    orders: '/orders',
    order: (orderID) => `/orders/${orderID}`,
    // filteredState: '/orders/all_states',
    //filteredState: '/orders/states2',
    states: '/orders/states',
    run: '/orders/run',
    dwlMarks: '/orders/posting_label',
    mandatoryMarkValidate: '/products/mandatory_mark_validate',
    setMandatoryMarks: '/orders/set_mandatory_marks',
    orderArbitration: (orderId) => `/orders/${orderId}/arbitration`,
    orderTrackingNumber: (orderId) => `/orders/${orderId}/set_track`,
    downloadDocs: (attachmentId) => `/orders/download/${attachmentId}`,
    ordersDocs: '/orders/docs',
    cancelReasons: '/cancel_reasons/1',
    allStates: 'orders/all_states',
    bulkUpdate: '/orders/fbs/bulk_update',
    productFromOrder: '/pim/pim-products/products/info/from-order',
})

export const AnalyticsUrl = Object.freeze({
    abcRevenueData: '/pim/reports/dashboard/abc_revenue/',
    abcReport: '/reports/abc',
    abcReportByProducts: '/reports/abc_by_products',
    commodityReportXls: '/pim/commodity_report/get_xlsx',
    abcReportXls: '/pim/abc_analyze/get_xlsx',
    postCost: (cabinetId, productId) =>
        `/pim/reports/references/cost/${cabinetId}/${productId}`,
    costHistory: (cabinetId, productId) =>
        `/pim/reports/references/cost/${cabinetId}/${productId}`,
    deleteCostHistory: (cabinetId, productId, dateFrom) =>
        `/pim/reports/references/cost/${cabinetId}/${productId}/${dateFrom}`,
    taxHistory: '/pim/reports/references/tax',
    postTax: '/pim/reports/references/tax',
    deleteTaxHistory: (recordId) => `/pim/reports/references/tax/${recordId}`,
    unitProfitThr: '/reports/profit/unit/profit_thr',
    unitByProducts: '/pim/reports/unit_economy/',
    unitByProductsTotal: '/pim/reports/unit_economy_total/',
    unitCard: '/reports/profit/unit',
    costFile: `/pim/reports/references/costs/filling`,
    costCreateStatusFile: (taskUuid) =>
        taskUuid
            ? `/pim/reports/references/costs/filling/create/status/${taskUuid}`
            : `/pim/reports/references/costs/filling/create/status/`,
    costHistoryList: `/pim/reports/references/costs/filling/history`,
    costStatus: (taskUuid) =>
        taskUuid
            ? `/pim/reports/references/costs/filling/status/${taskUuid}`
            : '/pim/reports/references/costs/filling/status/',
    costDownload: (taskUuid) =>
        `/pim/reports/references/costs/filling/download/${taskUuid}`,
})

export const UnitUrl = Object.freeze({
    unitList: '/pim/reports/unit_economy_new/',
    unitListV2: '/pim/reports/unit_economy_new_v2/',
    unitPromoList: '/pim/reports/unit_economy_promo/',
    unitTotal: '/pim/reports/unit_economy_total_new/',
    unitSettingsHistory: '/pim/reports/unit_economy_new_v2/history/',

    unitForecastList: '/pim/api/reports/unit_economy/v2/forecast/',
    unitForecastInfo: '/pim/api/reports/unit_economy/v2/forecast/info/',
})

export const SupplyForecastsUrl = Object.freeze({
    supplyForecast: '/pim/reports/supply_forecast/',
    supplyForecastProducts: '/pim/reports/supply_forecast_products/',
    supplyForecastTotal: 'pim/reports/supply_forecast_total/',
    // supplyForecast: '/pim/reports/supply_forecast_new/',
    supplyForecastSize: '/pim/reports/supply_forecast_sizes_new/',
    // supplyForecastTotal: 'pim/reports/supply_forecast_total_new/',
    supplyForecastOptions: '/pim/reports/supply_forecast/days_options',
    supplyForecastProvideStocks: '/pim/reports/supply_forecast/provide_stocks',
    supplyForecastActualDays: '/pim/reports/supply_forecast/actual_days',
    supplyForecastXls: '/pim/reports/supply_forecast/get_xlsx',
})

export const RegionalSupplyUrl = Object.freeze({
    regionalSupplyTotal: '/pim/reports/regional_supply_total/',
    regionalSupply: '/pim/reports/regional_supply/',
    regionalSupplyProducts: '/pim/reports/regional_supply_by_cabinet/',
    regionalSupplyBySize: '/pim/reports/regional_supply_by_size/',
    regionalSupplyTotalV3: '/pim/reports/regional_supply/v3/total/',
    regionalSupplyByCabinetV3: '/pim/reports/regional_supply/v3/by_cabinet/',
    regionalSupplyBySizeV3: '/pim/reports/regional_supply/v3/by_size/',
})

export const WarehousesForecastsUrl = Object.freeze({
    warehouses: '/marketplace_warehouses',
    warehousesParse: '/marketplace_warehouses/parse',
    warehouse: '/marketplace_warehouse',
})

export const InformerUrl = Object.freeze({
    informerData: (randomId) => `/api/data.json?${randomId}`,
})

export const UrlDisabledUrl = Object.freeze({
    urlDisabledData: (randomId) => `/api/urlDisabled.json?${randomId}`,
})

export const AdminUrl = Object.freeze({
    statisticsReviews: '/scrapper_statistic/product_reviews',
    statisticsMonitoring: '/scrapper_statistic/product_positions',
    users: (page) => `/api/users/${page}/`,
    usersLogin: (page) => `/api/users/login/${page}/`,
    usersLogout: '/api/users/logout/',
    setTariff: '/pim/api/tariff/subscription/set-tariff/',
    usersCreate: '/api/users/user/',
    mpAccount: '/api/users/mp_accounts/',
    tariffs: '/api/users/tariffs/',
    subscribeToDemo: '/pim/api/marketplace/account/subscribe_to_demo/',
    emailConfirm: '/api/users/set_email_confirmed/',
    phoneConfirm: '/api/users/set_phone_confirmed/',
    deleteCostsConfirm: (uuid) => `/pim/reports/costs/${uuid}`,
    managers: '/api/users/managers/',
    userEdit: (uuid) => `/api/users/user/${uuid}/`,
})

export const ProfileUrl = Object.freeze({
    profile: '/api/users/profile/',
    profileAvatar: '/api/users/profile/avatar/',
    profileConfirmChangePhone: '/api/users/confirm_change_phone/',
    profileConfirmUser: (userUuid) => `/api/users/confirm_user/${userUuid}/`,
    profileConfirmChangeEmail: (token) =>
        `/api/users/confirm_change_email/${token}/`,
})

export const WidgetUrl = Object.freeze({
    marginProfitExpenses: '/pim/reports/dashboard/margin_profit_expenses/',
})

export const ReportsStatusUrl = Object.freeze({
    fileInfo: '/file/info/',
    fileInfoUpload: '/file/info_upload/',
    deletePromoItem: (uuid) => `/pim/reports/promo/${uuid}/`,
    fileList: '/file/list/',
    getFile: '/file/get_xlsx/',

    marginXlsx: '/pim/reports/margin_new_xlsx/',
    abcXlsx: '/pim/reports/abc_analyze_new_xlsx/',
    commodityXlsx: '/pim/reports/commodity_new_xlsx/',
    unitXlsx: '/pim/reports/unit_economy_new_xlsx/',
    unitV2Xlsx: '/pim/reports/unit_economy_new_v2_xlsx/',
    forecastXlsx: 'pim/api/reports/unit_economy/v2/xlsx/forecast/ ',
    advXlsx: '/pim/reports/advert/v2/xlsx/',
    unitRegionalStocksXlsx: '/pim/reports/regional_stocks_xlsx/',
    unitPromoXlsx: '/pim/reports/unit_economy_promo_xlsx/',
    supplyForecastXlsx: '/pim/reports/supply_forecast_new_xlsx/',
    byProductsXlsx: '/pim/reports/get_sales_region_wh_group_by_product_xlsx/',
    supplyForecastRegionalXlsx: '/pim/reports/regional_supply_xlsx/',
    supplyForecastRegionalV3Xlsx: '/pim/reports/regional_supply/v3/xlsx/',
    costXlsx: '/pim/reports/costs/cost_xlsx/',
    activeXlsx: '/pim/reports/active/active_xlsx/',
    promoXlsx: '/pim/reports/promo/promo_xlsx/',
    marginCompareXlsx: '/pim/api/reports/margin/v2/xlsx/margin_comparison/',
    cohortXlsx: '/pim/api/reports/cohort/xlsx/list/',
    abcCompareXlsx: '/pim/api/reports/abc_analyze/v2/xlsx/compare/',
})
export const ProductsSettingsUrl = Object.freeze({
    productsList: 'pim/reports/product_info/v2/list-products/',
    setActivityProduct: 'pim/reports/product_info/set-activity/',
})
export const ProductInfoUrl = Object.freeze({
    productInfoCard: '/pim/reports/product_info/card_new/',
    productInfoAbc: '/pim/reports/product_info/abc_profit_new/',
    productInfoWidgets: '/pim/reports/product_info/widgets_new/',
    productInfoStockSalesBalance:
        '/pim/reports/product_info/stock_sales_balance_new/',
    productInfoUnitEconomy: '/pim/reports/product_info/unit_economy_new/',
    productInfoMarginExpenses: '/pim/reports/product_info/margin_expenses_new/',
    productInfoMarginGraph: '/pim/reports/product_info/margin_graph_new/',
    productInfoTransactions: '/pim/reports/product_info/transactions_new/',
})

export const RegionalStocksUrl = Object.freeze({
    regionalStocks: '/pim/reports/regional_stocks/',
    regionalStocksTotal: '/pim/reports/regional_stocks_total/',
    regionalStocksForSize: '/pim/reports/regional_stocks_for_size/',
})

export const RepostSettingsUrl = Object.freeze({
    settingsTypeReports: '/pim/reports/settings/type_reports/',
    settingsProfitability: '/pim/reports/settings/profitability/',
    settingsTax: '/pim/reports/settings/tax/',
    settingsActiveProduct: '/pim/reports/settings/active_product/',
})

export const OnboardingUrl = Object.freeze({
    needAudit: '/api/users/need_audit/',
    onboardCurrentStep: '/api/onboard/current/',
    onboardSetStep: '/api/onboard/set/',
})

export const SalesRegionByProductUrl = Object.freeze({
    salesByProducts: '/pim/reports/get_sales_region_wh_group_by_product/',
    salesProductTotalsByRegion: '/pim/reports/totals_regional_sales_by_region/',
    salesProductTotalsByGroup: '/pim/reports/totals_regional_sales_by_group/',
    macroRegion: '/pim/reports/get_macro_regions/',
})

export const AbcUrl = Object.freeze({
    abcAnalyze: '/pim/reports/abc_analyze_new/',
    abcAnalyzeTotal: '/pim/reports/abc_analyze_total_new/',
    brandList: '/pim/reports/brand_list/',
    categoryList: '/pim/reports/category_list/',
    compareFilters: '/pim/api/reports/abc_analyze/v2/filters/',
    compareList: '/pim/api/reports/abc_analyze/v2/compare/',
})

export const FileSharingUrl = Object.freeze({
    downloadFile: '/file_sharing/get_file/',
    fileListUser: '/file_sharing/get_files_list_user/',
    fileListAdmin: '/file_sharing/get_files_list_admin/',
    uploadFileS3: '/file_sharing/upload_files_to_s3/',
    addFiles: '/file_sharing/add_files/',
    editFile: '/file_sharing/edit_file/',
    deleteFile: '/file_sharing/delete_file/',
})

export const SalesRegionRecommendedStocksUrl = Object.freeze({
    whGroupByRecGroup: '/pim/reports/get_sales_region_wh_group_by_rec_group/',
    recWhGroups: '/pim/reports/get_rec_wh_groups/',
})

export const SalesRegionByRegionUrl = Object.freeze({
    totalsRegionalSales: '/pim/reports/totals_regional_sales_new/',
    salesRegionalTotalsByRegion:
        '/pim/api/reports/regional/v2/by_region/totals/',
    salesRegionalTotalsByGroup: '/pim/api/reports/regional/v2/by_group/totals/',
    salesAllRegions: '/pim/reports/get_sales_for_all_regions_new/',
    salesRegionWhGroup: '/pim/reports/get_sales_region_wh_group/',
    salesByCabinet: '/pim/reports/get_sales_by_cabinet_new/',
    salesByRegion: '/pim/api/reports/regional/v2/by_region/',
    salesByGroup: '/pim/api/reports/regional/v2/by_group/',
})

export const AdminDataImportUrl = Object.freeze({
    listImportsByUser: '/pim/api/mp_import_v2/list_imports_by_user/',
    listDetailedImports: '/pim/api/mp_import_v2/list_detailed_imports_by_job/',
    createImportJob: '/pim/api/mp_import_v2/create_import_job/',
})

export const DashboardUrl = Object.freeze({
    resupplyData: '/pim/reports/dashboard/resupply/',
    chartData: '/pim/reports/dashboard/db_orders/',
    cardsData: '/pim/reports/dashboard/db_stats/',
    profitabilityData: '/pim/reports/dashboard/profitability/',
    stockTurnoverData: '/pim/reports/dashboard/stock_turnover/',
    abcRevenueData: '/pim/reports/dashboard/abc_revenue/',
    stockBalanceData: '/pim/reports/dashboard/stock_balance/',
    productsNoCostAndTgData: '/pim/reports/dashboard/no_cost_and_tg/',
})

export const CommodityUrl = Object.freeze({
    commodityReport: '/pim/reports/commodity/',
})

export const MarginProfitUrl = Object.freeze({
    marginProfit: '/pim/reports/margin/',
    marginProfitTotal: '/pim/reports/margin_total/',
    marginProfitTransactions: `/pim/reports/margin_transactions/`,
    marginProfitCompare: `pim/api/reports/margin/v2/margin_comparison/`,
    marginGpt: '/pim/api/reports/margin/gpt/',
})

export const AdvUrl = Object.freeze({
    advStatistics: '/pim/reports/advert/v2/statistics/',
    advTotals: '/pim/reports/advert/v2/total/',
})

export const FinanceUrl = Object.freeze({
    standard: '/pim/reports/financial/',
    standardTotal: '/pim/reports/financial_total/',
    comparison: '/pim/reports/financial/v2/compare/',
    dynamics: '/pim/reports/financial/v2/dynamics/ ',
})

export const TaxUrl = Object.freeze({
    taxDict: '/pim/api/reports/tax/dict/',
    tax: (id) => `/pim/api/reports/tax/${id}/`,
})

export const ExpenseSellerUrl = Object.freeze({
    expenseDict: '/pim/api/reports/expense/dict/',
    expense: (id) => `/pim/api/reports/expense/${id}/`,
})

export const ImportWbUrl = Object.freeze({
    wbDetailed: '/pim/api/reports/wb_detailed/',
    wbDetailedSource: '/pim/api/reports/wb_detailed/source/',
    wbDetailedRecalc: '/pim/api/reports/wb_detailed/recalc/',
    wbDetailedList: '/pim/api/reports/wb_detailed/list/',
})

export const CohortUrl = Object.freeze({
    cohortList: '/pim/api/reports/cohort/list/',
    cohortTotal: '/pim/api/reports/cohort/total/',
})
