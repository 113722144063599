//libs
import React from 'react'

//components
import {Badge} from '@components/elements'

//icons
import {XCircleIcon} from '@heroicons/react/outline'

const Tag = ({children, onCancel, ...rest}) => {
    return (
        <>
            <Badge {...rest}>
                {children}
                <XCircleIcon
                    className={'w-4 h-4 cursor-pointer ml-2 -mr-1'}
                    onClick={onCancel}
                />
            </Badge>
        </>
    )
}

export {Tag}
