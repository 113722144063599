import {observer} from 'mobx-react'
import {useSelector} from '@hooks'
import {EmptyDataStub, NoCredentials} from '@components/applicationUi'
import React, {useEffect, useState} from 'react'
import {
    InformerBlock,
    InformerBlockDemo,
} from '@components/applicationUi/InformerBlock'
import {ContainerFullOnMobile} from '@components/layout'
import slide2Dark from '@assets/auth/images/slide-2-dark.png'
import slide2Light from '@assets/auth/images/slide-2-light.png'
import {ImportWbFilterStore} from '@views/marketplaces/pages/ImportWb/store/ImportWbFilterStore'
import {ozonFilter, wbFilter} from '@utils/helpers'
import {ImportWbInfo} from '@views/marketplaces/pages/ImportWb/components/ImportWbInfo'
import {Overlay} from '@components/overlays'
import {ImportWbOverlayStore} from '@views/marketplaces/pages/ImportWb/store/ImportWbOverlayStore'
import {ImportWbOverlay} from '@views/marketplaces/pages/ImportWb/components/ImportWbOverlay'
import {ImpostWbStore} from '@views/marketplaces/pages/ImportWb/store/ImpostWbStore'
import {ImportWbTable} from '@views/marketplaces/pages/ImportWb/components/ImportWbTable'
import {ImportWbStatusesStore} from '@views/marketplaces/pages/ImportWb/store/ImportWbStatusesStore'
import {ErrorLegalModal} from '@views/marketplaces/pages/ImportWb/components/ErrorLegalModal'
import {SuccessLegalModal} from '@views/marketplaces/pages/ImportWb/components/SuccessLegalModal'
import {toast} from 'react-toastify'
import {ErrorZipContent} from '@views/marketplaces/pages/ImportWb/components/ErrorZipContent'
import {ErrorUnknownZipContent} from '@views/marketplaces/pages/ImportWb/components/ErrorUnknownZipContent'

export const ImportWb = observer(() => {
    const {isDarkTheme} = useSelector((store) => store.themeStore)

    const marketStore = useSelector((store) => store.marketStore)
    const userStore = useSelector((store) => store.userStore)

    const [importWbOverlayStore] = useState(() => new ImportWbOverlayStore())
    const [importWbFilterStore] = useState(
        () => new ImportWbFilterStore(marketStore)
    )
    const [impostWbStore] = useState(
        () => new ImpostWbStore(importWbFilterStore)
    )

    const [importWbStatusesStore] = useState(
        () =>
            new ImportWbStatusesStore(
                importWbFilterStore,
                () => impostWbStore.importWbListPolling.startPolling(),
                () => userStore.requestUserData()
            )
    )

    useEffect(() => {
        const cabinet = marketStore.credentialsOptions.filter(wbFilter)[0] || {}
        importWbFilterStore.cabinet.setSelectedItem(cabinet)
        if (cabinet.key) {
            importWbFilterStore.initialPriorityData(cabinet.key)
        }
        return () => {
            importWbFilterStore.destroy()
            impostWbStore.destroy()
            importWbStatusesStore.destroy()
        }
    }, [])

    const handleCheckStatuses = (
        cabinet,
        isConnectedLegal = false,
        legalName
    ) => {
        if (!importWbStatusesStore.isConnectedLegal) {
            importWbStatusesStore.setIsConnectedLegal(isConnectedLegal)
            if (isConnectedLegal && legalName)
                importWbStatusesStore.setLegalOptions({
                    legalName,
                    cabinet,
                })
        }
        importWbStatusesStore.importStatusesListingPolling.startPolling(cabinet)
    }

    useEffect(() => {
        if (importWbStatusesStore.errorZipImport.result) {
            toast(
                <ErrorZipContent
                    documentList={
                        importWbStatusesStore.errorZipImport.documentList
                    }
                />,
                {
                    autoClose: false,
                    draggable: true,
                    type: 'warning',
                }
            )
        }
    }, [importWbStatusesStore.errorZipImport])

    useEffect(() => {
        if (importWbStatusesStore.errorUnknownZipImport.result) {
            const errorData = importWbStatusesStore.errorUnknownZipImport
            toast(
                <ErrorUnknownZipContent
                    unknownList={errorData.errorList}
                    cabinetName={errorData.cabinetName}
                />,
                {
                    autoClose: false,
                    draggable: true,
                    type: 'warning',
                }
            )
        }
    }, [importWbStatusesStore.errorUnknownZipImport])

    const handleSuccessApply = () => {
        importWbStatusesStore.resetLegalOptions()
    }
    return (
        <>
            {!marketStore.credentials?.length ? (
                <NoCredentials
                    className={'py-8 overflow-y-auto'}
                    title={'Доступно только для кабинетов WB'}
                />
            ) : (
                <>
                    <ContainerFullOnMobile className='py-6 space-y-6'>
                        <InformerBlock />
                        <InformerBlockDemo />
                        <ImportWbInfo
                            filterStore={importWbFilterStore}
                            impostWbStore={impostWbStore}
                            importWbOverlayStore={importWbOverlayStore}
                            importWbStatusesStore={importWbStatusesStore}
                        />
                    </ContainerFullOnMobile>
                    {!importWbFilterStore.cabinet.selected?.key ? (
                        <EmptyDataStub
                            title={
                                'Загрузка данных доступна только для кабинетов Wilberries'
                            }
                            description={''}
                            className={'sm:pl-6 lg:pl-8'}
                            img={isDarkTheme ? slide2Dark : slide2Light}
                        />
                    ) : (
                        <ImportWbTable importWbStore={impostWbStore} />
                    )}
                    <Overlay
                        open={importWbOverlayStore.overlayControl.isOpen}
                        setOpen={importWbOverlayStore.overlayControl.close}
                        title={'Загрузка отчетов Wildberries'}
                        size={'full'}
                    >
                        <ImportWbOverlay
                            onCheckStatuses={handleCheckStatuses}
                            importWbOverlayStore={importWbOverlayStore}
                            cabinet={importWbFilterStore.cabinet.selected}
                        />
                    </Overlay>
                    <ErrorLegalModal
                        open={
                            importWbStatusesStore.errorLegalModalControl.isOpen
                        }
                        onClose={
                            importWbStatusesStore.errorLegalModalControl.close
                        }
                        onOpenImportOverlay={
                            !importWbOverlayStore.overlayControl.isOpen &&
                            importWbOverlayStore.overlayControl.open
                        }
                        legalList={
                            importWbStatusesStore.errorLegalXlsImport.legalList
                        }
                        cabinetName={
                            importWbStatusesStore.errorLegalXlsImport
                                .cabinetName
                        }
                        isConnectedLegal={importWbFilterStore.validLegalEntity}
                    />
                    <SuccessLegalModal
                        open={
                            importWbStatusesStore.successLegalModalControl
                                .isOpen
                        }
                        onClose={() => {
                            importWbStatusesStore.successLegalModalControl.close()
                            userStore.requestUserData()
                        }}
                        legalOptions={importWbStatusesStore.legalOptions}
                        onApply={handleSuccessApply}
                    />
                </>
            )}
        </>
    )
})

ImportWb.displayName = 'ImportWb'
