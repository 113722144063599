import React, {useMemo} from 'react'
import {
    Locked,
    LockedWrapperWithoutIcon,
    MarketIcon,
    Mdash,
    TooltipLite,
} from '@components/elements'
import {NameCell, SkuDropdown, TableCostField} from '@components/applicationUi'
import {ChevronDownIcon} from '@heroicons/react/outline'
import {TableHeaderCell} from '@components/applicationUi/Lists/TableHeaderCell'
import {RateField} from '@components/applicationUi/RateField'
import {SIGNS_KEY} from '@components/elements/Amount/convertAmount'
import classNames from 'classnames'
import {COST_AVAILABILITY} from '@utils/constants'
import {format, isValid} from 'date-fns'
import {ImportWbExtensionItem} from '@views/marketplaces/pages/ImportWb/components/ImportWbExtensionItem'
import {ru} from 'date-fns/locale'
import {IMPORT_WB_HEADER_CELL_MAP} from '@views/marketplaces/pages/ImportWb/constants'
import {ImportWbDateCreated} from '@views/marketplaces/pages/ImportWb/components/ImportWbDateCreated'

export const useImportWbTableColumns = () => {
    return useMemo(
        () => [
            {
                accessor: 'ids',
                Header: '№ отчета',
                Cell: ({row}) => {
                    const isExpanded =
                        !!row.original.subRows?.length &&
                        row.original.subRows?.length > 1
                    const rootProps = row.getToggleRowExpandedProps({
                        title: '',
                        style: {
                            // We can even use the row.depth property
                            // and paddingLeft to indicate the depth
                            // of the row
                            paddingLeft: `${row.depth * 2}rem`,
                        },
                    })
                    return (
                        <NameCell
                            className={'flex items-center justify-start'}
                            rootProps={isExpanded ? rootProps : undefined}
                            name={row.values.ids.join(', ')}
                        >
                            {isExpanded ? (
                                <span className={'flex items-center'}>
                                    <ChevronDownIcon
                                        className={`h-4 w-4 ml-2 cursor-pointer transition-all ${
                                            row.isExpanded
                                                ? 'rotate-180'
                                                : 'text-blue-500'
                                        }`}
                                    />
                                </span>
                            ) : null}
                        </NameCell>
                    )
                },
            },
            {
                accessor: 'dateStart',
                Header: 'Дата начала и окончания',
                Cell: ({row}) => {
                    const dataStart = new Date(row.values.dateStart)
                    const dataEnd = new Date(row.original.dateEnd)
                    return (
                        <div className={'flex flex-col'}>
                            <span>{format(dataStart, 'yyyy-MM-dd')}</span>
                            <span>{format(dataEnd, 'yyyy-MM-dd')}</span>
                        </div>
                    )
                },
            },
            {
                accessor: 'dataExisting',
                Header: () => (
                    <TableHeaderCell
                        {...IMPORT_WB_HEADER_CELL_MAP.dataExisting}
                    />
                ),
                cls: 'w-full',
                cellCls: 'w-full',
                Cell: ({row}) => {
                    const {
                        detailedAmount,
                        detailedCommission,
                        marginAmount,
                        marginCommission,
                    } = row.original
                    return (
                        <div className={'flex flex-col'}>
                            {Object.entries(row.values.dataExisting).map(
                                ([name, color]) => {
                                    return (
                                        <ImportWbExtensionItem
                                            key={name}
                                            name={name}
                                            color={color}
                                            options={{
                                                detailedAmount,
                                                detailedCommission,
                                                marginAmount,
                                                marginCommission,
                                            }}
                                        />
                                    )
                                }
                            )}
                        </div>
                    )
                },
            },
            {
                accessor: 'dateCreated',
                Header: 'Дата формиров.',
                Cell: ({row}) => {
                    const isExpanded = !!row.original.subRows?.length
                    const dateCreated = row.values.dateCreated
                    return (
                        <div className={'flex flex-col'}>
                            {dateCreated.map((date, idx) => {
                                return (
                                    <ImportWbDateCreated
                                        key={idx}
                                        date={date}
                                        isExpanded={isExpanded}
                                    />
                                )
                            })}
                        </div>
                    )
                },
            },
            {
                accessor: 'sale',
                cls: 'text-right',
                cellCls: 'text-right',
                Header: 'Продажа',
                Cell: ({row}) => {
                    return <RateField value={row.values.sale} />
                },
            },
            {
                accessor: 'forPay',
                cls: 'text-right',
                cellCls: 'text-right',
                Header: 'К перечисл. за товар',
                Cell: ({row}) => {
                    return <RateField value={row.values.forPay} />
                },
            },
            {
                accessor: 'logistic',
                cls: 'text-right',
                cellCls: 'text-right',
                Header: 'Стоимость логистики',
                Cell: ({row}) => {
                    return <RateField value={row.values.logistic} />
                },
            },
            {
                accessor: 'penalty',
                cls: 'text-right',
                cellCls: 'text-right',
                Header: 'Общая сумма штрафов',
                Cell: ({row}) => {
                    return <RateField value={row.values.penalty} />
                },
            },
            {
                accessor: 'additionalPayment',
                cls: 'text-right',
                cellCls: 'text-right',
                Header: 'Доплаты',
                Cell: ({row}) => {
                    return <RateField value={row.values.additionalPayment} />
                },
            },
            {
                accessor: 'storage',
                cls: 'text-right',
                cellCls: 'text-right',
                Header: 'Стоимость хранения',
                Cell: ({row}) => {
                    return <RateField value={row.values.storage} />
                },
            },
            {
                accessor: 'paidAcceptance',
                cls: 'text-right',
                cellCls: 'text-right',
                Header: 'Стоимость платной приемки',
                Cell: ({row}) => {
                    return <RateField value={row.values.paidAcceptance} />
                },
            },
            {
                accessor: 'other',
                cls: 'text-right',
                cellCls: 'text-right',
                Header: 'Прочие удержания',
                Cell: ({row}) => {
                    return <RateField value={row.values.other} />
                },
            },
            {
                accessor: 'totalForPay',
                cls: 'text-right',
                cellCls: 'text-right',
                Header: 'Итого к оплате',
                Cell: ({row}) => {
                    return <RateField value={row.values.totalForPay} />
                },
            },
        ],
        []
    )
}
