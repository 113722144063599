import {RateField} from '@components/applicationUi/RateField'
import {PartCostTooltip} from '@components/applicationUi'
import classNames from 'classnames'
import {COST_AVAILABILITY} from '@utils/constants'
import {TableWarnTooltipWrapper} from '@components/applicationUi/TableWarnTooltipWrapper'

export const TableCostField = ({row, className, cost, onChangeCost}) => {
    if (row.original.hasCost === COST_AVAILABILITY.noCost) {
        return (
            <div
                className={classNames(
                    'text-sm whitespace-normal text-yellow-500 dark:text-yellow-300 hover:opacity-75',
                    {
                        'cursor-pointer': onChangeCost,
                    }
                )}
                onClick={onChangeCost && onChangeCost(row)}
            >
                Внесите себест.
            </div>
        )
    }

    if (row.original.hasCost === COST_AVAILABILITY.partCost) {
        return (
            <TableWarnTooltipWrapper
                content={'За выбранный период себестоимость внесена частично'}
            >
                <RateField
                    className={classNames(
                        'text-yellow-500 dark:text-yellow-300',
                        {
                            'cursor-pointer': onChangeCost,
                        }
                    )}
                    onClick={onChangeCost && onChangeCost(row)}
                    {...cost}
                />
            </TableWarnTooltipWrapper>
        )
    }
    return (
        <RateField
            className={classNames(className, {
                'cursor-pointer': onChangeCost,
            })}
            {...cost}
            onClick={onChangeCost && onChangeCost(row)}
        />
    )
}
