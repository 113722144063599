//modules
import FeedAttrsStore from '../Linked/Feed/store/FeedAttrsStore'
import MarketAttrsStore from '../Linked/Markets/store/MarketAttrsStore'
import MasterAttrsStore from '../Master/store/MasterAttrsStore'

class AttrsMatchingStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        this.stores = {
            feedAttrsStore: new FeedAttrsStore(rootStore, this),
            marketAttrsStore: new MarketAttrsStore(rootStore, this),
            masterAttrsStore: new MasterAttrsStore(rootStore, this),
        }
    }
}

export default AttrsMatchingStore
