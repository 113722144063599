import {NameBetta} from '@components/elements/NameBetta'

export const marketplacesHeaderTabs = [
    {
        id: 'connected',
        name: 'Подключения',
        disabled: false,
        visible: true,
    },
    {
        id: 'import_wb',
        name: 'Импорт отчетов WB',
        disabled: false,
        visible: true,
    },
]

export const priorityData = [
    {
        id: 'api',
        name: 'API Wildberries',
    },
    {
        id: 'xls',
        name: 'Фин. отчеты Excel',
    },
]
