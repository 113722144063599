//stores
import {YmlFeedStore} from '../YmlFeed/store/YmlFeedStore'
import {ImportMarketplacesStore} from '@views/products/Import/ImportMarketplaces/store/ImportMarketplacesStore'

export class ProductsImportStore {
    constructor(rootStore) {
        this.rootStore = rootStore
        this.stores = {
            ymlFeedStore: new YmlFeedStore(rootStore),
            importMarketplacesStore: new ImportMarketplacesStore(rootStore),
        }
    }

    tabs = [
        {
            id: 'marketplaces',
            name: 'Через маркетплейс',
            active: true,
            visible: true,
        },
        {
            id: 'yml',
            name: 'Через YML-фид',
            active: false,
            visible: false,
        },
    ]
}
