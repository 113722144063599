import React, {useState} from 'react'
import {Button} from '@components/elements'
import {DocumentTextIcon, TrashIcon} from '@heroicons/react/outline'
import {ModalConfirm} from '@components/overlays'
import {ModalControl} from '@store/ModalControl'
import {observer} from 'mobx-react'

export const FileImport = observer(
    ({file, onDelete, onUpload, checkSelectedDemoAcc = () => {}}) => {
        const [modalControl] = useState(() => new ModalControl())

        const handleClickImport = () => {
            if (checkSelectedDemoAcc()) {
                return modalControl.open()
            }
            onUpload()
        }

        return (
            <>
                <div className={'flex flex-col mb-10'}>
                    <h3
                        className={
                            'font-medium text-gray-700 dark:text-gray-300 mb-4'
                        }
                    >
                        Ваш файл для импорта
                    </h3>
                    <div className={'flex mb-4'}>
                        <DocumentTextIcon
                            className={
                                'w-5 h-5 text-blue-500 dark:text-blue-300 mr-3'
                            }
                        />
                        <span className={'text-blue-500 dark:text-blue-300'}>
                            {file.fileName}
                        </span>
                        <TrashIcon
                            className={
                                'w-5 h-5 text-red-500 dark:text-red-400 ml-4 cursor-pointer hover:opacity-80'
                            }
                            onClick={onDelete}
                        />
                    </div>
                    <div className={'flex items-center'}>
                        <Button onClick={handleClickImport}>
                            Импортировать
                        </Button>
                        <Button
                            className={'ml-4'}
                            color={'white'}
                            onClick={onDelete}
                        >
                            Очистить
                        </Button>
                    </div>
                </div>
                <ModalConfirm
                    open={modalControl.isOpen}
                    onClose={modalControl.close}
                    title={
                        'К сожалению, вы не можете обновлять себестоимость для демо-товаров. Загрузите себестоимость для товаров из ваших кабинетов'
                    }
                    acceptDisabled
                    accept={'Сохранить'}
                    onAccept={() => {}}
                />
            </>
        )
    }
)
