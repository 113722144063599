import {useEffect} from 'react'
const keyMap = {
    Esc: 27,
    Escape: 27,
}
export const useKeypress = (key, handler, connected = true) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.keyCode === keyMap[key]) {
                handler(event)
            }
        }
        if (connected) {
            window.addEventListener('keydown', handleKeyDown)
        }
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        }
    }, [key, connected])
}
