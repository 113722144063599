import {Button} from '@components/elements'
import {useEffect, useState} from 'react'

export const ConfirmCookies = ({children}) => {
    const [isConfirmPolicy, setIsConfirmPolicy] = useState(true)
    useEffect(() => {
        const isConfirm = JSON.parse(localStorage.getItem('confirm_policy'))
        if (!isConfirm) {
            setIsConfirmPolicy(false)
        }
    }, [])

    const handleClickPolicy = () => {
        localStorage.setItem('confirm_policy', JSON.stringify(true))
        setIsConfirmPolicy(true)
    }

    return (
        <>
            {children}
            {!isConfirmPolicy && (
                <div className='fixed z-[9999] bottom-4 left-1/2 transform -translate-x-1/2 max-w-xl w-full px-4'>
                    <div
                        className={
                            'flex p-4  rounded-md border-2 border-gray-300 dark:border-gray-600 bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-200'
                        }
                    >
                        <div
                            className={
                                'flex flex-col sm:flex-row justify-between items-center gap-4'
                            }
                        >
                            <p className={'w-full text-sm md:text-base'}>
                                Мы используем cookies для корректной работы
                                сайта в соответствии с{' '}
                                <a
                                    href={'https://seller24.ru/policy'}
                                    target={'_blank'}
                                    className={'underline cursor-pointer'}
                                >
                                    политикой
                                </a>
                            </p>
                            <Button
                                color={'white'}
                                className={'w-full sm:w-auto'}
                                onClick={handleClickPolicy}
                            >
                                Принять
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
